import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../../Wizard/Switch';

function SearchSwitch(props) {
  return <Switch selected={getSearchResultsState(props)}>{props.children}</Switch>;
}

export function getSearchResultsState(state) {
  const { searchedClaims, isLoaded, isSearchPending } = state;

  if (isSearchPending) {
    return 'search-loader';
  }

  if (searchedClaims && searchedClaims.length !== 0) {
    return 'search-results';
  }

  if (searchedClaims.length === 0 && isLoaded) {
    return 'search-no-results';
  }

  return 'search-initial';
}

SearchSwitch.propTypes = {
  searchedClaims: PropTypes.arrayOf(PropTypes.shape({})),
};

export default React.memo(SearchSwitch);
