import container from '../container';

export function publishTrackingPageAction(trackingPageId, force = false) {
  return container.api.publishTrackingPage(trackingPageId, force)
    .then(() => ({ success: true }))
    .catch(error => {
        if (error.response.status === 409) {
          return { conflict: true };
        }
        return { error };
      }
    )
}