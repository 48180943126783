import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../Translate/Locale';
import ExternalLink from '../common/ExternalLink';

import './HelpCard.scss';

class HelpCard extends React.PureComponent {
  render() {
    const {
      title,
      icon: Icon,
      description,
      linkText,
      linkUrl,
      onLinkClick,
    } = this.props;

    return (
      <div styleName="card">
        { Icon && <Icon styleName="icon" /> }
        <div styleName="content">
          <h4 styleName="title"><Locale>{title}</Locale></h4>
          <span styleName="description"><Locale>{description}</Locale></span>
          <ExternalLink href={linkUrl} onClick={onLinkClick}>{linkText}</ExternalLink>
        </div>
      </div>
    );
  }
}

HelpCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  description: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default HelpCard;