import React from 'react';

import Locale from '../../../Translate/Locale';
import Text from '../../../common/Text/Text';

import './TypeButtonWarningMessage.scss';

function TypeButtonWarningMessage({ children }) {
  return (
    <div styleName="container">
      <Text small styleName="message">
        <Locale>{children}</Locale>
      </Text>
    </div>
  );
}

export default TypeButtonWarningMessage;
