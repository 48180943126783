import React from 'react';

import './ModalBasicFooter.scss';

function ModalBasicFooter(props) {
  const { children } = props;
  return <div styleName="footer">{children}</div>;
}

export default React.memo(ModalBasicFooter);
