import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common2/Modal';
import ModalHeader from '../../common2/Modal/ModalHeader';
import ModalContent from '../../common2/Modal/ModalContent';
import ModalFooter from '../../common2/Modal/ModalFooter';
import Button from '../../common2/Button/Button';
import DeleteIcon from '../../../icons/delete.svg';
import Locale from '../../Translate/Locale';
import './ConfirmTrackingPageDeleteModal.scss';

function ConfirmTrackingPageDeleteModal(props) {
  const { removingPage = {}, onCancel, onDelete } = props;

  return (
    <Modal show={true} onClose={onCancel}>
      <ModalHeader icon={DeleteIcon} intent="danger">
        Delete tracking page
      </ModalHeader>
      <ModalContent>
        <p>
          <Locale>Are you sure you want to delete the tracking page </Locale>
          <span styleName="page-title">
            "<Locale>{removingPage?.name}</Locale>"
          </span>
        </p>
        <p>
          <Locale>After deletion, all links to this tracking page will stop working.</Locale>
        </p>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button intent="danger" onClick={onDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmTrackingPageDeleteModal.propTypes = {
  removingPage: PropTypes.shape({
    name: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default React.memo(ConfirmTrackingPageDeleteModal);
