import { createSelector } from '@reduxjs/toolkit';

export const selectParcelFinderReturns = (state) => state.parcelFinderReturns;

export const getReturningOrderId = createSelector([selectParcelFinderReturns], (state) => {
  return state.order?.id;
});

export const getReturnInfo = createSelector([selectParcelFinderReturns], (parcelFinderResults) => {
  if (!parcelFinderResults) {
    return {};
  }

  const { order } = parcelFinderResults;

  const outboundShipment = order?.shipments.find((shipment) => !shipment.isReturnShipment);

  if (!outboundShipment) {
    return {};
  }

  return {
    shopOrderId: order.shopOrderId,
    deliveryDate: outboundShipment.deliveryDate,
    carrier: outboundShipment.carrier,
    customer: outboundShipment.customer,
    returnShipmentsLeft: order.returnShipmentsLeft,
  };
});
