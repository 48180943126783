import React from 'react';
import ParcelFinderContainer from '../parcel-finder/ParcelFinderContainer';
import CenterLayout from '../common2/CenterLayout';
import Locale from '../Translate/Locale';
import Heading1 from '../common/Heading1';
import Text from '../common/Text';

function ClaimsCenterMaintenancePage(props) {
  return (
    <ParcelFinderContainer {...props}>
      <CenterLayout>
        <Heading1>CLAIMS_CENTER_MAINTENANCE_HEADING</Heading1>
        <Text>
          <Locale>CLAIMS_CENTER_MAINTENANCE_MESSAGE</Locale>
        </Text>
      </CenterLayout>
    </ParcelFinderContainer>
  );
}

export default ClaimsCenterMaintenancePage;
