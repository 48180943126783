import React from 'react';

export const SearchTypeEnum = {
  TRACKING_ORDERID: 'tracking_or_order_id',
  RECIPIENT_EMAIL: 'recipient_email',
  RECIPIENT_NAME: 'recipient_name',
};

const searchTypes = [
  {
    id: SearchTypeEnum.TRACKING_ORDERID,
    title: 'Tracking/Order ID',
  },
  {
    id: SearchTypeEnum.RECIPIENT_EMAIL,
    title: 'Email address',
  },
  {
    id: SearchTypeEnum.RECIPIENT_NAME,
    title: 'Full name',
  },
];

export const inputPlaceholders = {
  [SearchTypeEnum.TRACKING_ORDERID]: 'Search by tracking code or order ID',
  [SearchTypeEnum.RECIPIENT_EMAIL]: 'Search by email address',
  [SearchTypeEnum.RECIPIENT_NAME]: 'Search by full name',
};

export default searchTypes;
