import React from 'react';

import Button from '../../common2/Button';
import { openIntercomMessenger } from '../../../utils/openIntercomMessenger';

import AddIcon from '../../../icons/add.svg';

function IntercomButton(props) {
  return (
    <Button
      onClick={openIntercomMessenger}
      icon={AddIcon}
      intent={props.intent}
    >
      {props.children}
    </Button>);
}

export default React.memo(IntercomButton);
