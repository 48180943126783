import React from 'react';

import './MenuItemBasic.scss';

function MenuItemBasic(props) {
  const {
    children,
    onClick,
  } = props;

  return (
    <div styleName="item" onClick={onClick}>
      {children}
    </div>
  );
}

export default MenuItemBasic;