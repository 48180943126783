import React from 'react';
import PropTypes from 'prop-types';

import Input from '../common2/Input';
import Button from '../common/Button';
import loginFormValidator from './loginFormValidator';
import isEmptyObject from '../../utils/isEmptyObject';
import Link from '../common/Link/Link';
import Locale from '../Translate/Locale';

import './LoginForm.scss';

class LoginForm extends React.PureComponent {
  state = {
    email: '',
    password: '',
    formErrors: {},
  };

  handleUserInput = (value, name) => {
    this.setState({[name]: value});
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      email,
      password,
    } = this.state;
    const formErrors = loginFormValidator({ email, password });
    this.setState({ formErrors });
    if (isEmptyObject(formErrors)) {
      this.confirmSubmission({ email, password });
    }
  };

  confirmSubmission = (credentials) => {
    const { onSubmit } = this.props;
    onSubmit && onSubmit(credentials);
  };

  render() {
    const {
      email,
      password,
      formErrors,
    } = this.state;

    const { isLoading } = this.props;

    return (
      <form styleName="form" onSubmit={this.submitForm}>
        <Input
          label="Email address"
          placeholder="mail@example.com"
          value={email}
          onChange={this.handleUserInput}
          error={formErrors.email}
          name="email"
          type="email"
          disabled={isLoading}
          large
        />
        <div styleName="forgot-password">
          <Link to="/forgot-password" tabIndex={-1}>
            <span styleName="forgot-password-text">
              <Locale>Forgot your password?</Locale>
            </span>
          </Link>
        </div>
        <Input
          label="Password"
          placeholder="5 characters"
          value={password}
          onChange={this.handleUserInput}
          error={formErrors.password}
          name="password"
          type="password"
          disabled={isLoading}
          large
        />
        <Button
          styleName="login-button"
          primary
          type="submit"
          isLoading={isLoading}
        >
          Sign In
        </Button>
      </form>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;