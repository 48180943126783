import React from 'react';
import PropTypes from 'prop-types';
import {
  SettingsList,
  SettingsListItem,
} from './SettingsList';
import SettingsListSpacer from './SettingsList/SettingsListSpacer';
import { withEditorConfig } from '../editors/EditorContext';

class DesignList extends React.PureComponent {
  onTypographySelect = () => this.props.onSelect('typography');
  onColorsSelect = () => this.props.onSelect('colors');
  onStylingSelect = () => this.props.onSelect('styling');

  render() {
    const {
      onSelect,
      editorConfig: {
        designSettingsPanel = {},
      },
    } = this.props;

    const hasStyling = designSettingsPanel.styling;

    return (
      <SettingsList>
        <SettingsListSpacer key="top-spacer" />
        <SettingsListItem
          key="typography"
          type="typography"
          title="Typography"
          onClick={this.onTypographySelect}
        />
        <SettingsListItem
          key="colors"
          type="colors"
          title="Colors"
          onClick={this.onColorsSelect}
        />
        { hasStyling &&
          <SettingsListItem
            key="styling"
            type="styling"
            title="Styling"
            onClick={this.onStylingSelect}
          />
        }
      </SettingsList>
    );
  }
}

DesignList.defaultProps = {
  onSelect: () => {},
};

DesignList.propTypes = {
  onSelect: PropTypes.func,
};

export default withEditorConfig(DesignList);