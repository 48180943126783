import React from 'react';
import classNames from 'classnames';

import LocalizationHeader from './LocalizationHeader';

import CollpaseIcon from '../../icons/expand-arrow.svg';

import './CollapsibleLocalizationHeader.scss';

class CollapsibleLocalizationHeader extends React.PureComponent {
  render() {
    const {
      url,
      language,
      archived,
      isExpanded,
      children,
    } = this.props;

    const onClick = archived ? null : this.props.onClick;

    return (
      <div styleName={classNames('collapsible-header', { archived })} onClick={onClick}>
        <LocalizationHeader url={url} language={language} />
        <div styleName="elements">{children}</div>
        { !archived && <CollpaseIcon styleName={classNames('collapse-button', { expanded: isExpanded })} /> }
      </div>
    );
  }
}

export default CollapsibleLocalizationHeader;