import React from 'react';
import PropTypes from 'prop-types';
import SmallBadge from './SmallBadge';
import { Locale } from '../Translate';
import StatusBadge from '../common/StatusBadge';
import DropdownButton from '../common/DropdownButton';
import { MenuItem } from '../modal/Menu';
import WidgetsList from '../Widget/WidgetsList';
import TrackingPageIcon from '../../icons/tracking-page.svg';
import Card from '../common/Card';
import InfoIcon from '../../icons/info.svg';
import PublicLink from './PublicLink';

import './LegacyTrackingPage.scss';

class LegacyTrackingPage extends React.PureComponent {

  render () {
    const {
      legacy,
      onMakeDefault,
      onRemove,
    } = this.props;

    const isDefault = legacy.default;
    const publicUrl = legacy.url;

    return (
      <div styleName="legacy-tracking-page">
        <div styleName="tip">
          <InfoIcon styleName="tip-icon" />
          <Locale>We automatically imported your exiting tracking page</Locale>
        </div>
        <Card noPadding>
          <WidgetsList legacy>
            <tr>
              <td>
                <TrackingPageIcon styleName="icon" />
              </td>
              <td>
                <div styleName="info">
                  <span styleName="name">
                    <Locale>Hi, here is your old tracking pages</Locale>
                  </span>
                  { isDefault && (
                    <SmallBadge>Default</SmallBadge>
                  ) }
                  <PublicLink url={publicUrl} />
                </div>
              </td>
              <td>
                <StatusBadge published>
                  Published
                </StatusBadge>
              </td>
              <td/>
              <td/>
              <td>
                <DropdownButton>
                  { !isDefault && onMakeDefault && (
                    <MenuItem onClick={onMakeDefault}>Make default</MenuItem>
                  ) }
                  { !isDefault && onRemove && (
                    <MenuItem onClick={onRemove}>Delete</MenuItem>
                  ) }
                </DropdownButton>
              </td>
            </tr>
          </WidgetsList>
        </Card>
      </div>
    );
  }
}

LegacyTrackingPage.defaultProps = {
  onRemove : () => {},
  onMakeDefault : () => {},
};

LegacyTrackingPage.propTypes = {
  onRemove : PropTypes.func,
  onMakeDefault : PropTypes.func,
  legacy: PropTypes.shape({
    default: PropTypes.bool,
    url: PropTypes.string,
  }),
};


export default LegacyTrackingPage;