import produce from 'immer';
import { isRequestAction, requestProducer } from './requestReducerHelper';

const initialState = {};

const successProducer = (draft, action) => {
  const { token, expired } = action.data;
  draft.token = token;
  draft.expired = expired;
};

const proxyReducer = (state = initialState, action) => produce(state, draft => {
  if (isRequestAction(action, 'PROXY_TOKEN')) {
    return requestProducer(draft, action, 'PROXY_TOKEN', {
      requestProducer: (draft) => {
        draft.token = null;
        draft.expired = null;
      },
      successProducer
    });
  }
});

export default proxyReducer;