import getRoles from './getRoles';

class Firewall {
  constructor(whiteList, blackList) {
    this.whiteList = newSetFromArray(whiteList);
    this.blackList = newSetFromArray(blackList);
    this.roles = getRoles(this);
  }

  updatePermissions(whiteList, blackList) {
    this.whiteList = newSetFromArray(whiteList);
    this.blackList = newSetFromArray(blackList);
    this.roles = getRoles(this);
  }

  hasAccess(perm) {
    if (this.blackList.has(perm)) {
      return false;
    }

    return this.whiteList.has(perm);
  }

  inBlackList(perm) {
    return this.blackList.has(perm);
  }

  hasRole(role) {
    return this.roles.includes(role);
  }
}

function newSetFromArray(arr) {
  return new Set(arr ? [...arr] : []);
}

export default Firewall;