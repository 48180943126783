import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getLanguageName } from '../../../utils/langHelper';
import Locale from '../../Translate/Locale';
import LocalizationIcon from '../../../icons/localization.svg';
import LocalizationEditButtons from './LocalizationEditButtons';
import MenuItem from '../../modal/Menu/MenuItem';
import { setShopLocalization } from '../../../actions/shopLocalizations';

import './ShopLocalizationItem.scss';

export function ShopLocalizationsListItem(props) {
  const { localization, onDelete, onArchive, setShopLocalization } = props;

  const { url, language, archived, linkedResources = 0 } = localization;
  const hasLinkedResources = linkedResources !== 0;
  const deletable = !archived && !hasLinkedResources;
  const archivable = !archived && hasLinkedResources;

  const handleSelectLocId = () => {
    setShopLocalization(localization);
  };

  return (
    <tr>
      <td>
        <LocalizationIcon styleName="localization-icon" />
      </td>
      <td key="url">
        <span>{url}</span>
      </td>
      <td key="lang">
        <span styleName="mediumBadge">
          <Locale>{getLanguageName(language)}</Locale>
        </span>
      </td>
      <td key="edit">
        <LocalizationEditButtons onClick={handleSelectLocId}>
          {deletable && <MenuItem onClick={onDelete}>Delete</MenuItem>}
          {archivable && <MenuItem onClick={onArchive}>Archive</MenuItem>}
        </LocalizationEditButtons>
      </td>
    </tr>
  );
}

ShopLocalizationsListItem.defaultProps = {
  localization: {},
};

ShopLocalizationsListItem.propTypes = {
  localization: PropTypes.shape({
    url: PropTypes.string,
    language: PropTypes.string,
    archived: PropTypes.bool,
    linkedResources: PropTypes.number,
    activeLinkedResources: PropTypes.number,
    inactiveLinkedResources: PropTypes.number,
  }),
  editUrl: PropTypes.string,
  onDelete: PropTypes.func,
  onArchive: PropTypes.func,
  setShopLocalization: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    setShopLocalization: (localization) => dispatch(setShopLocalization(localization)),
  };
}

export default connect(null, mapDispatchToProps)(React.memo(ShopLocalizationsListItem));
