import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Locale } from '../../Translate';

import './SmallBadge.scss';

const SmallBadge = ({ children, selected }) => (
  <span styleName={classNames('small-badge', { selected })} data-test-id="badge">
    <Locale>{children}</Locale>
  </span>
);

SmallBadge.propTypes = {
  children: PropTypes.string,
  selected: PropTypes.bool,
};

export default SmallBadge;
