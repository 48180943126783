import { trackingPageSettings as defaultSettings } from '@sendwise/default-settings';

import editorTypeEnum from '../visualEditorTypeEnum';
import HeaderWidgetSettings from '../../WidgetEditionPage/WidgetSettings/HeaderWidgetSettings';
import BannerWidgetSettings from '../../WidgetEditionPage/WidgetSettings/BannerWidgetSettings';
import ProductsWidgetSettings from '../../WidgetEditionPage/WidgetSettings/ProductsWidgetSettings';
import FooterWidgetSettings from '../../WidgetEditionPage/WidgetSettings/FooterWidgetSettings';
import TrackingPageTypographySettings from './TrackingPageTypographySettings';
import TrackingPageStatusWidgetSettings from './settings/TrackingPageStatusWidgetSettings';
import { trpSettingsGroups } from '../../WidgetEditionPage/WidgetDesignSettings/colorSettingsHelper';

export default {
  editorType: editorTypeEnum.TRACKING_PAGE,
  widgets: [
    'header',
    'status',
    'banner',
    'products',
    'footer',
  ],
  fixedWidgets: {
    top: ['header'],
    bottom: ['footer'],
  },
  singletonWidgets: [
    'status',
  ],
  widgetsInfo: {
    header: {
      name: 'Header',
      settingsPanel: HeaderWidgetSettings,
    },
    status: {
      name: 'Status',
      settingsPanel: TrackingPageStatusWidgetSettings,
    },
    banner: {
      name: 'Banner',
      settingsPanel: BannerWidgetSettings,
    },
    products:     {
      name: 'Shop products',
      settingsPanel: ProductsWidgetSettings,
    },
    footer: {
      name: 'Footer',
      settingsPanel: FooterWidgetSettings,
    },
  },
  designSettingsPanel: {
    typography: TrackingPageTypographySettings,
  },
  colorGroups: trpSettingsGroups,
  defaultSettings,
};

export function injectWidget(config, injectedWidget) {
  const widgets = new Set([
    ...config.widgets,
    injectedWidget,
  ]);

  return {
    ...config,
    widgets: [...widgets],
  };
}