import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

/**
 * @deprecated Please use Input component
 */
export const LabeledInput = ({ inputError, ...props}) => <Input {...props} />;

LabeledInput.propTypes = {
  inputError: PropTypes.bool
};

export default LabeledInput;