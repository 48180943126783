import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../Translate/Locale';

import './EmbeddedWidgetCode.scss';

const widgetUrl = process.env.WIDGET_URL;

const scriptTemplate = `
<script>
  sevensendersEmbeddedWidget({
    embeddedWidgetId: <hash>,
    elementId: <selector>,
    orderId: <orderId>
  });
</script>
`;

const scriptTemplateWithZipCode = `
<script>
  sevensendersEmbeddedWidget({
    embeddedWidgetId: <hash>,
    elementId: <selector>,
    orderId: <orderId>,
    zipCode: <zipCode>
  });
</script>
`;

const EmbeddedWidgetCode = ({ hash, hasZipCodeProtection }) => {
  const template = hasZipCodeProtection ? scriptTemplateWithZipCode : scriptTemplate;
  const script = template.replace('<hash>', `'${hash}'`);

  return (
    <React.Fragment>
      <p styleName="title">
        <Locale>Your sendwise tag</Locale>:
      </p>
      <div styleName="code">
        <pre>
          {`<script type="text/javascript" src="${widgetUrl}widget.js"></script>`}
          {script}
        </pre>
      </div>
    </React.Fragment>
  );
};

EmbeddedWidgetCode.propTypes = {
  hash: PropTypes.string,
  hasZipCodeProtection: PropTypes.bool,
};

export default EmbeddedWidgetCode;
