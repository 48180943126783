import meaningEnum from '../ShopContentLibraryPage/meaningEnum';
import { format } from 'date-fns';

const intents = {
  [meaningEnum.GOOD]: 'positive',
  [meaningEnum.REALLY_BAD]: 'alert',
  [meaningEnum.BAD]: 'warning',
  [meaningEnum.NEUTRAL]: 'neutral',
};

export function getIntentForMeaning(meaning) {
  return intents[meaning];
}

export function getIntentForOrderState(state) {
  return state === 'completed' ? 'positive' : null;
}

export function shipmentTagsToString(tags) {
  return tags.map((tag) => `${tag.name}:${tag.content}`).join(', ');
}

export function formatDate(strDate) {
  if (!strDate) {
    return null;
  }

  const date = new Date(strDate);
  if (isNaN(date)) {
    return null;
  }

  return format(date, 'dd.MM.yyyy HH:mm');
}

export function getLoadingState(props) {
  const { loading, error, isEmpty } = props;

  if (error) {
    return 'error';
  }

  if (loading) {
    return 'loader';
  }

  if (isEmpty) {
    return 'empty';
  }

  return 'data';
}
