import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useOutsideClick from '../../../hooks/useOutsideClick';
import PlainButton from '../../common/PlainButton';

import './ButtonDropdown.scss';

function ButtonDropdown(props) {
  const { icon, children, text } = props;
  const [showMenu, setShowMenu] = useState(false);

  const onClose = () => setShowMenu(false);

  const onButtonClick = (e) => {
    setShowMenu((prevValue) => !prevValue);
    e.stopPropagation();
  };

  const ref = useOutsideClick(onClose);

  return (
    <div ref={showMenu ? ref : null} styleName="container">
      <PlainButton name="sort" onClick={onButtonClick} icon={icon}>
        {text}
      </PlainButton>
      {showMenu && (
        <div styleName="menu" onClick={onClose}>
          {children}
        </div>
      )}
    </div>
  );
}

ButtonDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
};

export default React.memo(ButtonDropdown);
