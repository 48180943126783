import React from 'react';
import PropTypes from 'prop-types';

import ExternalLink from '../../common2/links/ExternalLink';
import ViewIcon from '../../../icons/view.svg';
import PlainButton from '../../common/PlainButton';

function InputHelpLink(props) {
  const { to, children } = props;
  return (
    <ExternalLink to={to}>
      <PlainButton icon={ViewIcon}>{children}</PlainButton>
    </ExternalLink>
  );
}

InputHelpLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.string,
};
export default React.memo(InputHelpLink);
