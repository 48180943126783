import React from 'react';
import BulletedList from '../../common/bulleted-list/BulletedList';
import StartGuideTextContainer from './StartGuideTextContainer';

/**
 * @deprecated consider using RichLocale with StartGuideTextContainer instead
 */

function StartGuideBulletedList(props) {
  return (
    <StartGuideTextContainer>
      <BulletedList>{props.children}</BulletedList>
    </StartGuideTextContainer>
  );
}

export default React.memo(StartGuideBulletedList);
