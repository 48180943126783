import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../SettingsGroupCard';
import Checkbox from '../../common/Checkbox';
import Locale from '../../Translate/Locale';
import Select from '../../common/Select';
import Input from '../../common/Input';
import RadioGroup from '../../common/RadioGroup';
import WidgetInfo from '../../editors/common/WidgetInfo';
import CarrierSettings from './common/CarrierSettings';

const timelineOrderOptions = [
  { value: 'newestFirst', label: 'Show newest timeline state first' },
  { value: 'oldestFirst', label: 'Show oldest timeline state first' },
];

export class StatusWidgetSettings extends React.PureComponent {

  isGroupToggleDisabled(enabled, enabledCount) {
    // You should not be able to disable all the box on the configuration part
    return !!enabled && enabledCount < 2;
  }

  onChange = (e) => {
    const input = e.target;
    this.props.onSettingsChange({
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  onChangeCount = (option,name) => {
    const { value } = option;
    this.props.onSettingsChange({
      [name]: value,
    });
  };

  render() {
    const {
      settings,
      carrierMessagesCountOptions,
      isOptional,
      etaSupportLink,
    } = this.props;

    const enabledCount = !!settings.showStatus + !!settings.showETA
      + !!settings.showTimeline + !!settings.showCarrier + !!settings.showCarrierMessages;

    const toggleStatusProps = isOptional ? {
      toggleName: 'showStatus',
      toggleChecked: settings.showStatus,
      toggleDisabled: this.isGroupToggleDisabled(settings.showStatus, enabledCount),
      onToggle: this.onChange
    } : {};

    return (
      <React.Fragment>
        <SettingsGroupCard
          title="Status"
          subtitle="Show the status of your order / shipment"
          {...toggleStatusProps}
        >
          <h4><Locale>Order Status</Locale></h4>
          <Checkbox
            name="showOrderStatusIcon"
            checked={settings.showOrderStatusIcon}
            onChange={this.onChange}
          >
            Show icon
          </Checkbox>
          <Checkbox
            name="disabledOnceShipmentsCreated"
            checked={settings.disabledOnceShipmentsCreated}
            onChange={this.onChange}
          >
            Disable once shipments are created
          </Checkbox>
          <h4><Locale>Shipment Status</Locale></h4>
          <Checkbox
            name="showShipmentStatusIcon"
            checked={settings.showShipmentStatusIcon}
            onChange={this.onChange}
          >
            Show icon
          </Checkbox>
          <h4><Locale>Labels</Locale></h4>
          <Input
            label="Shipment tab label"
            name="shipmentTabLabel"
            value={settings.shipmentTabLabel}
            onChange={this.onChange}
          />
          <Input
            label="Return tab label"
            name="returnTabLabel"
            value={settings.returnTabLabel}
            onChange={this.onChange}
          />
        </SettingsGroupCard>
        <SettingsGroupCard
          title="Estimate time of arrival"
          subtitle="Show the ETA of your shipment"
          toggleName="showETA"
          toggleChecked={settings.showETA}
          toggleDisabled={this.isGroupToggleDisabled(settings.showETA, enabledCount)}
          onToggle={this.onChange}
        >
          <Checkbox
            name="etaIncludeDayOfWeek"
            checked={settings.etaIncludeDayOfWeek}
            onChange={this.onChange}
          >
            Include day of the week
          </Checkbox>
          <Checkbox
            name="etaDisableIfNoShipments"
            checked={settings.etaDisableIfNoShipments}
            onChange={this.onChange}
          >
            Disable ETA if no shipments
          </Checkbox>
          <h4><Locale>Labels</Locale></h4>
          <Input
            label="ETA label"
            name="etaLabel"
            value={settings.etaLabel}
            onChange={this.onChange}
          />
          <Input
            label="ETA not available lable"
            name="etaNotAvailableLabel"
            value={settings.etaNotAvailableLabel}
            onChange={this.onChange}
          />
          <WidgetInfo docUrl={etaSupportLink}>
            Learn more on how’s ETA calculated and when we show
          </WidgetInfo>
        </SettingsGroupCard>
        <SettingsGroupCard
          title="Timeline"
          subtitle="Show the timeline of your shipment"
          toggleName="showTimeline"
          toggleChecked={settings.showTimeline}
          toggleDisabled={this.isGroupToggleDisabled(settings.showTimeline, enabledCount)}
          onToggle={this.onChange}
        >
          <Checkbox
            name="timelineShowIcon"
            checked={settings.timelineShowIcon}
            onChange={this.onChange}
          >
            Show icon
          </Checkbox>
          <Checkbox
            name="timelineShowDate"
            checked={settings.timelineShowDate}
            onChange={this.onChange}
          >
            Show date
          </Checkbox>
          <Checkbox
            name="hideFutureStates"
            checked={settings.hideFutureStates}
            onChange={this.onChange}
          >
            Hide future states
          </Checkbox>
          <h4><Locale>Mobile display orientation</Locale></h4>
          <RadioGroup
            name="timelineMobileOrder"
            options={timelineOrderOptions}
            value={settings.timelineMobileOrder}
            onChange={this.onChange}
          />
        </SettingsGroupCard>
        <SettingsGroupCard
          title="Carrier messages"
          subtitle="Show the carrier messages of your shipment"
          toggleName="showCarrierMessages"
          toggleChecked={settings.showCarrierMessages}
          toggleDisabled={this.isGroupToggleDisabled(settings.showCarrierMessages, enabledCount)}
          onToggle={this.onChange}
        >
          <Checkbox
            name="carrierMessagesHideOnEntry"
            checked={settings.carrierMessagesHideOnEntry}
            onChange={this.onChange}
          >
            Hide on entry
          </Checkbox>
          <Checkbox
            name="carrierMessagesFullDate"
            checked={settings.carrierMessagesFullDate}
            onChange={this.onChange}
          >
            Show full date
          </Checkbox>
          <Select
            options={carrierMessagesCountOptions}
            name="carrierMessagesCount"
            value={settings.carrierMessagesCount || 0}
            onChange={this.onChangeCount}
          >
            Number of messages shown
          </Select>
          <h4><Locale>Labels</Locale></h4>
          <Input
            label="Show carrier messages label"
            name="showHistoryLabel"
            value={settings.showHistoryLabel}
            onChange={this.onChange}
          />
          <Input
            label="Hide carrier messages label"
            name="hideHistoryLabel"
            value={settings.hideHistoryLabel}
            onChange={this.onChange}
          />
        </SettingsGroupCard>
        <SettingsGroupCard
          title="Carrier"
          subtitle="Show the carrier of your shipment"
          toggleName="showCarrier"
          toggleChecked={settings.showCarrier}
          toggleDisabled={this.isGroupToggleDisabled(settings.showCarrier, enabledCount)}
          onToggle={this.onChange}
        >
          <CarrierSettings settings={settings} onChange={this.props.onSettingsChange} />
        </SettingsGroupCard>
      </React.Fragment>
    );
  }
}

StatusWidgetSettings.defaultProps = {
  onChange: () => {},
  carrierMessagesCountOptions: [
    { value: 1, label: 'Last one' },
    { value: 3, label: 'Last 3' },
    { value: 5, label: 'Last 5' },
  ],
};

StatusWidgetSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func,
  carrierMessagesCountOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  isOptional: PropTypes.bool,
};

export default StatusWidgetSettings;
