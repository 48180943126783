import container from '../container';

/**
 * Helper function for calling async API actions
 *
 * @param {function} fetchApi - callback with api object as argument
 * @param {string} prefix -  actions prefix (used for request/success/error actions)
 *
 * Example: withApiActions(api => api.fetchUserData(userId), 'USER_DATA')
 */
export function withApiActions(fetchApi, prefix) {
  return (dispatch) => {
    dispatch(requestAction(prefix));

    return fetchApi(container.api)
      .then((data) => dispatch(successAction(prefix, data)))
      .catch((data) => {
        const error = data.error || data;
        dispatch(errorAction(prefix, error));
      });
  };
}

export function withGQLApiActions(fetchApi, prefix) {
  return (dispatch) => {
    dispatch(requestAction(prefix));
    return fetchApi()
      .then((data) => {
        dispatch(successAction(prefix, data));
      })
      .catch((data) => {
        const error = data.error || data;
        dispatch(errorAction(prefix, error));
        throw error;
      });
  };
}

export function withTouchpointsApiActions(fetchApi, prefix) {
  return (dispatch) => {
    dispatch(requestAction(prefix));

    return fetchApi(container.touchpointsApi)
      .then((data) => dispatch(successAction(prefix, data)))
      .catch((data) => {
        const error = data.error || data;
        dispatch(errorAction(prefix, error));
      });
  };
}

export function requestAction(prefix) {
  return { type: `${prefix}_REQUEST` };
}

export function successAction(prefix, data) {
  return { type: `${prefix}_SUCCESS`, data };
}

export function errorAction(prefix, error) {
  return { type: `${prefix}_ERROR`, error };
}

export function isFulfilledAction(action) {
  return action.type.endsWith('/fulfilled');
}
