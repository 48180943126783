import React from 'react';
import BannerAccess from '../StartGuidePage/BannerAccess';
import BannerNoAccess from '../StartGuidePage/BannerNoAccess';

function AnalyticsStartGuideBanner(props) {
  if (props.hasAccess) {
    return (
      <BannerAccess
        heading="Get started with analytics"
        description="ANALYTICS_START_GUIDE_BANNER_DESC"
        url="/analytics"
        cta="Discover dashboards"
      />
    );
  } else {
    return (
      <BannerNoAccess
        heading="Get started with analytics"
        description="ANALYTICS_START_GUIDE_BANNER_NO_ACCESS_DESC"
        cta="Get analytics now"
      />
    );
  }
}

export default React.memo(AnalyticsStartGuideBanner);
