import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Switch } from '../Wizard';
import ConfigurationTabs from './ConfigurationTabs';
import WidgetSettings from './WidgetSettings';
import WidgetDesignSettings from './WidgetDesignSettings/WidgetDesignSettings';
import { findWidgetById } from '../editors/widgetsHelper';
import AddWidgetPanel from './AddWidgetPanel';
import ConfirmationModal from '../modal/ConfirmationModal';
import {
  addWidget,
  addWidgetPreview,
  removeWidget,
  removeWidgetPreview,
  selectWidget,
  updateDesignSettings,
  updateWidgetSettings,
} from '../../actions/widgetSettings';
import { withEditorConfig } from '../editors/EditorContext';

export class ConfigurationPanel extends React.Component {
  state = {
    selected: 'configuration',
    selectedTab: 'widgets',
    showConfirmation: false,
    insertWidgetAfterId: null,
  };

  static getDerivedStateFromProps(props, state) {
    let { selected } = state;

    if (props.selectedWidgetId) {
      selected = 'widgetSettings';
    } else if (selected === 'widgetSettings') {
      selected = 'configuration';
    }

    return {
      ...state,
      selected,
    };
  }

  onSelectTab = (id) => {
    this.setState({ selectedTab: id });
  };

  onSelectDesignSettings = (type) => {
    this.setState({
      selectedDesign: type,
      selected: 'designSettings',
    });
  };

  onCloseWidgetSettings = () => {
    this.props.onSelectWidget(null);
  };

  onShowAddWidgetPanel = (widgetId) => {
    this.setState({selected: 'addNewWidget', insertWidgetAfterId: widgetId});
  };

  onCloseSettings = () => {
    this.setState({
      selectedDesign: null,
      selected: 'configuration',
    });
  };

  onCloseDesignSettings = () => {
    this.setState({ selected: 'configuration' });
  };


  onConfirmationClose = () => {
    this.setState({showConfirmation: false});
  };

  onConfirmationShow = () => {
    this.setState({showConfirmation: true});
  };

  onRemoveWidget = () => {
    this.onConfirmationClose();
    this.props.onRemoveWidget(this.props.selectedWidgetId);
  };

  onWidgetSettingsChange = (settings) => {
    const {
      selectedWidgetId,
      onSettingsChange,
    } = this.props;

    onSettingsChange(selectedWidgetId, settings);
  };

  onAddWidgetPreview = (type) => {
    const widgetSettings = this.props.editorConfig.defaultSettings[type];
    this.props.onAddWidgetPreview(type, this.state.insertWidgetAfterId, widgetSettings);
  };

  onRemoveWidgetPreview = () => {
    this.props.onRemoveWidgetPreview();
    this.onCloseSettings();
  };

  render() {
    const {
      selected,
      selectedTab,
      selectedDesign,
      showConfirmation,
    } = this.state;

    const {
      widgets,
      settings,
      selectedWidgetId,
      previewWidget,
      onAddWidget,
      onDesignSettingsChange,
      onExit,
      title,
    } = this.props;

    return (
      <React.Fragment>
        <Switch selected={selected}>
          <ConfigurationTabs
            key="configuration"
            selected={selectedTab}
            onSelectTab={this.onSelectTab}
            widgets={widgets}
            onSelectDesignSettings={this.onSelectDesignSettings}
            onShowAddWidgetPanel={this.onShowAddWidgetPanel}
            title={title}
            onExit={onExit}
          />
          <WidgetSettings
            key="widgetSettings"
            widget={findWidgetById(widgets, selectedWidgetId)}
            onSettingsChange={this.onWidgetSettingsChange}
            onClose={this.onCloseWidgetSettings}
            onRemove={this.onConfirmationShow}
          />
          <WidgetDesignSettings
            key="designSettings"
            widgets={widgets}
            settings={settings}
            onSettingsChange={onDesignSettingsChange}
            type={selectedDesign}
            onClose={this.onCloseDesignSettings}
          />
          <AddWidgetPanel
            key="addNewWidget"
            widgets={widgets}
            onAddWidget={onAddWidget}
            onAddPreviewWidget={this.onAddWidgetPreview}
            onClose={this.onRemoveWidgetPreview}
            previewWidget={previewWidget}
          />
        </Switch>
        <ConfirmationModal
          show={showConfirmation}
          title="Removing widget"
          message="Are you sure you want to remove this widget?"
          onCancel={this.onConfirmationClose}
          onOK={this.onRemoveWidget}
        />
      </React.Fragment>
    );
  }
}

ConfigurationPanel.defaultProps = {
  widgets: [],
  settings: {},
  onSettingsChange: () => {},
  onDesignSettingsChange: () => {},
};

ConfigurationPanel.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.object,
  selectedWidgetId: PropTypes.string,
  onSettingsChange: PropTypes.func,
  onDesignSettingsChange: PropTypes.func,
  onAddWidget: PropTypes.func,
  onAddWidgetPreview: PropTypes.func,
  onRemoveWidgetPreview: PropTypes.func,
  onSelectWidget: PropTypes.func,
  onRemoveWidget: PropTypes.func,
  onDesignChange: PropTypes.func,
  onExit: PropTypes.func,
  title: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    onSettingsChange: (id, settings) => dispatch(updateWidgetSettings(id, settings)),
    onDesignSettingsChange: settings => dispatch(updateDesignSettings(settings)),
    onAddWidget: () => dispatch(addWidget()),
    onAddWidgetPreview: (type, position, settings) => dispatch(addWidgetPreview(type, position, settings)),
    onRemoveWidgetPreview: () => dispatch(removeWidgetPreview()),
    onSelectWidget: id => dispatch(selectWidget(id)),
    onRemoveWidget: (id) => dispatch(removeWidget(id)),
    onDesignChange: (settings) => dispatch(updateDesignSettings(settings)),
  };
}

export default connect(null, mapDispatchToProps)(withEditorConfig(ConfigurationPanel));