import React from 'react';
import PropTypes from 'prop-types';

import NameItem from '../../../touchpoints/TouchpointsListItem/NameItem';
import UserRoleBadge from '../../../common/badges/UserRoleBadge';
import {
  TOUCHPOINTS_PERMISSION,
  ANALYTICS_PERMISSION,
  SHOP_SETTINGS_PERMISSION,
} from '../../../../firewall/permissions';

import './UserListItem.scss';

function UserListItem(props) {
  const {
    name,
    email,
    restrictions = [],
    is_explorer: isExplorer,
  } = props.user;

  const accessType = getAccessTypes(restrictions).join(', ');

  return (
    <tr>
      <td>
        <NameItem>
          {name}
        </NameItem>
      </td>
      <td>
        {email}
      </td>
      <td styleName="restrictions">
        {accessType}
      </td>
      <td>
        <div styleName="roles">
          { isAdmin(restrictions) && <UserRoleBadge role="admin" /> }
          { isExplorer && <UserRoleBadge role="explorer" /> }
        </div>
      </td>
    </tr>
  );
}

function getAccessTypes(restrictions) {
  const types = [];

  if (!restrictions.includes(TOUCHPOINTS_PERMISSION)) {
    types.push(TOUCHPOINTS_PERMISSION);
  }

  if (!restrictions.includes(ANALYTICS_PERMISSION)) {
    types.push(ANALYTICS_PERMISSION);
  }

  return types;
}

function isAdmin(restrictions) {
  return !restrictions.includes(SHOP_SETTINGS_PERMISSION);
}

UserListItem.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    restrictions: PropTypes.arrayOf(PropTypes.string),
    is_explorer: PropTypes.bool,
  }).isRequired,
};

export default React.memo(UserListItem);