import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../../components/Translate';
import Toggle from '../common/Toggle';
import classNames from 'classnames';

import './SettingsGroupCard.scss';

class SettingsGroupCard extends React.PureComponent {
  render() {
    const {
      title,
      subtitle,
      children,
      toggleName,
      toggleChecked,
      toggleDisabled,
      onToggle,
      compact,
    } = this.props;
    const hasToggle = !!toggleName;

    return (
      <div styleName="settings-group">
        { !!title && (
          <div styleName="title">
            <div>
              <h3>
                <Locale>{title}</Locale>
              </h3>
            </div>
            <div styleName="toggle">
              { toggleName &&
              <Toggle
                name={toggleName}
                disabled={toggleDisabled}
                checked={toggleChecked}
                onChange={onToggle}
              />
              }
            </div>
          </div>
        )}
        { subtitle && (
          <div styleName="subtitle">
            <Locale>{subtitle}</Locale>
          </div>
        )}
        <div styleName={classNames('content', { compact })}>
          { (toggleChecked || !hasToggle) && children }
        </div>
      </div>
    );
  }
}

SettingsGroupCard.defaultProps = {
  toggleChecked: false,
};

SettingsGroupCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  toggleChecked: PropTypes.bool,
  toggleDisabled: PropTypes.bool,
  toggleName: PropTypes.string,
  onToggle: PropTypes.func,
  compact: PropTypes.bool,
};


export default SettingsGroupCard;
