import React from 'react';
import PropTypes from 'prop-types';
import PageWithTitle from '../Wizard/PageWithTitle';
import Button from '../common/Button';
import Card from '../common/Card';
import WhatsNext from '../common/WhatsNext';
import { HelpCard, HelpCardList } from '../HelpCard';
import LightHouseIcon from '../../icons/artwork/lighthouse.svg';
import ContactIcon from '../../icons/artwork/contact.svg';
import { helpUrl } from '../EmbeddedWidgetsPage/EmbeddedWidgetCodeModal';

import './InstallCodeTagPage.scss';
import EmbeddedWidgetCode from './EmbeddedWidgetCode';

class InstallCodeTagPage extends React.PureComponent {
  render() {
    const {
      hash,
      onButtonClick,
    } = this.props;

    return (
      <PageWithTitle title="Install the sendwise tag" secondaryText="Install the Sendwise tag">
        <div styleName="content">
          <Card>
            <EmbeddedWidgetCode hash={hash} />
          </Card>
        </div>
        <Button onClick={onButtonClick} primary>
          Publish
        </Button>
        <div styleName="bottom">
          <WhatsNext
            title="Instructions"
            listItems={[
              'INSTALL_CODE_EW_INSTRUCTION_FIRST_STEP',
              'INSTALL_CODE_EW_INSTRUCTION_SECOND_STEP',
              'INSTALL_CODE_EW_INSTRUCTION_THIRD_STEP',
            ]}
            buttonLinkTo="/tracking/embedded-widgets"
            buttonLabel="Got it"
          />
          <HelpCardList>
            <HelpCard
              title="Read our documentation"
              description="INSTALL_CODE_EW_READ_DOCUMENTATION"
              linkText="Link to documentation"
              linkUrl={helpUrl}
              icon={LightHouseIcon}
            />
            <HelpCard
              title="Your lost? Contact us"
              description="INSTALL_CODE_EW_CONTACT_US"
              linkText="Contact link"
              linkUrl="mailto:service@sevensenders.com"
              icon={ContactIcon}
            />
          </HelpCardList>
        </div>
      </PageWithTitle>
    );
  }
}

InstallCodeTagPage.propTypes = {
  hash: PropTypes.string,
};

export default InstallCodeTagPage;