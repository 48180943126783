import React from 'react';
import { Trans } from 'react-i18next';

import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import Locale from '../../../Translate/Locale';
import Column from '../../../common/layout/Column';
import ClaimStepClosableMessage from '../common/ClaimStepClosableMessage';
import InvestigationStepContainer from './InvestigationStepContainer';

function InvestigationDeliveredToMailboxStep(props) {
  const { claim, onChange, onNext, ...restProps } = props;

  return (
    <InvestigationStepContainer {...restProps} onNext={onNext}>
      <ClaimStepClosableMessage intent="warning">
        <Locale>CLAIMS_DELIVERED_TO_MAILBOX_ALERT</Locale>
      </ClaimStepClosableMessage>
      <SidePanelContentSection heading="CLAIMS_DELIVERED_TO_MAILBOX_HEADER">
        <Column>
          <span style={{ whiteSpace: 'pre-line' }}>
            <Trans i18nKey="CLAIMS_DELIVERED_TO_MAILBOX_MESSAGE" components={{ bold: <b /> }} />
          </span>
        </Column>
      </SidePanelContentSection>
    </InvestigationStepContainer>
  );
}

export default React.memo(InvestigationDeliveredToMailboxStep);
