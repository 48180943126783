import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNotifications from '../../../hooks/useNotifications';
import useDispatchCallback from '../../../hooks/useDispatchCallback';
import { cancelClaim, updateClaim } from '../../../reducers/claimReducer';
import submitClaim from '../../../actions/submitClaim';
import CreateClaim from './CreateClaim';
import { trackEvent } from '../../../utils/googleAnalytics';
import usePermissionAccess from '../../../hooks/usePermissionAccess';
import { CLAIMS_AUTOMATED } from '../../../firewall/permissions';
import machine from './stateMachine';

function CreateClaimContainer() {
  const { showNotice, showError } = useNotifications();

  const isClaimsAutomated = usePermissionAccess(CLAIMS_AUTOMATED);
  const claim = useSelector((state) => state.claim.claim);

  const isSubmitting = useSelector((state) => state.claim.isSubmitting);

  const onChange = useDispatchCallback((values) => updateClaim(values));

  const dispatch = useDispatch();

  useEffect(() => {
    trackClaimAction('Claim initiated');
  }, []);

  const onClose = useCallback(
    (step) => {
      trackClaimAction(`Claim exit: ${step}`);
      dispatch(cancelClaim());
    },
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    trackClaimAction('Claim submitted');

    dispatch(submitClaim({ isClaimsAutomated })).then((result) => {
      if (result.success) {
        showNotice({
          type: 'success',
          message: 'CLAIMS_SUCCESSFULLY_SUBMITTED_NOTICE',
        });
      } else {
        showError();
      }
    });
  }, [dispatch, showNotice, showError, isClaimsAutomated]);

  return (
    <CreateClaim
      claim={claim}
      machine={machine}
      onChange={onChange}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isClaimsAutomated={isClaimsAutomated}
    />
  );
}

function trackClaimAction(action) {
  return trackEvent({
    category: 'parcel-finder',
    action,
  });
}

export default React.memo(CreateClaimContainer);
