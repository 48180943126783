import qs from 'qs';

export class ApiError extends Error {
  constructor(response) {
    super(`${response.statusText} (${response.status})`);
    this.response = response;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

export const isApiError = (error) => error instanceof ApiError;

export class Api {
  constructor(baseUrl, token, handlers = {}) {
    this.baseUrl = baseUrl;
    this.token = token;
    this.handlers = handlers;
  }

  setToken(token) {
    this.token = token;
  }

  fetchAndHandle(resource, init, auth = true) {
    return fetch(resource, {
      ...init,
      headers: {
        ...init.headers,
        Accept: 'application/json',
        Authorization: auth ? `Bearer ${this.token}` : '',
      },
    }).then((res) => handleApiResponse(res, this.handlers.onUnauthorized));
  }

  get(path, params) {
    const query = qs.stringify(params);
    const uri = query ? `${path}?${query}` : path;

    return this.fetchAndHandle(`${this.baseUrl}${uri}`, {
      method: 'GET',
    });
  }

  patch(path, params) {
    return this.fetchAndHandle(`${this.baseUrl}${path}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  delete(path, params) {
    const query = qs.stringify(params);
    const uri = query ? `${path}?${query}` : path;

    return this.fetchAndHandle(`${this.baseUrl}${uri}`, {
      method: 'DELETE',
    });
  }

  put(path, params) {
    return this.fetchAndHandle(`${this.baseUrl}${path}`, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  post(path, params, auth = true) {
    return this.fetchAndHandle(
      `${this.baseUrl}${path}`,
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
        },
      },
      auth
    );
  }

  postUpload(path, formData) {
    return this.fetchAndHandle(`${this.baseUrl}${path}`, {
      method: 'POST',
      body: formData,
    });
  }

  form(path, params) {
    const formData = [];

    for (const key in params) {
      const value = encodeURIComponent(params[key]);
      formData.push(`${key}=${value}`);
    }

    return this.fetchAndHandle(`${this.baseUrl}${path}`, {
      method: 'POST',
      body: formData.join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    });
  }
}

async function getResponseJson(res) {
  const contentType = res.headers.get('Content-Type') || '';

  if (contentType.includes('application/json')) {
    return res.json();
  }

  return res;
}

async function handleApiResponse(res, onUnauthorized) {
  const data = await getResponseJson(res);

  if (res.ok) {
    return res.status === 204 ? null : data;
  }

  if (res.status === 401) {
    onUnauthorized && onUnauthorized();
  }

  throw new ApiError({ status: res.status, statusText: res.statusText, ...data });
}
