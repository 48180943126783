import React from 'react';

import Text from '../../../common/Text/Text';
import { Locale } from '../../../Translate';

import './SubSection.scss';

function SubSection(props) {
  return (
    <div styleName="container">
      <Text title>
        <Locale>{props.title}</Locale>
      </Text>
      <Text>
        <Locale>{props.description}</Locale>
      </Text>
      <div styleName="content">
        {props.children}
      </div>
    </div>
  );
}

export default React.memo(SubSection);