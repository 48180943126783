import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';
import meaningEnum from '../meaningEnum';

import './StatusBadge.scss';

function StatusBadge(props) {
  const {
    disabled,
    meaning,
    onClick,
  } = props;

  const styleName = classNames('status-badge', meaning, {
    disabled,
  });

  return (
    <div styleName={styleName} onClick={onClick}>
      <Locale>
        { getMeaningLabel(meaning) }
      </Locale>
    </div>
  );
}

const meaningLabels = {
  [meaningEnum.GOOD]: 'Positive',
  [meaningEnum.REALLY_BAD]: 'Negative',
  [meaningEnum.BAD]: 'Alert',
  [meaningEnum.NEUTRAL]: 'Neutral',
};

function getMeaningLabel(meaning) {
  return meaningLabels[meaning];
}

StatusBadge.propTypes = {
  disabled: PropTypes.bool,
  meaning: PropTypes.oneOf(Object.values(meaningEnum)),
  onClick: PropTypes.func,
};

export default StatusBadge;