import SendwiseApi from './sendwise';
import TouchpointsApi from './touchpoints';
import logout from '../actions/logout';
import { tokenSelector, selectedShopIdSelector } from '../reducers/selectors';
import NotificationsServiceApi from './notificationService';

export function createSendwiseApi(store) {
  const handlers = {
    onUnauthorized: () => store.dispatch(logout()),
  };

  const token = tokenSelector(store.getState());
  const api = new SendwiseApi(process.env.SENDWISE_API_URL, token, handlers);

  store.subscribe(() => {
    const token = tokenSelector(store.getState());
    api.setToken(token);
  });

  return api;
}

export function createTouchpointsApi(store) {
  const state = store.getState();
  const api = new TouchpointsApi({
    baseUrl: process.env.TOUCHPOINTS_API_URL,
    token: tokenSelector(state),
    shopId: selectedShopIdSelector(state),
    handlers: {
      onUnauthorized: () => store.dispatch(logout()),
    },
  });

  store.subscribe(() => {
    const state = store.getState();
    const token = tokenSelector(state);
    const shopId = selectedShopIdSelector(state);
    api.setToken(token);
    api.setShopId(shopId);
  });

  return api;
}

export function createNotificationsApi(store) {
  const state = store.getState();

  const api = new NotificationsServiceApi(process.env.NOTIFICATIONS_API_URL, tokenSelector(state));

  store.subscribe(() => {
    const state = store.getState();
    const token = tokenSelector(state);
    api.setToken(token);
  });

  return api;
}
