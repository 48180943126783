import produce from 'immer';
import nanoid from 'nanoid';
import { arrayMove } from 'react-sortable-hoc';
import { isRequestAction, requestProducer } from './requestReducerHelper';
import { findWidgetById, findWidgetIndexById, getWidgetWithPreview } from '../components/editors/widgetsHelper';

const initialState = {
  widgets: [],
};

const widgetSettingsReducer = (state = initialState, action) => {
  const newState = widgetSettingsProducer(state, action);
  const isChanged = calcIsChanged(state, newState, action);

  return { ...newState, isChanged };
};

function calcIsChanged(state, newState, action) {
  // reset isChanged on data fetch
  if (isRequestAction(action, 'WIDGET_SETTINGS')) {
    return false;
  }

  if (!state.isChanged) {
    return state.widgets !== newState.widgets || state.settings !== newState.settings;
  }

  return state.isChanged;
}

const widgetSettingsProducer = (state = initialState, action) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'WIDGET_SETTINGS')) {
      return requestProducer(draft, action, 'WIDGET_SETTINGS', {
        requestProducer: () => initialState,
        successProducer: (draft, action) => {
          Object.assign(draft, action.data);
          draft.selectedWidgetId = null;
        },
      });
    }

    switch (action.type) {
      case 'SELECT_WIDGET': {
        draft.selectedWidgetId = action.id;
        break;
      }

      case 'ADD_PREVIEW_WIDGET': {
        const { widgetId, widgetType, settings } = action;

        const previewWidgetId = generateWidgetId();

        draft.previewWidget = {
          position: widgetId ? draft.widgets.findIndex((w) => w.id === widgetId) + 1 : 0,
          widget: {
            id: previewWidgetId,
            type: widgetType,
            settings: settings || {},
          },
        };
        draft.scrollToWidgetId = previewWidgetId;

        break;
      }

      case 'RESET_SCROLL_TO_WIDGET':
        draft.scrollToWidgetId = null;
        break;

      case 'REMOVE_PREVIEW_WIDGET': {
        draft.previewWidget = null;
        break;
      }

      case 'ADD_WIDGET': {
        const { widgets, previewWidget } = draft;

        draft.widgets = getWidgetWithPreview(widgets, previewWidget);
        draft.previewWidget = null;
        draft.selectedWidgetId = previewWidget.widget.id;

        break;
      }

      case 'REMOVE_WIDGET': {
        const { selectedWidgetId } = state;

        draft.widgets = draft.widgets.filter((w) => w.id !== action.id);
        draft.selectedWidgetId = action.id === selectedWidgetId ? null : selectedWidgetId;

        break;
      }

      case 'MOVE_WIDGET': {
        const { widgets } = draft;
        const { fromId, toId } = action;

        const fromIndex = findWidgetIndexById(widgets, fromId);
        const toIndex = findWidgetIndexById(widgets, toId);

        draft.widgets = arrayMove(draft.widgets, fromIndex, toIndex);

        break;
      }

      case 'UPDATE_WIDGET_SETTINGS': {
        const { settings, id } = action;
        const widget = findWidgetById(draft.widgets, id);

        Object.assign(widget.settings, settings);

        break;
      }

      case 'UPDATE_DESIGN_SETTINGS': {
        const { settings } = action;
        draft.settings = { ...draft.settings, ...settings };

        break;
      }
    }
  });

function generateWidgetId() {
  return nanoid(10);
}

export default widgetSettingsReducer;
