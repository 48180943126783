import React from 'react';
import PageContainer from '../pagecontainer/PageContainer';
import * as permissions from '../../firewall/permissions';
import Locale from '../Translate/Locale';
import { useAnalyticsMenuItems } from './useAnalyticsMenuItems';

function AnalyticsPage(props) {
  const { children, ...pageProps } = props;
  const { fixedDashboards, customDashboards, looks, countryViews } = useAnalyticsMenuItems();

  const menuItems = React.useMemo(
    () => [
      {
        id: 'dashboards',
        title: 'Dashboards',
        children: [...fixedDashboards, ...customDashboards],
        permission: permissions.ANALYTICS_PERMISSION,
      },
      {
        id: 'reports',
        title: 'Reports',
        children: looks,
        permission: permissions.ANALYTICS_PERMISSION,
      },
      ...(countryViews.length > 0
        ? [
            {
              id: 'country-view',
              title: 'Country View',
              children: countryViews,
              permission: permissions.ANALYTICS_PERMISSION,
            },
          ]
        : []),
      {
        id: '/analytics/explore',
        title: 'Explore analytics',
        link: '/analytics/explore',
        permission: permissions.ANALYTICS_EXPLORE,
      },
      {
        id: 'start-guide',
        title: 'Start guide',
        link: '/analytics/start-guide',
      },
    ],
    [fixedDashboards, customDashboards, looks, countryViews]
  );

  return (
    <PageContainer {...pageProps} menuTitle="Analytics" menuItems={menuItems} collapsible>
      {children || (
        <h1>
          <Locale>Analytics dashboard</Locale>
        </h1>
      )}
    </PageContainer>
  );
}

export default React.memo(AnalyticsPage);
