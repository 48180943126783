import React from 'react';
import { useParams } from 'react-router-dom';

import useApi from '../../../hooks/useApi';
import EventsHistory from '../EventsHistrory';
import ShipmentDetails from './ShipmentDetails';
import Heading2 from '../../common/Heading2';
import Loader from '../../common/Loader';
import EventStatusBadge from '../EventStatusBadge';
import TrackingPageLink from '../details-links/TrackingPageLink';
import Button from '../../common2/Button';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';
import usePermissionAccess from '../../../hooks/usePermissionAccess';
import { TRACKING_PAGES_PERMISSION } from '../../../firewall/permissions';
import ShipmentNotificationsLog from '../NotificationLog/ShipmentNotificationsLog';
import ParcelFinderContainer from '../../parcel-finder/ParcelFinderContainer';
import './ShipmentDetailsPage.scss';
import DownloadIcon from '../../../icons/download.svg';

export function ShipmentDetailsPage(props) {
  const params = useParams();
  const shipmentId = parseInt(params.id);
  const shopId = useSelectedShopId();

  const [shipment, loading, error] = useApi(({ touchpoints }) => touchpoints.getShipment(shopId, shipmentId), [
    shopId,
    shipmentId,
  ]);

  const { lastEvent, trackingPageUrl, events, trackingCode, referenceNumber, labelUrl } = shipment || {};

  const reference = trackingCode || referenceNumber || '';

  const hasTrackingPagesAccess = usePermissionAccess(TRACKING_PAGES_PERMISSION);
  const showTrackingPageUrl = trackingPageUrl && hasTrackingPagesAccess;
  return (
    <ParcelFinderContainer {...props}>
      {loading && <Loader />}
      {error && <Heading2> Error getting Shipment</Heading2>}
      {shipment && !error && (
        <div styleName="container">
          <div styleName="heading">
            <Heading2 localeOptions={{ shipmentId: reference }}>{'Shipment: {{shipmentId}}'}</Heading2>
            <div styleName="badge">{lastEvent && <EventStatusBadge {...lastEvent} />}</div>
            {labelUrl && (
              <a styleName="download-link" href={labelUrl} target="_blank">
                <Button icon={DownloadIcon}>Download label</Button>
              </a>
            )}
          </div>
          {showTrackingPageUrl && <TrackingPageLink href={trackingPageUrl} />}
          <div styleName="content">
            <EventsHistory events={events} />
            <ShipmentDetails {...shipment} />
          </div>
          <ShipmentNotificationsLog shopId={shopId} shipmentId={shipmentId} />
        </div>
      )}
    </ParcelFinderContainer>
  );
}

export default React.memo(ShipmentDetailsPage);
