import PropTypes from 'prop-types';
import React from 'react';
import statusEnum from '../../../../constants/trackingPageStatusEnum';
import {getTrackingPagePreviewUrl} from '../../../../utils/trackingPageHelper';
import Link from '../../../common/Link';
import Row from '../../../common/Row/Row';
import StatusIndicator from '../../../common2/StatusIndicator/StatusIndicator';
import Locale from '../../../Translate';
import PublishDate from '../../../Widget/WidgetListItem/PublishDate';
import SaveDate from '../../../Widget/WidgetListItem/SaveDate';
import WidgetActionDropdownButton from '../../../Widget/WidgetListItem/WidgetActionDropdownButton';
import DefaultBadge from '../../DefaultBadge';

import './TrackingPagesListItem.scss';

const statusTooltip = {
  [statusEnum.DRAFT]: { status: 'Draft', intent: 'base' },
  [statusEnum.PUBLISHED]: { status: 'Published', intent: 'active' },
};

function TrackingPagesListItem(props) {
  const { trackingPage, onDuplicate, onDelete, onRename, onMakeDefault, onLiveLinkClick } = props;
  const { status } = trackingPage;
  const { status: statusText, intent } = statusTooltip[status];
  const url = getTrackingPagePreviewUrl(trackingPage);

  return (
    <tr role="row">
      <td>
        <Row spacing={20}>
          <StatusIndicator status={statusText} intent={intent} />
          <div>
            <Link to={`/tracking/tracking-pages/${trackingPage.id}/edit`}>
              <div styleName="title">{trackingPage.name}</div>
            </Link>

            {trackingPage.default && <DefaultBadge>Default</DefaultBadge>}

            <div styleName="links">
              <a styleName="preview-link" href={url} target="_blank">
                <Locale>Tracking Preview</Locale>
              </a>
              {isPublished(trackingPage) && (
                <React.Fragment>
                  <div styleName="separator" />
                  <div styleName="live-link" onClick={onLiveLinkClick}>
                    <Locale>Live link</Locale>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Row>
      </td>
      <td>
        <PublishDate date={trackingPage.publishDate} />
      </td>
      <td>
        <SaveDate saveDate={trackingPage.saveDate} publishDate={trackingPage.publishDate} />
      </td>
      <td>
        <WidgetActionDropdownButton
          onRename={onRename}
          onMakeDefault={isMakeDefaultEnabled(trackingPage) && onMakeDefault}
          onDelete={isDeleteEnabled(trackingPage) && onDelete}
          onDuplicate={onDuplicate}
        />
      </td>
    </tr>
  );
}

function isPublished(trackingPage) {
  return trackingPage.status === 'published';
}

function isMakeDefaultEnabled(trackingPage) {
  return isPublished(trackingPage) && !trackingPage.default;
}

function isDeleteEnabled(trackingPage) {
  return !isPublished(trackingPage);
}

TrackingPagesListItem.propTypes = {
  trackingPage: PropTypes.object.isRequired,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  onMakeDefault: PropTypes.func,
  onLiveLinkClick: PropTypes.func,
};

export default React.memo(TrackingPagesListItem);
