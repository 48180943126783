import React, { useCallback, useState } from 'react';
import makeAsyncScript from 'react-async-script';

import Loader from '../components/common/Loader';
import Text from '../components/common/Text';

function makeAsyncScriptComponentWithLoader(getScriptURL, options) {
  return (Component) => {
    const AsyncComponent = makeAsyncScript(getScriptURL, options)(Component);

    function ComponentWithLoader(props) {
      const [isLoading, setIsLoading] = useState(true);
      const [hasError, setHasError] = useState(false);

      const onLoad = useCallback((state) => {
        setIsLoading(false);
        setHasError(!!state.errored);
      }, []);

      return (
        <React.Fragment>
          {isLoading && <Loader />}
          {hasError && <Text>Failed to load preview</Text>}
          <AsyncComponent {...props} asyncScriptOnLoad={onLoad} />
        </React.Fragment>
      );
    }

    return ComponentWithLoader;
  };
}

export default makeAsyncScriptComponentWithLoader;
