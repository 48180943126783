import React from 'react';
import classNames from 'classnames';

import { getStatusLabel, isPublished } from './editorStatusHelpers';
import Locale from '../../../Translate';

import './EditorStatusBadge.scss';

const EditorStatusBadge = ({ status }) => (
  <div styleName={classNames('status-badge', { published: isPublished(status) })}>
    <Locale>{getStatusLabel(status)}</Locale>
  </div>
);

export default EditorStatusBadge;