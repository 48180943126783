import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import Locale from '../../Translate';
import BasicModal from '../BasicModal';
import CheckboxList from '../../common/CheckboxList';
import ModalButtonsContainer from '../ModalButtonsContainer';

import './DeleteModal.scss';

class DeleteModal extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (!props.show) {
      return { checkValues: null };
    }

    return state;
  }

  onChange = (value) => {
    this.setState({checkValues: {...this.state.checkValues, ...value}});
  };

  render() {
    const {
      onDelete,
      onCancel,
      show,
      name = '',
      title,
      message,
      warningMessages,
      nameElement,
    } = this.props;

    const { checkValues } = this.state;
    const areWarningsAccepted = !!checkValues && warningMessages.every(item => checkValues[item] === true);

    return (
      <BasicModal
        show={show}
        title={title}
        onClose={onCancel}
        danger
      >
        <h1 styleName="title"><Locale>{title}</Locale></h1>
        <div styleName="blockquote">
          <Locale>
            {message}
          </Locale>
          <blockquote>
            { nameElement || name }
          </blockquote>
        </div>
        <div styleName="warnings-checklist">
          <h4>
            <Locale>Be aware that:</Locale>
          </h4>
          <CheckboxList
            onChange={this.onChange}
            items={warningMessages}
            values={checkValues}
          />
          <ModalButtonsContainer align="right">
            <Button onClick={onCancel} primary gray outline>
              Cancel
            </Button>
            <Button onClick={onDelete} disabled={!areWarningsAccepted} primary danger>
              Delete
            </Button>
          </ModalButtonsContainer>
        </div>
      </BasicModal>
    );
  }
}

DeleteModal.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  warningMessages: PropTypes.arrayOf(PropTypes.string),
  nameElement: PropTypes.element,
};

export default DeleteModal;