import React from 'react';
import PropTypes from 'prop-types';
import RightIcon from '../../../icons/right.svg';
import Locale from '../../Translate/Locale';
import { Link } from 'react-router-dom';

import './ExternalLink.scss';

/**
 * Replacement for links which are looked the same as external
 */
const InternalLink = ({ children, ...props }) => (
  <Link styleName="link" {...props}>
    <RightIcon styleName="icon" />
    <Locale>{children}</Locale>
  </Link>
);

InternalLink.propTypes = {
  children: PropTypes.string,
};

export default InternalLink;