import React from 'react';

import './StartGuideImage.scss';

function StartGuideImage(props) {
  return (
    <div styleName="image-container">
      <img styleName="image" src={props.src} alt={props.alt} />
    </div>
  );
}

export default React.memo(StartGuideImage);