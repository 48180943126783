import { useState, useEffect, useMemo } from 'react';
import useApiAction from '../../../hooks/useApiAction';
import { getLanguages } from '../../../utils/langHelper';

function mergeTranslations(target, source) {
  const result = {};

  getLanguages().forEach((lang) => {
    result[lang] = { ...target[lang], ...source[lang] };
  });

  return result;
}

function useShopReturnPortalsTranslations(shopId) {
  const [isChanged, setIsChanged] = useState(false);
  const [translations, setTranslations] = useState({});

  const [loadServerTranslationsData, { result: serverTranslationsData, isLoading }] = useApiAction(
    (api) => api.getReturnPortalsTranslations(shopId),
    [shopId]
  );

  const [publishTranslations, { isLoading: isPublishing }] = useApiAction(
    (api) =>
      api.saveReturnPortalsTranslations(shopId, translations).then(() => {
        setIsChanged(false);
        loadServerTranslationsData();
      }),
    [shopId, translations],
    { feedbackMessage: 'Translations successfully published' }
  );

  const mergedTranslations = useMemo(() => {
    if (serverTranslationsData) {
      return mergeTranslations(serverTranslationsData.translations, translations);
    }

    return translations;
  }, [serverTranslationsData, translations]);

  useEffect(() => {
    loadServerTranslationsData();
  }, [loadServerTranslationsData]);

  const changeTranslations = (data) => {
    setIsChanged(true);
    setTranslations(data);
  };

  const resetTranslations = () => {
    setIsChanged(false);
    setTranslations({});
  };

  return {
    isLoading,
    isChanged,
    templateTranslations: serverTranslationsData?.translations,
    translations: mergedTranslations,
    publishTranslations,
    isPublishing,
    resetTranslations,
    changeTranslations,
  };
}

export default useShopReturnPortalsTranslations;
