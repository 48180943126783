import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '../../../icons/close.svg';

import './CloseButton.scss';

function CloseButton(props) {
  const { label = 'Close', onClick, iconSize = 24 } = props;

  return (
    <button styleName="button" aria-label={label} onClick={onClick}>
      <CloseIcon style={{ width: iconSize, height: iconSize }} />
    </button>
  );
}

CloseButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  iconSize: PropTypes.number,
};

export default React.memo(CloseButton);
