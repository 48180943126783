import React from 'react';
import Loader from '../../components/common/Loader';
import Error5xxPage from '../../components/error-pages/Error5xx';

const defaults = {
  LoaderComponent: Loader,
  ErrorComponent: ({ error }) => {
    console.error(error);
    return <Error5xxPage message="Internal Application" />;
  },
  NoDataComponent: () => null,
};

function loadWrapper(args) {
  const {
    LoaderComponent,
    ErrorComponent,
    NoDataComponent,
  } = {
    ...defaults,
    ...args,
  };

  return (DecoratedComponent) => {
    class LoadWrapper extends React.PureComponent {
      componentWillMount() {
        this.checkIfLoaded(this.props);
      }

      componentWillUpdate(nextProps) {
        this.checkIfLoaded(nextProps);
      }

      checkIfLoaded(props) {
        const {
          isLoaded,
          isLoading,
          error,
          fetchAction,
        } = props;

        if (!isLoaded && !isLoading && !error) {
          fetchAction();
        }
      }

      render() {
        const {
          isLoaded,
          isLoading,
          error,
        } = this.props;

        if (isLoaded) {
          return <DecoratedComponent {...this.props} />;
        }

        if (isLoading) {
          return <LoaderComponent {...this.props} />;
        }

        if (error) {
          return <ErrorComponent {...this.props} />;
        }

        return <NoDataComponent {...this.props} />;
      }
    }

    return LoadWrapper;
  };
}

export default loadWrapper;