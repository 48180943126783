import { connectedLoadWrapper } from './loadWrapper';
import container from '../container';

export const loadPermissions = ({ getPermissions, ...args }) => connectedLoadWrapper({
  ...args,
  isLoadedSelector: state => {
    const {
      user: {
        selectedShopId
      },
      permissions: {
        permissionsByShop
      },
    } = state;

    return selectedShopId && permissionsByShop[selectedShopId];
  },
  isLoadingSelector: state => state.permissions.loading,
  errorSelector: state => state.permissions.error,
  fetchAction: ({ selectedShopId }) => getPermissions(selectedShopId),
});

export default loadPermissions({ getPermissions: container.actions.getPermissions });