import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ConfigurationPanel from './ConfigurationPanel';
import ConfirmExitModal from '../editors/common/ExitButton/ConfirmExitModal';
import { widgetSettingsSelector } from '../../reducers/selectors';
import Arrow from '../../icons/arrow-right.svg';
import { getWidgetWithPreview } from '../editors/widgetsHelper';
import { scrollElementIntoView } from '../editors/domHelpers';
import WidgetPreviewCanvas from './WidgetPreviewCanvas';
import EditorHeader from '../editors/common/EditorHeader/EditorHeader';
import { resetScrollWidget } from '../../actions/widgetSettings';

import './WidgetsEditorContainer.scss';

export class WidgetsEditorContainer extends React.PureComponent {
  state = {
    previewMode: 'mobile',
    isExitModalOpened: false,
    isExpanded: true,
  };

  componentDidUpdate() {
    const { scrollToWidgetId } = this.props;
    if (scrollToWidgetId && scrollElementIntoView(scrollToWidgetId)) {
      this.props.resetScrollToWidget();
    }
  }

  toggleSidePanel = () => {
    this.setState((state) => ({ isExpanded: !state.isExpanded }));
  };

  onPreviewModeSelect = (previewMode) => {
    this.setState({ previewMode });
  };

  onExitModalShow = () => {
    if (this.props.isChanged) {
      this.setState({ isExitModalOpened: true });
    } else {
      this.props.onExit();
    }
  };

  onExitModalHide = () => {
    this.setState({ isExitModalOpened: false });
  };

  onSaveAndExit = () => {
    this.props.onSave();
    this.props.onExit();
  };

  render() {
    const { previewMode, isExitModalOpened, isExpanded } = this.state;

    const {
      name,
      status,
      widgets,
      settings,
      selectedWidgetId,
      previewWidget,
      editorType,
      onExit,
      children,
      renderHeaderButtons,
      headerButtons,
    } = this.props;

    return (
      <div styleName={classNames('container', { collapsed: !isExpanded })}>
        <div styleName="expand-button" onClick={this.toggleSidePanel}>
          <Arrow styleName="expand-icon" />
        </div>
        <div styleName="side-panel">
          {isExpanded && (
            <ConfigurationPanel
              settings={settings}
              widgets={widgets}
              previewWidget={previewWidget}
              selectedWidgetId={selectedWidgetId}
              title={name}
              onExit={this.onExitModalShow}
            />
          )}
        </div>
        <div styleName="main">
          <div styleName="header">
            <EditorHeader
              status={status}
              previewMode={previewMode}
              onPreviewModeSelect={this.onPreviewModeSelect}
              renderButtons={renderHeaderButtons}
              headerButtons={headerButtons}
            />
          </div>
          <div styleName="body">
            <WidgetPreviewCanvas mobileView={previewMode === 'mobile'} editorType={editorType}>
              {children(getWidgetWithPreview(widgets, previewWidget), settings, previewMode)}
            </WidgetPreviewCanvas>
          </div>
        </div>
        <ConfirmExitModal
          show={isExitModalOpened}
          onClose={this.onExitModalHide}
          onCancel={onExit}
          onOK={this.onSaveAndExit}
        />
      </div>
    );
  }
}

WidgetsEditorContainer.defaultProps = {
  children: () => null,
};

WidgetsEditorContainer.propTypes = {
  selectedWidgetId: PropTypes.string,
  widgets: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.object,
  onExit: PropTypes.func,
  onSave: PropTypes.func,
  renderHeaderButtons: PropTypes.func,
  headerButtons: PropTypes.element,
  children: PropTypes.func,

  settingsPanels: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    name,
    status,
    widgets = [],
    settings = {},
    previewWidget,
    scrollToWidgetId,
    selectedWidgetId,
    isChanged,
  } = widgetSettingsSelector(state);

  return {
    name,
    status,
    widgets,
    settings,
    previewWidget,
    scrollToWidgetId,
    selectedWidgetId,
    isChanged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetScrollToWidget: () => dispatch(resetScrollWidget()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsEditorContainer);
