import Heading3 from '../../common/Heading3/Heading3';
import React from 'react';

import './NotificationLog.scss';

export function NotificationsLogContainer(props) {
  return (
    <div key="data" styleName="container">
      <Heading3>Notifications Log</Heading3>
      {props.children}
    </div>
  );
}
