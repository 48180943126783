import React from 'react';
import classNames from 'classnames';

import DesktopIcon from '../../../../icons/device-desktop.svg';
import MobileIcon from '../../../../icons/device-smartphone.svg';
import styles from './PreviewTabs.scss';

const getTabIconStyles = (selected, value) => classNames(
  styles['tab-icon'],
  {
    [styles.selected]: selected === value,
  }
);

class PreviewTabs extends React.PureComponent {

  render () {
    const {
      previewMode,
      onPreviewModeSelect,
      forHtmlPreview,
    } = this.props;

    const headerStyle = classNames('preview-mode-tabs',{
      'bluish'  : forHtmlPreview
    });

    return (
      <div styleName={headerStyle}>
        <MobileIcon
          className={getTabIconStyles(previewMode, 'mobile')}
          onClick={() => onPreviewModeSelect('mobile')}
        />
        <DesktopIcon
          className={getTabIconStyles(previewMode, 'desktop')}
          onClick={() => onPreviewModeSelect('desktop')}
        />
      </div>
    )
  }
}

export default PreviewTabs;