import { useLocation, useNavigate } from 'react-router-dom';

function useSearchParam(name) {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const value = searchParams.get(name);

  const setValue = (newValue) => {
    searchParams.set(name, newValue);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return [value, setValue];
}

export default useSearchParam;
