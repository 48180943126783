import React from 'react';

import TextArea from '../../common2/TextArea';
import TakeActionFilesList from './TakeActionFilesList';
import Uploader from '../../common/Uploader';
import Column from '../../common/layout/Column';

function TakeActionForm(props) {
  const { action, onChange, errors, isSubmitting } = props;

  return (
    <Column spacing="small">
      <TextArea label="Comment" name="comment" value={action.comment} disabled={isSubmitting} onChange={onChange} />
      <Uploader
        name="files"
        errors={errors.files}
        maxNumberOfFiles={10}
        label="Upload files"
        helpText="CLAIM_ACTION_REQUIRED_FILES_HELPER"
        onChange={onChange}
        files={action.files}
        isDisabled={isSubmitting}
        headers={{ 'X-CLAIM-ID': action.claimId.toString() }}
        filesList={TakeActionFilesList}
      />
    </Column>
  );
}

export default React.memo(TakeActionForm);
