import React from 'react';

import './RemoveButton.scss';

import RemoveIcon from '../../../../../icons/remove.svg';

function RemoveButton(props) {
  return (
    <button {...props} styleName="button" aria-label={props.ariaLabel || 'Remove'}>
      <RemoveIcon styleName="icon" />
    </button>
  );
}

export default React.memo(RemoveButton);
