import React from 'react';
import { useTranslation } from 'react-i18next';

import BasicTextArea from '../BasicTextArea';
import InputContainer from '../InputContainer';
import useInputId from '../hooks/useInputId';

function TextArea(props) {
  const { label, isRequired, error, helpText, noErrorTranslation, ...inputProps } = props;

  const { t } = useTranslation();
  const id = useInputId();
  const errorText = noErrorTranslation ? error : t(error);

  return (
    <InputContainer
      label={label}
      labelFor={id}
      labelIsRequired={isRequired}
      errorWithTranslation={errorText}
      helpText={helpText}
    >
      <BasicTextArea id={id} isInvalid={!!errorText} {...inputProps} />
    </InputContainer>
  );
}

export default React.memo(TextArea);
