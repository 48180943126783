import trpStatusEnum from '../../../../constants/trackingPageStatusEnum';
import ewStatusEnum from '../../../../constants/embeddedWidgetStatusEnum';

const statusLabels = {
  [trpStatusEnum.DRAFT]: 'Draft',
  [trpStatusEnum.PUBLISHED]: 'Published',
  [ewStatusEnum.LIVE]: 'Live',
};

const publishedStatuses = [
  trpStatusEnum.PUBLISHED,
  ewStatusEnum.LIVE,
];

export function getStatusLabel(status) {
  return statusLabels[status];
}

export function isPublished(status) {
  return publishedStatuses.includes(status);
}