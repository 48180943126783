import React from 'react';
import PropTypes from 'prop-types';
import ImportanceIndicator from './ImportanceIndicator';
import DefaultBadge from '../Badges/DefaultBadge';
import NtsBadge from '../Badges/NtsBadge';
import ReturnBadge from '../Badges/ReturnBadge';
import Link from '../../common/Link';
import { getMessageEditUrl, messageShape } from '../contentLibraryHelper';

import EditIcon from '../../../icons/edit-field.svg';

import './ContentLibraryMessagesListItem.scss';

function ContentLibraryMessagesListItem(props) {
  const { isDefault, isTrackable, isReturn, meaning, title, message, ticketTypeId, language, type } = props.message;
  const editUrl = getMessageEditUrl(type, ticketTypeId, language);

  return (
    <tr>
      <td>
        <ImportanceIndicator type={meaning} />
      </td>
      <td>
        <span styleName="title">{title}</span>
      </td>
      <td>
        <div styleName="message">
          {message}
          {!isTrackable && (
            <div styleName="badge">
              <NtsBadge />
            </div>
          )}
          {isReturn && (
            <div styleName="badge">
              <ReturnBadge />
            </div>
          )}
        </div>
      </td>
      <td>
        <DefaultBadge changed={!isDefault} />
      </td>
      <td>
        <Link to={editUrl}>
          <EditIcon styleName="edit-icon" />
        </Link>
      </td>
    </tr>
  );
}

ContentLibraryMessagesListItem.propTypes = {
  message: PropTypes.shape(messageShape).isRequired,
};

export default React.memo(ContentLibraryMessagesListItem);
