import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../common2/Badge';

/* eslint-disable camelcase */
const claimStatusTitleIntent = {
  in_progress: { intent: 'neutral', title: 'In progress' },
  submitted: { intent: 'neutral', title: 'Submitted' },
  resubmitted: { intent: 'neutral', title: 'Resubmitted' },
  action_required: { intent: 'warning', title: 'Action required' },
  accepted: { intent: 'positive', title: 'Accepted' },
  credit_note_pending: { intent: 'positive', title: 'Credit note pending' },
  successful: { intent: 'positive', title: 'Successful' },
  declined: { intent: 'alert', title: 'Declined' },
  escalating: { intent: 'neutral', title: 'Escalating' },
  payment_overdue: { intent: 'warning', title: 'Payment overdue' },
  incorrect_payment: { intent: 'warning', title: 'Incorrect payment' },
  paid: { intent: 'positive', title: 'Paid' },
  unknown: { intent: 'alert', title: 'Unknown' },
};
/* eslint-enable camelcase */

function ClaimStatusBadge({ status }) {
  const { title, intent } = claimStatusTitleIntent[status] || claimStatusTitleIntent.unknown;

  return <Badge intent={intent}>{title}</Badge>;
}

ClaimStatusBadge.propTypes = {
  // TODO: instead of listing take values later from claimStatusEnum when it'll have all the statuses
  status: PropTypes.oneOf([
    'in_progress',
    'submitted',
    'action_required',
    'accepted',
    'credit_note_pending',
    'successful',
    'declined',
    'resubmitted',
    'escalating',
    'payment_overdue',
    'incorrect_payment',
    'paid',
  ]),
};

export default React.memo(ClaimStatusBadge);
