import React from 'react';
import { Link } from 'react-router-dom';

import './link.scss';

function InternalLink(props) {
  const {
    to,
    children,
  } = props;

  return (
    <Link
      styleName="link"
      to={to}
    >
      {children}
    </Link>
  );
}

export default React.memo(InternalLink);