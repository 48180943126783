import React, { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select3';
import { withTranslation } from 'react-i18next';

import components from '../common2/BasicSelect/components';
import { getOptionForValue } from '../common2/BasicSelect/utils';
import { styles } from './localizationStyles';
import InputContainer from '../common2/InputContainer';
import useInputId from '../common2/hooks/useInputId';
import Option from './Option';
import Value from './Value';
import { filterActiveShopLocalizations } from '../../utils/shopLocalizationsHelper';

function LocalizationSelect(props) {
  const { localizations, value: localizationId, onChange, t, name, placeholder = 'Select a localization' } = props;
  const inputId = useInputId();

  const activeLocalizations = useMemo(() => filterActiveShopLocalizations(localizations), [localizations]);

  const onChangeHandler = useCallback(
    (option) => {
      onChange(parseInt(option.value));
    },
    [onChange]
  );

  const options = React.useMemo(
    () =>
      activeLocalizations.map((localization) => ({
        label: localization.url,
        value: localization.id,
        badgeText: localization.language,
      })),
    [activeLocalizations]
  );

  return (
    <InputContainer label="Localization" labelFor={inputId}>
      <ReactSelect
        inputId={inputId}
        className="localization-select-container"
        name={name}
        value={getOptionForValue(localizationId, options)}
        placeholder={t(placeholder)}
        options={options}
        onChange={onChangeHandler}
        styles={styles}
        components={components}
        formatOptionLabel={formatOptionLabel}
      />
    </InputContainer>
  );
}

function formatOptionLabel(props, selectContext) {
  const { value, label, badgeText, isFocused } = props;
  const { context, selectValue } = selectContext;

  if (context === 'menu') {
    return <Option badgeText={badgeText} isFocused={isFocused} label={label} selectValue={selectValue} value={value} />;
  } else if (context === 'value') {
    return <Value badgeText={badgeText} label={label} />;
  }

  return null;
}

export default React.memo(withTranslation()(LocalizationSelect));
