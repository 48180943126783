import React from 'react';
import PropTypes from 'prop-types';

import BasicModal from './BasicModal';
import Locale from '../Translate/Locale';

import styles from './Modal.scss';

class Modal extends React.PureComponent {
  render() {
    const {
      icon,
      title,
      children,
    } = this.props;

    return (
      <BasicModal {...this.props}>
        <div className={styles.content}>
          { icon && <div className={styles.icon}>{icon}</div> }
          <h1 className={styles.title}><Locale>{title}</Locale></h1>
          {children}
        </div>
      </BasicModal>
    );
  }
}

Modal.defaultProps = {
  show: false,
  onClose: () => {},
};

Modal.propTypes = {
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  show: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default Modal;
