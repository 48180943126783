import React from 'react';
import PropTypes from 'prop-types';

import getRoles from '../../firewall/getRoles';
import { SHOP_SETTINGS_PERMISSION } from '../../firewall/permissions';
import Link from '../common/Link';
import { MenuItem } from '../modal/Menu';
import useFirewall from '../../hooks/useFirewall';

import UserButton from './UserButton';
import ShopMenuItem from './ShopMenuItem';

import './UserMenu.scss';

export function UserMenu(props) {
  const { userInfo, selectedShop, onSelectShop, onLogout } = props;

  const firewall = useFirewall(selectedShop.id);
  const roles = getRoles(firewall);
  const shopSettingsEnabled = firewall.hasAccess(SHOP_SETTINGS_PERMISSION);

  return (
    <UserButton title={selectedShop ? selectedShop.name : userInfo.name}>
      <div styleName="container" data-test-id="user-menu">
        {userInfo.shops.map((shop) => {
          const selected = shop.id === selectedShop.id;
          return (
            <ShopMenuItem
              key={shop.id}
              shopName={shop.name}
              selected={selected}
              roles={roles}
              onClick={() => !selected && onSelectShop(shop.id)}
            />
          );
        })}
        <div styleName="separator  top" />
        <Link to="/settings/user" key="account">
          <MenuItem>Account Settings</MenuItem>
        </Link>
        {shopSettingsEnabled && (
          <Link to="/settings/shop" key="settings">
            <MenuItem>Shop Settings</MenuItem>
          </Link>
        )}
        <a styleName="nav-a" target="_blank" href="https://status.sevensenders.com">
          <MenuItem key="status-page">Status Page</MenuItem>
        </a>
        <div styleName="separator" />
        <MenuItem key="logout" onClick={onLogout}>
          Log out
        </MenuItem>
      </div>
    </UserButton>
  );
}

const shopType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

UserMenu.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    shops: PropTypes.arrayOf(shopType),
  }).isRequired,
  selectedShop: shopType,
  onSelectShop: PropTypes.func,
  onLogout: PropTypes.func,
};

UserMenu.defaultProps = {
  selectedShop: {},
  onSelectShop: () => {},
};

export default React.memo(UserMenu);
