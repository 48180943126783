import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-responsive-modal';
import CloseIcon from '../../../icons/close-large.svg';

import styles from './BasicModal.scss';

class BasicModal extends React.PureComponent {
  render() {
    const {
      success,
      warning,
      danger,
      show,
      onClose,
      children,
    } = this.props;

    const headerClassName = classNames(styles.header, {
      [styles['header-warning']]: warning,
      [styles['header-danger']]: danger,
      [styles['header-success']]: success,
    });

    return (
      <ReactModal
        classNames={styles}
        open={show}
        onClose={onClose}
        closeIconSvgPath={<CloseIcon />}
        center
      >
        <div className={headerClassName} />
        {children}
      </ReactModal>
    );
  }
}

BasicModal.defaultProps = {
  show: false,
  onClose: () => {},
};

BasicModal.propTypes = {
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  show: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default BasicModal;
