import React from 'react';
import PropTypes from 'prop-types';

import Heading3 from '../../common/Heading3';
import Icon from '../Icon';

import './ModalHeader.scss';

function ModalHeader({ children, icon, intent }) {
  return (
    <div styleName="header">
      <Icon icon={icon} size={40} intent={intent} />
      <Heading3 styleName="heading">{children}</Heading3>
    </div>
  );
}

ModalHeader.propTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.string,
  intent: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
};

export default React.memo(ModalHeader);
