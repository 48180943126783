import React from 'react';
import Locale from '../../Translate';
import ExternalLink from '../../common2/links/ExternalLink';
import ViewIcon from '../../../icons/view.svg';

import './TrackingPageLink.scss';

function TrackingPageLink(props) {
  const { href } = props;

  return (
    <React.Fragment>
      <ViewIcon styleName="icon" />
      <div styleName="view-link">
        <ExternalLink to={href}>
          <Locale>View tracking page</Locale>
        </ExternalLink>
      </div>
    </React.Fragment>
  );
}

export default React.memo(TrackingPageLink);
