import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { getUrlType } from '../../utils/urlPathHelper';

function TouchpointsOverviewRedirect(props) {
  const { data = [], urls } = props;
  const location = useLocation();

  const urlType = getUrlType(location.pathname, urls);

  if (urlType === 'base' && data.length === 0) {
    return <Navigate to={urls.start} />;
  }

  if (urlType === 'start' && data.length > 0) {
    return <Navigate to={urls.base} />;
  }

  return null;
}

export default React.memo(TouchpointsOverviewRedirect);
