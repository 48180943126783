import React from 'react';

import Button from '../../common/Button/Button';
import Locale from '../../Translate/Locale';
import ExternalLink from '../../common2/links/ExternalLink';

import NotificationsIcon from '../../../icons/customer-notifications.svg';

import './EmptyNotificaitonLog.scss';

function EmptyNotificationLog() {
  return (
    <div styleName="container">
      <NotificationsIcon styleName="icon" />
      <div styleName="text">
        <Locale>You have no notifications for this order/shipment.</Locale>
      </div>
      <ExternalLink to="/customer-notifications/email-design-templates">
        <Button>Setup a notification</Button>
      </ExternalLink>
    </div>
  );
}

export default React.memo(EmptyNotificationLog);
