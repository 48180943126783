import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

function ProgressBar(props) {
  const { max, value } = props;

  return (
    <div styleName="progress" role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax={max}>
      <span styleName="bar" style={{ width: `${(value / max) * 100}%` }} />
    </div>
  );
}

ProgressBar.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default React.memo(ProgressBar);
