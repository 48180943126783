import React from 'react';
import classNames from 'classnames';

import './SettingsBodyWrapper.scss';

const SettingsBodyWrapper = ({ children, fill }) => (
  <div styleName={classNames('wrapper', {fill})}>
    {children}
  </div>
);

export default SettingsBodyWrapper;