import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from '../common2/Input';
import LocalizationSelect from '../LocalizationSelect/LocalizationSelect';
import Button from '../common2/Button';
import Column from '../common/layout/Column';
import Modal from '../common2/Modal';
import ModalContent from '../common2/Modal/ModalContent';
import ModalFooter from '../common2/Modal/ModalFooter';
import ModalHeader from '../common2/Modal/ModalHeader';
import DuplicateIcon from '../../icons/duplicate.svg';

function DuplicateTrackingPageModal(props) {
  const { onCancel, onDuplicate, trackingPage, localizations } = props;
  const [name, setName] = useState('');
  const [localization, setLocalization] = useState(null);
  const isValid = !!name && !!localization;

  const onChangeName = (name) => setName(name);

  const onChangeLocalization = (localization) => setLocalization(localization);

  const onClickDuplicate = () => {
    onDuplicate(trackingPage.id, { name, localization });
  };

  useEffect(() => {
    if (trackingPage?.name) {
      setName(`${trackingPage.name} – copy`);
    }
  }, [trackingPage]);

  return (
    <Modal show={!!trackingPage} onClose={onCancel}>
      <ModalHeader icon={DuplicateIcon}>Duplicate tracking page</ModalHeader>
      <ModalContent>
        <Column spacing="small">
          <Input label="Enter a new name" value={name} onChange={onChangeName} />
          <LocalizationSelect
            name="localization"
            placeholder="Select a localization"
            localizations={localizations}
            value={localization}
            onChange={onChangeLocalization}
          />
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onClickDuplicate} disabled={!isValid}>
          Duplicate
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DuplicateTrackingPageModal.defaultProps = {
  onDuplicate: () => {},
};

DuplicateTrackingPageModal.propTypes = {
  trackingPage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onDuplicate: PropTypes.func,
  onCancel: PropTypes.func,
  localizations: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(DuplicateTrackingPageModal);
