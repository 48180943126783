import { useCallback, useState } from 'react';

import useApiAction from '../../../hooks/useApiAction';
import useStepValidation from '../../parcel-finder/claims/common/useStepValidation';
import { getFirstValidationError, takeActionValidator } from '../../parcel-finder/claims/claimValidators';

/*
 * @param {object} params
 * @param {string} params.reason
 * @param {number} params.claimId
 * @param {function} params.onSubmitSuccess
 * @returns {{action: {comment: string, files: []}, submit: function, onChange: function, errors: object}}
 */

export function useTakeAction(params) {
  const { reason, claimId, onSubmitSuccess } = params;
  const [action, setAction] = useState({
    claimId,
    comment: '',
    files: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitCustomerAction] = useApiAction(
    (api) =>
      api.submitCustomerAction(claimId, {
        files: action.files.map((file) => ({
          id: file.response.body.id,
          fileType: file.fileType,
        })),
        reason: reason || 'Other',
        comment: action.comment,
      }),
    [claimId, action, reason],
    {
      feedbackMessage: 'CUSTOMER_ACTION_SUCCESSFULLY_SUBMITTED_NOTICE',
    }
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const { success } = await submitCustomerAction();

    if (success) {
      onSubmitSuccess();
    }

    setIsSubmitting(false);
  }, [submitCustomerAction, onSubmitSuccess]);

  const onChange = useCallback((value, name) => setAction((prev) => ({ ...prev, [name]: value })), [setAction]);

  const [errors, submit] = useStepValidation(action, onSubmit, takeActionValidator);

  return {
    action,
    onChange,
    errors,
    firstError: getFirstValidationError(errors),
    submit,
    isSubmitting,
  };
}
