import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import Locale from '../Translate/Locale';
import Drawer from '../common2/drawer/Drawer';
import DrawerHeader from '../common2/drawer/DrawerHeader';
import DrawerFooter from '../common2/drawer/DrawerFooter';
import Button from '../common/Button';
import ShopLocalizationForm from './ShopLocalizationForm/ShopLocalizationForm';
import { isUrlValid } from '../../utils/urlValidationHelper';
import useNotifications from '../../hooks/useNotifications';

import { createShopLocalization, editShopLocalization, restoreShopLocalization } from '../../actions/shopLocalizations';
import { selectedShopIdSelector, shopLocalizationFormSelector } from '../../reducers/selectors';

function LocalizationFormDrawer({ isOpen, onClose }) {
  const { showError } = useNotifications();
  const dispatch = useDispatch();
  const shopId = useSelector((state) => selectedShopIdSelector(state));
  const { localization } = useSelector((state) => shopLocalizationFormSelector(state));
  const { url: propUrl, language: propLanguage } = localization;

  const [state, setState] = useState({ url: localization.url, language: localization.language });
  const [stateErrors, setStateErros] = useState({});

  useEffect(() => {
    const { url, language } = state;
    if (url !== propUrl || language !== propLanguage) {
      setState({ url: propUrl, language: propLanguage });
    }
  }, [propUrl, propLanguage]);

  const handleChange = (value, name) => setState({ ...state, [name]: value });

  const handleCreateShopLocalization = (shopId, url, language) =>
    dispatch(createShopLocalization(shopId, url, language)).then(({ error }) => error && showError());

  const handleEditShopLocalization = (id, url, language) =>
    dispatch(editShopLocalization(id, url, language)).then(({ error }) => error && showError());

  const handleRestoreShopLocalization = (id) =>
    dispatch(restoreShopLocalization(id)).then(({ error }) => error && showError());

  const handleSubmit = () => {
    const { url, language } = state;
    const { urlError, languageError } = getFormErrors(url, language);
    setStateErros({ urlError, languageError });

    if (!urlError && !languageError) {
      if (isEmpty(localization)) {
        handleCreateShopLocalization(shopId, url, language);
      } else {
        const { id, archived } = localization;

        const action = archived ? handleRestoreShopLocalization : handleEditShopLocalization;
        action(id, url, language);
      }
    }
  };

  const handlePanelClose = () => {
    setStateErros({});
    onClose();
  };

  const { headerText, submitButtonText } = getFormTexts(localization);
  const { url, language } = state;
  const { urlError, languageError } = stateErrors;

  return (
    <Drawer isOpen={isOpen}>
      <DrawerHeader onClose={handlePanelClose}>
        <Locale>{headerText}</Locale>
      </DrawerHeader>
      <ShopLocalizationForm
        url={url}
        language={language}
        urlError={urlError}
        languageError={languageError}
        isNew={isEmpty(localization)}
        archived={get(localization, 'archived', false)}
        onChange={handleChange}
      />
      <DrawerFooter>
        <Button onClick={handleSubmit}>{submitButtonText}</Button>
      </DrawerFooter>
    </Drawer>
  );
}

function getFormErrors(url, language) {
  const urlError = url && isUrlValid(url) ? '' : 'The url provided is invalid';
  const languageError = language ? '' : 'Language must be selected';
  return { urlError, languageError };
}

function getFormTexts(localization) {
  const formTexts = {
    archived: {
      headerText: 'Archived localisation',
      submitButtonText: 'Restore localisation',
    },
    unArchived: {
      headerText: 'Edit localisation',
      submitButtonText: 'Edit localisation',
    },
  };

  if (isEmpty(localization)) {
    return { headerText: 'Add a localisation', submitButtonText: 'Add localisation' };
  }

  return localization.archived ? formTexts.archived : formTexts.unArchived;
}

LocalizationFormDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(LocalizationFormDrawer);
