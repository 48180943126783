import React from 'react';
import Locale from '../../Translate';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Card.scss';

class Card extends React.PureComponent {
  render() {
    const { title, children, noPadding } = this.props;

    return (
      <div styleName={classNames('card', { noPadding })}>
        { title && <span styleName="title"><Locale>{title}</Locale></span> }
        { children }
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  noPadding: PropTypes.bool
};

Card.defaultProps = {
  noPadding: false
};

export default Card;
