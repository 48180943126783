import React from 'react';
import PropTypes from 'prop-types';

import { Locale } from '../../Translate';

import './DefaultBadge.scss';

const DefaultBadge = ({ children }) => (
  <span styleName="default-badge">
    <Locale>{children}</Locale>
  </span>
);

DefaultBadge.propTypes = {
  children: PropTypes.string,
};

export default DefaultBadge;