import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../common/Link';
import Button from '../../common2/Button';
import Heading2 from '../../common/Heading2';
import AddIcon from '../../../icons/add.svg';

import './TouchpointsOverview.scss';

class TouchpointsOverview extends React.PureComponent {
  render() {
    const { title, buttonText, buttonLink, children, headerElement: HeaderElement, onClick } = this.props;

    return (
      <div styleName="panel">
        <div styleName="header">
          <Heading2>{title}</Heading2>
          <div styleName="filter-and-button">
            {HeaderElement}
            <div>
              {onClick && (
                <Button icon={AddIcon} onClick={onClick}>
                  {buttonText}
                </Button>
              )}
              {buttonLink && (
                <Link to={buttonLink}>
                  <Button icon={AddIcon}>{buttonText}</Button>
                </Link>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  }
}

TouchpointsOverview.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  headerElement: PropTypes.node,
};

export default TouchpointsOverview;
