import { useMutation } from '@tanstack/react-query';

import useNotifications from '../../../hooks/useNotifications';
import container from '../../../container';

export function useCancelClaim(onClose, onCancel) {
  const { showNotice, showError } = useNotifications();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (claim) => container.touchpointsApi.cancelClaim(claim.id),
    onError: () => {
      showError();
    },
    onSuccess: () => {
      onCancel();
      onClose();
      showNotice({
        message: 'Claim was successfully cancelled and status was updated to declined',
        type: 'success',
      });
    },
  });

  return [mutateAsync, { isLoading }];
}
