import { useCallback, useState } from 'react';

function useModal(initial = null) {
  const [object, setState] = useState(initial);
  const show = useCallback(object => setState(object), []);
  const close = useCallback(() => setState(null), []);

  return [object, show, close];
}

export default useModal;
