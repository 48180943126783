import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../common/Checkbox';
import Input from '../../../common/Input';
import getEventValueObject from '../../../../utils/getEventValueObject';

function CarrierSettings(props) {
  const {
    showCarrierLink,
    carrierLinkLabel,
    showCarrierLogo,
    showCarrierTrackingCode,
    carrierTrackingCodeLabel,
  } = props.settings;

  const onChange = useCallback(
    e => props.onChange(getEventValueObject(e)),
    [props.onChange]
  );

  return (
    <React.Fragment>
      <Checkbox
        name="showCarrierLogo"
        checked={showCarrierLogo}
        onChange={onChange}
      >
        Show carrier logo
      </Checkbox>
      <Checkbox
        name="showCarrierLink"
        checked={showCarrierLink}
        onChange={onChange}
      >
        Show carrier link
      </Checkbox>
      { showCarrierLink &&
        <Input
          label="Carrier link label"
          name="carrierLinkLabel"
          value={carrierLinkLabel}
          onChange={onChange}
        />
      }
      <Checkbox
        name="showCarrierTrackingCode"
        checked={showCarrierTrackingCode}
        onChange={onChange}
      >
        Show carrier tracking code
      </Checkbox>
      { showCarrierTrackingCode &&
        <Input
          label="Carrier tracking code label"
          name="carrierTrackingCodeLabel"
          value={carrierTrackingCodeLabel}
          onChange={onChange}
        />
      }
    </React.Fragment>
  );
}

CarrierSettings.defaultProps = {
  onSettingsChange: () => {},
};

CarrierSettings.propTypes = {
  settings: PropTypes.shape({
    showCarrierLink: PropTypes.bool,
    carrierLinkLabel: PropTypes.string,
    showCarrierLogo: PropTypes.bool,
    showCarrierTrackingCode: PropTypes.bool,
    carrierTrackingCodeLabel: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default React.memo(CarrierSettings);