import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../common/Table';
import UserListItem from './UserListItem';

import './UserList.scss';

const columns = [
  'Full name',
  'Email address',
  'Access type',
  '', // role badges column
];

function UserList(props) {
  const {
    users = [],
  } = props;

  return (
    <div styleName="list">
      <Table columns={columns}>
        { users.map(user => <UserListItem key={user.id} user={user} />) }
      </Table>
    </div>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(UserList);