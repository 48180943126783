import React from 'react';
import Locale from '../../Translate/Locale';
import PropTypes from 'prop-types';
import LeftIcon from '../../../icons/left.svg';

import './PanelWithHeader.scss';

class PanelWithHeader extends React.PureComponent {
  render () {
    const {
      title,
      onClose,
      children
    } = this.props;

    return (
      <div styleName="panel">
        <div styleName="header">
          { onClose && (
            <div styleName="back-button" onClick={onClose} >
              <LeftIcon styleName="back-icon" />
            </div>
          ) }
          <span styleName="title"><Locale>{title}</Locale></span>
        </div>
        <div styleName="body">
          {children}
        </div>
      </div>
    );
  }
}

PanelWithHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};

export default PanelWithHeader;