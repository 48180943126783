import React from 'react';

import Checkbox from '../Checkbox/Checkbox';

import './CheckboxList.scss';

class CheckboxList extends React.PureComponent {
  onChange = (e) => {
    this.props.onChange({[e.target.name]: e.target.checked})
  }

  render() {
    const { values } = this.props;

    return (
      <React.Fragment>
        {
          this.props.items.map(message => (
            <div styleName="checklist-item" key={message}>
              <Checkbox
                onChange={this.onChange}
                name={message}
                key={message}
                checked={!!values && values[message]}
              >
                {message}
              </Checkbox>
            </div>
          ))
        }
      </React.Fragment>
    );
  }
}

export default CheckboxList;