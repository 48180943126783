import React from 'react';

import logError from '../utils/logError';
import Error5xx from './error-pages/Error5xx';

export class ErrorBoundary extends React.Component {
  state = {
    error : false,
  }

  // react throws a warning if this isn't implemented so we do redundant setstate here
  static getDerivedStateFromError() {
    return {error:  true};
  }

  componentDidCatch(errorObj) {
    logError(errorObj);
    this.setState({error:  true});
  }

  render() {
    if (this.state.error) {
      return <Error5xx message="Internal Application" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;