import { useEffect, useMemo } from 'react';

import { useSelectedShopId } from '../../hooks/useSelectedShop';
import useApiAction from '../../hooks/useApiAction';

function useClaimsStats() {
  const shopId = useSelectedShopId();
  const [getClaimsStats, { result, error, isLoading }] = useApiAction((api) => api.getClaimsStats(), [shopId]);
  const claimsStats = useMemo(() => transformClaimsStats(result), [result]);

  useEffect(() => {
    getClaimsStats();
  }, [getClaimsStats]);

  return {
    claimsStats,
    isStatsLoading: isLoading,
    statsError: error,
    reloadClaimsStats: getClaimsStats,
    statusCounts: result,
  };
}

function transformClaimsStats(claimsStats = {}) {
  const {
    totalCount,
    successfulCount,
    declinedCount,
    paidCount,
    incorrectPaymentCount,
    actionRequiredCount,
    acceptedCount,
    creditNotePendingCount,
    paymentOverdueCount,
  } = claimsStats;

  const openCount = totalCount - successfulCount - declinedCount - paidCount - incorrectPaymentCount;

  const acceptedAndAwaitingPaymentCount = acceptedCount + creditNotePendingCount + paymentOverdueCount;

  return {
    totalCount,
    openCount,
    actionRequiredCount,
    acceptedAndAwaitingPaymentCount,
  };
}

export default useClaimsStats;
