export function showIntercom(show) {
  const intercomElement = getIntercomElement();

  if (intercomElement) {
    intercomElement.style.display = show ? '' : 'none';
  }
}

function getIntercomElement() {
  return (
    document.getElementById('intercom-container') || document.getElementsByClassName('intercom-lightweight-app')[0]
  );
}

export async function updateIntercom(attributes) {
  const { Intercom } = window;
  Intercom && Intercom('update', attributes);
}
