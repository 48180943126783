import React from 'react';
import Dropdown from '../../modal/Dropdown';
import { Menu } from '../../modal/Menu';
import MoreActionsIcon from '../../../icons/more-actions.svg';

import './DropdownButton.scss';

class DropdownButton extends React.Component {
  state = {};

  onCloseMenu = () => this.setState({showMenu: false});
  onButtonClick = () => this.setState({showMenu: !this.state.showMenu});

  renderMenu() {
    return (
      <Menu onClose={this.onCloseMenu}>
        {this.props.children}
      </Menu>
    );
  }

  render() {
    if (isMenuEmpty(this.props.children)) {
      return null;
    }

    return (
      <Dropdown
        show={this.state.showMenu}
        onClose={this.onCloseMenu}
        content={this.renderMenu()}
      >
        <MoreActionsIcon styleName="button" onClick={this.onButtonClick} />
      </Dropdown>
    );
  }
}

function isMenuEmpty(children) {
  if (!children) {
    return true;
  }

  return React.Children.toArray(children).every(child => !child);
}

export default DropdownButton;
