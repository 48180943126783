import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../Wizard/Switch';
function ClaimsCenterSwitch(props) {
  return <Switch selected={getLoadingState(props)}>{props.children}</Switch>;
}

export function getLoadingState(state) {
  const { isLoading, error, data, statuses, shipmentTypes, types } = state;

  if (isLoading) {
    return 'loader';
  }

  if (data.length !== 0) {
    return 'results';
  }

  if (error) {
    return 'error';
  }

  const isFilterApplied = statuses.length > 0 || types.length > 0 || shipmentTypes.length > 0;
  if (isFilterApplied && data.length === 0 && !isLoading) {
    return 'no-filter-results';
  }

  return 'no-results';
}

ClaimsCenterSwitch.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  statuses: PropTypes.arrayOf(PropTypes.string),
  shipmentTypes: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(ClaimsCenterSwitch);
