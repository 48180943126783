import React from 'react';
import PropTypes from 'prop-types';

import Heading4 from '../../common/Heading4';
import Locale from '../../Translate/Locale';
import Text from '../../common/Text';
import CloseButton from '../../common2/CloseButton';
import ContactSupportIcon from '../../../icons/contact-support.svg';

import './SidePanelHeader.scss';

function SidePanelHeader(props) {
  const { onClose, children, heading, helpLink } = props;

  return (
    <div styleName="header">
      <CloseButton onClick={onClose} />
      <div styleName="heading">
        <Heading4 styleName="heading">{heading}</Heading4>
        {children && (
          <Text small>
            <Locale>{children}</Locale>
          </Text>
        )}
      </div>
      <a target="_blank" href={helpLink}>
        <ContactSupportIcon styleName="contact-support" />
      </a>
    </div>
  );
}

SidePanelHeader.propTypes = {
  onClose: PropTypes.func,
  heading: PropTypes.string,
  helpLink: PropTypes.string,
  children: PropTypes.string,
};

export default React.memo(SidePanelHeader);
