import React from 'react';
import PropTypes from 'prop-types';

import './SidePanelContent.scss';

function SidePanelContent(props) {
  const { children } = props;

  return <div styleName="content">{children}</div>;
}

SidePanelContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default React.memo(SidePanelContent);
