import React from 'react';
import PropTypes from 'prop-types';

import { Locale } from '../../Translate';
import Tooltip from '../../common/Tooltip';

import InfoIcon from '../../../icons/info.svg';

import './PageWithTitle.scss';

const PageWithTitle = ({ title, children, secondaryText, tooltipText }) => {
  return (
    <div styleName="page">
      <h2 styleName="secondaryText">
        <Locale>{secondaryText}</Locale>
      </h2>
      <div styleName="title-container">
        <h1 styleName="title">
          <Locale>{title}</Locale>
        </h1>
        { !!tooltipText && (
          <div>
            <InfoIcon styleName="help-icon" data-tip data-for="info"/>
            <Tooltip id="info">
              {tooltipText}
            </Tooltip>
          </div>
        ) }
      </div>
      {children}
    </div>
  );
};

PageWithTitle.propTypes = {
  title: PropTypes.string,
  secondaryText: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default PageWithTitle;