import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';
import ExternalLink from '../../common2/links/ExternalLink';
import Text from '../../common/Text/Text';

import TipIcon from '../../../icons/tip.svg';
import './HelpCardContent.scss';

function HelpCardContent({ text, link, linkText }) {
  return (
    <div styleName="container">
      <TipIcon styleName="icon" />
      <div styleName="tip">
        <Text>
          <Locale>{text}</Locale>&nbsp;
        </Text>
        <ExternalLink to={link}>
          <Locale>{linkText}</Locale>
        </ExternalLink>
      </div>
    </div>
  );
}

HelpCardContent.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export default React.memo(HelpCardContent);
