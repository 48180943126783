import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { showIntercom } from '../../../utils/intercomHelper';

import styles from './Drawer.scss';
import Loader from '../../common/Loader';

const duration = 250;

const bgClassNames = {
  enter: styles['background-enter'],
  enterDone: styles['background-enter-done'],
};

const drawerClassNames = {
  enter: styles['drawer-enter'],
  enterDone: styles['drawer-enter-done'],
};

// Note: current Drawer implementation requires it to be rendered all the time
// Code like { isOpen && <Drawer isOpen={true} } won't work because show animation's transition
// depends on styles change. When we mount/dismount no style changes happening.
// "appear" prop helps with that but adds visible delay to show animation (might be due to component mounting)
// Hopefully one day someone knowledgeable can make Drawer great

function Drawer(props) {
  const { isOpen, isLoading, appear, children } = props;

  showIntercom(!isOpen);

  const content = isLoading ? <Loader /> : children;

  return (
    <div role="dialog" aria-hidden={!isOpen}>
      <CSSTransition in={isOpen} appear={appear} timeout={duration} classNames={bgClassNames}>
        <div styleName="background" />
      </CSSTransition>
      <CSSTransition in={isOpen} appear={appear} timeout={duration} classNames={drawerClassNames}>
        <div styleName="drawer">{isOpen && content}</div>
      </CSSTransition>
    </div>
  );
}

export default React.memo(Drawer);
