import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../../SettingsGroupCard';
import LinkSettings from './LinkSettings';

class SiteLinksSettings extends React.PureComponent {
  onShowSiteLinksChange = (e) => {
    this.props.onChange({ showSiteLinks: e.target.checked });
  };

  onSiteLinkTextChange(text, n) {
    const textField = `link${n}Text`;
    this.props.onChange({ [textField]: text });
  };


  onSiteLinkUrlChange(url, n) {
    const urlField = `link${n}Url`;
    this.props.onChange({ [urlField]: url });
  };

  render() {
    const {
      settings: {
        showSiteLinks,
        link1Text,
        link1Url,
        link2Text,
        link2Url,
        link3Text,
        link3Url,
        link4Text,
        link4Url,
      },
    } = this.props;

    return (
      <SettingsGroupCard
        title="Site links"
        subtitle="Add common links to your site here"
        toggleName="showSiteLinks"
        toggleChecked={showSiteLinks}
        onToggle={this.onShowSiteLinksChange}
      >
        <LinkSettings
          title="Site link 1"
          text={link1Text}
          url={link1Url}
          onTextChange={text => this.onSiteLinkTextChange(text, 1)}
          onUrlChange={text => this.onSiteLinkUrlChange(text, 1)}
        />
        <LinkSettings
          title="Site link 2"
          text={link2Text}
          url={link2Url}
          onTextChange={text => this.onSiteLinkTextChange(text, 2)}
          onUrlChange={text => this.onSiteLinkUrlChange(text, 2)}
        />
        <LinkSettings
          title="Site link 3"
          text={link3Text}
          url={link3Url}
          onTextChange={text => this.onSiteLinkTextChange(text, 3)}
          onUrlChange={text => this.onSiteLinkUrlChange(text, 3)}
        />
        <LinkSettings
          title="Site link 4"
          text={link4Text}
          url={link4Url}
          onTextChange={text => this.onSiteLinkTextChange(text, 4)}
          onUrlChange={text => this.onSiteLinkUrlChange(text, 4)}
        />
      </SettingsGroupCard>
    );
  }
}

SiteLinksSettings.propTypes = {
  showSiteLinks: PropTypes.bool,
  link1Text: PropTypes.string,
  link1Url: PropTypes.string,
  link2Text: PropTypes.string,
  link2Url: PropTypes.string,
  link3Text: PropTypes.string,
  link3Url: PropTypes.string,
  link4Text: PropTypes.string,
  link4Url: PropTypes.string,
  onChange: PropTypes.func,
};

export default SiteLinksSettings;