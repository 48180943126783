export function applyFallbackColorSettings(colorSettings) {
  const {
    bodyBackgroundColor,
    bodyTextColor,
    bodyTextLinksColor,
    bodyTextLinksHoverColor,
  } = colorSettings || {};

  return {
    carrierBackgroundColor: bodyBackgroundColor,
    carrierTextColor: bodyTextColor,
    carrierTextLinkColor: bodyTextLinksColor,
    carrierTextLinksHoverColor: bodyTextLinksHoverColor,
    ...colorSettings,
  };
}