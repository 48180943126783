import React from 'react';

import InformationIcon from '../../../../icons/info.svg';
import Locale from '../../../Translate/Locale';
import ExternalLink from '../../../common/ExternalLink';

import './WidgetInfo.scss';

function WidgetInfo({ docUrl, children, linkTitle }) {
  return (
    <div styleName="information">
      <InformationIcon styleName="icon" />
      <div styleName="description">
        <Locale>{children}</Locale>
      </div>
      <ExternalLink href={docUrl}>
        {linkTitle}
      </ExternalLink>
    </div>
  );
}

WidgetInfo.defaultProps = {
  linkTitle: 'Read our documentation',
};

export default WidgetInfo;