import React from 'react';

import Locale from '../../Translate/Locale';

import './Heading2.scss';

function Heading2(props) {
  return (
    <h2 className={props.className} styleName="default">
      <Locale options={props.localeOptions}>{props.children}</Locale>
    </h2>
  );
}

export default Heading2;