import React from 'react';

import PageContainer from './pagecontainer/PageContainer';
import Loader from './common/Loader';

function PageLoader(props) {
  return (
    <PageContainer {...props}>
      <Loader />
    </PageContainer>
  );
}

export default PageLoader;
