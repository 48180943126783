import { withApiActions } from './apiActionHelper';

export function getContentLibraryMessages(shopId, language) {
  return withApiActions(
    api => api.getContentLibraryMessages({shopId, language}).then(data => ({ data, language })),
    'CONTENT_LIBRARY_MESSAGES'
  );
}

export function editContentLibraryMessage(messageId, data) {
  return withApiActions(
    api => api.editContentLibraryMessage(messageId, data),
    'EDIT_CONTENT_LIBRARY_MESSAGE'
  );
}

export function resetContentLibraryMessage({id, shopId, type, language, ticketTypeId}) {
  return withApiActions(
    api => api.resetContentLibraryMessage(id)
      .then(() => api.getContentLibraryMessages({ shopId, language, type, ticketTypeId })),
    'RESET_CONTENT_LIBRARY_MESSAGE'
  );
}

export function setContentLibraryFilter(messageType, meaningType, filterText) {
  return {
    type: 'CONTENT_LIBRARY_FILTER',
    messageType,
    meaningType,
    filterText,
  };
}

/**
 * Fetches all message translations for message with specified type, ticket and shop
 */
export function getContentLibraryMessageByLanguage(shopId, type, ticketTypeId) {
  return withApiActions(
    api => api.getContentLibraryMessages({ shopId, type, ticketTypeId }),
    'CONTENT_LIBRARY_MESSAGE'
  );
}