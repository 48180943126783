import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../common/Text';
import Locale from '../../Translate/Locale';

import './StatsCard.scss';

function StatsCard(props) {
  const { value, intent, children } = props;

  return (
    <div styleName={classNames('card', intent)} data-testid="stats-card">
      <div styleName="value">{value}</div>
      <Text small ink>
        <Locale>{children}</Locale>
      </Text>
    </div>
  );
}

StatsCard.propTypes = {
  children: PropTypes.string,
  value: PropTypes.number,
  intent: PropTypes.oneOf(['warning', 'positive']),
};

export default React.memo(StatsCard);
