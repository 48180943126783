export function getUsedLocalizations(data) {
  if (!data) {
    return undefined;
  }

  const { emailTemplates, localizations } = data;
  const localizationIds = emailTemplates?.map(getUsedLocalizationId);

  function isUsedLocalizations(localization) {
    return localizationIds.includes(localization.id);
  }

  const usedLocals = localizations?.filter(isUsedLocalizations);
  return usedLocals;
}

function getUsedLocalizationId(emailTemplate) {
  return emailTemplate.localizationId;
}
