import React from 'react';
import PropTypes from 'prop-types';

import AccordionContext from './AccordionContext';
import storage from '../../models/storage';

class ListHeaderAccordion extends React.PureComponent {
  state = {
    expandedSections: this.getInitialPref(),
  };

  getInitialPref() {
    const { name } = this.props;

    const initialPref = storage.getItemWithShop(name);

    if (typeof initialPref === 'object' && initialPref) {
      return initialPref;
    }

    return this.expandFirstLocalization();
  }

  expandFirstLocalization() {
    const id = this.props.localizationIds[0];

    return { [id]: true };
  }

  onHeaderClick = (id) => {
    const { expandedSections } = this.state;

    this.setState(
      (state) => ({
        expandedSections: {
          ...state.expandedSections,
          [id]: !expandedSections[id],
        },
      }),
      () => this.saveToStorage()
    );
  };

  saveToStorage = () => {
    const { expandedSections } = this.state;
    const { name } = this.props;

    storage.setItemWithShop(name, expandedSections);
  };

  render() {
    const { expandedSections } = this.state;

    return (
      <AccordionContext.Provider
        value={{
          onHeaderClick: this.onHeaderClick,
          expandedSections,
        }}
      >
        {this.props.children}
      </AccordionContext.Provider>
    );
  }
}

ListHeaderAccordion.propTypes = {
  localizations: PropTypes.array,
  name: PropTypes.string,
};

export default ListHeaderAccordion;
