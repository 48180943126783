import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './BasicInput.scss';

export function BasicInput(props) {
  const { id, type = 'text', isInvalid, placeholder, value, large, onChange, rightElement, ...inputProps } = props;

  const { t } = useTranslation();

  const onChangeHandler = useCallback((e) => onChange(e.target.value, e.target.name, e), [onChange]);

  return (
    <div styleName="input-wrapper">
      {rightElement && <div styleName="right-element">{rightElement}</div>}
      <input
        id={id}
        type={type}
        styleName={classNames('input', { error: isInvalid, large })}
        value={value || ''}
        placeholder={t(placeholder)}
        onChange={onChangeHandler}
        aria-invalid={isInvalid}
        {...inputProps}
      />
    </div>
  );
}

BasicInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  rightElement: PropTypes.elementType,
};

export default React.memo(BasicInput);
