import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import './ResetPassword.scss';
import Input from '../common/Input';
import Button from '../common/Button';
import resetPasswordAction from '../../actions/resetPassword';
import LoginContainer from '../login/LoginContainer';
import { Locale } from '../Translate';
import isStrongPassword from './isStrongPassword';
import withLegacyRouter from '../../wrappers/withLegacyRouter';

function parseToken(location) {
  return qs.parse(location.search, { ignoreQueryPrefix: true }).token;
}

export class ResetPasswordPage extends React.PureComponent {
  state = {
    token: this.props.location && parseToken(this.props.location),
    email: '',
    password: '',
    repeatedPassword: '',
    passwordDidMatch: true,
  };

  handleUserInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { email, token, password, repeatedPassword } = this.state;

    const emailIsEmpty = email.length === 0;
    const passwordEmpty = password.length === 0;
    const passwordIsWeak = !isStrongPassword(password);
    const inputsNotEqual = password !== repeatedPassword;

    this.setState({
      emailIsEmpty,
      passwordIsWeak,
      passwordDidMatch: !inputsNotEqual,
    });

    if (!passwordEmpty && !passwordIsWeak && !inputsNotEqual && !emailIsEmpty) {
      this.props.resetPasswordAction(email, password, token).then(({ isSuccessful }) => {
        if (isSuccessful) {
          this.props.history.push('/login');
        }
      });
    }
  };

  render() {
    const { email, token, password, emailIsEmpty, repeatedPassword, passwordIsWeak, passwordDidMatch } = this.state;

    const { isLoading, error } = this.props;

    return (
      <LoginContainer>
        <form styleName="container" onSubmit={this.onSubmit}>
          <h1 styleName="title">
            <Locale>Reset your password</Locale>
          </h1>
          <div styleName="form-section">
            <div styleName="form-element-wrapper">
              <Input
                label="Email"
                onChange={this.handleUserInput}
                placeholder="Enter your email address"
                value={email}
                name="email"
                error={emailIsEmpty && 'Email can not be empty'}
              />
            </div>
            <div styleName="form-element-wrapper">
              <Input
                label="Verification code"
                onChange={this.handleUserInput}
                placeholder="Enter verification code"
                value={token}
                name="token"
                error={error && 'Verification code is not correct'}
              />
            </div>
            <div styleName="form-element-wrapper">
              <Input
                label="New password"
                onChange={this.handleUserInput}
                placeholder="Enter your password"
                value={password}
                name="password"
                type="password"
                error={passwordIsWeak && "The password doesn't meet requirements"}
              />
            </div>
            <div styleName="form-element-wrapper">
              <Input
                label="Confirm new password"
                onChange={this.handleUserInput}
                placeholder="Repeat your password"
                value={repeatedPassword}
                name="repeatedPassword"
                type="password"
                error={!passwordDidMatch && 'The passwords do not match'}
              />
            </div>
            <div styleName="form-element-wrapper">
              <Locale>RESET_PASSWORD_REQUIREMENTS_DESCRIPTION</Locale>
            </div>
          </div>
          <Button primary type="submit" isLoading={isLoading}>
            Reset password
          </Button>
        </form>
      </LoginContainer>
    );
  }
}

ResetPasswordPage.defaultProps = {
  resetPasswordAction: () => {},
};

ResetPasswordPage.propTypes = {
  resetPasswordAction: PropTypes.func,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  const { resetPassword } = state;

  return {
    ...resetPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPasswordAction: (email, password, token) => dispatch(resetPasswordAction(email, password, token)),
  };
}

export default withLegacyRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));
