import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../common/Input';
import TextArea from '../../common/TextArea';
import WidgetIconSelect from './WidgetIconSelect';
import Button from '../../common/Button';
import { eventTypes, getMessageEventName } from '../contentLibraryHelper';

import './ContentLibraryMessageForm.scss';

class ContentLibraryMessageForm extends React.PureComponent {
  onMessageChange = (e) => {
    this.props.onChange({ message: e.target.value });
  };

  onDescriptionChange = (e) => {
    this.props.onChange({ description: e.target.value });
  };

  onIconChange = ({ value }) => {
    this.props.onChange({ icon: value });
  };

  render() {
    const {
      isDefault,
      type,
      message,
      icon,
      description,
      onSubmit,
      onReset,
      isChanged,
    } = this.props;

    const messageTitle = `${getMessageEventName(type)} message`;
    const descTitle = `${getMessageEventName(type)} description`;

    return (
      <div styleName="form">
        <div styleName="field">
          <Input label={messageTitle} value={message} onChange={this.onMessageChange} />
        </div>
        { type === 'shipment_event' &&
          <div styleName="field">
            <TextArea label={descTitle} value={description} onChange={this.onDescriptionChange} />
          </div>
        }
        <div styleName="field icon-select">
          <WidgetIconSelect type={type} value={icon} onChange={this.onIconChange} />
        </div>
        <div styleName="buttons">
          <div styleName="default">
            <Button outline disabled={isDefault} onClick={onReset}>Reset to default</Button>
          </div>
          <div styleName="save">
            <Button primary disabled={!isChanged} onClick={onSubmit}>Save changes</Button>
          </div>
        </div>
      </div>
    );
  }
}

ContentLibraryMessageForm.propTypes = {
  isDefault: PropTypes.bool,
  type: PropTypes.oneOf(eventTypes),
  message: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  isChanged: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};

export default ContentLibraryMessageForm;