const hash = process.env.GIT_COMMIT;

export function getWidgetPreviewUrl() {
  return `${process.env.WIDGET_URL}preview.js` + (hash ? `?${hash}` : '');
}

export function getEmailPreviewUrl() {
  return `${process.env.EMAIL_PREVIEW_URL}preview.js` + (hash ? `?${hash}` : '');
}

export function getReturnPortalPreviewUrl() {
  return `${process.env.RETURN_PORTAL_URL}preview.js` + (hash ? `?${hash}` : '');
}
