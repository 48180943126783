const specialChars = '=+^$*.[]{}()?-"!@#%&/\\,><\':;|_~';
const escapedChars = specialChars.split('').map(c => `\\${c}`).join('');

const strongRegex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${escapedChars}])`);
const validRegex = new RegExp(`^[a-zA-Z0-9${escapedChars}]{8,99}$`);

function isStrongPassword(password) {
  return strongRegex.test(password) && validRegex.test(password);
}

export default isStrongPassword;