import React from 'react';
import PropTypes from 'prop-types';

import ClaimsTableCell from './ClaimsTableCell';
import ClaimStatusBadge from '../../parcel-finder/ClaimStatusBadge/ClaimStatusBadge';
import ClaimsTypeBadge from './ClaimsTypeBadge';
import ClaimsValue from './ClaimsValue';
import TrackingCodeWithBadge from './TrackingCodeWithBadge';
import Text from '../../common/Text/Text';
import { formatDate } from '../../parcel-finder/cards/dateHelper';

import './ClaimsTableRow.scss';
import ClaimsTableActionDropdownButton from './ClaimsTableActionDropdownButton';

function ClaimsTableRow({ claim, onTakeActionButtonClick, onCancelClaimButtonClick }) {
  const { status, claimType, netValueCurrency, netValue, createdAt, trackingCode, isNon7sShipment } = claim;

  return (
    <tr data-testid="claim-table-row">
      <ClaimsTableCell itemType="status">
        <ClaimStatusBadge status={status} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="type">
        <ClaimsTypeBadge claimType={claimType} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="value">
        <ClaimsValue currency={netValueCurrency} value={netValue} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="date">
        <Text ink>{formatDate(createdAt)}</Text>
      </ClaimsTableCell>
      <ClaimsTableCell itemType="code">
        <TrackingCodeWithBadge trackingCode={trackingCode} isNon7sShipment={isNon7sShipment} />
      </ClaimsTableCell>
      <ClaimsTableCell itemType="action">
        <ClaimsTableActionDropdownButton
          claim={claim}
          onTakeActionButtonClick={onTakeActionButtonClick}
          onCancelClaimButtonClick={onCancelClaimButtonClick}
        />
      </ClaimsTableCell>
    </tr>
  );
}

ClaimsTableRow.propTypes = {
  claim: PropTypes.object,
  onTakeActionButtonClick: PropTypes.func,
  onCancelClaimButtonClick: PropTypes.func,
};

export default ClaimsTableRow;
