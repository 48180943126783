import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../common2/Modal';
import ModalContent from '../../common2/Modal/ModalContent';
import ModalFooter from '../../common2/Modal/ModalFooter';
import ModalHeader from '../../common2/Modal/ModalHeader';
import Column from '../../common/layout/Column'; //is there a common2 version of this?
import Select from '../../common2/Select';
import { getSelectOptions } from '../../../utils/langHelper';
import Input from '../../common2/Input';
import Button from '../../common2/Button';
import DuplicateIcon from '../../../icons/duplicate.svg';

function DuplicateEmbeddedWidgetModal(props) {
  const { embeddedWidget, onCancel, onDuplicate } = props;

  const [name, setName] = useState();
  const [language, setLanguage] = useState();
  const isValid = name && language;

  const onChangeName = (name) => setName(name);
  const onChangeLanguage = (language) => setLanguage(language);
  const onDuplicateHandler = () => onDuplicate(embeddedWidget.id, { name, language });

  useEffect(() => {
    setName(`${embeddedWidget?.name} - copy`);
    setLanguage(embeddedWidget?.language);
  }, [embeddedWidget]);

  return (
    <Modal show={!!embeddedWidget} onClose={onCancel}>
      <ModalHeader icon={DuplicateIcon}>Duplicate embedded widget</ModalHeader>
      <ModalContent>
        <Column spacing="small">
          <Input label="Enter a new name" value={name} onChange={onChangeName} />
          <Select label="Select a language" value={language} options={getSelectOptions()} onChange={onChangeLanguage} />
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!isValid} onClick={onDuplicateHandler}>
          Duplicate
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DuplicateEmbeddedWidgetModal.propTypes = {
  onCancel: PropTypes.func,
  onDuplicate: PropTypes.func,
  embeddedWidget: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    language: PropTypes.string,
  }),
};

export default React.memo(DuplicateEmbeddedWidgetModal);
