import React from 'react';

import Heading4 from '../common/Heading4';
import ClaimIcon from '../../icons/claim.svg';
import AddIcon from '../../icons/add.svg';
import Button from '../common2/Button';
import Locale from '../Translate/';
import ExternalLink from '../common2/links/ExternalLink';
import Icon from '../common2/Icon';
import InfoLinkCard from '../common/InfoLinkCard';

import './ClaimsCenterNoResults.scss';

function ClaimsCenterNoResults(props) {
  const { onSubmitClaimButtonClick } = props;
  return (
    <>
      <div styleName="content">
        <Icon icon={ClaimIcon} />
        <Heading4 styleName="heading">No claims submitted</Heading4>
        <Button onClick={onSubmitClaimButtonClick} icon={AddIcon}>
          Submit manual claim
        </Button>
      </div>
      <div styleName="footer">
        <InfoLinkCard>
          <Locale>Learn how to</Locale>&nbsp;
          <ExternalLink
            to={'https://support.portal.sevensenders.com/support/solutions/articles/15000030711-how-to-submit-a-claim'}
          >
            <Locale>submit a claim</Locale>
          </ExternalLink>
        </InfoLinkCard>
      </div>
    </>
  );
}

export default React.memo(ClaimsCenterNoResults);
