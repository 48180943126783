import { useState } from 'react';

function useFocus() {
  const [isFocused, setFocus] = useState(false);

  return [
    isFocused,
    {
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
    },
  ];
}

export default useFocus;