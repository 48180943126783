import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ShopMenuItem from './ShopMenuItem';
import SideMenu from '../SideMenu';
import Text from '../common/Text';

import logoutAction from '../../actions/logout';

import './MobileMenu.scss';

function MobileMenu(props) {
  const { shop, items, title, titleBadge, pathname } = props;

  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(logoutAction()), [dispatch]);

  return (
    <div styleName="menu">
      <ShopMenuItem shopName={shop.name} roles={shop.roles} />
      <div styleName="devider" />
      {items && <SideMenu items={items} pathname={pathname} title={title} titleBadge={titleBadge} />}
      <div styleName="expander" />
      <div styleName="devider" />
      <div styleName="item" onClick={onLogout}>
        <Text ink>Log out</Text>
      </div>
    </div>
  );
}

export default MobileMenu;
