import { useCallback, useState } from 'react';

import useNotifications from './useNotifications';
import container from '../container';

function useApiAction(func, deps, options = {}) {
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { showNotice, showError } = useNotifications();
  const { feedbackMessage, formatErrorMessage } = options;

  const action = useCallback(() => {
    setIsLoading(true);

    return func(container.touchpointsApi)
      .then((data) => {
        setResult(data);
        setIsLoading(false);

        if (feedbackMessage) {
          showNotice({
            message: feedbackMessage,
            type: 'success',
          });
        }

        return { success: true };
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);

        showError(formatErrorMessage ? formatErrorMessage(error) : undefined);

        return { success: false };
      });
  }, [...deps, feedbackMessage, formatErrorMessage]);

  return [action, { result, isLoading, error }];
}

export default useApiAction;
