import React, { useState } from 'react';
import Dropdown from '../../modal/Dropdown';
import MoreActionsIcon from '../../../icons/more-actions.svg';
import useInputId from '../hooks/useInputId';
import Locale from '../../Translate/Locale';
import Tooltip from '../../common/Tooltip';

import './DropdownButton.scss';

function DropdownButton(props) {
  const { icon: Icon = MoreActionsIcon, tooltipText, position, ariaLabel, children } = props;
  const [showMenu, setShowMenu] = useState(false);

  const idForTooltip = useInputId();

  const onClose = () => setShowMenu(false);
  // passing menu as props is necessary for correct position calculation logic in dropdown component
  const menu = (
    <div styleName="menu" onClick={onClose}>
      {children}
    </div>
  );
  return (
    <>
      <Dropdown show={showMenu} onClose={onClose} position={position} content={menu}>
        <button
          data-testid="expand-actions-button"
          styleName="button"
          aria-label={ariaLabel}
          onClick={() => setShowMenu(!showMenu)}
        >
          <Icon data-tip data-for={idForTooltip} styleName="icon" />
        </button>
      </Dropdown>
      <Tooltip id={idForTooltip} position="bottom" type="dark">
        <Locale>{tooltipText}</Locale>
      </Tooltip>
    </>
  );
}

export default React.memo(DropdownButton);
