import React from 'react';
import { useTranslation } from 'react-i18next';

import InputContainer from '../InputContainer';
import BasicInput from '../BasicInput';
import useInputId from '../hooks/useInputId';

export function Input(props) {
  const { label, isRequired, isWarned, error, helpText, noErrorTranslation, topRightElement, ...inputProps } = props;

  const { t } = useTranslation();
  const id = useInputId();
  const errorText = noErrorTranslation ? error : t(error);

  return (
    <InputContainer
      labelFor={id}
      label={label}
      labelIsRequired={isRequired}
      labelHasWarning={isWarned}
      errorWithTranslation={errorText}
      topRightElement={topRightElement}
      helpText={helpText}
    >
      <BasicInput id={id} isInvalid={!!errorText} {...inputProps} />
    </InputContainer>
  );
}

export default React.memo(Input);
