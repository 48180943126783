import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { isI18nextDebugEnabled } from '../../i18n';

export class Locale extends React.PureComponent {
  render() {
    const { t, i18n, children, options } = this.props;

    if (!children) {
      return null;
    }

    const res = t(children, { ...options, interpolation: { escapeValue: false } });

    if (isI18nextDebugEnabled()) {
      const hasTranslation = i18n.exists(children, options);

      if (hasTranslation) {
        return <span style={{ border: '1px ForestGreen dashed' }}>{res}</span>;
      } else {
        return <span style={{ backgroundColor: 'pink' }}>{res}</span>;
      }
    }

    return res;
  }
}

Locale.propTypes = {
  children: PropTypes.string,
};

export default withTranslation()(Locale);
