import React from 'react';
import classNames from 'classnames';
import getDroppedFiles from '@uppy/utils/lib/getDroppedFiles';

import './DragDrop.scss';

class DragDrop extends React.PureComponent {
  state = {};

  onInputRef = (ref) => (this.inputRef = ref);
  onButtonClick = () => this.inputRef.click();

  addFile(file) {
    const { uppy } = this.props;
    try {
      uppy.addFile({
        source: this.id,
        name: file.name,
        type: file.type,
        data: file,
        meta: {
          relativePath: file.relativePath || null,
        },
      });
    } catch (err) {
      if (!err.isRestriction) {
        uppy.log(err);
      }
    }
  }

  addFiles(files) {
    files.forEach((file) => this.addFile(file));
  }

  onInputChange = (e) => {
    this.props.onBeforeAddFiles();

    this.addFiles([...e.target.files]); // convert FileList to Array
    e.target.value = null; // workaround like in original "@uppy/drag-drop"
  };

  onDragOver = (e) => {
    this.setState({ isDragging: true });

    e.preventDefault();
    e.stopPropagation();
  };

  onDragLeave = (e) => {
    this.setState({ isDragging: false });

    e.preventDefault();
    e.stopPropagation();
  };

  onDrop = (e) => {
    const { onBeforeAddFiles } = this.props;
    onBeforeAddFiles && onBeforeAddFiles();

    this.setState({ isDragging: false });

    getDroppedFiles(e.dataTransfer).then((files) => this.addFiles(files));

    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { isDisabled, uppy } = this.props;

    if (!uppy) {
      return;
    }

    const {
      opts: {
        restrictions: { maxNumberOfFiles, allowedFileTypes },
        inputName,
      },
    } = uppy;

    const { isDragging } = this.state;

    return (
      <button
        styleName={classNames('drop-zone', { dragging: isDragging, disabled: isDisabled })}
        type="button"
        disabled={isDisabled}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={!isDisabled && this.onDrop}
        onClick={this.onButtonClick}
      >
        <input
          ref={this.onInputRef}
          styleName="input"
          type="file"
          tabIndex={-1}
          name={inputName}
          multiple={maxNumberOfFiles !== 1}
          accept={allowedFileTypes}
          onChange={this.onInputChange}
        />
        {this.props.children}
      </button>
    );
  }
}

DragDrop.defaultProps = {
  onBeforeAddFiles: () => {},
};

export default DragDrop;
