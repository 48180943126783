import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import nanoid from 'nanoid';

import Locale from '../../Translate/Locale';
import { getRoleName } from '../../../firewall/roles';

import './UserRoleBadge.scss';

function UserRoleBadge({ role }) {
  const id = nanoid();
  return (
    <React.Fragment>
      <span styleName={classNames('role-badge', role)} data-tip data-for={id}>
        <Locale>{getRoleName(role)}</Locale>
      </span>
      <Tooltip id={id} position="top">
        <Locale>{tooltipText[role]}</Locale>
      </Tooltip>
    </React.Fragment>
  );
}

const tooltipText = {
  admin: 'ADMIN_ROLE_TOOLTIP',
  explorer: 'EXPLORER_ROLE_TOOLTIP',
};

UserRoleBadge.propTypes = {
  role: PropTypes.oneOf(['admin', 'explorer']).isRequired,
};

export default UserRoleBadge;