import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';

import EventStatusBadge from '../EventStatusBadge';
import Locale from '../../Translate/Locale';
import Text from '../../common/Text';
import meaningEnum from '../../ShopContentLibraryPage/meaningEnum';

import './EventCard.scss';

function EventCard(props) {
  const {
    type,
    status,
    carrierMessage,
    isLastEvent,
  } = props;

  const meaning = isLastEvent ? props.meaning : undefined;

  return (
    <div styleName="card">
      <div styleName={classNames('left-border', meaning)} />
      <div styleName="content">
        <div styleName="status">
          <Text as="div" title>
            <Locale>Status</Locale>:
          </Text>
          <EventStatusBadge type={type} meaning={meaning} />
        </div>
        <div styleName="status-text">
          <Text as="div">
            {status || '-'}
          </Text>
        </div>
        { carrierMessage && (
          <React.Fragment>
            <Text as="div" title>
              <Locale>Carrier message</Locale>:
            </Text>
            <Text as="div">
              {carrierMessage}
            </Text>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

EventCard.propTypes = {
  children: PropType.node,
  type: PropType.string,
  status: PropType.string,
  meaning: PropType.oneOf(Object.values(meaningEnum)),
  carrierMessage: PropType.string,
  date: PropType.string,
};

export default EventCard;