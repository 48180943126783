import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Button from '../common/Button';
import ModalButtonsContainer from './ModalButtonsContainer';
import ModalMessage from './ModalMessage';

import AlertIcon from '../../icons/artwork/alert.svg';
import './ConfirmationModal.scss';

class ConfirmationModal extends React.PureComponent {
  render() {
    const { message, onOK, onClose, onCancel, okText, cancelText, title, ...props } = this.props;

    return (
      <Modal {...props} title={title} onClose={onClose || onCancel} warning>
        <div styleName="content">
          <ModalMessage>{message}</ModalMessage>
          <ModalButtonsContainer align="right">
            <Button warning onClick={onOK} primary>
              {okText}
            </Button>
            <Button warning onClick={onCancel} primary outline>
              {cancelText}
            </Button>
          </ModalButtonsContainer>
        </div>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  icon: <AlertIcon style={{ width: 128, height: 128 }} />,
  okText: 'OK',
  cancelText: 'Cancel',
};

ConfirmationModal.propTypes = {
  ...Modal.propTypes,
  message: PropTypes.string,
  onOK: PropTypes.func,
  onCancel: PropTypes.func, // will be used also for onClose action if isn't passed
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default ConfirmationModal;
