import React from 'react';
import PropTypes from 'prop-types';

import './Row.scss';

function Row(props) {
  const {children, spacing } = props;

  return (
    <div styleName="row">
      {React.Children.map(children, (child, index) => {
        if (index === 0) return child;
        return <div key={index} style={ { marginLeft: spacing } }>{child}</div>;
      })}
    </div>);
}

Row.propTypes = {
  spacing: PropTypes.number,
};

Row.defaultProps = {
  spacing: 0,
};

export default React.memo(Row);