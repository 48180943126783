import React from 'react';
import PropTypes from 'prop-types';

const Switch = (props) => {
  const {
    children,
    selected,
  } = props;

  return getOneChildren(children, ({key}) => key === selected);
};

function getOneChildren(children, func) {
  if (React.Children.count(children) === 1) {
    return func(children) ? children : null;
  }

  for (let i = 0; i < React.Children.count(children); i++) {
    if (func(children[i])) {
      return children[i];
    }
  }

  return null;
}

Switch.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired, // selected page
};

export default Switch;
