import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text/Text';
import Locale from '../../Translate/Locale';

import './SidePanelSection.scss';

function SidePanelSection(props) {
  const { title, children } = props;

  return (
    <section styleName="section">
      <Text styleName="title">
        <Locale>{title}</Locale>
      </Text>
      {children}
    </section>
  );
}

SidePanelSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(SidePanelSection);
