import React from 'react';

import { TRACKING_PAGES_PERMISSION } from '../../firewall/permissions';

import TrackingContainer from '../touchpoints/TrackingContainer';
import Column from '../common/layout/Column';
import Row from '../common/Row';
import StartGuideCard from './StartGuidePage/StartGuideCard';
import StartGuideTabs from './StartGuidePage/StartGuideTabs';
import StartGuideTabContent from './StartGuidePage/StartGuideTabContent';
import StartGuideBulletedList from './StartGuidePage/StartGuideBulletedList';
import BulletedListItem from '../common/bulleted-list/BulletedListItem';
import StartGuideImage from './StartGuidePage/StartGuideImage';
import usePermissionAccess from '../../hooks/usePermissionAccess';
import TrackingStartGuideBanner from './banners/TrackingStartGuideBanner';
import CenterLayout from '../common2/CenterLayout/CenterLayout';
import StartGuideHeading from './StartGuidePage/StartGuideHeading';
import { HelpCardContent } from '../homepage/HelpCard';

export const supportLinks = {
  localisation:
    'https://support.portal.sevensenders.com/support/solutions/articles/15000010418-managing-shop-localisations',
  trackingPage:
    'https://support.portal.sevensenders.com/support/solutions/articles/15000010426-designing-and-publishing-a-tracking-page',
  embeddedWidget: 'https://support.portal.sevensenders.com/support/solutions/folders/15000003852',
};

const tabs = [
  {
    id: 'tracking-pages',
    title: 'Tracking pages',
  },
  {
    id: 'embedded-widgets',
    title: 'Embedded widgets',
  },
  {
    id: 'visual-editor',
    title: 'Visual editor',
  },
];

export function TrackingStartGuidePage(props) {
  const hasAccess = usePermissionAccess(TRACKING_PAGES_PERMISSION);

  return (
    <TrackingContainer {...props}>
      <CenterLayout>
        <StartGuideHeading />
        <Column spacing="large">
          <TrackingStartGuideBanner hasAccess={hasAccess} />
          <StartGuideCard
            heading="TRACKING_START_GUIDE_CONTENT_HEADING"
            description="TRACKING_START_GUIDE_CONTENT_DESK"
          >
            <StartGuideTabs tabs={tabs}>
              <StartGuideTabContent key="tracking-pages">
                <StartGuideBulletedList>
                  <BulletedListItem>TRACKING_START_GUIDE_TRP_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>TRACKING_START_GUIDE_TRP_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/tracking/tracking-pages.jpg" alt="tracking-pages" />
              </StartGuideTabContent>
              <StartGuideTabContent key="embedded-widgets">
                <StartGuideBulletedList>
                  <BulletedListItem>TRACKING_START_GUIDE_EW_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>TRACKING_START_GUIDE_EW_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/tracking/embedded-widgets.jpg" alt="embedded-widgets" />
              </StartGuideTabContent>
              <StartGuideTabContent key="visual-editor">
                <StartGuideBulletedList>
                  <BulletedListItem>TRACKING_START_GUIDE_VE_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>TRACKING_START_GUIDE_VE_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/tracking/visual-editor.jpg" alt="visual-editor" />
              </StartGuideTabContent>
            </StartGuideTabs>
          </StartGuideCard>
          <StartGuideCard heading="TRACKING_START_GUIDE_HELP_HEADING">
            <Row spacing={56}>
              <HelpCardContent
                text="TRACKING_START_GUIDE_LOCALISATION_ARE_NEEDED"
                linkText="TRACKING_START_GUIDE_LOCALISATION_LINK"
                link={supportLinks.localisation}
              />
              <HelpCardContent
                text="TRACKING_START_GUIDE_BUILD_TRACKING_PAGE"
                linkText="TRACKING_START_GUIDE_BUILD_TRACKING_PAGE_LINK"
                link={supportLinks.trackingPage}
              />
              <HelpCardContent
                text="TRACKING_START_GUIDE_BUILD_EMBEDDED_WIDGET"
                linkText="TRACKING_START_GUIDE_BUILD_EMBEDDED_WIDGET_LINK"
                link={supportLinks.embeddedWidget}
              />
            </Row>
          </StartGuideCard>
        </Column>
      </CenterLayout>
    </TrackingContainer>
  );
}

export default React.memo(TrackingStartGuidePage);
