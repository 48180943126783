import PropTypes from 'prop-types';
import meaningEnum from './meaningEnum';
import eventTypeEnum from './eventTypeEnum';

export function filterMessages(messages, messageType, meaningType, filterText) {
  return messages.filter((msg) => {
    if (messageType && messageType !== msg.type) {
      return false;
    }

    if (meaningType && meaningType !== msg.meaning) {
      return false;
    }

    if (filterText) {
      const text = filterText.toLowerCase();
      const title = msg.title.toLowerCase();
      const message = msg.message.toLowerCase();

      if (!title.includes(text) && !message.includes(text)) {
        return false;
      }
    }

    return true;
  });
}

export function sortMessages(messages) {
  return messages.slice().sort((a, b) => a.title.localeCompare(b.title));
}

const eventNames = {
  [eventTypeEnum.ORDER]: 'Order event',
  [eventTypeEnum.SHIPMENT]: 'Shipment event',
  [eventTypeEnum.TIMELINE]: 'Shipment timeline',
};

const eventTitles = {
  [eventTypeEnum.ORDER]: 'Order event messages',
  [eventTypeEnum.SHIPMENT]: 'Shipment event messages',
  [eventTypeEnum.TIMELINE]: 'Shipment timeline states',
};

const urlParts = {
  [eventTypeEnum.ORDER]: 'order-events',
  [eventTypeEnum.SHIPMENT]: 'shipment-events',
  [eventTypeEnum.TIMELINE]: 'timeline-states',
};

export const eventTypes = Object.keys(eventNames);

export function getMessageEventName(type) {
  return eventNames[type] || '';
}

export function getMessageEventsTitle(type) {
  return eventTitles[type] || '';
}

export function getMessageEditUrl(type, ticketTypeId, language) {
  return `${getContentLibraryEventsBaseUrl(type)}/${language}/${ticketTypeId}/edit`;
}

export function getContentLibraryEventsBaseUrl(type) {
  return `/content-library/${urlParts[type]}`;
}

export const messageShape = {
  id: PropTypes.number,
  language: PropTypes.string,
  type: PropTypes.oneOf(eventTypes),
  ticketTypeId: PropTypes.number,
  isDefault: PropTypes.bool,
  meaning: PropTypes.oneOf(Object.values(meaningEnum)),
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  description: PropTypes.string,
};
