import React, { Suspense } from 'react';

import PageLoader from '../PageLoader';

const CustomerNotificationsRoutes = React.lazy(() =>
  import('./CustomerNotificationsRoutes' /* webpackChunkName: "notifications" */)
);

function SuspendedCustomerNotificationsRoutes(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <CustomerNotificationsRoutes {...props} />
    </Suspense>
  );
}

export default SuspendedCustomerNotificationsRoutes;
