import React from 'react';

import sortBy from '../../../utils/sortBy';
import Table from '../../common/Table/Table';
import NotificationListItem from './NotificationListItem';

import './NotificationsLogTable.scss';

const columns = ['Date', 'Type', 'Event Trigger', 'Sending logic', 'Recipient'];

export function NotificationsLogTable(props) {
  const { notifications } = props;

  const sortedNotifications = sortBy(notifications, getDate).reverse();

  return (
    <div styleName="list">
      <div styleName="table-container">
        <Table columns={columns}>
          {sortedNotifications.map((notification, index) => (
            <NotificationListItem key={index} notification={notification} />
          ))}
        </Table>
      </div>
    </div>
  );
}

function getDate(notification) {
  return new Date(notification.time);
}

export default React.memo(NotificationsLogTable);
