const translationsLanguages = ['en', 'de', 'fr'];

function getBrowserLanguage() {
  return (
    navigator.language || navigator.userLanguage // for all browsers
  ); // for IE
}

export function getSupportedBrowserLanguage(browserLanguage = getBrowserLanguage()) {
  const language = translationsLanguages.find(
    (value) => value === browserLanguage || `${value}-` === browserLanguage.substring(0, 3) // incase in 'xx-XX' format
  );

  return language || translationsLanguages[0];
}

export default function languageReducer(state = getSupportedBrowserLanguage(), action) {
  switch (action.type) {
    case 'FETCH_ME_SUCCESS':
      return action.data.language;

    default:
      return state;
  }
}
