import React from 'react';
import Heading2 from '../../common/Heading2';
import Heading4 from '../../common/Heading4';
import ExternalLink from '../../common2/links/ExternalLink';
import Text from '../../common/Text';

import './SearchFilterNoResult.scss';
function SearchFilterNoResult() {
  return (
    <div styleName="no-result">
      <div styleName="heading">
        <Heading2>Sorry, no results match your search</Heading2>
      </div>
      <Heading4>Please check your tracking code or change the filters and try again</Heading4>
      <div styleName="body">
        <Text ink> If you are still having issues, please feel free to contact us</Text>&nbsp;
        <ExternalLink to={'mailto:service@sevensenders.com'}>service@sevensenders.com</ExternalLink>
        <Text ink> or +49 (0) 30 5683 8181</Text>
      </div>
    </div>
  );
}

export default React.memo(SearchFilterNoResult);
