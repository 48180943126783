import React from 'react';
import { Route, Routes } from 'react-router-dom';

import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import ShopContentLibraryPage from '../ShopContentLibraryPage/ShopContentLibraryPage';
import ShopContentLibraryEditPage from '../ShopContentLibraryPage/ShopContentLibraryEditPage';
import eventTypeEnum from '../ShopContentLibraryPage/eventTypeEnum';
import * as permissions from '../../firewall/permissions';
import PageNavigate from '../PageNavigate';

const withShopContentLibraryAccess = withPermissionRedirect(permissions.SHOP_CONTENT_LIBRARY_PERMISSION);

// We do not want to create a new page component for each route render because specific component handle url changes
const ShopContentLibraryEditPageWithAccess = withShopContentLibraryAccess(ShopContentLibraryEditPage);
const ShopContentLibraryPageWithAccess = withShopContentLibraryAccess(ShopContentLibraryPage);

const ShopContentLibraryWithOrderEvents = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.ORDER);
const ShopContentLibraryWithShipmentEvents = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.SHIPMENT);
const ShopContentLibraryWithTimelineStates = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.TIMELINE);

const ShopContentLibraryEditPageWithOrderEvents = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.ORDER
);
const ShopContentLibraryEditPageWithShipmentEvents = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.SHIPMENT
);
const ShopContentLibraryEditPageWithTimelineStates = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.TIMELINE
);

const ContentLibraryRoutes = () => (
  <Routes>
    <Route path="order-events" element={<ShopContentLibraryWithOrderEvents />} />
    <Route path="shipment-events" element={<ShopContentLibraryWithShipmentEvents />} />
    <Route path="timeline-states" element={<ShopContentLibraryWithTimelineStates />} />
    <Route path="order-events/:language/:ticketTypeId/edit" element={<ShopContentLibraryEditPageWithOrderEvents />} />
    <Route
      path="shipment-events/:language/:ticketTypeId/edit"
      element={<ShopContentLibraryEditPageWithShipmentEvents />}
    />
    <Route
      path="timeline-states/:language/:ticketTypeId/edit"
      element={<ShopContentLibraryEditPageWithTimelineStates />}
    />
    <Route index element={<PageNavigate to="order-events" />} />
  </Routes>
);

function withEventType(Component, eventType) {
  return (props) => <Component {...props} eventType={eventType} />;
}

export default withPermissionRedirect(permissions.TOUCHPOINTS_PERMISSION)(ContentLibraryRoutes);
