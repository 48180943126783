import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CommsCenterPage from '../analytics/CommsCenterPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import { INVOICE_DETAILS } from '../../firewall/permissions';
import PageNavigate from '../PageNavigate';

const CommsCenterPageWithInvoiceDetailsAccess = withPermissionRedirect(INVOICE_DETAILS)(CommsCenterPage);

const CommsCenterRoutes = () => (
  <Routes>
    <Route
      path="delivery-performance"
      element={<CommsCenterPage dashboardId="analytics::delivery_performance" type="dashboard" />}
    />
    <Route
      path="partner-interface-usage"
      element={<CommsCenterPage dashboardId="analytics::partner_interface_usage" type="dashboard" />}
    />
    <Route
      path="invoice-details"
      element={
        <CommsCenterPageWithInvoiceDetailsAccess dashboardId={process.env.INVOICE_DETAILS_LOOK_ID} type="look" />
      }
    />
    <Route index element={<PageNavigate to="delivery-performance" />} />
  </Routes>
);

export default CommsCenterRoutes;
