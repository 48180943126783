import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import container from '../container';
import { ApiError } from '../api/Api';

function useApi(func, deps, options = {}) {
  const [data, setData] = useState(undefined); // undefined allows us to use default values
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    throwError = true,
  } = options;

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    const {
      api: sendwise,
      touchpointsApi: touchpoints,
    } = container;

    func({ sendwise, touchpoints })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        if (throwError) {
          dispatch({type: 'API_ERROR', error: new ApiError(error.response)});
        }
      });
  }, deps);

  return [data, loading, error];
}

export default useApi;