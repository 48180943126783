import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';
import { dictById } from '../utils/dictById';

const initialState = {};

const sendingLogicStatsReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'SENDING_LOGIC_STATS')) {
    return requestProducer(draft, action, 'SENDING_LOGIC_STATS', {
      successProducer: (draft, action) => draft.stats = dictById(action.data, stats => stats.sendingLogicId),
    });
  }

  return draft;
});

export default sendingLogicStatsReducer;