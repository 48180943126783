import React from 'react';

import Locale from '../Translate/Locale';

import Heading2 from '../common/Heading2';

import './ParcelFinderNoResults.scss';

function ParcelFinderNoResults() {
  return (
    <div styleName="no-result-container">
      <Heading2 styleName="header">No results</Heading2>
      <span>
        <Locale>You can always try again.</Locale>
      </span>
    </div>
  );
}

export default ParcelFinderNoResults;
