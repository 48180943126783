import React from 'react';
import Heading2 from '../../common/Heading2/Heading2';

import './StartGuideHeading.scss';

function StartGuideHeading() {
  return (
    <div styleName="heading">
      <Heading2>Start guide</Heading2>
    </div>
  );
}

export default React.memo(StartGuideHeading);
