import React from 'react';
import AnalyticsPage from './AnalyticsPage';
import LookerDashboard from './LookerDashboard';

const DashboardPage = ({ dashboardId, type, ...props }) => (
  <AnalyticsPage {...props} selectedMenuItem={dashboardId}>
    <LookerDashboard dashboardId={dashboardId} type={type} />
  </AnalyticsPage>
);

export default DashboardPage;
