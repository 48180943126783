import container from '../container';

export function setDefaultTrackingPage(trackingPageId, force = false) {
  return dispatch => {
    return container.api.setDefaultTrackingPage(trackingPageId, force)
      .then(() => {
        dispatch(setDefaultTrackingPageSuccess(trackingPageId));
      })
      .catch(error => {
        if (error.response.status === 423 && !force) {
          dispatch(confirmSetDefaultRequired(trackingPageId));
        } else {
          dispatch(setDefaultTrackingPageError(error));
        }
      });
  }
}

export function setDefaultTrackingPageConfirmed(trackingPageId) {
  return dispatch => dispatch(setDefaultTrackingPage(trackingPageId, true))
}

function confirmSetDefaultRequired(trackingPageId) {
  return { type: 'DEFAULT_TRACKING_PAGE_CONFIRM_REQUIRED', trackingPageId };
}

function setDefaultTrackingPageSuccess(trackingPageId) {
  return { type: 'SET_DEFAULT_TRACKING_PAGE_SUCCESS', trackingPageId };
}

function setDefaultTrackingPageError(error) {
  return { type: 'SET_DEFAULT_TRACKING_PAGE_ERROR', error }
}