import container from '../container';

function getPermissions(shopId) {
  return dispatch => {
    dispatch(requestPermissions());
    container.touchpointsApi.permissions(shopId)
      .then(data => dispatch(receivedPermissions({ ...data, shopId })))
      .catch(error => dispatch(errorPermissions(error)))
  }
}

function requestPermissions() {
  return { type: 'PERMISSIONS_REQUEST' }
}

function receivedPermissions(data) {
  return { type: 'PERMISSIONS_RECEIVED', data }
}

function errorPermissions(error) {
  return { type: 'PERMISSIONS_ERROR', error }
}

export default getPermissions;