import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectedShopIdSelector, contentLibrarySelector } from '../../reducers/selectors';
import { getContentLibraryMessages, setContentLibraryFilter } from '../../actions/contentLibraryActions';
import SelectLanguage from '../common/SelectLanguage';
import { filterMessages, getMessageEventsTitle, sortMessages } from './contentLibraryHelper';
import LanguageSelectWrapper from './LanguageSelectWrapper';
import SearchInput from '../common/SearchInput';
import ContentLibraryMessagesList from './ContentLibraryMessagesList';
import HelpCard from '../HelpCard';
import StatusBadge from './Badges/StatusBadge';
import meaningEnum from './meaningEnum';
import SettingsBodyWrapper from '../SettingsPage/SettingsBodyWrapper';

import LightHouseIcon from '../../icons/artwork/lighthouse.svg';

import './ShopContentLibraryPage.scss';
import PageContainer from '../pagecontainer/PageContainer';

const menuItems = [
  {
    id: '/content-library/order-events',
    link: '/content-library/order-events',
    title: 'Order event messages',
  },
  {
    id: '/content-library/shipment-events',
    link: '/content-library/shipment-events',
    title: 'Shipment event messages',
  },
  {
    id: '/content-library/timeline-states',
    link: '/content-library/timeline-states',
    title: 'Shipment timeline states',
  },
];

export class ShopContentLibraryPage extends React.PureComponent {
  componentDidMount() {
    this.props.getMessages(this.props.shopId, this.props.selectedLanguage);
  }

  onLanguageChange = (option) => {
    this.props.getMessages(this.props.shopId, option.value);
  };

  onFilterTextChange = (e) => {
    this.props.setFilter(this.props.eventType, this.props.filterMeaning, e.target.value);
  };

  onFilterMeaningChange = (meaning) => {
    const { filterMeaning } = this.props;
    const nextMeaning = filterMeaning && filterMeaning === meaning ? null : meaning;

    this.props.setFilter(this.props.eventType, nextMeaning, this.props.filterText);
  };

  render() {
    const { messages, eventType, filterText, filterMeaning, ...pageProps } = this.props;

    const filteredMessages = filterMessages(messages, eventType, filterMeaning, filterText);

    const linkUrl =
      'https://support.portal.sevensenders.com/support/solutions/articles/15000010675-managing-event-triggered-content';

    return (
      <PageContainer {...pageProps} menuItems={menuItems} menuTitle="Event and content library" collapsible>
        <SettingsBodyWrapper>
          <div styleName="filter-panel">
            {Object.values(meaningEnum).map((meaning) => (
              <div styleName="status-badge" key={meaning}>
                <StatusBadge
                  meaning={meaning}
                  disabled={filterMeaning && filterMeaning !== meaning}
                  onClick={() => this.onFilterMeaningChange(meaning)}
                />
              </div>
            ))}
            <div styleName="input">
              <SearchInput placeholder="Search for an event" value={filterText} onChange={this.onFilterTextChange} />
            </div>
            <div styleName="lang-select">
              <LanguageSelectWrapper>
                <SelectLanguage value={this.props.selectedLanguage} onChange={this.onLanguageChange} />
              </LanguageSelectWrapper>
            </div>
          </div>
          <ContentLibraryMessagesList data={filteredMessages} titleColumnName="Event" />
          <div styleName="help">
            <HelpCard
              title="Get Help"
              icon={LightHouseIcon}
              description="Learn more about dynamic content and content library."
              linkUrl={linkUrl}
              linkText="Read our documentation"
            />
          </div>
        </SettingsBodyWrapper>
      </PageContainer>
    );
  }
}

ShopContentLibraryPage.defaultProps = {
  getMessages: () => {},
  setFilter: () => {},
};

ShopContentLibraryPage.propTypes = {
  shopId: PropTypes.number.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  filterMeaning: PropTypes.string,
  filterText: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  getMessages: PropTypes.func,
  setFilter: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const shopId = selectedShopIdSelector(state);

  const { selectedLanguage, messages = [], filtersByType = {} } = contentLibrarySelector(state);

  const { meaning: filterMeaning, text: filterText } = filtersByType[ownProps.eventType] || {};

  return {
    shopId,
    selectedLanguage,
    filterMeaning,
    filterText,
    messages: sortMessages(messages),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMessages: (shopId, language) => dispatch(getContentLibraryMessages(shopId, language)),
    setFilter: (type, meaning, text) => dispatch(setContentLibraryFilter(type, meaning, text)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopContentLibraryPage);
