import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';
import Text from '../../common/Text';

import './LabeledValue.scss';

function LabeledValue(props) {
  return (
    <div styleName="container">
      <Text title>
        <Locale>{props.label}</Locale>
      </Text>
      <Text>{props.children}</Text>
    </div>
  );
}

LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.string,
};

export default React.memo(LabeledValue);
