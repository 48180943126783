import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Card from '../common/Card';
import Select from '../common/Select/Select';
import SettingsBodyWrapper from '../SettingsPage/SettingsBodyWrapper';
import PageContainer from '../pagecontainer/PageContainer';
import Heading2 from '../common/Heading2/Heading2';
import Button from '../common/Button';
import { updateUserInfo } from '../../actions/user';

import './UserSettingsPage.scss';

const langOptions = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' },
  { value: 'fr', label: 'French' },
];

export class UserSettingsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.language) {
      this.setState({
        language: nextProps.language,
      });
    }
  }

  onLanguageChange = (option) => {
    const { value } = option;

    this.setState({ language: value });
  };

  onSave = () => {
    const { id, onUpdateUserInfo } = this.props;
    const { language } = this.state;

    onUpdateUserInfo(id, { language });
  };

  render() {
    const { language } = this.state;

    return (
      <PageContainer {...this.props}>
        <SettingsBodyWrapper>
          <Heading2 styleName="heading">Account Settings</Heading2>
          <div styleName="card">
            <Card title="Change language">
              <div styleName="select-lang">
                <Select
                  options={langOptions}
                  value={language}
                  onChange={this.onLanguageChange}
                  placeholder="Select a language"
                >
                  Language
                </Select>
              </div>
              <div styleName="button">
                <Button onClick={this.onSave}>Save changes</Button>
              </div>
            </Card>
          </div>
        </SettingsBodyWrapper>
      </PageContainer>
    );
  }
}

UserSettingsPage.defaultProps = {
  onUpdateUserInfo: () => {},
};

UserSettingsPage.propTypes = {
  id: PropTypes.number.isRequired,
  language: PropTypes.oneOf(['en', 'de', 'fr']).isRequired,
  onUpdateUserInfo: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    user: {
      info: { id, language },
    },
  } = state;

  return {
    id,
    language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateUserInfo: (id, info) => dispatch(updateUserInfo(id, info)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPage);
