import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../common2/Badge';
import { getIntentForMeaning } from './parcelFinderHelper';
import meaningEnum from '../ShopContentLibraryPage/meaningEnum';

function EventStatusBadge(props) {
  const { meaning, type } = props;

  return <Badge intent={getIntentForMeaning(meaning)}>{type}</Badge>;
}

EventStatusBadge.propTypes = {
  meaning: PropTypes.oneOf(Object.values(meaningEnum)),
  type: PropTypes.string,
};

export default React.memo(EventStatusBadge);
