import storage from '../models/storage';

export function featureToggled(feature) {
  return !!storage.getItem(feature);
}

export function isExternalReturnWorkflowSupportedShop(selectedShopId) {
  const shops = (process.env.SHOPS_WITH_EXTERNAL_RETURN_WORKFLOW || '').split(',');

  return shops.includes(selectedShopId.toString());
}