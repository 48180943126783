import React from 'react';
import { Navigate } from 'react-router';
import { useFlag } from '@unleash/proxy-client-react';

export function withMaintenanceRedirect(toggleName, redirectPath = '/') {
  return (DecoratedComponent) => (props) => {
    const isMaintenanceEnabled = useFlag('claims-maintenance');

    if (isMaintenanceEnabled) {
      return <Navigate to={redirectPath} />;
    }

    return <DecoratedComponent {...props} />;
  };
}
