import React from 'react';

import SendwiseLogo from '../../icons/navigation/7s-logo.svg';
import TrackingIcon from '../../icons/navigation/tracking.svg';
import NotificationsIcon from '../../icons/navigation/notifications-vx.svg';
import AnalyticsIcon from '../../icons/navigation/analytics-v-2.svg';
import ReturnCenterIcon from '../../icons/navigation/return-center.svg';
import SearchIcon from '../../icons/navigation/parcel-finder-v-2.svg';
import ContentLibraryIcon from '../../icons/navigation/content-library.svg';
import SupportIcon from '../../icons/navigation/support.svg';
import ProductUpdatesIcon from '../../icons/navigation/product-updates.svg';
import AccountIcon from '../../icons/navigation/account.svg';
import CommsCenterIcon from '../../icons/navigation/comms-center.svg';
import AIChatbotIcon from '../../icons/navigation/ai-chatbot.svg';

import './navIcons.scss';

const Logo = <SendwiseLogo styleName="icon" />;
const Tracking = <TrackingIcon styleName="icon" />;
const Notifications = <NotificationsIcon styleName="icon" />;
const Analytics = <AnalyticsIcon styleName="icon" />;
const Search = <SearchIcon styleName="icon" />;
const Returns = <ReturnCenterIcon styleName="icon" />;
const Chatbot = <AIChatbotIcon styleName="icon" />;
const ContentLibrary = <ContentLibraryIcon styleName="icon" />;
const Support = <SupportIcon styleName="icon" />;
const ProductUpdates = <ProductUpdatesIcon styleName="icon" />;
const Account = <AccountIcon styleName="icon" />;
const CommsCenter = <CommsCenterIcon styleName="icon" />;

export {
  Logo,
  Tracking,
  Notifications,
  Analytics,
  Search,
  Returns,
  Chatbot,
  ContentLibrary,
  Support,
  ProductUpdates,
  Account,
  CommsCenter,
};
