import React from 'react';
import PropTypes from 'prop-types';

import ClaimsCenterContainer from './containers/ClaimsCenterContainer';
import ClaimsHeaderContainer from './containers/ClaimsHeaderContainer';
import ClaimsPageHeader from './ClaimsPageHeader';
import ClaimsCenterNoResults from './ClaimsCenterNoResults';

function ClaimsCenterEmptyOverview({ showSidePanel }) {
  return (
    <ClaimsCenterContainer>
      <ClaimsHeaderContainer>
        <ClaimsPageHeader />
      </ClaimsHeaderContainer>
      <ClaimsCenterNoResults onSubmitClaimButtonClick={showSidePanel} />
    </ClaimsCenterContainer>
  );
}

ClaimsCenterEmptyOverview.propTypes = {
  reloadClaimStats: PropTypes.func,
};

export default React.memo(ClaimsCenterEmptyOverview);
