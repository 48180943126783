import { combineReducers } from 'redux';
import auth from './authReducer';
import user from './userReducer';
import permissions from './permissionsReducer';
import resetPassword from './resetPasswordReducer';
import embeddedWidgets from './embeddedWidgetsReducer';
import widgetSettings from './widgetSettingsReducer';
import trackingPages from './trackingPagesReducer';
import shopLocalizations from './shopLocalizationsReducer';
import shopLocalizationFormReducer from './shopLocalizationFormReducer';
import proxy from './proxyReducer';
import lookerDashboard from './lookerDashboardReducer';
import analyticsDashboards from './analyticsDashboardsReducer';
import legacyTrackingPage from './legacyTrackingPageReducer';
import previewTracking from './previewTrackingReducer';
import contentLibrary from './contentLibraryReducer';
import contentLibraryForm from './contentLibraryFormReducer';
import shopIntegrations from './shopIntegrationsReducer';
import emailTemplate from './emailTemplateReducer';
import emailTemplates from './emailTemplatesReducer';
import sendingLogic from './sendingLogicReducer';
import notificationEvents from './notificationEventsReducer';
import carrierCompanies from './carrierCompaniesReducer';
import carriers from './carriersReducer';
import sendingLogicStats from './sendingLogicStatsReduer';
import apiError from './apiErrorReducer';
import claim from './claimReducer';
import parcelFinder from './parcelFinderReducer';
import returns from './returnsReducer';
import returnPortalEditor from './returnPortalEditorReducer';
import parcelFinderReturns from './parcelFinderReturnsReducer';
import language from './languageReducer';
import customerResponses from './claimCustomerResponseReducer';

export default combineReducers({
  auth,
  user,
  permissions,
  resetPassword,
  embeddedWidgets,
  widgetSettings,
  trackingPages,
  shopLocalizations,
  shopLocalizationFormReducer,
  proxy,
  lookerDashboard,
  analyticsDashboards,
  legacyTrackingPage,
  previewTracking,
  contentLibrary,
  contentLibraryForm,
  shopIntegrations,
  emailTemplate,
  emailTemplates,
  sendingLogic,
  sendingLogicStats,
  notificationEvents,
  carrierCompanies,
  carriers,
  apiError,
  claim,
  parcelFinder,
  returns,
  returnPortalEditor,
  parcelFinderReturns,
  language,
  customerResponses,
});
