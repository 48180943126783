import React from 'react';

import Paper from '../../common2/Paper';
import HelpCardContent from './HelpCardContent';

function HelpCard(props) {
  return (
    <Paper>
      <HelpCardContent {...props} />
    </Paper>
  );
}

export default React.memo(HelpCard);
