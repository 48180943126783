import React from 'react';
import ReactTooltip from 'react-tooltip';
import './Tooltip.scss';

const Tooltip = ({ children, position, ...props }) => {
  return (
    <ReactTooltip place={position} effect="solid" {...props} styleName="theme">
      {children}
    </ReactTooltip>
  );
};

Tooltip.defaultProps = {
  position: 'bottom',
};

export default Tooltip;
