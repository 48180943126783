import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from './Select';

import './GroupedSelect.scss';

function GroupedSelect(props) {
  const {
    children,
    options,
    groups,
    ...selectProps
  } = props;

  return (
    <Select
      {...selectProps}
      options={getGroupedOptions(options, groups)}
      optionRenderer={renderOption}
    >
      {children}
    </Select>
  );
}

function renderOption(option) {
  const styleName = classNames({ 'group-option': option.isGroup});

  return <span styleName={styleName}>{option.label}</span>;
}

function getGroupedOptions(options, groups) {
  const groupedOptions = [];

  groups.forEach(group => {
    groupedOptions.push({
      value: group.value,
      label: group.label,
      disabled: true,
      isGroup: true,
    }, ...options.filter(option => option.group === group.value));
  });

  return groupedOptions;
}

GroupedSelect.propTypes = {
  ...Select.propTypes,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    group: PropTypes.string,
  })),
  groups: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default GroupedSelect;