import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {};

const emailTemplateReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'EMAIL_TEMPLATE')) {
      return requestProducer(draft, action, 'EMAIL_TEMPLATE', {
        requestProducer: () => initialState,
        successProducer: (draft, action) => {
          draft.isChanged = false;
          return Object.assign(draft, action.data);
        },
        errorProducer: (draft, action) => {
          const [graphQlError] = action.error?.graphQLErrors || [];
          draft.error = graphQlError || action.error;
          return draft;
        },
      });
    }

    if (action.type === 'CHANGE_EMAIL_TEMPLATE') {
      draft.isChanged = draft.content !== action.content;
      draft.content = action.content;

      return draft;
    }

    return draft;
  });

export default emailTemplateReducer;
