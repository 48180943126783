import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../Translate/Locale';
import ErrorPage from './ErrorPage';
import ErrorText from './ErrorText';
import {trackPageview} from '../../utils/googleAnalytics';

import './Error5xx.scss';

function Error5xx(props) {
  trackPageview('/5xxError');

  return (
    <ErrorPage
      upperHeader={`${props.message} Error`}
      header = "Shoot"
      subHeader = "Well, this is unexpected"
    >
      <ErrorText>
        <Locale>An error has occurred, we’re working hard to fix the problem.</Locale><br />
        <Locale>We’ll be up and running shortly.</Locale>
      </ErrorText>
      <ErrorText>
        <Locale>Check our live status on: </Locale>
        <a styleName="link" href="https://status.sevensenders.com">status.sevensenders.com</a>
      </ErrorText>
    </ErrorPage>
  );
}

Error5xx.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error5xx;