import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Label.scss';

const Label = ({children, disabled, inlineLeft, inlineRight}) => {
  const styleName = classnames('label', {
    'inline': inlineLeft || inlineRight,
    'inline-left': inlineLeft,
    'inline-right': inlineRight,
    disabled,
  });

  return <label styleName={styleName}>{children}</label>;
};

Label.propTypes = {
  disabled: PropTypes.bool,
  inlineLeft: PropTypes.bool,
  inlineRight: PropTypes.bool
};

export default Label;