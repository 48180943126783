import produce from 'immer';
import { isApiError } from '../api/Api';

const initialState = {};

const apiErrorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    if (action.type.endsWith('_ERROR') && isApiError(action.error)) {
      const { status } = action.error.response;
      draft.errorCode = status;
    }

    if (action.type.endsWith('/rejected') && isApiError(action.payload)) {
      const { status } = action.payload.response;
      draft.errorCode = status;
    }
  });

export default apiErrorReducer;
