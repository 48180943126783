import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../SettingsGroupCard';
import LogoSettings from './common/LogoSettings';
import CopyrightSettings from './common/CopyrightSettings';
import PromoSettings from './common/PromoSettings';

class FooterWidgetSettings extends React.PureComponent {
  render() {
    const {
      settings,
      onSettingsChange,
    } = this.props;

    return (
      <React.Fragment>
        <SettingsGroupCard
          title="Logo"
          subtitle="Upload your own logo"
        >
          <LogoSettings noLogoAlign settings={this.props.settings} onChange={this.props.onSettingsChange} />
        </SettingsGroupCard>
        <CopyrightSettings
          settings={settings}
          onChange={onSettingsChange}
        />
        <PromoSettings
          settings={settings}
          onChange={onSettingsChange}
        />
      </React.Fragment>
    );
  }
}

FooterWidgetSettings.defaultProps = {
  onSettingsChange: () => {},
};

FooterWidgetSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func,
};

export default FooterWidgetSettings;
