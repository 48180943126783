import React from 'react';
import PropTypes from 'prop-types';
import EmbeddedWidgetsListItem from './EmbeddedWidgetsListItem';
import WidgetsListTracking from '../Widget/WidgetsListTracking';
import groupBy from '../../utils/groupBy';
import ListHeaderAccordion from '../touchpoints/ListHeaderAccordion';
import ListWithCollapsibleLocalizationHeader from '../ListWithLocalizationHeader/ListWithCollapsibleLocalizationHeader';
import { sortWidgets } from '../../utils/sortWidgets';

class EmbeddedWidgetsList extends React.PureComponent {
  render() {
    const { widgets, onViewCode, onDelete, onRename, onDuplicate } = this.props;

    const widgetsByLanguage = groupBy(widgets, (widget) => widget.language);
    const languages = Object.keys(widgetsByLanguage);

    return (
      <ListHeaderAccordion name="embeddedWidgetExpansionList" localizationIds={languages}>
        {languages.map((lang) => {
          const widgets = widgetsByLanguage[lang];

          return (
            <ListWithCollapsibleLocalizationHeader key={lang} id={lang} language={lang}>
              <WidgetsListTracking>
                {sortWidgets(widgets).map((widget) => (
                  <EmbeddedWidgetsListItem
                    widget={widget}
                    key={widget.id}
                    onViewCode={() => onViewCode(widget)}
                    onDelete={() => onDelete(widget)}
                    onRename={() => onRename(widget)}
                    onDuplicate={() => onDuplicate(widget)}
                  />
                ))}
              </WidgetsListTracking>
            </ListWithCollapsibleLocalizationHeader>
          );
        })}
      </ListHeaderAccordion>
    );
  }
}

EmbeddedWidgetsList.defaultProps = {
  widgets: [],
  onDuplicate: () => {},
  onDelete: () => {},
  onRename: () => {},
  onViewCode: () => {},
};

EmbeddedWidgetsList.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.object),
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  onViewCode: PropTypes.func,
};

export default EmbeddedWidgetsList;
