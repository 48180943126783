import React from 'react';
import PropType from 'prop-types';

import EventCard from './EventCard';
import TimelineSegment from './TimelineSegment';
import Heading3 from '../../common/Heading3';

import './EventsHistory.scss';

function EventsHistory(props) {
  const events = props.events || [];

  return (
    <div styleName="container">
      <Heading3 styleName="heading">Events history</Heading3>
      { events.slice().map((event, i) => {
        const isLastEvent = i === 0;
        const isFirstEvent = i === events.length - 1;

        return (
          <div styleName="event-container" key={i}>
            <TimelineSegment
              date={event.time}
              isLastEvent={isLastEvent}
              isFirstEvent={isFirstEvent}
            />
            <EventCard
              {...event}
              isLastEvent={isLastEvent}
            />
          </div>
        );
      })}
    </div>
  );
}

EventsHistory.propTypes = {
  events: PropType.arrayOf(PropType.object),
};

export default React.memo(EventsHistory);