import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Locale } from '../../Translate';

import AttentionIcon from '../../../icons/attention.svg';
import CheckIcon from '../../../icons/check.svg';

const statusIcons = {
  info: AttentionIcon,
  warning: AttentionIcon,
  error: AttentionIcon,
  success: CheckIcon,
};

import './StatusLabel.scss';

const StatusLabel = ({ status, children }) => {
  const Icon = statusIcons[status];

  return (
    <div styleName={classNames('status-label', status)}>
      <Icon styleName="icon" />
      <Locale>{children}</Locale>
    </div>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.keys(statusIcons)),
  children: PropTypes.string,
};

StatusLabel.defaultProps = {
  status: 'info',
};

export default StatusLabel;