/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../common2/Badge';

const claimTypeTitles = {
  investigation: 'Investigation',
  partial_damage: 'Partial damage',
  whole_damage: 'Whole damage',
  missing_item: 'Missing item',
};

function ClaimsTypeBadge({ claimType }) {
  return <Badge>{claimTypeTitles[claimType]}</Badge>;
}

ClaimsTypeBadge.propTypes = {
  claimType: PropTypes.string,
};

export default React.memo(ClaimsTypeBadge);
