import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { getReturnPortalPreviewUrl } from '../../../../utils/previewHelper';
import useLivePreview from '../../../../hooks/useLivePreview';
import makeAsyncScriptComponentWithLoader from '../../../../wrappers/makeAsyncScriptComponentWithLoader';

import './ReturnPortalPreview.scss';

// react-async-script uses react's forwardRef to pass ref to wrapper component, so
// it expects functional components to wrapper with his method.
export const ReturnPortalPreview = forwardRef((props, _ref) => {
  const {
    page = 'login',
    isLite,
    settings = {},
    translations = {},
    availableLanguages,
    language,
    ReturnPortal,
    order,
    returnRequest,
  } = props;
  const staticUrl = process.env.RETURN_PORTAL_URL;

  const ref = useLivePreview({
    globalInstance: ReturnPortal,
    previewName: 'livePreview',
    props: {
      page,
      settings,
      language,
      translations,
      staticUrl,
      order,
      returnRequest,
      availableLanguages,
      isLite,
    },
  });

  return <div styleName={classNames('container', { loaded: !!ReturnPortal })} ref={ref} />;
});

export default makeAsyncScriptComponentWithLoader(getReturnPortalPreviewUrl, { globalName: 'ReturnPortal' })(
  ReturnPortalPreview
);
