import React from 'react';

import './ErrorText.scss';

function ErrorText(props) {
  const { children } = props;

  return (
    <div styleName="text">
      {children}
    </div>
  );
}

export default React.memo(ErrorText);