import React from 'react';
import PropTypes from 'prop-types';

import Heading2 from '../common/Heading2/Heading2';
import PageContainer from '../pagecontainer/PageContainer';

import './SettingsPage.scss';

const menuItems = [
  {
    id: '/settings/user-management',
    link: '/settings/user-management',
    title: 'User management',
  },
  {
    id: '/settings/shop/localizations',
    link: '/settings/shop/localizations',
    title: 'Localizations',
  },
  {
    id: '/settings/shop/warehouses',
    link: '/settings/shop/warehouses',
    title: 'Warehouses',
  },
  {
    id: '/settings/shop/service-level-agreements',
    link: '/settings/shop/service-level-agreements',
    title: 'Service level agreements',
  },
];

class SettingsPage extends React.PureComponent {
  render() {
    const {
      children,
      title,
      renderHeaderElements,
      ...pageProps
    } = this.props;

    return (
      <PageContainer {...pageProps}
        menuItems={menuItems}
        menuTitle="Settings"
      >
        <div styleName="header">
          <Heading2>{title}</Heading2>
          {renderHeaderElements()}
        </div>
        <div styleName="container">{children}</div>
      </PageContainer>
    );
  }
}

SettingsPage.defaultProps = {
  title: 'Settings',
  renderHeaderElements: () => null,
};

SettingsPage.propTypes = {
  title: PropTypes.string,
  renderHeaderElements: PropTypes.func,
};

export default SettingsPage;
