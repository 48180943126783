import React from 'react';

import SearchSwitch from './search/SearchSwitch';
import SearchHelpText from './search/SearchHelpText';
import ParcelFinderLoader from '../parcel-finder/ParcelFinderLoader';
import ClaimsCenterResults from './ClaimsCenterResults';
import SearchFilterNoResult from './search/SearchFilterNoResult';

function ClaimsCenterSearchResults(props) {
  const { isLoaded, isSearchPending, searchedClaims, showTakeActionPanel, showCancelClaimModal } = props;

  return (
    <SearchSwitch key="search" isLoaded={isLoaded} isSearchPending={isSearchPending} searchedClaims={searchedClaims}>
      <SearchHelpText key="search-initial" />
      <ParcelFinderLoader key="search-loader" />
      <ClaimsCenterResults
        key="search-results"
        claims={searchedClaims}
        onTakeActionButtonClick={showTakeActionPanel}
        onCancelClaimButtonClick={showCancelClaimModal}
      />
      <SearchFilterNoResult key="search-no-results" />
    </SearchSwitch>
  );
}

export default React.memo(ClaimsCenterSearchResults);
