import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { warehouseDeliveryConfirmationInfoValidator } from '../claimValidators';
import useStepValidation from './useStepValidation';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import Locale from '../../../Translate/Locale';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import { format } from 'date-fns';
import DateInput from '../../../common2/DateInput/DateInput';
import Column from '../../../common/layout/Column';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';

function WarehouseDeliveryConfirmationStep(props) {
  const { as: Container, claim, onChange, onNext, ...restProps } = props;

  const { t } = useTranslation();

  const { isReturnShipment, deliveryDate, postalReturnDeliveryDate, warehouseDeliveryDate, deadlines } = claim;

  const warehouseDeliveryDateCutoffDays = deadlines[claim.type]?.maximumDaysAfterEvent;

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const deliveryDateToShow = isReturnShipment ? deliveryDate : postalReturnDeliveryDate;
  const formattedDate = format(deliveryDateToShow, 'dd.MM.yyyy');

  const validator = warehouseDeliveryConfirmationInfoValidator;
  const [errors, onClickNext] = useStepValidation(claim, onNext, validator);

  return (
    <Container {...restProps} onNext={onClickNext}>
      <ClaimStepClosableMessage intent="warning">
        <Locale>CLAIMS_WAREHOUSE_DELIVERY_DEADLINE_ALERT</Locale>
      </ClaimStepClosableMessage>
      <SidePanelContentHeader>CLAIMS_WAREHOUSE_DELIVERY_DEADLINE_HEADER</SidePanelContentHeader>

      <Column spacing={'medium'}>
        <span style={{ whiteSpace: 'pre-line' }}>
          <Trans
            t={t}
            i18nKey="CLAIMS_WAREHOUSE_DELIVERY_DEADLINE_MESSAGE"
            components={{ bold: <b /> }}
            values={{ deadline: warehouseDeliveryDateCutoffDays, date: formattedDate }}
          />
        </span>
      </Column>

      <div style={{ marginTop: '20px' }}>
        <Column>
          <ColumnsLayout>
            <DateInput
              name="warehouseDeliveryDate"
              label="Warehouse delivery date"
              value={warehouseDeliveryDate}
              placeholder="Add date"
              isRequired
              error={errors.warehouseDeliveryDate}
              onChange={onInputChange}
            />
            <div></div>
          </ColumnsLayout>
        </Column>
      </div>
    </Container>
  );
}

WarehouseDeliveryConfirmationStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(WarehouseDeliveryConfirmationStep);
