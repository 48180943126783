import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-responsive-modal';
import ReactTooltip from 'react-tooltip';

import styles from './Modal.scss';

function Modal(props) {
  const { show, children, onClose } = props;

  // set animationDuration to 0 because otherwise react-responsive-modal will try to animate modal
  // with invalid data (empty input fields and so on) because usually our data object that affects modal content
  // also controls modal visibility

  // to make tooltip work inside modal (https://github.com/wwayne/react-tooltip#1-using-tooltip-within-the-modal-eg-react-modal)
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [show]);

  return (
    <ReactModal
      classNames={styles}
      open={show}
      onClose={onClose}
      center
      animationDuration={0}
      closeIconId="modal-close-icon"
    >
      {children}
    </ReactModal>
  );
}

Modal.defaultProps = {
  show: false,
  onClose: () => {},
};

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
};

export default Modal;
