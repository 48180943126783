import React from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';

import './Tabs.scss';

function Tabs(props) {
  const { selectedTab, tabs = [], onChange = () => {} } = props;

  return (
    <div styleName="tabs">
      {tabs.map(({ id, title }) => (
        <Tab key={id} selected={id === selectedTab} onClick={() => onChange(id)}>
          {title}
        </Tab>
      ))}
    </div>
  );
}

Tabs.propTypes = {
  selectedTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default React.memo(Tabs);
