import { trackingPageSettings as defaultSettings } from '@sendwise/default-settings';

import editorTypeEnum from '../visualEditorTypeEnum';
import HeaderWidgetSettings from '../../WidgetEditionPage/WidgetSettings/HeaderWidgetSettings';
import BannerWidgetSettings from '../../WidgetEditionPage/WidgetSettings/BannerWidgetSettings';
import ProductsWidgetSettings from '../../WidgetEditionPage/WidgetSettings/ProductsWidgetSettings';
import FooterWidgetSettings from '../../WidgetEditionPage/WidgetSettings/FooterWidgetSettings';
import EmbeddedWidgetStatusSettings from '../embedded-widget-editor/settings/EmbeddedWidgetStatusSettings';
import TrackingPageTypographySettings from '../tracking-page-editor/TrackingPageTypographySettings';
import { ewSettingsGroups } from '../../WidgetEditionPage/WidgetDesignSettings/colorSettingsHelper';

const editorConfig = {
  editorType: editorTypeEnum.EMBEDDED_WIDGET,
  fixedWidgets: {
    top: ['header'],
    bottom: ['footer'],
  },
  widgets: [
    'header',
    'status',
    'banner',
    'products',
    'footer',
  ],
  singletonWidgets: [
    'status',
  ],
  widgetsInfo: {
    header: {
      name: 'Header',
      settingsPanel: HeaderWidgetSettings,
    },
    status: {
      name: 'Status',
      settingsPanel: EmbeddedWidgetStatusSettings,
    },
    banner: {
      name: 'Banner',
      settingsPanel: BannerWidgetSettings,
    },
    products:     {
      name: 'Shop products',
      settingsPanel: ProductsWidgetSettings,
    },
    footer: {
      name: 'Footer',
      settingsPanel: FooterWidgetSettings,
    },
  },
  designSettingsPanel: {
    typography: TrackingPageTypographySettings,
  },
  colorGroups: ewSettingsGroups,
  defaultSettings,
};

export default editorConfig;