import React from 'react';
import PropTypes from 'prop-types';
import SettingsPanel from '../SettingsPanel';
import { withEditorConfig } from '../../editors/EditorContext';
import { isWidgetOptional } from '../../editors/widgetsHelper';

class WidgetSettings extends React.PureComponent {
  render() {
    const {
      widget: {
        type,
        settings: widgetSettings,
      },
      onSettingsChange,
      onClose,
      editorConfig,
    } = this.props;

    const {
      fixedWidgets,
    } = editorConfig;

    const onRemove = isWidgetOptional(type, fixedWidgets) ? this.props.onRemove : null; // only optional widgets can be removed
    const {
      settingsPanel: Panel,
      name = '',
    } = editorConfig.widgetsInfo[type] || {};

    const settings = {
      ...editorConfig.defaultSettings[type],
      ...widgetSettings,
    };

    return (
      <SettingsPanel selected={type} title={name} onClose={onClose} onRemove={onRemove}>
        {
          Panel && (
            <Panel
              key={type}
              settings={settings}
              onSettingsChange={onSettingsChange}
              isOptional={isWidgetOptional(type, fixedWidgets)}
            />
          )
        }
      </SettingsPanel>
    );
  }
}

WidgetSettings.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  widget: PropTypes.object,
  onSettingsChange: PropTypes.func,
  onSettingsChangeByEvent: PropTypes.func,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};

export default withEditorConfig(WidgetSettings);
