import React from 'react';
import Loader from '../common/Loader';
import LoadingError from '../common/LoadingError';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 */

/**
 * @param {Object} props
 * @param {UseQueryResult} props.result
 * @param {function(UseQueryResult): React.ReactNode} props.children
 * @return {React.ReactNode}
 */
function QueryResult(props) {
  const { result, children } = props;

  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <LoadingError />;
  }

  return children(result);
}

export default QueryResult;
