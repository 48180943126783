import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import container from '../container';

const initialState = {
  isLoading: false,
  loaded: false,
  error: null,
  portalsByShopId: {},
};

export const getReturnPortals = createAsyncThunk('returns/getReturnPortals', (shopId, { rejectWithValue }) => {
  return container.touchpointsApi
    .getReturnPortals(shopId)
    .then((data) => ({ shopId, data }))
    .catch(rejectWithValue);
});

export const duplicateReturnPortal = createAsyncThunk(
  'returns/duplicateReturnPortal',
  async ({ shopId, hash, localization, countryCode }) => {
    const returnPortal = await container.touchpointsApi.getReturnPortal(shopId, hash);

    const { settings, isLite } = returnPortal;

    return await container.touchpointsApi.createReturnPortal(shopId, {
      shopLocalizationId: localization.id,
      language: localization.language,
      countryCode,
      settings,
      isLite
    });
  }
);

const slice = createSlice({
  name: 'returns',
  initialState,
  extraReducers: {
    [getReturnPortals.pending](state) {
      state.isLoading = true;
      state.error = null;
    },
    [getReturnPortals.fulfilled](state, action) {
      const { shopId, data } = action.payload;

      state.portalsByShopId[shopId] = data;
      state.isLoading = false;
      state.loaded = true;
    },
    [getReturnPortals.rejected](state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    [duplicateReturnPortal.pending](state) {
      state.isDuplicating = true;
      state.error = null;
    },
    [duplicateReturnPortal.fulfilled](state) {
      state.isDuplicating = false;
      state.error = null;
    },
    [duplicateReturnPortal.rejected](state, action) {
      state.isDuplicating = false;
      state.error = action.error;
    },
  },
});

export default slice.reducer;
