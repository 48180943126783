import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Paper.scss';

function Paper(props) {
  const { intent = 'neutral', children } = props;

  return <div styleName={classNames('card', intent)}>{children}</div>;
}

Paper.propTypes = {
  intent: PropTypes.oneOf(['neutral', 'positive']),
};

export default Paper;
