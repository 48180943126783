import { createSlice } from '@reduxjs/toolkit';
import { createClaimFromShipment } from '../components/parcel-finder/claims/claimsHelpers';

const initialState = {
  claim: {},
  shipment: null,
  isSubmitting: false,
};

// reducer is used only for parcel finer claim
const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    initClaim(state, { payload: shipment }) {
      state.claim = createClaimFromShipment(shipment);
      state.shipment = shipment;
    },
    cancelClaim() {
      return initialState;
    },
    updateClaim(state, action) {
      Object.assign(state.claim, action.payload);
    },
    submitClaimRequest(state) {
      state.isSubmitting = true;
    },
    submitClaimSuccess() {
      return initialState;
    },
    submitClaimFailure(state) {
      state.isSubmitting = false;
    },
  },
});

export const {
  initClaim,
  cancelClaim,
  updateClaim,
  submitClaimRequest,
  submitClaimSuccess,
  submitClaimFailure,
} = claimSlice.actions;

export function getClaimingShipmentId(state) {
  const { shipment } = state.claim;
  return shipment && shipment.id;
}

export default claimSlice.reducer;
