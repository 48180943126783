import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../common/Text';
import CloseButton from '../../common2/CloseButton';
import InfoIcon from '../../../icons/intents/info.svg';
import DangerIcon from '../../../icons/intents/danger.svg';
import CheckIcon from '../../../icons/check-circle-filled.svg';

import './Message.scss';

const icons = {
  info: InfoIcon,
  danger: DangerIcon,
  warning: DangerIcon,
  success: CheckIcon,
};

function Message(props) {
  const { children, intent = 'info', onClick } = props;
  const Icon = icons[intent] || (() => null);

  return (
    <div styleName={classNames('container', intent)}>
      <div styleName="content">
        <Icon styleName="icon" />
        <Text ink>{children}</Text>
      </div>
      {onClick && (
        <div styleName="button">
          <CloseButton iconSize={20} onClick={onClick} />
        </div>
      )}
    </div>
  );
}

Message.propTypes = {
  intent: PropTypes.oneOf(['info', 'danger', 'success', 'warning']),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default React.memo(Message);
