import React from 'react';

import EmptyNotificationLog from './EmptyNotificationLog';
import { Switch } from '../../Wizard';
import Loader from '../../common/Loader';
import Heading2 from '../../common/Heading2';
import { NotificationsLogContainer } from './NotificationsLogContainer';
import NotificationsLogTable from './NotificationsLogTable';
import { getLoadingState } from '../parcelFinderHelper';

import './NotificationLog.scss';

function NotificationLog(props) {
  const { notifications, loading, error } = props;
  const isEmpty = !notifications?.length;

  const loadingState = getLoadingState({ loading, error, isEmpty });

  return (
    <Switch selected={loadingState}>
      <Loader key="loader" />
      <Heading2 key="error">Error getting notifications log</Heading2>
      <NotificationsLogContainer key="empty">
        <EmptyNotificationLog />
      </NotificationsLogContainer>
      {notifications?.length && (
        <NotificationsLogContainer key="data">
          <NotificationsLogTable notifications={notifications} />
        </NotificationsLogContainer>
      )}
    </Switch>
  );
}

export default React.memo(NotificationLog);
