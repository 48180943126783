import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ChatbotPage from '../../chatbot/ChatbotPage';
import ChatbotStartGuidePage from '../../chatbot/ChatbotStartGuide';
import { CHATBOT_PERMISSION } from '../../firewall/permissions';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';

const ChatbotWithAccess = withPermissionRedirect(CHATBOT_PERMISSION, '/chatbot/start-guide')(ChatbotPage);

function ChatbotRoutes() {
  return (
    <Routes>
      <Route index element={<ChatbotWithAccess />} />
      <Route path="start-guide" element={<ChatbotStartGuidePage />} />
    </Routes>
  );
}

export default ChatbotRoutes;
