export function getTranslatedDefaultSettings(settings, tFunc) {
  // override default prefix and suffix as we have internal {{placeholders}}. Replace with { skipInterpolation } after we update i18next.
  const t = (x) => tFunc(x, { interpolation: { prefix: '[[', suffix: ']]' } });

  return {
    ...settings,
    emailSubject: t(settings.emailSubject),
    emailMessage: t(settings.emailMessage),
    keepItemsConfirmationTitle: t(settings.keepItemsConfirmationTitle),
    keepItemsConfirmationBody: t(settings.keepItemsConfirmationBody),
    keepItemsEmailSubject: t(settings.keepItemsEmailSubject),
    keepItemsEmailMessage: t(settings.keepItemsEmailMessage),
  };
}

export function getTranslatedGroupedReasonOptions(reasons, t) {
  return reasons.map((group) => ({
    label: t(group.label),
    options: group.values.map((value) => ({
      value,
      label: t(value),
    })),
  }));
}

export function getTranslatedReasonOptions(reasons, t) {
  return reasons.map((value) => ({
    value,
    label: t(value),
  }));
}
