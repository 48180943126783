import React from 'react';
import PropTypes from 'prop-types';

import SidePanelContainer from '../../common2/side-panel/SidePanelContainer';
import SidePanelContent from '../../common2/side-panel/SidePanelContent';
import SidePanelFooter from '../../common2/side-panel/SidePanelFooter';
import Column from '../../common/layout/Column';
import TakeActionPanelError from './TakeActionPanelError';
import { useTakeAction } from './useTakeAction';
import TakeActionForm from './TakeActionForm';
import TakeActionSubmitButton from './TakeActionSubmitButton';
import TakeActionPanelHeading from './TakeActionPanelHeading';
import DeclinedDateSection from './DeclinedDateSection';
import DeclinedReasonSection from './DeclinedReasonSection';
import SidePanelContentSection from '../../common2/side-panel/SidePanelContentSection';
import Locale from '../../Translate/Locale';

function DisputeDeclinedReasonPanel(props) {
  const { claim, onClose, onSubmitSuccess, customerResponse } = props;
  const { action, onChange, submit, isSubmitting, errors, firstError } = useTakeAction({
    claimId: claim.id,
    reason: customerResponse?.name,
    onSubmitSuccess,
  });

  return (
    <SidePanelContainer>
      <TakeActionPanelHeading onClose={onClose}>Declined</TakeActionPanelHeading>
      <SidePanelContent>
        <Column spacing="medium">
          {firstError && <TakeActionPanelError>{firstError}</TakeActionPanelError>}
          <DeclinedDateSection rejectedAt={claim.rejectedAt} />
          <DeclinedReasonSection customerResponse={customerResponse} />
          <SidePanelContentSection heading="Dispute decline">
            <Locale>CLAIM_DECLINED_DISPUTE_MESSAGE</Locale>
          </SidePanelContentSection>
          <TakeActionForm action={action} onChange={onChange} errors={errors} isSubmitting={isSubmitting} />
        </Column>
      </SidePanelContent>
      <SidePanelFooter>
        <TakeActionSubmitButton isLoading={isSubmitting} onClick={submit} />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

DisputeDeclinedReasonPanel.propTypes = {
  claim: PropTypes.shape({
    id: PropTypes.number,
    rejectedAt: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
};

export default React.memo(DisputeDeclinedReasonPanel);
