import React from 'react';
import ExitIcon from '../../../../icons/exit.svg';

import './ExitButton.scss';

const ExitButton = ({ onClick }) => (
  <button styleName="exit-btn" onClick={onClick} aria-label="Exit">
    <div styleName="inner-container">
      {/* workaround to make justify-content work in Safari */}
      <ExitIcon styleName="icon" />
    </div>
  </button>
);

export default ExitButton;
