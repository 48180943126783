import produce from 'immer';
import { isRequestAction, requestProducer } from './requestReducerHelper';

const initialState = {};

const previewTrackingReducer = (state = initialState, action) => produce(state, draft => {
  if (isRequestAction(action, 'PREVIEW_TRACKING')) {
    return requestProducer(draft, action, 'PREVIEW_TRACKING', {
      requestProducer: draft => draft.data = null,
      successProducer: (draft, {data}) => draft.data = data,
    });
  }
});

export default previewTrackingReducer;