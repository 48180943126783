import { getTrackingPageSettings, getEmbeddedWidgetSettings } from './widgetSettings';
import { getPreviewTrackingData } from './previewTracking';

export function getTrackingPageEditorData(trackingPageId, shopId) {
  return (dispatch) => {
    dispatch(getTrackingPageSettings(trackingPageId)).then(({ data }) => {
      const { localization = {} } = data;
      return dispatch(getPreviewTrackingData(shopId, localization.language));
    });
  };
}

export function getEmbeddedWidgetEditorData(embeddedWidgetId, shopId) {
  return (dispatch) => {
    dispatch(getEmbeddedWidgetSettings(embeddedWidgetId)).then(({ data }) => {
      return dispatch(getPreviewTrackingData(shopId, data.language));
    });
  };
}
