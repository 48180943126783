import { isRequestAction, requestProducer } from './requestReducerHelper';
import { normalizeDataArrayWithLocalization } from '../models/touchpoints';
import produce from 'immer';
import { getUsedLocalizations } from '../utils/getUsedLocalizations';

const initialState = {
  templatesByShop: {},
  localizationsByShop: {},
};

const emailTemplatesReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'OLD_EMAIL_TEMPLATES')) {
      return requestProducer(draft, action, 'OLD_EMAIL_TEMPLATES', {
        successProducer: (draft, action) => {
          const { shopId } = action.data;

          const { localizations, data } = normalizeDataArrayWithLocalization(action.data);

          draft.templatesByShop[shopId] = data;
          draft.localizationsByShop[shopId] = Object.values(localizations);
        },
      });
    } else if (isRequestAction(action, 'EMAIL_TEMPLATES')) {
      return requestProducer(draft, action, 'EMAIL_TEMPLATES', {
        successProducer: (draft, action) => {
          const { shopId, data } = action.data;

          const localizations = getUsedLocalizations(data);
          const { emailTemplates } = data;

          draft.templatesByShop[shopId] = emailTemplates;
          draft.localizationsByShop[shopId] = localizations;
        },
      });
    }

    return draft;
  });

export default emailTemplatesReducer;
