import produce from 'immer';
import { isRequestAction, requestProducer } from './requestReducerHelper';

const initialState = {};

const successProducer = (draft, action) => {
  const { id , name, ssoUrl } = action.data;
  draft.id = id;
  draft.name = name;
  draft.ssoUrl = ssoUrl;
};

const lookerDashboardReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'LOOKER_DASHBOARD')) {
    return requestProducer(draft, action, 'LOOKER_DASHBOARD', {
      successProducer,
      requestProducer: draft => draft.loaded = false,
    });
  }

  return draft;
});

export default lookerDashboardReducer;
