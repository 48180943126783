import { useState, useCallback } from 'react';
import { isEmptyValidationResult } from '../claimValidators';

export default function useStepValidation(claim, onNext, validator) {
  const [errors, setErrors] = useState({});

  const onClickNext = () => {
    const errors = validator(claim);
    const noErrors = isEmptyValidationResult(errors);

    setErrors(errors);

    if (noErrors) {
      onNext();
    }
  };

  return [errors, useCallback(onClickNext, [claim, onNext])];
}
