import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '../../common2/drawer/Drawer';
import CreateClaimContainer from './CreateClaimContainer';

function ClaimsSidePanel(props) {
  return (
    <Drawer isOpen={props.isOpen}>
      <CreateClaimContainer onClose={props.onClose} />
    </Drawer>
  );
}

ClaimsSidePanel.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(ClaimsSidePanel);
