import container from '../container';

export function refreshTokenActionCreator(refreshToken) {
  return dispatch => {
    return container.api.refreshToken(refreshToken)
      .then(data => dispatch(refreshTokenAction(data)));
  };
}

export function refreshTokenAction(data) {
  return { type: 'REFRESH_TOKEN', data };
}