import React from 'react';
import PropTypes from 'prop-types';

import './DrawerBody.scss';

function DrawerBody(props) {
  const { children } = props;

  return <div styleName="body">{children}</div>;
}

DrawerBody.propTypes = {
  children: PropTypes.node,
};

export default React.memo(DrawerBody);
