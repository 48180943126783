import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';
import ValidationError from '../../common/ValidationError';
import Text from '../../common/Text';
import DangerIcon from '../../../icons/intents/danger.svg';

import './InputContainer.scss';

function InputContainer(props) {
  const {
    label,
    labelFor,
    labelIsRequired,
    labelHasWarning,
    errorWithTranslation,
    children,
    topRightElement,
    helpText,
  } = props;

  return (
    <div styleName="input-container">
      <div styleName="header">
        <div>
          {label && (
            <label styleName={classNames('label', { required: labelIsRequired })} htmlFor={labelFor}>
              <Locale>{label}</Locale>
            </label>
          )}
          {labelHasWarning && <DangerIcon styleName="icon warning" />}
        </div>
        <div styleName="right-content">{topRightElement}</div>
      </div>
      {children}
      {errorWithTranslation && (
        <div styleName="validation-error ">
          <ValidationError>{errorWithTranslation}</ValidationError>
        </div>
      )}
      {helpText && (
        <Text styleName="help-text">
          <Locale>{helpText}</Locale>
        </Text>
      )}
    </div>
  );
}

InputContainer.propTypes = {
  label: PropTypes.string,
  errorWithTranslation: PropTypes.string,
  helpText: PropTypes.string,
  children: PropTypes.element,
  topRightElement: PropTypes.object,
};

export default React.memo(InputContainer);
