import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';
import SettingsListItemIcon from '../SettingsListItemIcon';
import ArrowRight from '../../../icons/arrow-right.svg'
import DragHandle from '../SortableWidgetsList/DragHandle';

import './SettingsListItem.scss';

class SettingsListItem extends React.PureComponent {
  render() {
    const {
      type,
      onClick,
      title,
      children,
      isSortable,
      hideRightArrow,
    } = this.props;

    const styleName = classNames('item',{
      'item-clickable': onClick,
    });

    return (
      <div styleName={styleName} onClick={onClick}>
        <div styleName="icon">
          <SettingsListItemIcon type={type} />
        </div>
        <span styleName="title"><Locale>{title}</Locale></span>
        { isSortable && <DragHandle /> }
        { !hideRightArrow && <ArrowRight styleName="arrow-right" /> }
        { children && <div styleName="children">{children}</div>}
      </div>
    );
  }
}

SettingsListItem.defaultProps = {
  hideRightArrow: false,
};

SettingsListItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any,
  hideRightArrow: PropTypes.bool,
};

export default SettingsListItem;