import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  carrierCompanies: [],
};

const carrierCompaniesReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'CARRIER_COMPANIES')) {
      return requestProducer(draft, action, 'CARRIER_COMPANIES', {
        successProducer: (draft, action) => {
          draft.carrierCompanies = action.data;
        },
      });
    }

    return draft;
  });

export default carrierCompaniesReducer;
