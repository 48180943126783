import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import Dropdown from '../../modal/Dropdown';

import styles from './ColorButton.scss';

class ColorButton extends React.PureComponent {
  state = {
    showPicker: false,
  };

  handleClick = () => {
    this.setState(state => ({
      showPicker: !state.showPicker,
    }));
  };

  handleClose = () => {
    this.setState({ showPicker: false });
  };

  handleChange = ({ rgb }) => {
    this.props.onChange(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`, this.props.name);
  };

  render() {
    const style = {
      background: this.props.color,
    };

    return (
      <Dropdown
        show={this.state.showPicker}
        onClose={this.handleClose}
        content={<SketchPicker color={this.props.color} onChange={this.handleChange} />}
        position="bottomRight"
      >
        <div className={styles.swatch} onClick={this.handleClick}>
          <div styleName="color" style={style} />
        </div>
      </Dropdown>
    );
  }
}

ColorButton.propTypes = {
  onChange: PropTypes.func,
  color: PropTypes.string,
};

ColorButton.defaultProps = {
  onChange: () => {},
  color: 'rgba(255, 255, 255, 1)',
};

export default ColorButton;