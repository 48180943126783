import React from 'react';

import { Tracking, Notifications, Analytics, Search, Returns, CommsCenter, Chatbot } from './navIcons';
import { CHATBOT_PERMISSION } from '../../firewall/permissions';

const PRODUCTS = [
  {
    id: 'tracking',
    link: '/tracking',
    title: 'Touchpoints',
    icon: Tracking,
  },
  {
    id: 'customer-notifications',
    link: '/customer-notifications',
    title: 'Notifications',
    icon: Notifications,
  },
  {
    id: 'analytics',
    link: '/analytics',
    title: 'Analytics',
    icon: Analytics,
  },
  {
    id: 'parcel-finder',
    link: '/parcel-finder',
    title: 'Parcel Finder + Claims',
    icon: Search,
  },
  {
    id: 'communication-center',
    link: '/communication-center',
    title: 'Comms center',
    icon: CommsCenter,
  },
  {
    id: 'returns',
    title: 'Returns',
    link: '/returns',
    icon: Returns,
  },
  {
    id: 'chatbot',
    title: 'ParcelAI',
    link: '/chatbot',
    permission: CHATBOT_PERMISSION,
    icon: Chatbot,
  },
];

export default PRODUCTS;
