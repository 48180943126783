import { normalize, denormalize, schema } from 'normalizr';

const shop = new schema.Entity('shops');

const userInfo = { shops: [shop] };

export function normalizeUserInfo(data) {
  return normalize(data, userInfo);
}

export function denormalizeUserInfo(info, entities) {
  return denormalize(info, userInfo, entities);
}

export function denormalizeShop(shopId, entities) {
  return denormalize(shopId, shop, entities);
}