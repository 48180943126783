import React from 'react';

import Text from '../Text';
import Locale from '../../Translate/Locale';

import UploadIcon from '../../../icons/upload.svg';

import './Invitation.scss';
import classNames from 'classnames';

function Invitation(props) {
  return (
    <div styleName="container">
      <UploadIcon styleName={classNames('icon')} />
      <Text styleName={classNames('text')}>
        <Locale>{props.children}</Locale>
      </Text>
    </div>
  );
}

export default React.memo(Invitation);