import { useCallback, useEffect } from 'react';

export default function useUnload(condition) {
  const onBeforeUnload = useCallback((e) => {
    e.preventDefault();
    return (e.returnValue = '');
  }, []);

  useEffect(() => {
    if (condition) {
      window.addEventListener('beforeunload', onBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      };
    }
  }, [condition, onBeforeUnload]);
}
