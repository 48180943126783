import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../../Wizard/Switch';

import { useClaimsShopCarriers } from './common/useClaimsShopCarriers';
import { useSubmitClaimWorkflow } from '../../claims-center/submit-claims/useSubmitClaimWorkflow';

import { useSelectedShopId } from '../../../hooks/useSelectedShop';

import InvestigationStepContainer from './investigation/InvestigationStepContainer';
import PartialDamageStepContainer from './partial-damage/PartialDamageStepContainer';
import MissingItemStepContainer from './missing-item/MissingItemStepContainer';
import WholeDamageStepContainer from './whole-damage/WholeDamageStepContainer';

import ChooseClaimTypeStep from './ChooseClaimTypeStep';
import ChooseDamageTypeStep from './ChooseDamageTypeStep';
import InvestigationDeliveryInfoStep from './investigation/InvestigationDeliveryInfoStep';
import PartialDamageInfoStep from './partial-damage/PartialDamageInfoStep';
import WholeDamageInfoStep from './whole-damage/WholeDamageInfoStep';
import MissingItemInfoStep from './missing-item/MissingItemInfoStep';
import OutsideOfDeadlineStep from './common/OutsideOfDeadlineStep';
import DeadlineShippingInfoStep from './common/DeadlineShippingInfoStep';
import WarehouseDeliveryConfirmationStep from './common/WarehouseDeliveryConfirmationStep';
import ManualDeadlineCheckStep from './common/ManualDeadlineCheckStep';
import InvestigationDeliveredToMailboxStep from './investigation/InvestigationDeliveredToMailboxStep';
import ShippingInfoStep from './common/ShippingInfoStep';
import InvoiceInfoStep from './common/InvoiceInfoStep';
import SearchClaimInfoStep from './common/SearchClaimInfoStep';

const stepContainersByWorkflowState = {
  investigation: InvestigationStepContainer,
  'partial-damage': PartialDamageStepContainer,
  'whole-damage': WholeDamageStepContainer,
  'missing-item': MissingItemStepContainer,
};

function CreateClaim(props) {
  const {
    claim,
    trackingCode,
    nonClaimableMessage,
    machine,
    onChange = () => {},
    onSubmit,
    resetClaim = () => {},
    onLoadShipmentByTrackingId,
    onResetShipmentSearch,
    onChangeTrackingCode,
    isSubmitting,
    isDisabled,
    isClaimsAutomated,
  } = props;

  const shopId = useSelectedShopId();
  const { workflowStep, transition, next, back, canonicalWorkflowStep, workflowState } = useSubmitClaimWorkflow(
    machine,
    claim
  );

  const onResetClaim = () => {
    resetClaim();
  };

  const onSelectDamage = () => transition('SELECT_DAMAGE');
  const onSelectInvestigation = () => {
    transition('SELECT_INVESTIGATION');
    onChange({ type: 'investigation' });
  };

  const onSelectPartialDamage = () => {
    transition('SELECT_PARTIAL_DAMAGE');
    onChange({ type: 'partial_damage' });
  };

  const onSelectWholeDamage = () => {
    transition('SELECT_WHOLE_DAMAGE');
    onChange({ type: 'whole_damage' });
  };

  const onSelectMissingItem = () => {
    transition('SELECT_MISSING_ITEM');
    onChange({ type: 'missing_item' });
  };

  const isShipmentFieldsPrefilled = claim?.shipmentId;

  const onClose = () => props.onClose(canonicalWorkflowStep);

  const { data: carriers = [] } = useClaimsShopCarriers();

  const stepProps = {
    shopId,
    claim,
    carriers,
    trackingCode,
    nonClaimableMessage,
    as: stepContainersByWorkflowState[workflowState] || React.Fragment,
    onChange,
    onNext: next,
    onBack: back,
    onClose,
    onResetClaim,
    onResetShipmentSearch,
    onLoadShipmentByTrackingId,
    onChangeTrackingCode,
    isSubmitting,
    isDisabled,
    isClaimsAutomated,
    isManual: !claim.shipmentId,
    showDeliveryInfo: claim.type !== 'investigation',
  };

  return (
    <React.Fragment>
      <Switch selected={workflowStep}>
        <ChooseClaimTypeStep
          key="choose-claim-type"
          name="choose-claim-type"
          onClickDamage={onSelectDamage}
          onClickMissingItem={onSelectMissingItem}
          onClickInvestigation={onSelectInvestigation}
          onClose={onClose}
          isConditionalFlowDisabled={
            isShipmentFieldsPrefilled && !claim.deliveryDate && !claim.postalReturnDeliveryDate
          }
        />
        <ChooseDamageTypeStep
          key="choose-damage-type"
          name="choose-damage-type"
          onClickPartialDamage={onSelectPartialDamage}
          onClickWholeDamage={onSelectWholeDamage}
          onBack={back}
          onClose={onClose}
        />
        <SearchClaimInfoStep key="search-claim-info" name="search-claim-info" {...stepProps} />
        <ManualDeadlineCheckStep key="manual-deadline-check" name="manual-deadline-check" {...stepProps} />
        <ShippingInfoStep key="shipping-info" name="shipping-info" {...stepProps} />
        <OutsideOfDeadlineStep key="outside-of-deadline" name="outside-of-deadline" {...stepProps} />
        <DeadlineShippingInfoStep key="deadline-shipping-info" name="deadline-shipping-info" {...stepProps} />
        <InvestigationDeliveredToMailboxStep
          key="investigation-delivered-to-mailbox"
          name="investigation-delivered-to-mailbox"
          {...stepProps}
        />
        <InvestigationDeliveryInfoStep
          key="investigation-delivery-info"
          name="investigation-delivery-info"
          {...stepProps}
        />
        <InvoiceInfoStep
          key="invoice-info"
          name="invoice-info"
          {...stepProps}
          onNext={onSubmit}
          isSubmitting={isSubmitting}
          isSubmitButton={true}
        />
        <WarehouseDeliveryConfirmationStep
          key="warehouse-delivery-confirmation-info"
          name="warehouse-delivery-confirmation-info"
          {...stepProps}
        />
        <PartialDamageInfoStep key="partial-damage-info" name="partial-damage-info" {...stepProps} />
        <WholeDamageInfoStep key="whole-damage-info" name="whole-damage-info" {...stepProps} />
        <MissingItemInfoStep key="missing-item-info" name="missing-item-info" {...stepProps} />
      </Switch>
    </React.Fragment>
  );
}

CreateClaim.propTypes = {
  claim: PropTypes.object.isRequired,
  machine: PropTypes.shape({}),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isClaimsAutomated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isShipmentFieldsPrefilled: PropTypes.bool,
};

export default React.memo(CreateClaim);
