import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClaimCustomerResponse, customerResponseSelector } from '../reducers/claimCustomerResponseReducer';

function useClaimCustomerResponse(shopId, claimId) {
  const dispatch = useDispatch();
  const { data = {}, isLoading, loaded, error } = useSelector((state) => customerResponseSelector(state));

  useEffect(() => {
    if (shopId && claimId) {
      dispatch(getClaimCustomerResponse({ shopId, claimId }));
    }
  }, [shopId, claimId, dispatch]);

  return { customerResponse: data[claimId], isLoading, loaded, error };
}

export default useClaimCustomerResponse;
