import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';

import './ResetButton.scss';

function ResetButton(props) {
  const { onClick, children, disabled } = props;

  return (
    <button styleName="button" name="reset-button" onClick={onClick} disabled={disabled}>
      <Locale>{children}</Locale>
    </button>
  );
}

ResetButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(ResetButton);
