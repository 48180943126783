import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';
import ResultCard from './ResultCard';
import ResultHeader from './ResultHeader';
import ResultTable from './ResultTable';
import PlainButton from '../../common/PlainButton';
import Locale from '../../Translate/Locale';
import { formatDate } from './dateHelper';
import { featureToggled } from '../../../utils/featureToggle';

import ReturnsIcon from '../../../icons/returns.svg';

import './OrderCard.scss';

function OrderCard(props) {
  const {
    id,
    shopOrderId,
    creationDate,
    promisedDeliveryDate,
    language,
    lastEvent,
    isReturnable,
    onCreateReturn,
    hasAdhocReturnsLabelAccess,
    hasOrderDetailsAccess,
  } = props;

  const orderUrl = `/parcel-finder/orders/${id}`;

  return (
    <ResultCard>
      <ResultHeader
        type="order"
        name="Order"
        labelForId="ID"
        id={shopOrderId}
        lastEvent={lastEvent}
        link={orderUrl}
        showDetailsButton={hasOrderDetailsAccess}
      >
        {hasAdhocReturnsLabelAccess && isReturnable && (
          <PlainButton icon={ReturnsIcon} onClick={onCreateReturn}>
            Create a return
          </PlainButton>
        )}
      </ResultHeader>
      <ResultTable>
        <thead styleName="table-header">
          <tr>
            <th>
              <Text>
                <Locale>Order date</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Promised delivery</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Language</Locale>
              </Text>
            </th>
          </tr>
        </thead>
        <tbody styleName="table-body">
          <tr>
            <td>
              <Text ink>{formatDate(creationDate)}</Text>
            </td>
            <td>
              <Text ink>{formatDate(promisedDeliveryDate)}</Text>
            </td>
            <td>
              <Text ink>{language}</Text>
            </td>
          </tr>
        </tbody>
      </ResultTable>
    </ResultCard>
  );
}

OrderCard.propTypes = {
  id: PropTypes.number,
  shopOrderId: PropTypes.string,
  creationDate: PropTypes.string,
  promisedDeliveryDate: PropTypes.string,
  language: PropTypes.string,
  lastEvent: PropTypes.shape({
    type: PropTypes.string,
    meaning: PropTypes.oneOf(['really_bad', 'bad', 'good', 'neutral']),
  }),
  isReturnable: PropTypes.bool,
  onCreateReturn: PropTypes.func,
  hasAdhocReturnsLabelAccess: PropTypes.bool,
};

export default React.memo(OrderCard);
