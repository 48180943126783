import React from 'react';
import PropTypes from 'prop-types';

import useShipmentNotifications from '../ShipmentDetailsPage/hooks/useShipmentNotifications';
import NotificationLog from './NotificationLog';

function ShipmentNotificationsLog(props) {
  const { shipmentId, shopId } = props;
  const notificationsState = useShipmentNotifications(shopId, shipmentId);

  return <NotificationLog {...notificationsState} />;
}

ShipmentNotificationsLog.propTypes = {
  shipmentId: PropTypes.number.isRequired,

  shopId: PropTypes.number.isRequired,
};

export default React.memo(ShipmentNotificationsLog);
