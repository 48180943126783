import React from 'react';
import classNames from 'classnames';
import Arrow from '../../../icons/arrow-right.svg';

import './CollapseButton.scss';

class CollapseButton extends React.PureComponent {

  render() {
    const {
      collapsed,
      onClick,
    } = this.props;

    return (
      <div styleName={classNames('button', { collapsed })} onClick={onClick}>
        <Arrow styleName="icon" />
      </div>
    );
  }
}

export default CollapseButton;