import React from 'react';
import Switch from '../../Wizard/Switch';
import PanelWithHeader from '../PanelWithHeader';
import Button from '../../common/Button';
import PropTypes from 'prop-types';

import './SettingsPanel.scss';

class SettingsPanel extends React.PureComponent {
  render() {
    const {
      selected,
      title,
      onClose,
      onRemove,
      children,
    } = this.props;

    return (
      <PanelWithHeader onClose={onClose} onRemove={onRemove} title={title}>
        <Switch selected={selected}>
          {children}
        </Switch>
        { onRemove && (
          <div styleName="remove-button">
            <Button onClick={onRemove} outline>
              Remove widget
            </Button>
          </div>
        ) }
      </PanelWithHeader>
    );
  }
}

SettingsPanel.propTypes = {
  onRemove: PropTypes.func,
};

export default SettingsPanel;
