import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from '../CloseButton';

import './DrawerHeader.scss';

function DrawerHeader(props) {
  const { onClose, children } = props;

  return (
    <div styleName="header">
      <div styleName="button">
        <CloseButton onClick={onClose} />
      </div>
      {children}
    </div>
  );
}

DrawerHeader.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default React.memo(DrawerHeader);
