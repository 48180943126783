const initialState = {
  error: false,
  status: false,
};

function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_PASSWORD_REQUEST': return { ...initialState, isLoading: true };
    case 'RESET_PASSWORD_SUCCESS': return { ...state, status: true, isLoading: false };
    case 'RESET_PASSWORD_ERROR': return { ...state, error: true, isLoading: false };
    case 'LOGIN_SUCCESS': return initialState;
    default: return state;
  }
}

export default resetPasswordReducer;