import React from 'react';
import PropTypes from 'prop-types';
import statusEnum from '../../constants/embeddedWidgetStatusEnum';
import PublishDate from '../Widget/WidgetListItem/PublishDate';
import SaveDate from '../Widget/WidgetListItem/SaveDate';
import StatusIndicator from '../common2/StatusIndicator/StatusIndicator';
import WidgetActionDropdownButton from '../../components/Widget/WidgetListItem/WidgetActionDropdownButton';
import Row from '../common/Row/Row';
import Link from '../common/Link';

import './EmbeddedWidgetListItem.scss';

const statusTooltip = {
  [statusEnum.INSTALL_CODE]: { status: 'Install code', intent: 'base' },
  [statusEnum.READY_TO_PUBLISH]: { status: 'Ready to publish', intent: 'base' },
  [statusEnum.LIVE]: { status: 'Live', intent: 'active' },
  [statusEnum.INACTIVE]: { status: 'Inactive', intent: 'inactive' },
};

function EmbeddedWidgetsListItem(props) {
  const { widget, onDuplicate, onDelete, onRename, onViewCode } = props;
  const { status, intent } = statusTooltip[widget.status];

  return (
    <tr role="row">
      <td>
        <Row spacing={20}>
          <StatusIndicator status={status} intent={intent} />
          <Link to={`/tracking/embedded-widgets/${widget.id}/edit`}>
            <div styleName="title">{widget.name}</div>
          </Link>
        </Row>
      </td>
      <td>
        <PublishDate date={widget.publishDate} />
      </td>
      <td>
        <SaveDate saveDate={widget.saveDate} publishDate={widget.publishDate} />
      </td>
      <td>
        <WidgetActionDropdownButton
          onRename={onRename}
          onViewCode={onViewCode}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      </td>
    </tr>
  );
}

EmbeddedWidgetsListItem.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    language: PropTypes.string,
    status: PropTypes.oneOf(Object.values(statusEnum)),
    publishDate: PropTypes.number,
    saveDate: PropTypes.number,
  }),
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  onViewCode: PropTypes.func,
};

export default React.memo(EmbeddedWidgetsListItem);
