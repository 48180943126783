import React from 'react';
import PropTypes from 'prop-types';

import Wizard from '../Wizard';
import InstallCodeTagPage from './InstallCodeTagPage';
import { getEmbeddedWidgetSettings, publishEmbeddedWidget } from '../../actions/widgetSettings';
import { connect } from 'react-redux';
import { widgetSettingsSelector } from '../../reducers/selectors';
import { withTranslation } from 'react-i18next';
import withLegacyRouter from '../../wrappers/withLegacyRouter';

export class InstallCodePage extends React.PureComponent {
  componentDidMount() {
    this.props.getWidgetSettings();
  }

  onPublish = () => {
    this.props.publishEmbeddedWidget().then(() => this.onClose());
  };

  onClose = () => {
    this.props.history.push(`/tracking/embedded-widgets/${this.props.embeddedWidgetId}/edit`);
  };

  render() {
    const { hash } = this.props;

    return (
      <Wizard onClose={this.onClose}>
        <InstallCodeTagPage key="installCode" hash={hash} onButtonClick={this.onPublish} />
      </Wizard>
    );
  }
}

InstallCodePage.propTypes = {
  t: PropTypes.func.isRequired,
  embeddedWidgetId: PropTypes.string.isRequired,
  getWidgetSettings: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  hash: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { hash } = widgetSettingsSelector(state);

  return {
    hash,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const embeddedWidgetId = ownProps.match.params.id;

  return {
    getWidgetSettings: () => dispatch(getEmbeddedWidgetSettings(embeddedWidgetId)),
    publishEmbeddedWidget: () => dispatch(publishEmbeddedWidget(embeddedWidgetId)),
    embeddedWidgetId,
  };
}

export const InstallCodePageConnected = connect(mapStateToProps, mapDispatchToProps)(InstallCodePage);

export default withTranslation()(withLegacyRouter(InstallCodePageConnected));
