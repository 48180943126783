import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Navigation from './Navigation';
import SideMenu from '../SideMenu';
import CollapseButton from './CollapseButton';
import { getPartFromPath } from '../../utils/urlPathHelper';

import './DesktopPageContainer.scss';

function DesktopPageContainer(props) {
  const {
    menuItems,
    onSelectMenu,
    mobileMenuOnly,
    collapsible = false,
    menuCollapsed = false,
    menuTitle,
    menuTitleBadge,
    children,
  } = props;

  const location = useLocation();

  const [isMenuOpen, toggleMenu] = useReducer((state) => !state, !menuCollapsed);

  const selectedProduct = getPartFromPath(location.pathname, 0);

  return (
    <div styleName="page">
      <Navigation location={location} selectedProduct={selectedProduct} />
      <div styleName="menu-with-content">
        {!mobileMenuOnly && menuItems && (
          <React.Fragment>
            {isMenuOpen && (
              <div styleName="side-menu-container">
                <SideMenu
                  title={menuTitle}
                  titleBadge={menuTitleBadge}
                  items={menuItems}
                  pathname={location.pathname}
                  onSelect={onSelectMenu}
                />
              </div>
            )}
            {collapsible && <CollapseButton collapsed={!isMenuOpen} onClick={toggleMenu} />}
          </React.Fragment>
        )}
        <div styleName="content">
          <div styleName="content-wrapper">{children}</div>
        </div>
      </div>
    </div>
  );
}

DesktopPageContainer.propTypes = {
  menuTitle: PropTypes.string,
  menuTitleBadge: PropTypes.element,
  menuItems: PropTypes.arrayOf(PropTypes.object), // undefined if you do not want side menu
  selectedMenuItem: PropTypes.string,
  onSelectMenu: PropTypes.func,
  menuCollapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
};

export default DesktopPageContainer;
