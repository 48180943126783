import React from 'react';
import PropTypes from 'prop-types';
import SettingsList from './SettingsList/SettingsList';
import SettingsListItem from './SettingsList/SettingsListItem';
import PanelWithHeader from './PanelWithHeader/PanelWithHeader';
import Button from '../common/Button';
import { withEditorConfig } from '../editors/EditorContext';
import { isWidgetCanBeAdded, getWidgetName, isWidgetOptional } from '../editors/widgetsHelper';

class AddWidgetPanel extends React.PureComponent {
  getAddButtonHandler(item) {
    const { previewWidget } = this.props;

    if (previewWidget && previewWidget.widget.type === item) {
      return (e) => {
        /* We do not want this event to be triggered when clicked on the parent div. Hence the stop event propagation */
        e.stopPropagation();
        this.props.onAddWidget(item);
      };
    }

    return null;
  };

  renderItemAddButton(item) {
    const onAdd = this.getAddButtonHandler(item);
    return onAdd ? <Button onClick={onAdd} small>Add</Button> : null;
  }

  renderWidgetList = () => {
    const { editorConfig } = this.props;
    const widgets = editorConfig.widgets
      .filter(type => isWidgetOptional(type, editorConfig.fixedWidgets))
      .filter(type => isWidgetCanBeAdded(type, editorConfig.singletonWidgets, this.props.widgets));

    return widgets.map(widgetType => {
      return (
        <SettingsListItem
          type={widgetType}
          key={widgetType}
          title={getWidgetName(widgetType, editorConfig)}
          onClick={() => this.props.onAddPreviewWidget(widgetType)}
          hideRightArrow
        >
          {this.renderItemAddButton(widgetType)}
        </SettingsListItem>
      );
    });
  };

  render() {
    const {
      onClose,
    } = this.props;

    return (
      <PanelWithHeader title="Add a widget" onClose={onClose} >
        <SettingsList key="main">
          {this.renderWidgetList()}
        </SettingsList>
      </PanelWithHeader>
    );
  }
}

AddWidgetPanel.propTypes = {
  previewWidget: PropTypes.object,
  onAddPreviewWidget: PropTypes.func,
  onAddWidget: PropTypes.func,
  onClose: PropTypes.func,
  widgets: PropTypes.arrayOf(PropTypes.object),
  editorConfig: PropTypes.shape({
    widgets: PropTypes.array,
    fixedWidgets: PropTypes.object,
    singletonWidgets: PropTypes.array,
  }),
};

export default withEditorConfig(AddWidgetPanel);
