import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common2/Icon';
import HelpIcon from '../../../icons/investigation.svg';

import './InfoLinkCard.scss';

function InfoLinkCard({ children }) {
  return (
    <div styleName="link-card">
      <div styleName="icon">
        <Icon icon={HelpIcon} size={40} />
      </div>
      <div styleName="tip">{children}</div>
    </div>
  );
}

InfoLinkCard.propTypes = {
  children: PropTypes.node,
};

export default InfoLinkCard;
