import React, { useState } from 'react';

import BasicModal from './BasicModal';
import ModalButtonsContainer from './ModalButtonsContainer';
import Button from '../common/Button';
import CheckboxList from '../common/CheckboxList';
import Locale from '../Translate/Locale';

import './DeleteModal/DeleteModal.scss';

function useChecklist(initial = null) {
  const [checkValues, setState] = useState(initial);

  return [
    checkValues,
    value => setState({ ...checkValues, ...value }),
  ];
}

function ConfirmArchiveModal(props) {
  const {
    onArchive,
    onCancel,
    show,
    title,
    message,
    warningMessages,
    children,
  } = props;

  const [checkValues, onCheck] = useChecklist();
  const areWarningsAccepted = !!checkValues && warningMessages.every(item => checkValues[item] === true);

  return (
    <BasicModal
      show={show}
      title={title}
      onClose={onCancel}
      warning
    >
      <h1 styleName="title">
        <Locale>{title}</Locale>
      </h1>
      <div styleName="blockquote">
        <Locale>
          { message }
        </Locale>
        <blockquote>
          { children }
        </blockquote>
      </div>
      <div styleName="warnings-checklist">
        <h4>
          <Locale>Be aware that:</Locale>
        </h4>
        <CheckboxList
          onChange={onCheck}
          items={warningMessages}
          values={checkValues}
        />
        <ModalButtonsContainer align="right">
          <Button onClick={onCancel} primary gray outline>
            Cancel
          </Button>
          <Button onClick={onArchive} disabled={!areWarningsAccepted} primary warning>
            Archive
          </Button>
        </ModalButtonsContainer>
      </div>
    </BasicModal>
  );
}

export default ConfirmArchiveModal;