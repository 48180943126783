// NOTE: this list must be synchronized with PermissionTypeEnum in backend code (same order, same values)
export const TRACKING_PAGES_PERMISSION = 'trackingPages';
export const EMBEDDED_WIDGETS_PERMISSION = 'embeddedWidgets';
export const TOUCHPOINTS_PERMISSION = 'touchpoints';
export const CUSTOMER_NOTIFICATIONS_PERMISSION = 'customerNotifications';
export const ANALYTICS_PERMISSION = 'analytics';
export const ORDER_STATUS_PERMISSION = 'orderStatus';
export const SHOP_SETTINGS_PERMISSION = 'shopSettings';
export const SHOP_LOCALIZATIONS_PERMISSION = 'localizations';
export const ACCOUNT_SETTINGS_PERMISSION = 'accountSettings';
export const USER_MANAGEMENT_PERMISSION = 'userMgmt';
export const BILLING_PERMISSION = 'billing';
export const ORDER_PERMISSION = 'order';
export const SHIPMENT_PERMISSION = 'shipment';
export const INTEGRATIONS_PERMISSION = 'integrations';
export const CARRIER_PERMISSION = 'carrier';
export const WAREHOUSES = 'warehouses';
export const ORDER_DETAILS = 'orderDetails';
export const SHIPMENT_DETAILS = 'shipmentDetails';
export const SLA = 'sla';
export const SHOP_CONTENT_LIBRARY_PERMISSION = 'shopContentLibrary';
export const ANALYTICS_EXPLORE = 'analyticsExplore';
export const RETURNS = 'returns';
export const COMMS_CENTER = 'commsCenter';
export const COMMS_CENTER_LEGACY_PERMISSION = 'comms-center';
export const ADHOC_RETURNS_LABELS = 'adhocReturnLabels';
export const CLAIMS_AUTOMATED = 'claimsAutomated';
export const INVOICE_DETAILS = 'invoiceDashboard';
export const CHATBOT_PERMISSION = 'chatbot';
