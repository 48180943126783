import React from 'react';
import DetailsCardItem from '../DetailsCard/DetailsCardItem';
import InternalLink from '../../common2/links/InternalLink';
import Badge from '../../common/Badge';
import { getIntentForOrderState } from '../parcelFinderHelper';
import EventStatusBadge from '../EventStatusBadge';

import './ShipmentCard.scss';
import PropTypes from 'prop-types';

function ShipmentCard(props) {
  const { id, referenceNumber, trackingCode, status, lastEvent } = props;

  const linkToShipment = `/parcel-finder/shipments/${id}`;

  return (
    <div styleName="shipment">
      {referenceNumber && !trackingCode && (
        <DetailsCardItem label="Reference number">
          <InternalLink to={linkToShipment}>{referenceNumber}</InternalLink>
        </DetailsCardItem>
      )}
      {trackingCode && (
        <DetailsCardItem label="Tracking code">
          <InternalLink to={linkToShipment}>{trackingCode}</InternalLink>
        </DetailsCardItem>
      )}
      <DetailsCardItem label="Status">
        {status && <Badge intent={getIntentForOrderState(status)}>{status}</Badge>}
      </DetailsCardItem>
      <DetailsCardItem label="Last event">{lastEvent && <EventStatusBadge {...lastEvent} />}</DetailsCardItem>
    </div>
  );
}

ShipmentCard.propTypes = {
  id: PropTypes.number,
  trackingCode: PropTypes.string,
  status: PropTypes.string,
  lastEvent: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    meaning: PropTypes.string,
    time: PropTypes.string,
  }),
};
export default React.memo(ShipmentCard);
