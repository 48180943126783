import React from 'react';

import ArchivedSeparator from '../../ArchivedSeparator';
import ShopLocalizationsList from './ShopLocalizationsList';
import SettingsBodyWrapper from '../../SettingsPage/SettingsBodyWrapper';
import ShopLocalizationArchiveModal from './ShopLocalizationArchiveModal';
import container from '../../../container';
import ShopLocalizationDeleteModal from './ShopLocalizationDeleteModal';
import useModal from '../../modal/useModal';
import { filterActiveShopLocalizations, filterArchivedShopLocalizations } from '../../../utils/shopLocalizationsHelper';

function getLocalizationName({ url, language }) {
  return `${url} (${language})`;
}

export function ShopLocalizationsOverview(props) {
  const { localizations = [], resolveWithNotice = (p) => p, loadLocalisations = () => {} } = props;

  const deleteLocalization = (localization) =>
    resolveWithNotice(container.api.deleteShopLocalization(localization), '{{name}} has been deleted', {
      name: getLocalizationName(localization),
    }).then(({ success }) => {
      container.notificationsApi.refreshLocalizations();
      success && loadLocalisations();
    });

  const [localizationToBeDeleted, showDeleteModal, hideDeleteModal] = useModal();

  const onDelete = () => {
    hideDeleteModal();
    if (localizationToBeDeleted) {
      return deleteLocalization(localizationToBeDeleted);
    }
  };

  const archiveLocalization = (localization) =>
    resolveWithNotice(container.api.archiveShopLocalization(localization), '{{name}} has been archived', {
      name: getLocalizationName(localization),
    }).then(({ success }) => {
      container.notificationsApi.refreshLocalizations();
      success && loadLocalisations();
    });

  const [localizationToBeArchived, showArchiveModal, hideArchiveModal] = useModal();

  const onArchive = () => {
    hideArchiveModal();
    if (localizationToBeArchived) {
      return archiveLocalization(localizationToBeArchived);
    }
  };

  const activeLocalizations = filterActiveShopLocalizations(localizations);
  const archivedLocalizations = filterArchivedShopLocalizations(localizations);

  return (
    <React.Fragment>
      <SettingsBodyWrapper>
        <ShopLocalizationsList
          localizations={activeLocalizations}
          onDelete={showDeleteModal}
          onArchive={showArchiveModal}
        />
        {archivedLocalizations.length > 0 && (
          <React.Fragment>
            <ArchivedSeparator>Archived localizations</ArchivedSeparator>
            <ShopLocalizationsList localizations={archivedLocalizations} />
          </React.Fragment>
        )}
      </SettingsBodyWrapper>
      <ShopLocalizationDeleteModal
        localization={localizationToBeDeleted}
        onCancel={hideDeleteModal}
        onDelete={onDelete}
      />
      <ShopLocalizationArchiveModal
        localization={localizationToBeArchived}
        onCancel={hideArchiveModal}
        onArchive={onArchive}
      />
    </React.Fragment>
  );
}

export default React.memo(ShopLocalizationsOverview);
