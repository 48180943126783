export function isUrlValid(url = '') {
  return !!url.match(/^$|(http|https):\/\/[^ "]+$/);
}

export function isValidMailtoLink(url = '') {
  return !!url.match(/^mailto:[\w-+._]+@([\w-]+\.)+[\w-]{2,}$/);
}

export function isValidTelLink(url = '') {
  return !!url.match(/^tel:(\+?)[0-9]+$/);
}

export function isValidSubdomain(subdomain = '') {
  return !!subdomain.match(/^[A-Za-z0-9\-]*$/);
}
