import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  localizationsByShop: {},
  loaded: false,
};

const shopLocalizationsReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'SHOP_LOCALIZATIONS')) {
      return requestProducer(draft, action, 'SHOP_LOCALIZATIONS', {
        successProducer: (draft, action) => {
          const { shopId, data } = action.data;
          draft.localizationsByShop[shopId] = data;
        },
      });
    }

    return draft;
  });

export default shopLocalizationsReducer;
