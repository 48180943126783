import React from 'react';
import Locale from '../../Translate/Locale';

import './NtsBadge.scss';

function NtsBadge() {
  return (
    <div styleName="nts-badge">
      <Locale>Non-trackable shipments only</Locale>
    </div>
  );
}

export default NtsBadge;