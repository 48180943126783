import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';
import Locale from '../../Translate/Locale';

import './StartGuideCard.scss';

function StartGuideCard(props) {
  const { heading, description, children } = props;

  return (
    <div styleName="card">
      <Text title styleName="heading">
        <Locale>{heading}</Locale>
      </Text>
      {description && (
        <Text styleName="description">
          <Locale>{description}</Locale>
        </Text>
      )}
      {children}
    </div>
  );
}

PropTypes.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(StartGuideCard);
