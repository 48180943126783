import React from 'react';
import PropTypes from 'prop-types';

import { Locale } from '../../Translate';
import Link from '../Link';
import Button from '../Button';

import styles from './WhatsNext.scss';

class WhatsNext extends React.PureComponent {

  render() {
    const {
      title,
      subtitle,
      listItems,
      buttonLinkTo,
      buttonLabel,
    } = this.props;

    return (
      <div styleName="whats-next">
        <h1 className={styles.title}>
          <Locale>{title}</Locale>
        </h1>
        { !!subtitle && (
          <span className={styles.subtitle}>
            <Locale>{subtitle}</Locale>
          </span>
        ) }
        <ul styleName="list">
          { listItems.map((item, i) => (
            <li key={i}>
              <Locale>{item}</Locale>
            </li>
          )) }
        </ul>
        { !!buttonLinkTo && !!buttonLabel && (
          <div styleName="button-container">
            <Link to={buttonLinkTo}>
              <Button primary>
                {buttonLabel}
              </Button>
            </Link>
          </div>
        ) }
      </div>
    );
  }
}

WhatsNext.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default WhatsNext;