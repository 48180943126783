import React from 'react';
import Locale from '../Translate/Locale';
import InternalLink from '../common/ExternalLink/InternalLink';
import LocalizationSelect from '../LocalizationSelect/LocalizationSelect';

import './Localizations.scss';

function Localizations(props) {
  const { localizations = [], onChange, shopLocalisationId } = props;

  if (!localizations.length) {
    return (
      <div styleName="no-localization">
        <span styleName="text">
          <Locale>No localization defined.</Locale>
        </span>
        <InternalLink to="/settings/shop/localizations/create">Click here to create a localization.</InternalLink>
      </div>
    );
  }

  return (
    <LocalizationSelect
      name="localization"
      localizations={localizations}
      value={shopLocalisationId}
      onChange={onChange}
    />
  );
}

export default React.memo(Localizations);
