import React from 'react';

import Heading2 from '../common/Heading2';

import './ClaimsPageHeader.scss';

function ClaimsPageHeader(props) {
  const { children } = props;

  return (
    <div styleName="header">
      <Heading2>Claims center</Heading2>
      {children}
    </div>
  );
}

export default React.memo(ClaimsPageHeader);
