import React from 'react';
import PropTypes from 'prop-types';

import './CustomerResponseMessage.scss';

function CustomerResponseMessage({ html }) {
  return <div styleName="message" dangerouslySetInnerHTML={{ __html: html }} />;
}

CustomerResponseMessage.propTypes = {
  html: PropTypes.string,
};

export default React.memo(CustomerResponseMessage);
