import React from 'react';

import ButtonsGroup from '../../common/layout/ButtonsGroup';
import ModalBasicFooter from './ModalBasicFooter';

import './ModalFooter.scss';

function ModalFooter(props) {
  const { children } = props;

  return (
    <ModalBasicFooter>
      <div styleName="group">
        <ButtonsGroup>{children}</ButtonsGroup>
      </div>
    </ModalBasicFooter>
  );
}

export default React.memo(ModalFooter);
