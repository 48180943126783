import React from 'react';
import SmallBadge from '../TrackingPagesPage/SmallBadge';
import LocalizationIcon from '../../icons/localization2.svg';
import Locale from '../Translate/Locale';
import './LocalizationHeader.scss';
import { getLanguageName } from '../../utils/langHelper';

function LocalizationHeader(props) {
  const { url, language } = props;

  return (
    <div styleName="localization-header" data-test-id="localization-header" data-testid="localization-header">
      <LocalizationIcon styleName="icon" />
      <span styleName="url" data-test-id="url">
        {url || <Locale>{getLanguageName(language)}</Locale>}
      </span>
      {!!url && (
        <span styleName="small-badge">
          <SmallBadge>{getLanguageName(language)}</SmallBadge>
        </span>
      )}
    </div>
  );
}

export default React.memo(LocalizationHeader);
