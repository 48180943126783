import React from 'react';
import Locale from '../Translate';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './OptionWithSmallBadge.scss';

function OptionWithSmallBadge(props) {
  const { children, badgeText, onClick, isSelected, isFocused, inline = false } = props;

  return (
    <div styleName={classNames('item', { inline })} onClick={onClick}>
      <div styleName="text">{children}</div>
      <div styleName={classNames('small-badge', { focus: isSelected || isFocused })}>
        <Locale>{badgeText}</Locale>
      </div>
    </div>
  );
}

OptionWithSmallBadge.propTypes = {
  children: PropTypes.string.isRequired,
  badgeText: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  inline: PropTypes.bool,
};

export default React.memo(OptionWithSmallBadge);
