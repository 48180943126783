import { validator, chain, isRequired } from 'simple-object-validation';
import { isValidEmail } from '../../utils/emailValidationHelper';

const emailValidator = validator(
  email => !!email && isValidEmail(email),
  () => 'Please enter a valid email address',
);

const emailValidators = {
  email: chain([
    isRequired,
    emailValidator,
  ])('Email address'),
};

export default emailValidators;