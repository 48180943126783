import { isApiError } from '../api/Api';

/**
 * Immer.js producer for async action like api requests
 * @param draft - draft state
 * @param action - action
 * @param namePrefix - actions prefix
 *
 * @returns new state if action is matched, initial state otherwise
 */

export function requestProducer(draft, action, namePrefix, producers) {
  const { type } = action;
  const {
    requestProducer = () => {},
    successProducer,
    errorProducer = (draft, {error}) => {
      if (isApiError(error)) {
        draft.error = error;
      } else {
        throw error; //rethrow
      }
    }
  } = producers;

  if (type === `${namePrefix}_REQUEST`) {
    draft.isLoading = true;
    draft.error = null;
    requestProducer(draft, action);
  } else if (type === `${namePrefix}_SUCCESS`) {
    draft.loaded = true;
    draft.isLoading = false;
    successProducer(draft, action);
  } else if (type === `${namePrefix}_ERROR`) {
    draft.loaded = false;
    draft.isLoading = false;
    errorProducer(draft, action);
  }

  return draft;
}

export function isRequestAction(action, namePrefix) {
  const { type } = action;
  return type === `${namePrefix}_REQUEST` || type === `${namePrefix}_SUCCESS` || type === `${namePrefix}_ERROR`;
}
