import React from 'react';
import TextArea from '../../../common2/TextArea';
import PropTypes from 'prop-types';

function CustomerCommentInput(props) {
  const { claim, onChange } = props;
  const { customerComment } = claim;

  return (
    <TextArea
      name="customerComment"
      label="Optional comment"
      value={customerComment}
      class="optional-comment"
      onChange={onChange}
    />
  );
}

CustomerCommentInput.propTypes = {
  claim: PropTypes.shape({
    isDelivered: PropTypes.bool,
    customerComment: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default React.memo(CustomerCommentInput);
