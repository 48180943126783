import React from 'react';
import PropTypes from 'prop-types';

const SwitchWithPagination = ({ selected, children }) => {
  const pagesCount = React.Children.count(children);

  for (let i = 0; i < pagesCount; i++) {
    if (children[i].key === selected) {
      return React.cloneElement(children[i], { page: i + 1, pagesCount });
    }
  }

  return null;
};

SwitchWithPagination.propTypes = {
  selected: PropTypes.string.isRequired,
};

export default SwitchWithPagination;