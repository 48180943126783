import React from 'react';

import PublishDate from './PublishDate';
import InfoIcon from '../../../icons/info.svg';

import './WidgetListItem.scss';

function SaveDate({ saveDate, publishDate }) {
  const showIcon = saveDate && publishDate && saveDate > publishDate;

  return (
    <React.Fragment>
      <PublishDate date={saveDate} />
      {showIcon && (
        <div styleName="warn-icon" title="Your last saved version is not published yet">
          <InfoIcon />
        </div>
      )}
    </React.Fragment>
  );
}

export default SaveDate;
