import React from 'react';

import { SortedSelect } from '../Select';
import { getSelectOptions } from '../../../utils/langHelper';

class SelectLanguage extends React.PureComponent {
  render() {
    return (
      <SortedSelect
        {...this.props}
        searchable={false}
        placeholder="Select a language"
        options={getSelectOptions()}
      />
    );
  }
}

export default SelectLanguage;