import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SettingsList,
  SettingsListItem,
  SettingsListSpacer,
} from './SettingsList';

import { getLastWidgetID, getWidgetName, isWidgetOptional } from '../editors/widgetsHelper';
import { scrollElementIntoView } from '../editors/domHelpers';
import SortableWidgetsList from './SortableWidgetsList';
import { moveWidget, selectWidget } from '../../actions/widgetSettings';
import AddWidget from './AddWidget';
import { withEditorConfig } from '../editors/EditorContext';

class WidgetsList extends React.PureComponent {
  onSelect = id => {
    if (id) {
      scrollElementIntoView(id);
    }

    this.props.onSelect(id);
  };

  renderWidgetItem(widget) {
    if (!widget) {
      return null;
    }

    const {
      type,
      id,
    } = widget;

    return (
      <SettingsListItem
        key={id}
        type={type}
        title={getWidgetName(type, this.props.editorConfig)}
        onClick={() => this.onSelect(id)}
      />
    );
  }

  onAddWidgetClick = () => {
    const {
      widgets,
      onShowAddWidgetPanel,
      editorConfig,
    } = this.props;

    const {
      top: topWidgets,
      middle: middleWidgets,
    } = splitWidgetsIntoGroups(widgets, editorConfig.fixedWidgets);

    onShowAddWidgetPanel(getLastWidgetID(topWidgets, middleWidgets));
  };

  render() {
    const {
      top: topWidgets,
      middle: middleWidgets,
      bottom: bottomWidgets,
    } = splitWidgetsIntoGroups(this.props.widgets, this.props.editorConfig.fixedWidgets);

    return (
      <SettingsList>
        {!!topWidgets.length && <SettingsListSpacer key="top-spacer" />}
        { topWidgets.map(w => this.renderWidgetItem(w)) }
        { !!middleWidgets.length && <SettingsListSpacer key="head-spacer" /> }
        <SortableWidgetsList
          key="sortable"
          widgets={middleWidgets}
          onSelect={this.onSelect}
          onMove={this.props.onMove}
        />
        <AddWidget onClick={this.onAddWidgetClick} />
        <SettingsListSpacer key="bottom-spacer" />
        { bottomWidgets.map(w => this.renderWidgetItem(w)) }
      </SettingsList>
    );
  }
}

WidgetsList.defaultProps = {
  onSelect: () => {},
};

WidgetsList.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
  onShowAddWidgetPanel: PropTypes.func,
  onMove: PropTypes.func,
};

function splitWidgetsIntoGroups(widgets, fixed) {
  return {
    top: widgets.filter(w => fixed.top.includes(w.type)),
    middle: widgets.filter(w => isWidgetOptional(w.type, fixed)),
    bottom: widgets.filter(w => fixed.bottom.includes(w.type)),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelect: id => dispatch(selectWidget(id)),
    onMove: (from, to) => dispatch(moveWidget(from, to)),
  };
}

export default connect(null, mapDispatchToProps)(withEditorConfig(WidgetsList));
