import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Message from '../Message';

import './ClosableMessage.scss';

function ClosableMessage({ children, intent }) {
  const [showMessage, setShowMessage] = useState(true);

  const element = (
    <div styleName="banner">
      <Message intent={intent} onClick={() => setShowMessage(!showMessage)}>
        {children}
      </Message>
    </div>
  );

  return showMessage && element;
}

ClosableMessage.propTypes = {
  children: PropTypes.any,
  intent: PropTypes.string,
};

export default ClosableMessage;
