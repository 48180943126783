import React, { useCallback } from 'react';

import Select from '../../../common2/Select';

const options = [
  { value: 'no', label: 'No' },
  { value: 'yes', label: 'Yes' },
];

// Small and ridiculous wrapper around select to work with boolean value properly
function BooleanSelect(props) {
  const { value, onChange, ...restProps } = props;
  const optionValue = value ? 'yes' : 'no';

  const handleChange = useCallback((value, name) => onChange(value === 'yes', name), [onChange]);

  return <Select {...restProps} value={optionValue} options={options} onChange={handleChange} />;
}

export default React.memo(BooleanSelect);
