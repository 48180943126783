import React from 'react';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

class Slider extends React.PureComponent {
  render() {
    return (
      <RcSlider
        style={{
          marginRight: 16,
          width: 'auto',
          height: 24,
        }}
        trackStyle={{
          height: 12,
          backgroundColor: '#00b1e1',
        }}
        handleStyle={{
          width: 24,
          height: 24,
          backgroundColor: '#ffffff',
          border: 'solid 2px #e5e5e5',
          marginTop: -6,
        }}
        railStyle={{
          height: 12,
          borderRadius: 8,
          backgroundColor: '#f8f8f8',
          border: 'solid 2px #f1f1f1',
        }}
        {...this.props}
      />
    );
  }
}

export default Slider;