import container from '../container';
import { applyFallbackColorSettings } from '../reducers/applyFallbackSettings';

export function getEmbeddedWidgetSettings(id) {
  return withApiHandler((api) => api.getEmbeddedWidgetSettings(id).then(migrateColorSettings));
}

export function updateEmbeddedWidgetSettings(embeddedWidgetId) {
  return withApiHandler((api) => api.updateEmbeddedWidgetSettings(embeddedWidgetId, prepareWidgetSettings()));
}

export function publishEmbeddedWidget(embeddedWidgetId) {
  return withApiHandler((api) => api.publishEmbeddedWidget(embeddedWidgetId));
}

export function getTrackingPageSettings(trackingPageId) {
  return withApiHandler((api) => api.getTrackingPageSettings(trackingPageId).then(migrateColorSettings));
}

export function updateTrackingPageSettings(trackingPageId) {
  return withApiHandler((api) => api.updateTrackingPageSettings(trackingPageId, prepareWidgetSettings()));
}

function withApiHandler(fetchApi) {
  return (dispatch) => {
    dispatch(widgetSettingsRequest());

    return fetchApi(container.api)
      .then((data) => dispatch(widgetSettingsSuccess(data)))
      .catch((error) => {
        dispatch(widgetSettingsError(error));
        throw error;
      });
  };
}

function prepareWidgetSettings() {
  const state = container.store.getState();

  const { widgets, settings } = state.widgetSettings;

  return {
    settings,
    widgets: filterWidgetsData(widgets),
  };
}

export function filterWidgetsData(widgets) {
  return widgets && widgets.map(({ id, ...widget }) => widget);
}

export function cleanWidgetId(trackingPage) {
  trackingPage.widgets.forEach((widget) => {
    delete widget.id;
  });

  return trackingPage;
}

/**
 * Applies fallback color settings to tracking page or embedded widget object
 */
function migrateColorSettings({ settings, ...data }) {
  return {
    settings: applyFallbackColorSettings(settings),
    ...data,
  };
}

export function widgetSettingsRequest() {
  return { type: 'WIDGET_SETTINGS_REQUEST' };
}

export function widgetSettingsSuccess(data) {
  return { type: 'WIDGET_SETTINGS_SUCCESS', data };
}

export function widgetSettingsError(error) {
  return { type: 'WIDGET_SETTINGS_ERROR', error };
}

export function updateWidgetSettings(id, settings) {
  return { type: 'UPDATE_WIDGET_SETTINGS', id, settings };
}

export function updateDesignSettings(settings) {
  return { type: 'UPDATE_DESIGN_SETTINGS', settings };
}

export function selectWidget(id) {
  return { type: 'SELECT_WIDGET', id };
}

export function addWidgetPreview(widgetType, widgetId, settings) {
  return { type: 'ADD_PREVIEW_WIDGET', widgetType, widgetId, settings };
}

export function removeWidgetPreview() {
  return { type: 'REMOVE_PREVIEW_WIDGET' };
}

export function addWidget() {
  return { type: 'ADD_WIDGET' };
}

/**
 * Widget with fromId will be removed from widgets array and placed before toId if fromId located after toId
 * or after otherwise.
 *
 * Examples (widgets: ['a', 'b', 'c', 'd']):
 * 1. b -> d: ['a', 'c', 'd', 'b']
 * 2. a -> c: ['b', 'c', 'a', 'd']
 * 3. d -> b: ['a', 'd', 'b', 'c']
 */
export function moveWidget(fromId, toId) {
  return { type: 'MOVE_WIDGET', fromId, toId };
}

export function removeWidget(id) {
  return { type: 'REMOVE_WIDGET', id };
}

export function resetScrollWidget() {
  return { type: 'RESET_SCROLL_TO_WIDGET' };
}
