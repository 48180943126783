import React from 'react';

import ChatbotPageContainer from './ChatbotPageContainer';
import Iframe from '../components/common/Iframe';
import { useSelectedShopId } from '../hooks/useSelectedShop';
import useApi from '../hooks/useApi';

function ChatbotPage(props) {
  const shopId = useSelectedShopId();
  const [{ token } = {}, loading] = useApi(({ touchpoints }) => touchpoints.createChatbotToken(shopId), [shopId]);

  return (
    <ChatbotPageContainer {...props}>
      <Iframe
        showIframe={!!token}
        showLoader={loading}
        pageUrl={`${process.env.CHATBOT_URL}?embed=true&token=${token}&embed_options=light_theme`}
      />
    </ChatbotPageContainer>
  );
}

export default React.memo(ChatbotPage);
