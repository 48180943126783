import { withTouchpointsApiActions } from './apiActionHelper';

export function getDashboardSSO(id, type) {
  return withTouchpointsApiActions((api) => {
    switch (type) {
      case 'explore':
        return api.getExplore(id);
      case 'look':
        return api.getLook(id);
      case 'dashboard':
      default:
        return api.getDashboard(id);
    }
  }, 'LOOKER_DASHBOARD');
}
