import Notification from 'rc-notification';
import nanoid from 'nanoid';
import logError from '../../utils/logError';

export default function notificationFactory(options = {}) {
  let notification;
  const { duration = 3, maxCount } = options;

  Notification.newInstance(
    {
      style: {
        bottom: 24,
        left: 88,
        borderRadius: 8,
        zIndex: 2000,
      },
      maxCount,
    },
    (n) => (notification = n)
  );

  return {
    notice: (content) => {
      const key = nanoid();
      notification.notice({
        key,
        content,
        duration,
        onClose: () => notification.removeNotice(key),
        style: {
          borderRadius: 0,
          background: 'none',
          boxShadow: 'none',
        },
      });
    },
    destroy: () => {
      if (notification) {
        notification.destroy();
      } else {
        logError(new Error('Empty notification instance'));
      }
    },
  };
}
