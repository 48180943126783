import React from 'react';

import PageContainer from '../components/pagecontainer/PageContainer';
import { CHATBOT_PERMISSION } from '../firewall/permissions';
import EarlyAccessBadge from '../components/common/badges/EarlyAccessBadge';

export const menuItems = [
  {
    id: '/chatbot',
    link: '/chatbot',
    permission: CHATBOT_PERMISSION,
    title: 'Chat now',
  },
  {
    id: '/chatbot/start-guide',
    link: '/chatbot/start-guide',
    title: 'Start guide',
  },
];

function ChatbotPageContainer(props) {
  return (
    <PageContainer
      {...props}
      menuTitle="ParcelAI"
      menuTitleBadge={<EarlyAccessBadge />}
      menuItems={menuItems}
      hideMobileExperienceWarning
    />
  );
}

export default React.memo(ChatbotPageContainer);
