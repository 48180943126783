import React from 'react';
import { formatPublishDate } from '../formatDateTime';

import './WidgetListItem.scss';

function PublishDate({ date }) {
  return <span styleName="date">{formatPublishDate(date)}</span>;
}

export default PublishDate;
