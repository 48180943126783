import React from 'react';
import PropTypes from 'prop-types';

import ImageSettings from './ImageSettings';
import URLInput from '../../../common/URLInput';
import { LabeledSlider } from '../../../common/Slider';
import AlignmentSettings from './AlignmentSettings';
import Locale from '../../../Translate/Locale';

class LogoSettings extends React.PureComponent {
  onImageChange = ({imageUrl, mobileImageUrl}) => {
    this.props.onChange({ imageUrl, mobileImageUrl });
  };

  onLinkChange = (e) => {
    this.props.onChange({ imageLink: e.target.value });
  };

  onScaleChange = (value) => {
    this.props.onChange({ imageScale: value });
  };

  onAlignChange = (imageAlign) => {
    this.props.onChange({ imageAlign });
  };

  render() {
    const {
      settings: {
        imageUrl,
        mobileImageUrl,
        imageLink,
        imageScale,
        imageAlign,
      },
      showAlign,
      showMobile,
      showScale,
    } = this.props;

    return (
      <React.Fragment>
        <h4>
          <Locale>Image</Locale>
        </h4>
        <ImageSettings
          imageUrl={imageUrl}
          mobileImageUrl={mobileImageUrl}
          label="Logo image url"
          mobileLabel="Mobile logo image url"
          onChange={this.onImageChange}
          showMobile={showMobile}
        />

        <URLInput
          label="Logo links to"
          value={imageLink}
          placeholder="Type a link/URL"
          onChange={this.onLinkChange}
        />
        { showScale &&
          <LabeledSlider
            min={0}
            max={100}
            value={imageScale}
            onChange={this.onScaleChange}
          >
            Logo size
          </LabeledSlider>
        }
        { showAlign &&
          <React.Fragment>
            <h4>
              <Locale>Alignment in desktop view</Locale>
            </h4>
            <AlignmentSettings value={imageAlign} onChange={this.onAlignChange} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

LogoSettings.defaultProps = {
  showAlign: true,
  showMobile: true,
  showScale: true,
};

LogoSettings.propTypes = {
  settings: PropTypes.object,
  onChange: PropTypes.func,
  showAlign: PropTypes.bool,
  showMobile: PropTypes.bool,
  showScale: PropTypes.bool,
};

export default LogoSettings;