import React from 'react';
import PropTypes from 'prop-types';

import Input from '../common/Input';
import NameIcon from '../../icons/artwork/name.svg';

import WizardStep from './WizardStep';

class ChooseNameStep extends React.PureComponent {
  state = {
    nameValue: this.props.nameValue || `Shop ${this.props.entityName}`,
  };

  onChange = (e) => {
    this.setState({ nameValue: e.target.value });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.nameValue);
  };

  render() {
    const { nameValue } = this.state;
    const { entityName } = this.props;
    const {
      header = `Name ${entityName}`,
      subheader = `Create a new ${entityName}`,
      buttonText = `Create my ${entityName}`,
      ...props
    } = this.props;

    return (
      <WizardStep
        {...props}
        header={header}
        subheader={subheader}
        icon={NameIcon}
        buttonText={buttonText}
        onSubmit={this.onSubmit}
        disabled={!nameValue}
        paginationTitle="Choose a name"
      >
        <Input
          value={nameValue}
          onChange={this.onChange}
        />
      </WizardStep>
    );
  }
}

ChooseNameStep.defaultProps = {
  onSubmit: () => {}
};

ChooseNameStep.propTypes = {
  onSubmit: PropTypes.func,
  entityName: PropTypes.string.isRequired,
};

export default ChooseNameStep;