import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Locale from '../../Translate/Locale';

import './Table.scss';

function Table(props) {
  const { columns = [], noHeaderTopBorder, children } = props;

  return (
    <table styleName={classNames('table', { 'no-header-top-border': noHeaderTopBorder })} >
      <colgroup>
        {columns.map(({ style }, i) => (
          <col style={style} key={i} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map(({ title, element }, i) => (
            <th key={i}>{element ? element : <Locale>{title}</Locale>}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      element: PropTypes.node,
      style: PropTypes.object,
    })
  ),
  noHeaderTopBorder: PropTypes.bool,
};

export default Table;
