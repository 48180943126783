import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../common/Text';
import Row from '../../common/Row';
import Locale from '../../Translate/Locale';
import ArrowDownLightIcon from '../../../icons/arrow-down-light.svg';
import ArrowUpLightIcon from '../../../icons/arrow-up-light.svg';

import './FilterSelectButton.scss';

function FilterSelectButton(props) {
  const { children, label, ariaLabel, isActive, onClick } = props;

  return (
    <button styleName={classNames('header', { active: isActive })} aria-label={ariaLabel} onClick={onClick}>
      <Row spacing={8}>
        <Text>
          <Locale>{children}</Locale>
        </Text>
        {label && (
          <div styleName={classNames('label', { active: isActive })}>
            <Locale>{label}</Locale>
          </div>
        )}
        {isActive ? <ArrowUpLightIcon styleName="icon-up" /> : <ArrowDownLightIcon styleName="icon-down" />}
      </Row>
    </button>
  );
}

FilterSelectButton.propTypes = {
  children: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(FilterSelectButton);
