import React from 'react';
import MenuItemBasic from './MenuItemBasic';
import { Locale } from '../../Translate';

function MenuItem(props) {
  const {
    children,
    onClick,
  } = props;

  return (
    <MenuItemBasic onClick={onClick}>
      <Locale>{children}</Locale>
    </MenuItemBasic>
  );
}

export default MenuItem;