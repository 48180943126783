import React from 'react';
import PropTypes from 'prop-types';
import LabeledInput from '../../../common/LabeledInput';
import URLInput from '../../../common/URLInput';
import Locale from '../../../Translate/Locale';

class LinkSettings extends React.PureComponent {
  onTextChange = (e) => {
    this.props.onTextChange(e.target.value);
  };

  onUrlChange = (e) => {
    this.props.onUrlChange(e.target.value);
  };

  render() {
    const {
      title,
      text,
      url,
    } = this.props;

    return (
      <React.Fragment>
        <h4><Locale>{title}</Locale></h4>
        <LabeledInput label="Text" value={text} onChange={this.onTextChange} />
        <URLInput label="Link/URL" value={url} onChange={this.onUrlChange} />
      </React.Fragment>
    );
  }
}

LinkSettings.defaultProps = {
  onTextChange: () => {},
  onUrlChange: () => {},
};

LinkSettings.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  onTextChange: PropTypes.func,
  onUrlChange: PropTypes.func,
};

export default LinkSettings;