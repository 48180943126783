import { useRef, useEffect, useCallback } from 'react';

function useOutsideClick(onClick) {
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClick();
      }
    },
    [onClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return ref;
}

export default useOutsideClick;
