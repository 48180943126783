import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../../common/Tooltip';
import Locale from '../../Translate/Locale';
import useInputId from '../../common2/hooks/useInputId';

import './StatusIndicator.scss';

function StatusIndicator(props) {
  const { status, intent = 'inactive' } = props;
  const tooltipId = useInputId();

  return (
    <React.Fragment>
      <div data-tip data-for={tooltipId} styleName={classNames('status-indicator', intent)} />
      <Tooltip id={tooltipId} position="bottom" type="dark">
        <span>
          <Locale>{status}</Locale>
        </span>
      </Tooltip>
    </React.Fragment>
  );
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  intent: PropTypes.oneOf(['active', 'base', 'warning', 'inactive']),
};

export default React.memo(StatusIndicator);
