import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from '../../common2/DropdownButton/DropdownButton';
import DropdownMenuItem from '../../common/DropdownMenuItem/DropdownMenuItem';
import EditIcon from '../../../icons/more-vertical.svg';
import ViewIcon from '../../../icons/view.svg';
import WarningIcon from '../../../icons/warning.svg';
import RemoveIcon from '../../../icons/remove.svg';
import { getActionTypeForClaim, customerActionTypeEnum } from '../take-action/actionHelper';

import './ClaimsTableActionDropdownButton.scss';

const actionButtonPropsByType = {
  [customerActionTypeEnum.TAKE_CUSTOMER_ACTION]: {
    icon: WarningIcon,
    intent: 'action',
    name: 'takeActionButton',
    children: 'Take action',
  },
  [customerActionTypeEnum.VIEW_DECLINED_REASON]: {
    icon: ViewIcon,
    name: 'viewDeclinedReasonButton',
    intent: 'primary',
    children: 'View reason',
  },
  [customerActionTypeEnum.DISPUTE_DECLINED_REASON]: {
    icon: ViewIcon,
    name: 'disputeDeclinedReasonButton',
    intent: 'primary',
    children: 'View reason',
  },
};

function getActionsFromClaim(claim, onTakeActionButtonClick, onCancelClaimButtonClick) {
  const { shipmentId } = claim || {};

  const actions = [];

  if (claim.shipmentId) {
    actions.push({
      Wrapper: ({ children }) => (
        <a styleName="link" href={`/parcel-finder/shipments/${shipmentId}`} target="_blank">
          {children}
        </a>
      ),
      icon: ViewIcon,
      intent: 'primary',
      name: 'viewShipmentDetailButton',
      children: 'View details',
    });
  }

  if (claim.isCancellableByCustomer) {
    actions.push({
      icon: RemoveIcon,
      intent: 'cancel',
      name: 'cancelClaimButton',
      children: 'Cancel claim',
      onClick: onCancelClaimButtonClick,
    });
  }

  const actionType = getActionTypeForClaim(claim);
  const actionByType = actionButtonPropsByType[actionType];

  if (actionByType) {
    actions.push({ ...actionByType, onClick: onTakeActionButtonClick });
  }

  return actions;
}

function ClaimsTableActionDropdownButton(props) {
  const { claim, onTakeActionButtonClick, onCancelClaimButtonClick } = props;

  const actions = getActionsFromClaim(claim, onTakeActionButtonClick, onCancelClaimButtonClick);

  if (actions.length === 0) {
    return null;
  }

  return (
    <DropdownButton ariaLabel="actions-dropdown-button" icon={EditIcon} tooltipText="Actions">
      {actions.map((action) => {
        const { Wrapper, name } = action;

        const MenuItem = ({ icon, intent, name, children, onClick }) => (
          <DropdownMenuItem intent={intent} name={name} icon={icon} onClick={onClick ? () => onClick(claim) : null}>
            {children}
          </DropdownMenuItem>
        );

        if (Wrapper) {
          return (
            <Wrapper key={name}>
              <MenuItem {...action} />
            </Wrapper>
          );
        }

        return <MenuItem key={name} {...action} />;
      })}
    </DropdownButton>
  );
}

ClaimsTableActionDropdownButton.propTypes = {
  claim: PropTypes.object.isRequired,
  onTakeActionButtonClick: PropTypes.func.isRequired,
  onCancelClaimButtonClick: PropTypes.func.isRequired,
};

export default React.memo(ClaimsTableActionDropdownButton);
