import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';

import './ReturnLabelsList.scss';

import DownloadIcon from '../../../icons/download.svg';
import Locale from '../../Translate/Locale';
import PlainButton from '../../common/PlainButton';

function ReturnLabelsList(props) {
  const { shipments } = props;

  return (
    <div styleName="container">
      <Text title>
        <Locale>Return labels</Locale>
      </Text>
      <Text>
        <Locale>RETURN_LABELS_LIST_DESC</Locale>
      </Text>
      <div styleName="list" role="list">
        {shipments?.map((shipment) => (
          <div key={shipment.id} styleName="item" role="listitem">
            <Text ink>{shipment.trackingCode || shipment.preliminaryCode}</Text>
            <a styleName="link" href={shipment.labelUrl} target="_blank">
              <PlainButton icon={DownloadIcon}>Download</PlainButton>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

ReturnLabelsList.propTypes = {
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      trackingCode: PropTypes.string.isRequired,
      preliminaryCode: PropTypes.string,
      labelId: PropTypes.number.isRequired,
      labelUrl: PropTypes.string.isRequired,
    })
  ),
};

export default React.memo(ReturnLabelsList);
