import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReturnsStartGuide from '../start-guides/ReturnsStartGuide';
import ReturnsTranslationsPage from '../returns/ReturnsTranslationsPage';
import PageLoader from '../PageLoader';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import PageNavigate from '../PageNavigate';

const ReturnPortalRoutes = React.lazy(() => import('./ReturnPortalRoutes' /* webpackChunkName: "returns" */));
const withReturnsAccess = withPermissionRedirect(permissions.RETURNS, '/returns/start-guide');
const ReturnsTranslationsPageWithAccess = withReturnsAccess(ReturnsTranslationsPage);

function ReturnsRoutes() {
  return (
    <Routes>
      <Route
        path="return-portals/*"
        element={
          <Suspense fallback={<PageLoader />}>
            <ReturnPortalRoutes />
          </Suspense>
        }
      />
      <Route path="translations" element={<ReturnsTranslationsPageWithAccess />} />
      <Route path="start-guide" element={<ReturnsStartGuide />} />
      <Route index element={<PageNavigate to="return-portals" />} />
    </Routes>
  );
}

export default ReturnsRoutes;
