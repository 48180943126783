import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExitClaimsModal from '../../parcel-finder/claims/ExitClaimsModal';

import './SidePanelContainer.scss';

function SidePanelContainer(props) {
  const { children } = props;

  return <div styleName="container">{children}</div>;
}

SidePanelContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default React.memo(SidePanelContainer);
