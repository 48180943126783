import React from 'react';
import PropTypes from 'prop-types';

import Column from '../../common/layout/Column';
import StartGuideCard from './StartGuideCard';
import StartGuideTabs from './StartGuideTabs';
import StartGuideTabContent from './StartGuideTabContent';
import StartGuideContextWithImage from './StartGuideContextWithImage';
import useIsMobile from '../../../hooks/useIsMobile';

function StartGuideContentSections(props) {
  const { heading, sections } = props;

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Column>
        {sections.map((section) => (
          <StartGuideCard key={section.id} heading={section.title}>
            <StartGuideContextWithImage text={section.content} imageUrl={section.imageUrl} imageAlt={props.title} />
          </StartGuideCard>
        ))}
      </Column>
    );
  }

  return (
    <StartGuideCard heading={heading}>
      <StartGuideTabs tabs={sections}>
        {sections.map((section) => (
          <StartGuideTabContent key={section.id}>
            <StartGuideContextWithImage text={section.content} imageUrl={section.imageUrl} imageAlt={props.title} />
          </StartGuideTabContent>
        ))}
      </StartGuideTabs>
    </StartGuideCard>
  );
}

StartGuideContentSections.propTypes = {
  heading: PropTypes.string, // desktop heading
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default React.memo(StartGuideContentSections);
