import React from 'react';
import PropTypes from 'prop-types';

import container from '../../container';
import EmbeddedWidgetsList from './EmbeddedWidgetsList';
import EmbeddedWidgetCodeModal from './EmbeddedWidgetCodeModal';
import TouchpointsOverview from '../touchpoints/TouchpointsOverview/TouchpointsOverview';
import EmbeddedWidgetDeleteModal from './EmbeddedWidgetDeleteModal';
import DuplicateEmbeddedWidgetModal from './DuplicateEmbeddedWidgetModal/';
import { withNotifications } from '../Notification';
import { duplicateEmbeddedWidget } from '../../actions/embeddedWidgets';
import RenameModal from '../touchpoints/RenameModal';
import urls from './urls';

export class EmbeddedWidgetsOverview extends React.PureComponent {
  state = {
    showingCodeEmbeddedWidget: null,
    duplicatingEmbeddedWidget: null,
  };

  onShowViewCodeModal = (showingCodeEmbeddedWidget) => {
    this.setState({ showingCodeEmbeddedWidget });
  };

  onCloseViewCodeModal = () => {
    this.setState({ showingCodeEmbeddedWidget: null });
  };

  removeEmbeddedWidget = () => {
    const { removeEmbeddedWidget } = this.state;

    if (removeEmbeddedWidget) {
      const apiResult = container.api.removeEmbeddedWidget(removeEmbeddedWidget.id);

      this.props
        .resolveWithNotice(apiResult, '{{name}} has been deleted', { name: removeEmbeddedWidget.name })
        .then(({ success }) => success && this.props.onReload(this.props.shopId));

      this.setState({ removeEmbeddedWidget: null });
    }
  };

  cancelRemoving = () => this.setState({ removeEmbeddedWidget: null });

  onCloseRenameModal = () => {
    this.setState({ renamingEmbeddedWidget: null });
  };

  renameEmbeddedWidget = (name) => {
    const { renamingEmbeddedWidget } = this.state;

    if (renamingEmbeddedWidget && renamingEmbeddedWidget.name !== name) {
      const apiResult = container.api.updateEmbeddedWidgetSettings(renamingEmbeddedWidget.id, { name });

      this.props
        .resolveWithNotice(apiResult, 'Embedded widget was successfully renamed')
        .then((res) => res && res.success && this.props.onReload(this.props.shopId));
    }

    this.onCloseRenameModal();
  };

  onDelete = (removeEmbeddedWidget) => {
    this.setState({ removeEmbeddedWidget });
  };

  onRename = (renamingEmbeddedWidget) => {
    this.setState({ renamingEmbeddedWidget });
  };

  onDuplicate = (duplicatingEmbeddedWidget) => this.setState({ duplicatingEmbeddedWidget });
  resetDuplicating = () => this.setState({ duplicatingEmbeddedWidget: null });

  duplicateEmbeddedWidget = (embeddedWidgetId, newData) => {
    this.setState({ duplicatingEmbeddedWidget: null });

    const { shopId, resolveWithNotice, onReload } = this.props;

    return resolveWithNotice(
      duplicateEmbeddedWidget(embeddedWidgetId, newData, shopId),
      'The embedded widget has been successfully duplicated'
    ).then(({ success }) => success && onReload(shopId));
  };

  render() {
    const { showingCodeEmbeddedWidget, removeEmbeddedWidget } = this.state;

    const { widgets, shopId } = this.props;

    return (
      <TouchpointsOverview
        title="Your embedded widgets"
        buttonText="Create a new embedded widget"
        buttonLink={urls.create}
      >
        <EmbeddedWidgetsList
          key={shopId}
          widgets={widgets}
          onViewCode={this.onShowViewCodeModal}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
          onRename={this.onRename}
        />
        <EmbeddedWidgetCodeModal embeddedWidget={showingCodeEmbeddedWidget} onClose={this.onCloseViewCodeModal} />
        <EmbeddedWidgetDeleteModal
          embeddedWidget={removeEmbeddedWidget}
          onDelete={this.removeEmbeddedWidget}
          onCancel={this.cancelRemoving}
        />
        <RenameModal
          title="Rename embedded widget"
          objectWithName={this.state.renamingEmbeddedWidget}
          onRename={this.renameEmbeddedWidget}
          onCancel={this.onCloseRenameModal}
        />
        <DuplicateEmbeddedWidgetModal
          embeddedWidget={this.state.duplicatingEmbeddedWidget}
          onCancel={this.resetDuplicating}
          onDuplicate={this.duplicateEmbeddedWidget}
        />
      </TouchpointsOverview>
    );
  }
}

EmbeddedWidgetsOverview.defaultProps = {
  resolveWithNotice: (p) => p,
};

EmbeddedWidgetsOverview.propTypes = {
  shopId: PropTypes.number,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  resolveWithNotice: PropTypes.func,
  onReload: PropTypes.func,
};

export default withNotifications()(EmbeddedWidgetsOverview);
