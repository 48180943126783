import React from 'react';
import { assemble } from 'simple-object-validation';

import container from '../../container';
import InternalLink from '../common/ExternalLink/InternalLink';
import Input from '../common/Input';
import Button from '../common/Button';
import LoginContainer from '../login/LoginContainer';
import emailValidators from '../login/emailValidators';
import isEmptyObject from '../../utils/isEmptyObject';
import Locale from '../Translate/Locale';
import withLegacyRouter from '../../wrappers/withLegacyRouter';

import styles from './ForgotPassword.scss';

const formValidator = assemble(emailValidators);

class ForgotPasswordPage extends React.PureComponent {
  state = {
    email: '',
    formErrors: {},
  };

  handleUserInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = () => {
    const { email } = this.state;

    const formErrors = formValidator({ email });
    this.setState({ formErrors });

    if (isEmptyObject(formErrors)) {
      this.setState({ errorCode: null, isLoading: true });
      return container.api
        .forgotPassword(this.state.email)
        .then(() => this.props.history.push('/reset-password'))
        .catch(({ errorCode }) => this.setState({ errorCode, isLoading: false }));
    }
  };

  render() {
    const {
      email,
      formErrors: { email: validationError },
      errorCode,
      isLoading,
    } = this.state;

    return (
      <LoginContainer>
        <h1 styleName="title">
          <Locale>Forgot your password?</Locale>
        </h1>
        <h2 styleName="subtitle">
          <Locale>Enter your email address below and we’ll cover you</Locale>
        </h2>
        {!!errorCode && (
          <div className={styles['error-container']}>
            <Locale>{getErrorMessageByCode(errorCode)}</Locale>
          </div>
        )}
        <div styleName="formSection">
          <div styleName="form-element-wrapper">
            <Input
              label="Email address"
              placeholder="name@domain.com"
              value={email}
              onChange={this.handleUserInput}
              error={validationError}
              name="email"
              type="email"
            />
          </div>
          <Button primary type="submit" onClick={this.submitForm} isLoading={isLoading}>
            Reset password
          </Button>
          <div styleName="back-link">
            <InternalLink to="/login">Back to sign in</InternalLink>
          </div>
        </div>
      </LoginContainer>
    );
  }
}

function getErrorMessageByCode(errorCode) {
  if (errorCode === 400) {
    return 'Sorry, we can’t find an account with this email address. Please try again or create a new account';
  }

  if (errorCode === 429) {
    // eslint-disable-next-line max-len
    return 'We already processed your request, if you did not received an email from us, check your spam folder or try again in 5 minutes.';
  }

  if (errorCode >= 500) {
    return 'Server Error';
  }
}

export default withLegacyRouter(ForgotPasswordPage);
