import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../../SettingsGroupCard';
import Checkbox from '../../../common/Checkbox';
import TextArea from '../../../common/TextArea';

class CopyrightSettings extends React.PureComponent {
  onShowCopyright = (e) => {
    this.props.onChange({
      showCopyright: e.target.checked,
    });
  };

  onCopyrightChange = (e) => {
    this.props.onChange({
      copyright: e.target.value,
    });
  };

  render() {
    const {
      settings: {
        showCopyright = false,
        copyright = '',
      },
    } = this.props;

    return (
      <SettingsGroupCard
        title="Copyright"
        subtitle="Add a copyright of your company here"
      >
        <Checkbox checked={showCopyright} onChange={this.onShowCopyright}>
          Show copyright
        </Checkbox>
        <TextArea value={copyright} onChange={this.onCopyrightChange} />
      </SettingsGroupCard>
    );
  }
}

CopyrightSettings.defaultProps = {
  onSettingsChange: () => {},
};

CopyrightSettings.propTypes = {
  settings: PropTypes.shape({
    showCopyright: PropTypes.bool,
    copyright: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default CopyrightSettings;