import React from 'react';
import PropTypes from 'prop-types';
import Link from './common/Link';

function createOnClick(onClick, selected, disabled) {
  if (onClick && !disabled && !selected) {
    return onClick;
  }

  return null;
}

const ListItem = (props) => {
  const { className, selected, disabled, link, title, children } = props;
  const onClick = createOnClick(props.onClick, selected, disabled);

  const content = (
    <div
      role="listitem"
      aria-disabled={disabled}
      aria-label={title}
      data-selected={selected}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  );

  return link && !disabled ? <Link to={link}>{content}</Link> : content;
};

ListItem.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
};

export default ListItem;
