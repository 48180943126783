import container from '../container';

export function fetchUserInfo() {
  return (dispatch, getState) => {
    dispatch(fetchUserInfoRequest());
    return container.api
      .me()
      .then((data) => {
        dispatch(fetchUserInfoSuccess(data));
      })
      .catch((error) => dispatch(fetchUserInfoError(error)));
  };
}

export function fetchUserInfoRequest() {
  return { type: 'FETCH_ME_REQUEST' };
}

export function fetchUserInfoSuccess(data) {
  return { type: 'FETCH_ME_SUCCESS', data };
}

export function fetchUserInfoError(error) {
  return { type: 'FETCH_ME_ERROR', error };
}

export function updateUserInfo(id, info) {
  return (dispatch) => {
    dispatch(fetchUserInfoRequest());

    container.api
      .updateUserInfo(id, info)
      .then((data) => dispatch(fetchUserInfoSuccess(data)))
      .catch((error) => dispatch(fetchUserInfoError(error)));
  };
}
