import React from 'react';
import PropTypes from 'prop-types';

import ContentLibraryMessageForm from './ContentLibraryMessageForm';
import WidgetPartPreview from './WidgetPartPreview';

import './ContentLibraryMessageWithPreview.scss';

class ContentLibraryMessageWithPreview extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      message,
      icon,
      description,
    } = props.message;

    this.state = {
      message,
      icon,
      description,
    };
  }

  onChange = (data) => {
    this.setState(data);
  };

  onSubmit = () => {
    const {
      message,
      icon,
      description,
    } = this.state;

    this.props.onSave({ message, icon, description });
  };

  render() {
    const {
      message,
    } = this.props;

    const { state } = this;

    const isChanged = (
      state.message !== message.message ||
      state.icon !== message.icon ||
      state.description !== message.description
    );

    return (
      <div styleName="container">
        <div styleName="edit-form">
          <ContentLibraryMessageForm
            message={state.message}
            description={state.description}
            icon={state.icon}
            type={message.type}
            isDefault={message.isDefault}
            isChanged={isChanged}
            onChange={this.onChange}
            onReset={this.props.onReset}
            onSubmit={this.onSubmit}
          />
        </div>
        <div styleName="preview">
          <WidgetPartPreview
            type={message.type}
            icon={state.icon}
            message={state.message}
            description={state.description}
          />
        </div>
      </div>
    );
  }
}

ContentLibraryMessageWithPreview.propTypes = {
  message: PropTypes.object,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
};

export default ContentLibraryMessageWithPreview;