import React from 'react';

import Locale from '../Translate/Locale';

import './ModalTitle.scss';

function ModalTitle(props) {
  return (
    <h1 styleName="title">
      <Locale>{props.children}</Locale>
    </h1>
  );
}

export default ModalTitle;