import React from 'react';
import PropTypes from 'prop-types';

import StatsCard from './StatsCard';
import Row from '../common/Row/Row';

import './ClaimsStatsOverview.scss';

function ClaimsStatsOverview(props) {
  const { claimsStats = {} } = props;
  const { openCount, actionRequiredCount, acceptedAndAwaitingPaymentCount } = claimsStats;

  return (
    <div styleName="container">
      <Row spacing={20}>
        <StatsCard value={openCount}>Total open</StatsCard>
        <StatsCard value={actionRequiredCount} intent="warning">
          Action required
        </StatsCard>
        <StatsCard value={acceptedAndAwaitingPaymentCount} intent="positive" isLast>
          Accepted & awaiting payment
        </StatsCard>
      </Row>
    </div>
  );
}

ClaimsStatsOverview.propTypes = {
  claimsStats: PropTypes.object,
};

export default ClaimsStatsOverview;
