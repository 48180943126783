import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Table from '../common/Table';

import '../Widget/WidgetsList.scss';

const columns = [
  '',
  'Basic info',
  'Status',
  'Last publish',
  'Last saved',
  '',
];

class WidgetsList extends React.PureComponent {
  render() {
    const {
      children,
      legacy,
      firstColumnName,
    } = this.props;

    return (
      <div styleName={classNames('list', {legacy})}>
        <Table columns={getColumnNames(columns, firstColumnName)}>
          {children}
        </Table>
      </div>
    );
  }
}

function getColumnNames(columns, firstColumnName) {
  return [columns[0], firstColumnName || columns[1], ...columns.slice(2)];
}

WidgetsList.propTypes = {
  legacy: PropTypes.bool,
  firstColumnName: PropTypes.string, // overrides column name for the first column
};

export default WidgetsList;