import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Text.scss';

function Text(props) {
  const { as: Element = 'span', children, small, title, ink, className } = props;

  const styleName = classNames('text', { small, ink, title });

  return (
    <Element className={className} styleName={styleName}>
      {children}
    </Element>
  );
}

Text.propTypes = {
  as: PropTypes.string,
  title: PropTypes.bool,
  small: PropTypes.bool,
  ink: PropTypes.bool,
};

export default React.memo(Text);
