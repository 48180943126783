import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common2/Modal';
import EmbeddedWidgetCode from '../InstallCodePage/EmbeddedWidgetCode';
import ExternalLink from '../common2/links/ExternalLink';
import ModalContent from '../common2/Modal/ModalContent';
import ModalHeader from '../common2/Modal/ModalHeader';
import Locale from '../Translate';
import Message from '../common2/Message';
import Column from '../common/layout/Column';
import ViewCodeIcon from '../../icons/view-code.svg';

export const helpUrl =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010427-installing-your-embedded-widget';

function EmbeddedWidgetCodeModal({ embeddedWidget, onClose = () => {} }) {
  if (!embeddedWidget) {
    return null;
  }

  const { hash, hasZipCodeProtection } = embeddedWidget;

  return (
    <Modal show onClose={onClose}>
      <ModalHeader icon={ViewCodeIcon}>View code</ModalHeader>
      <ModalContent>
        <Column spacing="medium">
          <Message>
            <Locale>Read </Locale>
            <ExternalLink intent="underline" to={helpUrl}>
              <Locale>our implementation guide</Locale>
            </ExternalLink>
          </Message>
          <EmbeddedWidgetCode hash={hash} hasZipCodeProtection={hasZipCodeProtection} />
        </Column>
      </ModalContent>
    </Modal>
  );
}

EmbeddedWidgetCodeModal.propTypes = {
  embeddedWidget: PropTypes.shape({
    hash: PropTypes.string,
    hasZipCodeProtection: PropTypes.bool,
  }),
  onClose: PropTypes.func,
};

export default React.memo(EmbeddedWidgetCodeModal);
