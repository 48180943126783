import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import Column from '../../../common/layout/Column';
import Text from '../../../common/Text';
import RichLocale from '../../../common2/RichLocale/RichLocale';
import Uploader from '../../../common/Uploader/Uploader';
import { formatDate } from '../claimsHelpers';
import TextArea from '../../../common2/TextArea/TextArea';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import useStepValidation from './useStepValidation';
import { deadlineValidator } from '../claimValidators';

import './SearchClaimInfoStep.scss';

/**
 * @typedef {Object} Deadline
 * @property {('within'|'early'|'past')} outcome
 * @property {string} firstSubmissionDate
 * @property {string} lastSubmissionDate
 * @property {number} minimumDaysAfterEvent
 * @property {number} maximumDaysAfterEvent
 * @property {string} eventDate
 * @property {number} daysLeftAfterEvent
 * @property {boolean} useCarrierDeadlines
 */

function OutsideOfDeadlineStep(props) {
  const { claim, carriers, manualDeadline, as: Container, onNext, onBack, onChange, ...restProps } = props;
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  const { t } = useTranslation();
  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const [errors, onClickNext] = useStepValidation(claim, onNext, deadlineValidator);

  /** @type {Deadline  | null | undefined } */
  const deadline = manualDeadline || claim.deadlines[claim.type];

  if (!deadline || deadline.outcome === 'within') {
    console.warn('OutsideOfDeadlineStep should not be rendered when deadline is within or missing');
    return null;
  }

  const { useCarrierDeadlines } = deadline;

  const { message, intent, submissionBoundaryDateHeading, submissionBoundaryDateField } =
    textsByOutcome[deadline.outcome] || {};

  const isUndeliverableShipment =
    deadline.eventKind === 'postal_return' || deadline.eventKind === 'postal_return_delivery';

  const eventKind = claim.warehouseDeliveryDate ? 'warehouse_delivery_date' : deadline.eventKind;

  const eventName = t(textsByEventKind[eventKind]);
  const claimType = t(textsByClaimType[claim.type]);
  const direction = t(textsByDirection[claim.isReturnShipment]);
  const isUndeliverable = t(textsByDeliverable[isUndeliverableShipment]);
  const undeliverableSuffix = t(textsForDeliverableSuffix[deadline.eventKind]);
  const daysType = t(daysByCountType[deadline.useBusinessDays]);

  const carrierName = carriers.find(
    ({ code, countryCode }) => code === claim.carrierCode && countryCode === claim.carrierCountryCode
  )?.name;

  const carrier = `${carrierName} ${claim.carrierCountryCode.toUpperCase()}`;

  const getExplanationKey = () => {
    if (useCarrierDeadlines) {
      return 'CLAIM_CARRIER_DEADLINE_EXPLANATION';
    }

    if (claim.type === 'investigation') {
      return 'CLAIM_DEADLINE_EXPLANATION';
    }

    return 'CLAIM_DEADLINE_EXPLANATION_DAMAGE_AND_MISSING_ITEM';
  };

  const explanationKey = getExplanationKey();

  const isDhl = claim.carrierCode === 'dhl';
  const isDhlParcelUk = claim.carrierCode === 'dhlparceluk';
  const isInvestigationClaim = claim.type === 'investigation';
  const isUk = claim.carrierCountryCode === 'gb';
  const isSpecialDhlUkDeadline = isInvestigationClaim && isUk && (isDhl || isDhlParcelUk);

  return (
    <Container {...restProps} isDisabled={isNextBtnDisabled} onNext={onClickNext} onBack={onBack}>
      <ClaimStepClosableMessage intent={intent}>{t(message)}</ClaimStepClosableMessage>
      <Column spacing="small">
        <SidePanelContentSection heading={submissionBoundaryDateHeading}>
          <Text ink>{formatDate(deadline[submissionBoundaryDateField])}</Text>
        </SidePanelContentSection>
        <SidePanelContentSection heading="CLAIM_DEADLINE_EXPLANATION_HEADING">
          <Column>
            <Text ink>
              <Trans
                i18nKey={explanationKey}
                values={{
                  minimumDaysAfterEvent: deadline.minimumDaysAfterEvent,
                  maximumDaysAfterEvent: deadline.maximumDaysAfterEvent,
                  eventDate: formatDate(deadline.eventDate),
                  daysLeftAfterEvent: deadline.daysLeftAfterEvent,
                  eventName,
                  daysType,
                  claimType,
                  direction,
                  isUndeliverable,
                  undeliverableSuffix,
                  carrier,
                }}
              />
            </Text>
            {isSpecialDhlUkDeadline && (
              <Text ink>
                <Trans i18nKey="CLAIM_DEADLINE_EXPLANATION_DHL_UK" />
              </Text>
            )}
          </Column>
        </SidePanelContentSection>
        <SidePanelContentSection heading="Submit claim anyway">
          <Column spacing="small">
            <RichLocale ink>CLAIM_DEADLINE_SUBMIT_ANYWAY_WARNING</RichLocale>
            <TextArea
              name="outsideOfDeadlineReason"
              label="Reason why"
              value={claim.outsideOfDeadlineReason}
              error={errors.outsideOfDeadlineReason}
              onChange={onInputChange}
              isRequired
            />
            <Uploader
              name="deadlineSupportingDocuments"
              files={claim.deadlineSupportingDocuments}
              label="Supporting documentation (if any)"
              allowedFileTypes={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']}
              helpText="CLAIMS_ALL_ALLOWED_FILE_TYPES_UPLOAD_HELP_TEXT"
              onChange={onInputChange}
              onBusy={setIsNextBtnDisabled}
              maxNumberOfFiles={10}
            />
          </Column>
        </SidePanelContentSection>
      </Column>
    </Container>
  );
}

const textsByEventKind = {
  first_hub_scan: 'CLAIM_DEADLINE_EXPLANATION_FIRST_HUB_SCAN',
  delivered: 'CLAIM_DEADLINE_EXPLANATION_DELIVERY_DATE',
  postal_return_delivery: 'CLAIM_DEADLINE_EXPLANATION_POSTAL_RETURN_DELIVERY_DATE',
  postal_return: 'CLAIM_DEADLINE_EXPLANATION_POSTAL_RETURN_DATE', // TODO
  warehouse_delivery_date: 'CLAIM_DEADLINE_EXPLANATION_WAREHOUSE_DELIVERY_DATE',
};

const textsByClaimType = {
  investigation: 'CLAIM_DEADLINE_EXPLANATION_INVESTIGATION',
  whole_damage: 'CLAIM_DEADLINE_EXPLANATION_WHOLE_DAMAGE',
  partial_damage: 'CLAIM_DEADLINE_EXPLANATION_PARTIAL_DAMAGE',
  missing_item: 'CLAIM_DEADLINE_EXPLANATION_MISSING_ITEM',
};

const textsForDeliverableSuffix = {
  postal_return: 'CLAIM_DEADLINE_EXPLANATION_UNDELIVERABLE_SUFFIX_POSTAL_RETURN',
  postal_return_delivery: 'CLAIM_DEADLINE_EXPLANATION_UNDELIVERABLE_SUFFIX_POSTAL_RETURN_DELIVERY',
};

const daysByCountType = {
  true: 'CLAIM_DEADLINE_EXPLANATION_BUSINESS_DAYS',
  false: 'CLAIM_DEADLINE_EXPLANATION_DAYS',
};

const textsByDeliverable = {
  true: 'CLAIM_DEADLINE_EXPLANATION_UNDELIVERABLE',
};

const textsByDirection = {
  true: 'CLAIM_DEADLINE_EXPLANATION_INBOUND',
  false: 'CLAIM_DEADLINE_EXPLANATION_OUTBOUND',
};

const textsByOutcome = {
  early: {
    message: 'CLAIM_DEADLINE_EARLY_MESSAGE',
    intent: 'warning',
    submissionBoundaryDateHeading: 'First submission date',
    submissionBoundaryDateField: 'firstSubmissionDate',
  },
  past: {
    message: 'CLAIM_DEADLINE_PAST_MESSAGE',
    intent: 'danger',
    submissionBoundaryDateHeading: 'Last submission date',
    submissionBoundaryDateField: 'lastSubmissionDate',
  },
};

OutsideOfDeadlineStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  manualDeadline: PropTypes.object,
  carrierName: PropTypes.string,
  carrierCountryCode: PropTypes.string,
  claim: PropTypes.shape({
    type: PropTypes.string.isRequired,
    isReturnShipment: PropTypes.bool.isRequired,
    deadlines: PropTypes.object,
    carrierCode: PropTypes.string,
    carrierCountryCode: PropTypes.string,
    warehouseDeliveryDate: PropTypes.string,
  }).isRequired,
  carriers: PropTypes.array,
};

export default React.memo(OutsideOfDeadlineStep);
