import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../common/Table';
import ShopLocalizationsListItem from './ShopLocalizationsListItem';

import './ShopLocalizationsList.scss';

const columns = [
  '',
  'Site URL',
  'Shop language',
  '', // edit button column
];

export function ShopLocalizationsList(props) {
  const { onDelete, onArchive, localizations = [] } = props;

  return (
    <div styleName="localizationList">
      <Table columns={columns}>
        {localizations.map((localization) => (
          <ShopLocalizationsListItem
            key={localization.id}
            localization={localization}
            onDelete={() => onDelete(localization)}
            onArchive={() => onArchive(localization)}
          />
        ))}
      </Table>
    </div>
  );
}

ShopLocalizationsList.propTypes = {
  localizations: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
  onArchive: PropTypes.func,
};

export default React.memo(ShopLocalizationsList);
