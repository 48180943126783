import React from 'react';

import { ANALYTICS_PERMISSION } from '../../firewall/permissions';

import AnalyticsPage from '../analytics/AnalyticsPage';
import Column from '../common/layout/Column';
import StartGuideCard from './StartGuidePage/StartGuideCard';
import StartGuideTabs from './StartGuidePage/StartGuideTabs';
import StartGuideTabContent from './StartGuidePage/StartGuideTabContent';
import StartGuideBulletedList from './StartGuidePage/StartGuideBulletedList';
import BulletedListItem from '../common/bulleted-list/BulletedListItem';
import StartGuideImage from './StartGuidePage/StartGuideImage';
import usePermissionAccess from '../../hooks/usePermissionAccess';
import AnalyticsStartGuideBanner from './banners/AnalyticsStartGuideBanner';
import CenterLayout from '../common2/CenterLayout/CenterLayout';
import StartGuideHeading from './StartGuidePage/StartGuideHeading';

const tabs = [
  {
    id: 'dashboards',
    title: 'Dashboards',
  },
  {
    id: 'explore',
    title: 'Explore',
  },
  {
    id: 'alerts',
    title: 'Alerts',
  },
];

export function AnalyticsStartGuidePage(props) {
  const hasAccess = usePermissionAccess(ANALYTICS_PERMISSION);

  return (
    <AnalyticsPage {...props}>
      <CenterLayout>
        <StartGuideHeading />
        <Column spacing="large">
          <AnalyticsStartGuideBanner hasAccess={hasAccess} />
          <StartGuideCard
            heading="ANALYTICS_START_GUIDE_CONTENT_HEADING"
            description="ANALYTICS_START_GUIDE_CONTENT_DESK"
          >
            <StartGuideTabs tabs={tabs}>
              <StartGuideTabContent key="dashboards">
                <StartGuideBulletedList>
                  <BulletedListItem>ANALYTICS_START_GUIDE_DASHBOARD_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_DASHBOARD_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage
                  src="/images/start-guides/analytics/analytics-dashboards.png"
                  alt="dashboards"
                />
              </StartGuideTabContent>
              <StartGuideTabContent key="explore">
                <StartGuideBulletedList>
                  <BulletedListItem>ANALYTICS_START_GUIDE_EXPLORE_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_EXPLORE_LIST_ITEM_1</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_EXPLORE_LIST_ITEM_2</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_EXPLORE_LIST_ITEM_3</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage
                  src="/images/start-guides/analytics/analytics-explore.png"
                  alt="explore"
                />
              </StartGuideTabContent>
              <StartGuideTabContent key="alerts">
                <StartGuideBulletedList>
                  <BulletedListItem>ANALYTICS_START_GUIDE_ALERTS_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_ALERTS_LIST_ITEM_1</BulletedListItem>
                  <BulletedListItem>ANALYTICS_START_GUIDE_ALERTS_LIST_ITEM_2</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage
                  src="/images/start-guides/analytics/analytics-alerts.png"
                  alt="alerts"
                />
              </StartGuideTabContent>
            </StartGuideTabs>
          </StartGuideCard>
        </Column>
      </CenterLayout>
    </AnalyticsPage>);
}

export default React.memo(AnalyticsStartGuidePage);
