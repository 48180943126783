import React from 'react';
import PropTypes from 'prop-types';

function ClaimsTableCell({ children, as: Cell = 'td' }) {
  return <Cell>{children}</Cell>;
}

ClaimsTableCell.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

export default ClaimsTableCell;
