import React from 'react';

import JuniqueLogo from '../../icons/login/junique-logo.svg';
import EMPLogo from '../../icons/login/emp.svg';
import FoodspringLogo from '../../icons/login/foodspring.svg';
import SevenSendersLogoMobile from '../../icons/login/7s-logo-colored.svg';
import SevenSendersLogoDesktop from '../../icons/seven-senders-portal-logo-white.svg';
import Picture, { Source } from '../common/Picture';
import ExternalLink from '../common2/links/ExternalLink';
import Locale from '../Translate/Locale';

import './LoginContainer.scss';

function LoginContainer(props) {
  return (
    <div styleName="container">
      <div styleName="side-container">
        <div styleName="side-picture">
          <Picture stretchHorizontally={true} alt="Touchpoints" fallback="/images/login/touchpoints-team@2x.jpg">
            <Source srcset="/images/login/touchpoints-team@2x.webp" type="image/webp" media="(min-width: 1300px)" />
            <Source srcset="/images/login/touchpoints-team.webp" type="image/webp" media="(min-width: 768px)" />
          </Picture>
        </div>
        <SevenSendersLogoDesktop styleName="sevensenders-logo" />
        <div styleName="text-and-logos">
          <div styleName="promotion-text">USED BY</div>
          <div styleName="logos">
            <FoodspringLogo />
            <JuniqueLogo />
            <EMPLogo />
          </div>
        </div>
      </div>
      <div styleName="content">
        <div styleName="login-form">{props.children}</div>
        <div styleName="privacy-policy">
          <ExternalLink to={'https://sevensenders.com/privacy-policy'}>
            <Locale>Privacy policy</Locale>
          </ExternalLink>
        </div>
      </div>
      <SevenSendersLogoMobile styleName="sevensenders-logo-mobile" />
    </div>
  );
}

export default LoginContainer;
