import { connect } from 'react-redux';
import loadWrapper from './loadWrapper';

const defaults = {
  isLoadedSelector: () => false,
  isLoadingSelector: () => false,
  errorSelector: () => false,
};

function connectedLoadWrapper(args) {
  const {
    isLoadedSelector,
    isLoadingSelector,
    errorSelector,
    fetchAction,
  } = {
    ...defaults,
    ...args,
  };

  const mapStateToProps = (state, props) => ({
    isLoaded: isLoadedSelector(state, props),
    isLoading: isLoadingSelector(state, props),
    error: errorSelector(state, props),
  });

  const mapDispatchToProps = (dispatch, props) => ({
    fetchAction: () => fetchAction && dispatch(fetchAction(props)),
  });

  return (DecoratedComponent) =>
    connect(mapStateToProps, mapDispatchToProps)(
      loadWrapper(args)(DecoratedComponent)
    );
}

export default connectedLoadWrapper;