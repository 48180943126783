import React from 'react';
import PropType from 'prop-types';
import Label from '../Label';
import Locale from '../../Translate/Locale';
import CheckIcon from '../../../icons/checkbox-check.svg';

import './Checkbox.scss';

const Checkbox = ({ children, checked, ...props }) => {
  return (
    <Label inlineRight disabled={props.disabled}>
      <input type="checkbox" styleName="checkbox" checked={checked} {...props} />
      <div styleName="indicator">
        <CheckIcon />
      </div>
      <Locale>{children}</Locale>
    </Label>
  );
};

Checkbox.defaultProps = {
  checked: false,
};

Checkbox.propTypes = {
  checkbox: PropType.bool,
};

export default Checkbox;