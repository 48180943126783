import React from 'react';
import PropTypes from 'prop-types';
import Heading4 from '../../common/Heading4';

import './DetailsCard.scss';

function DetailsCard(props) {
  const {
    title,
    children,
  } = props;

  return (
    <div styleName="card">
      { title && <Heading4 styleName="heading">{title}</Heading4> }
      <div styleName="content">
        {children}
      </div>
    </div>
  );
}

DetailsCard.propTypes = {
  title: PropTypes.string,
};

export default React.memo(DetailsCard);