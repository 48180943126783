import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ImportanceIndicator.scss';

const ImportanceIndicator = ({ type }) => <div styleName={classNames('indicator', type)} />;

ImportanceIndicator.propTypes = {
  type: PropTypes.oneOf(['really_bad', 'bad', 'good', 'neutral']),
};

export default ImportanceIndicator;