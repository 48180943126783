import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ItemPicturesUploader from '../common/ItemPicturesUploader';
import ItemInfo from '../common/ItemInfo';
import useStepValidation from '../common/useStepValidation';
import { partialDamageInfoValidator } from '../claimValidators';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import CustomerCommentInput from '../common/CustomerCommentInput';
import Column from '../../../common/layout/Column';
import InputHelpLink from '../InputHelpLink';

function PartialDamageInfoStep(props) {
  const { as: Container, onChange, claim, onNext } = props;

  const [errors, onClickNext] = useStepValidation(claim, onNext, partialDamageInfoValidator);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeDamageInfo = (partialDamageInfo) => onChange({ partialDamageInfo });
  const onInputChange = (value, name) => onChange({ [name]: value });

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Damage-Photo-Requirements%C2%A0">
      Damage photo requirements
    </InputHelpLink>
  );

  return (
    <Container {...props} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Damage information</SidePanelContentHeader>
      <Column spacing="small">
        <ItemInfo
          hasDescription
          data={claim.partialDamageInfo}
          errorsWithTranslation={errors.partialDamageInfo}
          emptyInfoErrorText="BAD_PARTIAL_DAMAGE_INFO_ITEMS"
          onChange={onChangeDamageInfo}
        />
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        <ItemPicturesUploader
          name="picturesOfDamage"
          label="Pictures of damage"
          helpText="CLAIMS_DAMAGE_PICTURES_HELP_TEXT"
          claim={claim}
          errors={errors}
          uploaderHelpLinkElement={uploaderHelpLinkElement}
          onChange={onInputChange}
          onBusy={setIsDisabled}
        />
      </Column>
    </Container>
  );
}

PartialDamageInfoStep.propTypes = {
  claim: PropTypes.shape({
    partialDamageInfo: PropTypes.arrayOf(PropTypes.object),
    picturesOfDamage: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onChange: PropTypes.func,
};

export default React.memo(PartialDamageInfoStep);
