import Bottle from 'bottlejs';
import { configureAppStore } from './store';
import { createFirewall } from './firewall';
import { createNotificationsApi, createSendwiseApi, createTouchpointsApi } from './api';
import { initI18n } from './i18n';

import getPermissions from './actions/permissions';
const bottle = new Bottle();

bottle.factory('store', () => configureAppStore());
bottle.factory('firewall', (container) => createFirewall(container.store));
bottle.factory('api', (container) => createSendwiseApi(container.store));
bottle.factory('touchpointsApi', (container) => createTouchpointsApi(container.store));
bottle.factory('notificationsApi', (container) => createNotificationsApi(container.store));
bottle.factory('i18n', (container) => initI18n(container.store));

bottle.value('actions.getPermissions', getPermissions);
bottle.value('env', {
  refreshTokenInterval: process.env.SENDWISE_REFRESH_TOKEN_INTERVAL || 30,
});

/**
 * @typedef {Object} Container
 * @property {Api} api
 * @property {TouchpointsApi} touchpointsApi
 * @property {NotificationsServiceApi} notificationsApi
 */

/**
 * @type {Container}
 */
const container = bottle.container; // re-assign for better type inference

export default container;
