import React from 'react';
import PropTypes from 'prop-types';

import SmallBadge from '../../../TrackingPagesPage/SmallBadge/SmallBadge';

import './ShopLocalizationName.scss';

function ShopLocalizationName({ localization }) {
  return (
    <React.Fragment>
      {localization.url}
      <div styleName="badge">
        <SmallBadge>{localization.language}</SmallBadge>
      </div>
    </React.Fragment>
  );
}

ShopLocalizationName.propTypes = {
  localization: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default React.memo(ShopLocalizationName);