import React from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import { getWidgetPreviewUrl } from '../../utils/previewHelper';

export class WidgetPreview extends React.PureComponent {
  state = {
    widget: null,
  };

  componentDidMount() {
    this.initWidget();
  }

  componentDidUpdate() {
    this.initWidget();
  }

  componentWillUnmount() {
    if (this.state.widget) {
      this.state.widget.unmount();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.widget) {
      this.updateWidget(nextProps);
    }
  }

  initWidget() {
    const { SendwiseEmbeddedWidget, mountId, trackingInfo, settings, onChangeLanguage } = this.props;

    if (SendwiseEmbeddedWidget && !this.state.widget) {
      const widget = SendwiseEmbeddedWidget.newWidget({ mountId, onChangeLanguage, settings });

      widget.initSettings(settings);
      widget.setTrackingInfo(trackingInfo);
      widget.render();
      this.setState({ widget });
    }
  }

  updateWidget(nextProps) {
    const { widget } = this.state;

    if (nextProps.trackingInfo !== this.props.trackingInfo) {
      widget.setTrackingInfo(nextProps.trackingInfo);
    }

    if (nextProps.settings !== this.props.settings) {
      widget.initSettings(nextProps.settings);
    }
  }

  render() {
    const { mountId } = this.props;
    return <div id={mountId} />;
  }
}

WidgetPreview.propTypes = {
  mountId: PropTypes.string,
  trackingInfo: PropTypes.object,
  settings: PropTypes.object,
};

WidgetPreview.defaultProps = {
  mountId: 'tracking-widget',
  trackingInfo: {},
  settings: [],
};

export default makeAsyncScriptLoader(getWidgetPreviewUrl, { globalName: 'SendwiseEmbeddedWidget' })(WidgetPreview);
