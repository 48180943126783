import React from 'react';
import PropTypes from 'prop-types';
import Table from '../common2/Table';

import '../Widget/WidgetsList.scss';

const columns = [
  {
    title: 'Basic info',
    style: { width: 428 },
  },
  {
    title: 'Last published',
  },
  {
    title: 'Last edited',
  },
  {
    title: '', // actions
    style: { width: 72 },
  },
];

class WidgetsListTracking extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <Table columns={columns} noHeaderTopBorder={true}>
        {children}
      </Table>
    );
  }
}

WidgetsListTracking.propTypes = {
  legacy: PropTypes.bool,
  firstColumnName: PropTypes.string, // overrides column name for the first column
};

export default WidgetsListTracking;
