import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import './BasicTextArea.scss';

export function BasicTextArea(props) {
  const { id, name, value, placeholder, disabled, isInvalid, t, onChange } = props;

  const onChangeHandler = useCallback((e) => onChange(e.target.value, e.target.name, e), [onChange]);

  return (
    <textarea
      styleName={classNames('input', { error: isInvalid })}
      id={id}
      name={name}
      value={value || ''}
      placeholder={t(placeholder)}
      disabled={disabled}
      onChange={onChangeHandler}
    />
  );
}

BasicTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(withTranslation()(BasicTextArea));
