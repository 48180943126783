import PropTypes from 'prop-types';
import ListItem from '../components/ListItem';

// Checks 'permission' field for each item and returns filtered array with filled 'disabled' field
// If item doesn't have 'permission' id will not be checked with firewall
export function getItemsWithPermission({ items, firewall, shop }) {
  if (!firewall) {
    return items;
  }

  return items
    .filter((item) => (item.permission ? !firewall.inBlackList(item.permission) : true))
    .map((item) => {
      if (item.permission) {
        return { ...item, disabled: !firewall.hasAccess(item.permission) };
      }

      if (item.shopAccess) {
        return { ...item, disabled: !shop[item.shopAccess] };
      }

      return { ...item, disabled: false };
    });
}

// basic props for item
export const ItemPropType = {
  ...ListItem.propTypes,
  title: PropTypes.string,
  icon: PropTypes.element,
  markup: PropTypes.element,
  badge: PropTypes.element,
  navigation: PropTypes.bool,
};

// basic props for items container
export const ItemsContainerPropType = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  firewall: PropTypes.object,
};
