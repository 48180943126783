import React from 'react';
import PropTypes from 'prop-types';
import SortableWidgetItem from './SortableWidgetItem';
import SortableWidgetsContainer from './SortableWidgetsContainer';
import classNames from 'classnames';

import styles from './SortableWidgetsList.scss';

class SortableWidgetsList extends React.PureComponent {
  state = {};

  onSortStart = ({ index }) => {
    this.setState({ isSorting: true, index });
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({ isSorting: false, index: null }, () => this.onMove(oldIndex, newIndex));
  };

  onMove(from, to) {
    const {
      widgets,
      onMove,
    } = this.props;

    if (from !== to) {
      onMove(widgets[from].id, widgets[to].id);
    }
  };

  getOnClickHandler(widget) {
    return this.state.isSorting ? null : () => this.props.onSelect(widget.id);
  }

  render() {
    const {
      widgets,
    } = this.props;

    /*
      A purpose of isSorting flag is to change cursor during sorting. Because there is no safe way to change
      mouse cursor globally, we change it for some elements. And it is better not to have another cursors in
      this component hierarchy because of unwanted changes during sorting (see getOnClickHandler)

      1. SortableWidgetsContainer
      2. SortHelper. It is an element that follows mouse cursor during sorting
        (react-sortable-hoc appends it to the end of the <body> element)
    */

    const customStyle = classNames(styles['sort-cursor'] , styles['highlight']);

    return (
      <SortableWidgetsContainer
        isSorting={this.state.isSorting}
        helperClass={customStyle}
        lockAxis="y"
        useDragHandle
        lockToContainerEdges
        onSortStart={this.onSortStart}
        onSortEnd={this.onSortEnd}
      >
        { widgets.map((widget, index) =>
          <SortableWidgetItem
            key={widget.id}
            index={index}
            widget={widget}
            onClick={this.getOnClickHandler(widget)}
            isSortable={widgets.length > 1} // hide handle when nothing to sort
          />)
        }
      </SortableWidgetsContainer>
    );
  }
}

SortableWidgetsList.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  onMove: PropTypes.func,
};

export default SortableWidgetsList;