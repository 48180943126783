import React from 'react';
import ParcelFinderContainer from './ParcelFinderContainer';
import ParcelFinder from './ParcelFinder';

function ParcelFinderPage(props) {
  return (
    <ParcelFinderContainer {...props}>
      <ParcelFinder />
    </ParcelFinderContainer>
  );
}

export default ParcelFinderPage;
