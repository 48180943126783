import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import InputLabel from '../Input/InputLabel';

import './TextArea.scss';

class TextArea extends React.PureComponent {
  render() {
    const { label, error, placeholder, value, t, ...props } = this.props;

    return (
      <InputLabel label={label} error={error}>
        <textarea styleName="textarea" value={value || ''} placeholder={t(placeholder)} {...props} />
      </InputLabel>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
};

export default withTranslation()(TextArea);
