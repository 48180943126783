import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListItem from '../ListItem';
import { ItemPropType } from '../../utils/listItemHelper';
import Locale from '../Translate';

import './SideMenuItem.scss';

class SideMenuItem extends React.PureComponent {
  render() {
    const { level, title, noTitleTranslation, badge, selected, disabled, selectedChildless } = this.props;

    const styleName = classNames('item', `level${level}`, {
      selected,
      'selected-childless': selectedChildless,
      disabled,
    });

    return (
      <ListItem styleName={styleName} {...this.props}>
        <span styleName="title">{noTitleTranslation ? title : <Locale>{title}</Locale>}</span>
        {badge}
      </ListItem>
    );
  }
}

SideMenuItem.propTypes = {
  ...ItemPropType,
  level: PropTypes.number,
};

export default SideMenuItem;
