import storage from '../models/storage';

function logoutAction() {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT' });

    storage.removeItem('token');
    storage.removeItem('refreshToken');
  };
}

export default logoutAction;
