import { Api } from './Api';

class SendwiseApi {
  constructor(baseUrl, token = null, handlers = {}) {
    this.api = new Api(baseUrl, token, handlers);
  }

  setToken(token) {
    this.api.setToken(token);
  }

  login(email, password) {
    return this.api.post('/login', { email, password }, false);
  }

  refreshToken(refreshToken) {
    // eslint-disable-next-line camelcase
    return this.api.post('/token', { refresh_token: refreshToken }, false);
  }

  getEmbeddedWidgetSettings(id) {
    return this.api.get(`/embedded-widgets/${id}`);
  }

  me() {
    return this.api.get('/me');
  }

  updateUserInfo(userId, info) {
    return this.api.patch(`/users/${userId}`, info);
  }

  forgotPassword(email) {
    return this.api.post('/forgot-password', { email }, false);
  }

  resetPassword(email, password, token) {
    return this.api.post('/reset-password', { email, password, token }, false);
  }

  embeddedWidgets(shopId) {
    return this.api.get('/embedded-widgets', { shopId });
  }

  createEmbeddedWidget(embeddedWidget) {
    return this.api.post('/embedded-widgets', embeddedWidget);
  }

  updateEmbeddedWidgetSettings(embeddedWidgetId, data) {
    return this.api.patch(`/embedded-widgets/${embeddedWidgetId}`, data);
  }

  publishEmbeddedWidget(embeddedWidgetId, data) {
    return this.api.post(`/embedded-widgets/${embeddedWidgetId}/publish`, data);
  }

  trackingPages(shopId) {
    return this.api.get('/tracking-pages', { shopId });
  }

  createTrackingPage(trackingPage) {
    return this.api.post('/tracking-pages', trackingPage);
  }

  getTrackingPageSettings(trackingPageId) {
    return this.api.get(`/tracking-pages/${trackingPageId}`);
  }

  updateTrackingPageSettings(trackingPageId, data) {
    return this.api.patch(`/tracking-pages/${trackingPageId}`, data);
  }

  publishTrackingPage(trackingPageId, force) {
    return this.api.post(`/tracking-pages/${trackingPageId}/publish`, { force });
  }

  setDefaultTrackingPage(trackingPageId, force) {
    return this.api.post(`/tracking-pages/${trackingPageId}/default`, { force });
  }

  removeTrackingPage(trackingPageId) {
    return this.api.delete(`/tracking-pages/${trackingPageId}`);
  }

  removeEmbeddedWidget(embeddedWidgetId) {
    return this.api.delete(`/embedded-widgets/${embeddedWidgetId}`);
  }

  getShopLocalizations(shopId) {
    return this.api.get('/shop-localizations', { shopId });
  }

  createShopLocalization(shop, url, language) {
    return this.api.post('/shop-localizations', { shop, url, language });
  }

  editShopLocalization(localizationId, url, language) {
    return this.api.patch(`/shop-localizations/${localizationId}`, { url, language });
  }

  getShopLocalization(localizationId) {
    return this.api.get(`/shop-localizations/${localizationId}`);
  }

  deleteShopLocalization(localization) {
    const { id, linkedResources } = localization;

    return this.api.delete(`/shop-localizations/${id}`, { linkedResources });
  }

  archiveShopLocalization(localization) {
    const { id, activeLinkedResources } = localization;

    return this.api.patch(`/shop-localizations/${id}/archive`, { archived: true, activeLinkedResources });
  }

  restoreShopLocalization(id) {
    return this.api.patch(`/shop-localizations/${id}/archive`, { archived: false });
  }

  getProxyToken() {
    return this.api.get('/proxy/analytics/token');
  }

  getLegacyTrackingPage(shopId) {
    return this.api.get('/legacy-tracking-page', { shopId });
  }

  removeLegacyTrackingPage(shopId) {
    return this.api.delete('/legacy-tracking-page', { shopId });
  }

  updateLegacyTrackingPage(shopId, data) {
    return this.api.patch(`/legacy-tracking-page?shopId=${shopId}`, data);
  }

  getPreviewTrackingData(shopId, language) {
    return this.api.get('/tracking', { shopId, language });
  }

  sendEmbeddedWidgetInstruction(embeddedWidgetId, options) {
    return this.api.post(`/embedded-widgets/${embeddedWidgetId}/send-instruction`, options);
  }

  sendIntegrationInstruction(method, options) {
    return this.api.post(`/shop-integration/${method}/send-instruction`, options);
  }

  getContentLibraryMessages({ shopId, language, type, ticketTypeId }) {
    return this.api.get('/content-library', { shopId, language, type, ticketTypeId });
  }

  editContentLibraryMessage(messageId, data) {
    return this.api.patch(`/content-library/${messageId}`, data);
  }

  resetContentLibraryMessage(messageId) {
    return this.api.delete(`/content-library/${messageId}`);
  }

  updateShopName(name, alias, shopId) {
    return this.api.patch(`/shops/${shopId}`, { name, alias });
  }

  getShopIntegrations(shopId) {
    return this.api.get('/shop-integration', { shopId });
  }

  getNotificationEvents(type) {
    return this.api.get('/notifications/events', { type });
  }
}

export default SendwiseApi;
