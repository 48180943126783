import React from 'react';
import ExitButton from '../../editors/common/ExitButton';

import './ExitHeader.scss';

class ExitHeader extends React.PureComponent {
  render() {
    const {
      title,
      onExit,
    } = this.props;

    return (
      <div styleName="exit-header">
        <ExitButton onClick={onExit} />
        <span styleName="title">{title}</span>
      </div>
    );
  }
}

export default ExitHeader;