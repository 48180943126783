import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';

function ClaimsValue({ currency, value }) {
  return <Text ink>{`${currencySigns[currency]} ${(value / 100).toLocaleString('en-US')}`}</Text>;
}

export const currencySigns = {
  EUR: '€',
  CHF: 'CHF',
  CZK: 'Kč',
  DKK: 'kr',
  GBP: '£',
  HUF: 'ft',
  NOK: 'kr',
  PLN: 'zł',
  RON: 'lei',
  SEK: 'kr',
};

ClaimsValue.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.number,
};

export default ClaimsValue;
