import React from 'react';
import Locale from '../Translate/Locale';
import Popup from '../common/Popup';
import Icon from '../../icons/attention-large.svg';
import Heading4 from '../common/Heading4/Heading4';

import './MobilePreviewPopup.scss';

class MobilePreviewPopup extends React.PureComponent {
  render() {
    return (
      <Popup storageCloseKey="mobile_preview_popup_closed">
        <div styleName="content">
          <Icon styleName="icon"/>
          <div>
            <Heading4>We believe in mobile first</Heading4>
            <p>
              <Locale>Did you know xx% of your visits are from a mobile device?</Locale>
            </p>
          </div>
        </div>
      </Popup>
    );
  }
}

export default MobilePreviewPopup;