import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './Overlay.scss';

export default class Overlay extends React.PureComponent {
  componentWillMount() {
    this.element = document.createElement('div');
    document.body.appendChild(this.element);
  }

  componentWillUnmount() {
    document.body.removeChild(this.element);
  }

  render() {
    if (!this.element) {
      return null;
    }

    return ReactDOM.createPortal(
      <div
        styleName="overlay"
        data-testid="overlay"
        onClick={this.props.onClick}
        onWheel={(e) => e.stopPropagation()}
      />,
      this.element
    );
  }
}

Overlay.propTypes = {
  onClick: PropTypes.func,
};
