import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  messageByLanguage: {},
};

const contentLibraryFormReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'CONTENT_LIBRARY_MESSAGE')) {
    return requestProducer(draft, action, 'CONTENT_LIBRARY_MESSAGE', {
      requestProducer: (draft) => draft.messageByLanguage = {},
      successProducer: (draft, action) => {
        draft.messageByLanguage  = {};
        action.data.forEach(message => draft.messageByLanguage[message.language] = message);
      }
    });
  }

  if (isRequestAction(action, 'EDIT_CONTENT_LIBRARY_MESSAGE')) {
    return requestProducer(draft, action, 'EDIT_CONTENT_LIBRARY_MESSAGE', {
      successProducer: (draft, action) => {
        const message = action.data;
        draft.messageByLanguage[message.language] = message;
      }
    });
  }

  if (isRequestAction(action, 'RESET_CONTENT_LIBRARY_MESSAGE')) {
    return requestProducer(draft, action, 'RESET_CONTENT_LIBRARY_MESSAGE', {
      successProducer: (draft, action) => {
        const message = action.data[0]; // because we filter messages and receive array with 1 element
        draft.messageByLanguage[message.language] = message;
      }
    });
  }

  return draft;
});

export default contentLibraryFormReducer;