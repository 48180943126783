import React from 'react';

import DeleteModal from '../../modal/DeleteModal/DeleteModal';
import ShopLocalizationName from './ShopLocalizationName/ShopLocalizationName';

class ShopLocalizationDeleteModal extends React.PureComponent {
  getDeletionWarnings() {
    const { localization } = this.props;

    const defaultWarning = 'This action can\'t be reversed.';
    const linkedResourceWarning = 'WARNING_DELETE_SHOP_LOCALIZATION_LINKED_TO_RESOURCES';

    if (localization) {
      return (localization.linkedResources > 0) ? [defaultWarning, linkedResourceWarning] : [defaultWarning];
    }

    return [];
  };

  render() {
    const {
      onCancel,
      onDelete,
    } = this.props;

    const localization = this.props.localization || {};

    return (
      <DeleteModal
        show={!!this.props.localization}
        key={localization.id}
        title="Delete localization"
        message="You are about to delete the following localization:"
        warningMessages={this.getDeletionWarnings()}
        onCancel={onCancel}
        onDelete={onDelete}
        nameElement={<ShopLocalizationName localization={localization} />}
      />
    );
  }
}

export default ShopLocalizationDeleteModal;