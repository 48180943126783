import React from 'react';
import PropTypes from 'prop-types';

import Button from '../common2/Button';
import Modal from '../common2/Modal';
import ModalContent from '../common2/Modal/ModalContent';
import ModalFooter from '../common2/Modal/ModalFooter';
import ModalHeader from '../common2/Modal/ModalHeader';
import Locale from '../Translate';
import DamageIcon from '../../icons/damage.svg';

function ConfirmDefaultTrackingPageModal({ show, onConfirm, onCancel }) {
  return (
    <Modal show={show} onClose={onCancel}>
      <ModalHeader icon={DamageIcon}>Make default</ModalHeader>
      <ModalContent>
        <Locale>Do you want to change the default Tracking Page?</Locale>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Set Default</Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmDefaultTrackingPageModal.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default React.memo(ConfirmDefaultTrackingPageModal);
