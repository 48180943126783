import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ShopLocalizationsPage from '../ShopSettingsPage/ShopLocalizationsPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import WarehousesPage from '../settings/warehouses/WarehousesPage';
import SLAPage from '../settings/sla/SLAPage';
import PageNavigate from '../PageNavigate';

const ShopLocalizationsPageWithAccess = withPermissionRedirect(permissions.SHOP_LOCALIZATIONS_PERMISSION)(
  ShopLocalizationsPage
);
const WarehousesPageWithAccess = withPermissionRedirect(permissions.WAREHOUSES)(WarehousesPage);
const SLAPageWithAccess = withPermissionRedirect(permissions.SLA)(SLAPage);

const ShopSettingsRoutes = () => (
  <Routes>
    <Route path="warehouses" element={<WarehousesPageWithAccess />} />
    <Route path="service-level-agreements" element={<SLAPageWithAccess />} />
    <Route path="localizations" element={<ShopLocalizationsPageWithAccess />} />
    <Route index element={<PageNavigate to="/settings/user-management" />} />
  </Routes>
);

export default withPermissionRedirect(permissions.SHOP_SETTINGS_PERMISSION)(ShopSettingsRoutes);
