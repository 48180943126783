import React from 'react';

import './PreviewContainer.scss';

function PreviewContainer(props) {
  const { children } = props;

  return (
    <div styleName="preview-container">
      {children}
    </div>
  );
}

export default React.memo(PreviewContainer);
