import React from 'react';
import PropTypes from 'prop-types';

import NavItem from './NavItemWithTooltip';
import TabControl from '../common/TabControl';
import PRODUCTS from './products';

function getProducts(selectedProduct) {
  return PRODUCTS.map((product) => {
    const markup = (
      <NavItem id={product.id} tooltipText={`${product.id}_NAV`} selected={selectedProduct === product.id}>
        {product.icon}
      </NavItem>
    );

    return { ...product, markup };
  });
}

function ProductMenu(props) {
  const { selectedProduct } = props;

  return <TabControl grow navigation vertical tabs={getProducts(selectedProduct)} />;
}

ProductMenu.propTypes = {
  selectedProduct: PropTypes.string,
};

export default React.memo(ProductMenu);
