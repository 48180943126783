import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';

import SideMenuItem from './SideMenuItem';
import Locale from '../Translate/Locale';
import { getItemsWithPermission, ItemsContainerPropType } from '../../utils/listItemHelper';
import { getFirewallForSelectedShop, getSelectedShop } from '../../reducers/selectors';

import './SideMenu.scss';

export class SideMenu extends React.PureComponent {
  render() {
    const { firewall, onSelect, pathname, title, titleBadge, items, shop } = this.props;
    const itemsWithPermission = getItemsWithPermission({ items, firewall, shop });

    return (
      <nav styleName="container" aria-label={title}>
        {title && (
          <div styleName="title">
            <span styleName="text" role="heading">
              <Locale>{title}</Locale>
            </span>
            {titleBadge && <div styleName="badge">{titleBadge}</div>}
          </div>
        )}
        <div styleName="menu" role="list">
          {getItemsAsArray(itemsWithPermission, pathname).map(({ item, level, childLess }) => (
            <SideMenuItem {...getItemProps(item, onSelect, pathname, childLess)} level={level} />
          ))}
        </div>
      </nav>
    );
  }
}

// Returns array of {item, level}
function getItemsAsArray(rootItems, selectedId) {
  const result = [];

  rootItems.forEach((item) => {
    if (isChildSelected(item, selectedId)) {
      const children = item.children || [];
      result.push({ item, level: 0 }, ...children.map((item) => ({ item, level: 1 })));
    } else {
      if (!item.children) {
        return result.push({ item, level: 0, childLess: true });
      }

      // if any child is not selected we hide them all
      const firstChild = (item.children || [])[0];

      if (!item.link && firstChild) {
        // if link for root item is not specified we use link from the first child
        result.push({ item: { ...item, link: firstChild.link }, level: 0 });
      } else {
        result.push({ item, level: 0 });
      }
    }
  });

  return result;
}

export function getItemProps(item, onSelect, pathname, childLess) {
  const selected = isItemSelected(item, pathname) || isChildSelected(item, pathname);

  return {
    ...item,
    key: item.id,
    selected,
    selectedChildless: selected && childLess,
    onClick: () => {
      onSelect && onSelect(item.id);
      item.onSelect && item.onSelect();
    },
  };
}

export function isChildSelected(item, pathname) {
  return !!(item.children || []).find((child) => isItemSelected(child, pathname));
}

function isItemSelected(item, pathname) {
  if (!pathname || !item.link) {
    return false;
  }

  if (item.matchPaths) {
    return item.matchPaths.some((path) => !!matchPath({ path, end: true }, pathname));
  }

  return !!matchPath({ path: item.link, end: true }, pathname);
}

SideMenu.defaultProps = {
  onSelect: () => {},
};

SideMenu.propType = {
  ...ItemsContainerPropType,
  titleBadge: PropTypes.element,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    firewall: getFirewallForSelectedShop(state),
    shop: getSelectedShop(state),
  };
}

export default connect(mapStateToProps)(SideMenu);
