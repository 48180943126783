import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../common/Loader/Loader';

import './Iframe.scss';

const Iframe = (props) => {
  const { showLoader, showIframe, pageUrl, onLoad } = props;

  return (
    <div styleName="container">
      {showLoader && (
        <div styleName="loader">
          <Loader />
        </div>
      )}
      {showIframe && <iframe src={pageUrl} frameBorder="0" onLoad={onLoad} data-hj-allow-iframe />}
    </div>
  );
};

Iframe.propTypes = {
  onLoad: PropTypes.func,
  showLoader: PropTypes.bool,
  showIframe: PropTypes.bool,
  pageUrl: PropTypes.string,
};

export default Iframe;
