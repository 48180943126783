import React from 'react';

import PageWithTitle from '../Wizard/PageWithTitle';
import Button from '../common/Button';
import { HelpCard, HelpCardList } from '../HelpCard';
import WhatsNext from '../common/WhatsNext';
import Blockquote from './Blockquote';

import PageActiveIcon from '../../icons/artwork/success.svg';
import HelpIcon from '../../icons/artwork/lighthouse.svg';

import './PublishSuccess.scss';

const helpUrl =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010426-designing-and-publishing-a-tracking-page';

class PublishSuccess extends React.PureComponent {
  render() {
    const { trackingPageUrl, goToEditor } = this.props;

    return (
      <React.Fragment>
        <div styleName="icon-container">
          <PageActiveIcon styleName="icon" />
        </div>
        <PageWithTitle title="Your page is active" secondaryText="Hooray!">
          <Blockquote>{trackingPageUrl}</Blockquote>
          <Button onClick={goToEditor} primary>
            View Page
          </Button>
        </PageWithTitle>
        <div styleName="footer">
          <WhatsNext
            title="What's next?"
            subtitle="Integrate your platform"
            listItems={['whats next text 1', 'whats next text 2', 'whats next text 3']}
          />
          <HelpCardList>
            <HelpCard
              title="Need some help?"
              icon={HelpIcon}
              description="help text" // TODO: change text placeholders
              linkText="Link title"
              linkUrl={helpUrl}
            />
          </HelpCardList>
        </div>
      </React.Fragment>
    );
  }
}

export default PublishSuccess;
