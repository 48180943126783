import React from 'react';

import ShopLocalizationName from './ShopLocalizationName';
import ConfirmArchiveModal from '../../modal/ConfirmArchiveModal';

export function ShopLocalizationArchiveModal(props) {
  const localization = props.localization || {};

  return (
    <ConfirmArchiveModal
      key={localization.id}
      show={!!props.localization}
      title="Archive localisation"
      message="You are about to archive the following localisation:"
      warningMessages={[
        'All tracking pages will be unpublished',
        'All active sending logics will become inactive. You will stop sending out notifications',
      ]}
      onCancel={props.onCancel}
      onArchive={props.onArchive}
    >
      { localization && (
        <ShopLocalizationName localization={localization} />
      ) }
    </ConfirmArchiveModal>
  );
}

export default React.memo(ShopLocalizationArchiveModal);