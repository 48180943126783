import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { DashboardPage, ExplorePage } from '../analytics';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import AnalyticsStartGuidePage from '../start-guides/AnalyticsStartGuidePage';

import { useDispatch, useSelector } from 'react-redux';
import { selectAnalyticsDashboards, getAnalyticsDashboards } from '../../reducers/analyticsDashboardsReducer';
import PageLoader from '../PageLoader';
import { useSelectedShopId } from '../../hooks/useSelectedShop';
import { useAnalyticsMenuItems } from '../analytics/useAnalyticsMenuItems';
import withLegacyRouter from '../../wrappers/withLegacyRouter';
import PageContainer from '../pagecontainer/PageContainer';
import PageNavigate from '../PageNavigate';

const withAnalyticsAccess = withPermissionRedirect(permissions.ANALYTICS_PERMISSION, '/analytics/start-guide');
const withAnalyticsExploreAccess = withPermissionRedirect(permissions.ANALYTICS_EXPLORE, '/analytics/explore');

const withDashboardIdAndType = (dashboardId, type) => (props) => (
  <DashboardPage key={dashboardId} dashboardId={dashboardId} type={type} {...props} />
);

const OrderStatusOverview = withDashboardIdAndType('order_status_overview');
const OrderFulfillmentPerformance = withDashboardIdAndType('order_fulfillment_performance');
const OrderList = withDashboardIdAndType('order_list');
const OrderStatusChangeOutliers = withDashboardIdAndType('days_from_order_status_changed_outliers', 'look');

function NavigateWithId({ path }) {
  const { id } = useParams();
  return <PageNavigate to={`${path}/${id}`} />;
}

const OrderRoutes = () => (
  <Routes>
    <Route path="status-overview" element={<OrderStatusOverview />} />
    <Route path="fulfillment-performance" element={<OrderFulfillmentPerformance />} />
    <Route path="list" element={<OrderList />} />
    <Route path="days-from-status-changed-outliers" element={<OrderStatusChangeOutliers />} />
    <Route path=":id" element={<NavigateWithId path="/parcel-finder/orders" />} />
    <Route path="/" element={<PageNavigate to="status-overview" />} />
  </Routes>
);

const ShipmentStatusOverview = withDashboardIdAndType('shipment_status_overview');
const ShipmentCarrierPerformance = withDashboardIdAndType('shipment_carrier_performance');
const ShipmentList = withDashboardIdAndType('shipment_list');
const ShipmentCarrierComparison = withDashboardIdAndType('shipment_carrier_comparison');
const ShipmentStatusChangeOutliers = withDashboardIdAndType('days_from_shipment_status_changed_outliers', 'look');
const ReturnOverview = withDashboardIdAndType('returns_overview');
const ClaimsOverview = withDashboardIdAndType('claims_overview');
const ClaimsOverviewWithAccess = withAnalyticsAccess(ClaimsOverview);

const ShipmentRoutes = () => (
  <Routes>
    <Route path="carrier-comparison" element={<ShipmentCarrierComparison />} />
    <Route path="carrier-performance" element={<ShipmentCarrierPerformance />} />
    <Route path="list" element={<ShipmentList />} />
    <Route path="status-overview" element={<ShipmentStatusOverview />} />
    <Route path="returns-overview" element={<ReturnOverview />} />
    <Route path=":id" element={<NavigateWithId path="/parcel-finder/shipments" />} />
    <Route path="days-from-status-changed-outliers" element={<ShipmentStatusChangeOutliers />} />
  </Routes>
);

const OrderRoutesWithAccess = withAnalyticsAccess(OrderRoutes);
const ShipmentRoutesWithAccess = withAnalyticsAccess(ShipmentRoutes);
const ExplorePageWithAccess = withAnalyticsExploreAccess(ExplorePage);
const CountryViewWithAccess = withAnalyticsAccess(
  withLegacyRouter((props) => (
    <DashboardPage key={props.match.params.id} dashboardId={props.match.params.id} type="dashboard" {...props} />
  ))
);
const LooksViewWithAccess = withAnalyticsAccess(
  withLegacyRouter((props) => (
    <DashboardPage key={props.match.params.id} dashboardId={props.match.params.id} type="look" {...props} />
  ))
);

const AnalyticsRoutes = () => {
  const dispatch = useDispatch();
  const shopId = useSelectedShopId();
  const { isLoaded } = useSelector(selectAnalyticsDashboards);
  const { fixedDashboards, customDashboards, countryViews } = useAnalyticsMenuItems();

  useEffect(() => {
    dispatch(getAnalyticsDashboards(shopId));
  }, [dispatch, shopId]);

  // we display loader only on initial load
  // subsequent navigations to /analytics display cached data and load in background without loader for better UX
  if (!isLoaded) {
    return <PageLoader />;
  }

  const defaultDashboardRoute =
    fixedDashboards[0]?.link || customDashboards[0]?.link || countryViews[0]?.link || '/analytics/start-guide';

  return (
    <Routes>
      <Route path="orders/*" element={<OrderRoutesWithAccess />} />
      <Route path="shipments/*" element={<ShipmentRoutesWithAccess />} />
      <Route path="explore" element={<ExplorePageWithAccess />} />
      <Route path="start-guide" element={<AnalyticsStartGuidePage />} />
      <Route path="looks/:id" element={<LooksViewWithAccess />} />
      <Route path="dashboards/:id" element={<CountryViewWithAccess />} />
      <Route path="claims/claims-overview" element={<ClaimsOverviewWithAccess />} />
      <Route index element={<PageNavigate to={defaultDashboardRoute} />} />
    </Routes>
  );
};

export default AnalyticsRoutes;
