import React from 'react';

import Loader from '../Loader/Loader';
import Text from '../Text/Text';
import Locale from '../../Translate/Locale';

import './ProgressIndicator.scss';

function ProgressIndicator(props) {
  return (
    <div styleName="container">
      <Loader />
      <Text styleName="text">
        <Locale>{props.children}</Locale>
      </Text>
    </div>
  );
}

export default React.memo(ProgressIndicator);