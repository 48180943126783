import produce from 'immer';
import { isRequestAction, requestProducer } from './requestReducerHelper';

const initialState = {
  widgetsByShop: {},
};

const embeddedWidgetsReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'EMBEDDED_WIDGETS')) {
    return requestProducer(draft, action, 'EMBEDDED_WIDGETS', {
      successProducer: (draft, action) => {
        const { shopId, data } = action.data;
        draft.widgetsByShop[shopId] = data;
      }
    });
  }

  return draft;
});

export default embeddedWidgetsReducer;