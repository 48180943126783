import React from 'react';
import PropTypes from 'prop-types';

import Heading3 from '../../common/Heading3';
import Locale from '../../Translate';
import Badge from '../../common/Badge';
import BooleanBadge from '../BooleanBadge';
import DetailsCard from '../DetailsCard';
import ExternalLink from '../../common2/links/ExternalLink';
import DetailsCardItem from '../DetailsCard/DetailsCardItem';
import Shipments from './Shipments';
import { shipmentTagsToString, formatDate } from '../parcelFinderHelper';

import './OrderDetails.scss';

function OrderDetails(props) {
  const {
    creationDate,
    promisedDeliveryDate,
    boardingComplete,
    language,
    shopOrderId,
    shopOrderUrl,
    isOnTime,
    tags,
    id,
    shopId,
  } = props;

  return (
    <div styleName="container">
      <Heading3 styleName="heading">Order details</Heading3>
      <DetailsCard title="Basic info">
        <DetailsCardItem label="ID">{shopOrderId}</DetailsCardItem>
        <DetailsCardItem label="Creation date">{formatDate(creationDate)}</DetailsCardItem>
        <DetailsCardItem label="Language">
          <Locale>{language}</Locale>
        </DetailsCardItem>
        <DetailsCardItem label="Order URL">
          {shopOrderUrl && <ExternalLink to={shopOrderUrl}>{shopOrderUrl}</ExternalLink>}
        </DetailsCardItem>
        <DetailsCardItem label="Boarding Complete">
          <BooleanBadge value={boardingComplete} />
        </DetailsCardItem>
        <DetailsCardItem label="Tags">{tags && shipmentTagsToString(tags)}</DetailsCardItem>
      </DetailsCard>
      <DetailsCard title="Promised delivery date">
        <DetailsCardItem label="Date">
          {formatDate(promisedDeliveryDate)} <TimeBadge isOnTime={isOnTime} />
        </DetailsCardItem>
      </DetailsCard>
      <Shipments orderId={id} shopId={shopId} />
    </div>
  );
}

export function TimeBadge(props) {
  return props.isOnTime ? <Badge intent="positive">On Time</Badge> : <Badge intent="alert">Not On Time</Badge>;
}

OrderDetails.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string,
  creationDate: PropTypes.string,
  boardingComplete: PropTypes.bool,
  language: PropTypes.string,
  shopOrderUrl: PropTypes.string,
  isOnTime: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      content: PropTypes.string,
    })
  ),
};

export default React.memo(OrderDetails);
