import React from 'react';

import Text from '../Text';

import WarningIcon from '../../../icons/error2.svg';

import './ValidationError.scss';

function ValidationError(props) {
  return (
    <div styleName="container">
      <WarningIcon styleName="icon" />
      <Text styleName="text">
        {props.children}
      </Text>
    </div>
  );
}

export default React.memo(ValidationError);