export default {
  white: '#ffffff',
  snow: '#f8f8f8',
  smoke: '#f1f1f1',
  isabeline: '#ececec',
  silver: '#c5c7c7',
  grey: '#8b9294',
  lightGrey: '#e5e5e5',
  darkGray: '#3d4345',
  skyBlue: '#00b1e1',
  lighterSkyBlueTwo: '#d0f0f9',
  darkRed: '#cc2944',
};
