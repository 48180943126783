export function isValidEmail (email) {
  return !!email.match(/(.+)@(.+){2,}\.(.+){2,}/) ;
}

// Returns object with validation information:
// { 'aa@bb.com': true, 'fff': false, 'aa@': false }
export function validateEmails(emails) {
  if (emails.trim() === '') {
    return {};
  }

  const emailList = emails.split(/,| /).map(email => email.trim()).filter(email => email !== '');
  return emailList.reduce((res, email) => ({...res, [email]: isValidEmail(email)}), {});
}