import React from 'react';

import Loader from '../common/Loader';

import './ParcelFinderLoaders.scss';

function ParcelFinderLoader() {
  return (
    <div styleName="container">
      <Loader />
    </div>
  );
}

export default ParcelFinderLoader;