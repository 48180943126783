import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';
import { normalizeDataArrayWithLocalization } from '../models/touchpoints';
import { dictById } from '../utils/dictById';

const initialState = {
  logicByShop: {},
  localizationsByShop: {},
};

const sendingLogicReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'SENDING_LOGIC')) {
      return requestProducer(draft, action, 'SENDING_LOGIC', {
        successProducer: (draft, action) => {
          const { shopId } = action.data;

          // it is much more easier to work with this data when localization is normalized into separated object
          const { localizations, data } = normalizeDataArrayWithLocalization(action.data);

          draft.logicByShop[shopId] = data;
          draft.localizationsByShop[shopId] = Object.values(localizations);
        },
      });
    }

    return draft;
  });

export default sendingLogicReducer;
