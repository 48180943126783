function translateOption(option, t) {
  return {
    ...option,
    label: t(option.label),
  };
}

export function translateOptions(options, t) {
  return options.map((option) => ({
    ...translateOption(option, t),
    options: option.options ? option.options.map((option) => translateOption(option, t)) : undefined,
  }));
}

export function getOptionForValue(value, options) {
  if (!value) {
    return null;
  }

  for (const option of options) {
    if (option.options) {
      const foundOption = getOptionForValue(value, option.options);

      if (foundOption) {
        return foundOption;
      }
    }

    if (option.value == value) {
      return option;
    }
  }
}
