import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../common/Input/index';
import URLInput from '../../common/URLInput';
import ImageSettings from './common/ImageSettings';

class ProductItemSettings extends React.PureComponent {
  onNameChange = (e) => {
    this.props.onNameChange(e.target.value);
  };

  onPriceChange = (e) => {
    this.props.onPriceChange(e.target.value);
  };

  onLinkChange = (e) => {
    this.props.onLinkChange(e.target.value);
  };

  render() {
    const {
      name,
      price,
      imageUrl,
      mobileImageUrl,
      link,
      onImageChange,
    } = this.props;

    return (
      <React.Fragment>
        <Input label="Product title" value={name} onChange={this.onNameChange} />
        <Input  label="Price" value={price} onChange={this.onPriceChange}  />
        <ImageSettings
          imageUrl={imageUrl}
          mobileImageUrl={mobileImageUrl}
          label="Product image url"
          mobileLabel="Product mobile image url"
          onChange={onImageChange}
        />
        <URLInput
          label="Link/URL"
          placeholder="Type a product link URL"
          value={link}
          onChange={this.onLinkChange}
        />
      </React.Fragment>
    );
  }
}

ProductItemSettings.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  imageUrl: PropTypes.string,
  mobileImageUrl: PropTypes.string,
  link: PropTypes.string,
  onNameChange: PropTypes.func,
  onPriceChange: PropTypes.func,
  onLinkChange: PropTypes.func,
  onImageChange: PropTypes.func,
};

export default ProductItemSettings;