import React from 'react';

import PageWithTitle from '../PageWithTitle';
import Button from '../../common/Button';
import Pagination from '../../common/Pagination';

import './WizardStep.scss';

class WizardStep extends React.PureComponent {

  render() {
    const {
      header,
      subheader,
      tooltipText,
      icon: Icon,
      buttonText,
      onSubmit,
      disabled,
      paginationTitle,
      page,
      pagesCount,
      children,
    } = this.props;

    return (
      <PageWithTitle
        title={header}
        secondaryText={subheader}
        tooltipText={tooltipText}
      >
        <Icon styleName="icon" />
        <div styleName="fields">
          {children}
        </div>
        <div styleName="button">
          <Button primary onClick={onSubmit} disabled={disabled}>
            {buttonText}
          </Button>
        </div>
        <div styleName="pagination">
          <Pagination
            title={paginationTitle || header}
            page={page}
            pagesCount={pagesCount}
          />
        </div>
      </PageWithTitle>
    );
  }
}

export default WizardStep;