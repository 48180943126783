import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import './SortableWidgetsList.scss';

class SortableWidgetsContainer extends React.PureComponent {
  render() {

    return <div styleName="sort-cursor">{this.props.children}</div>;
  }
}

SortableWidgetsContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default SortableContainer(SortableWidgetsContainer);