import React from 'react';
import PropTypes from 'prop-types';

import useOrderNotifications from '../OrderDetailsPage/hooks/useOrderNotifications';
import NotificationLog from './NotificationLog';

function OrderNotificationsLog(props) {
  const { orderId, shopId } = props;
  const notificationsState = useOrderNotifications(shopId, orderId);

  return <NotificationLog {...notificationsState} />;
}

OrderNotificationsLog.propTypes = {
  orderId: PropTypes.number.isRequired,
  shopId: PropTypes.number.isRequired,
};

export default React.memo(OrderNotificationsLog);
