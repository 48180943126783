import React from 'react';
import RichLocale from '../../common2/RichLocale/RichLocale';

import './StartGuideContextWithImage.scss';

function StartGuideContextWithImage(props) {
  const { text, imageUrl, imageAlt } = props;

  return (
    <div styleName="section">
      <RichLocale>{text}</RichLocale>
      <img styleName="image" src={imageUrl} alt={imageAlt} />
    </div>
  );
}

export default React.memo(StartGuideContextWithImage);
