import React from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';
import { BackButton, CloseButton } from './NavigationButton';

import './Wizard.scss';

const Wizard = (props) => {
  const {
    children,
    selected,
    onBack,
    onClose,
  } = props;

  return (
    <div styleName="wizard">
      <div styleName="header">
        { onBack &&
          <div styleName="button back" key="back">
            <BackButton onClick={onBack} />
          </div>
        }
        { onClose &&
          <div styleName="button close" key="close">
            <CloseButton onClick={onClose} />
          </div>
        }
      </div>
      <div styleName="content">
        { selected ? <Switch selected={selected}>{children}</Switch> : children }
      </div>
    </div>
  );
};

Wizard.propTypes = {
  selected: PropTypes.string, // selected wizard page
  onClose: PropTypes.func,
  onBack: PropTypes.func
};

export default Wizard;