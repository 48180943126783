import React from 'react';
import OptionWithSmallBadge from './OptionWithSmallBadge';

function Value({ badgeText, label }) {
  return (
    <OptionWithSmallBadge badgeText={badgeText} inline={true}>
      {label}
    </OptionWithSmallBadge>
  );
}

export default React.memo(Value);
