import { useMemo } from 'react';

/**
 * @typedef Result
 * @property {boolean} isNon7sCarrier
 * @property {Array} carrierOptions
 * @property {Array} carrierCountryOptions
 */

/**
 * @param {string} [carrierCode]
 * @param {string} [carrierCountryCode]
 * @param {Array<ShopClaimCarrier>} carriers
 * @return {Result} result
 */
export function useClaimCarrierSelectOptions({ carrierCode, carrierCountryCode, carriers }) {
  const carrierOptions = useMemo(
    () => getUniqueCarriers(carriers).map(({ name, code }) => ({ label: name || code, value: code })),
    [carriers]
  );

  const carrierCountryOptions = useMemo(() => getCarrierCountryOptions(carrierCode, carriers), [carriers, carrierCode]);

  const carrier = useMemo(
    () => carriers.find(({ code, countryCode }) => code === carrierCode && countryCode === carrierCountryCode),
    [carriers, carrierCode, carrierCountryCode]
  );

  return {
    isNon7sCarrier: carrier?.isNon7s || null,
    carrierOptions,
    carrierCountryOptions,
  };
}

function getUniqueCarriers(carriers) {
  return [...new Map(carriers.map((carrier) => [carrier.code, carrier])).values()];
}

function getCarrierCountryOptions(carrierCode, carriers) {
  return carriers
    .filter(({ code }) => code === carrierCode)
    .map((carrier) => ({ label: carrier.countryName, value: carrier.countryCode }));
}
