import React from 'react';

import MessageWithIcon from './MessageWithIcon';

function ParcelFinderStart() {
  return (
    <React.Fragment>
      <MessageWithIcon>PARCEL_FINDER_START_MESSAGE_1</MessageWithIcon>
      <MessageWithIcon>PARCEL_FINDER_START_MESSAGE_2</MessageWithIcon>
    </React.Fragment>
  );
}

export default ParcelFinderStart;
