import React from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import Locale from '../../Translate/Locale';
import { eventTypes } from '../contentLibraryHelper';
import { getWidgetPreviewUrl } from '../../../utils/previewHelper';

import './WidgetPartPreview.scss';

class WidgetPartPreview extends React.PureComponent {
  onRef = (ref) => {
    if (!ref) {
      this.widget && this.widget.unmount();
      return;
    }

    this.widget = this.props.SendwiseEmbeddedWidget.newWidgetPart(ref, this.props);
  };

  render() {
    if (!this.props.SendwiseEmbeddedWidget) {
      return null;
    }

    this.widget && this.widget.setProps(this.props);

    return (
      <div styleName="preview">
        <div styleName="mobile-badge">
          <Locale>Mobile preview</Locale>
        </div>
        <div styleName="wrapper">
          <div ref={this.onRef} />
        </div>
      </div>
    );
  }
}

WidgetPartPreview.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(eventTypes),
};

export default makeAsyncScriptLoader(
  getWidgetPreviewUrl,
  { globalName: 'SendwiseEmbeddedWidget' }
)(WidgetPartPreview);