export function isGraphQLError(graphQLErrors = [], matchCode, matchMessage) {
  for (const error of graphQLErrors) {
    const isMatchingCode = getErrorCode(error) === matchCode;
    const isMatchingMessage = error.message === matchMessage;
    if (isMatchingCode && isMatchingMessage) {
      return error;
    }
  }
}

function getErrorCode(error) {
  return error.extensions.code;
}
