import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select3';
import { withTranslation } from 'react-i18next';

import components from './components';
import { getStyles } from './styles';
import { getOptionForValue, translateOptions } from './utils';

import './BasicSelect.scss';

function BasicSelect(props) {
  const {
    name,
    placeholder,
    value,
    isInvalid,
    isSearchable = false,
    noOptionTranslation,
    menuPlacement,
    useMenuPortal,
    t,
    isDisabled,
    ariaLabel,
    onChange,
  } = props;

  const options = useMemo(() => {
    if (noOptionTranslation) {
      return props.options || [];
    }

    return translateOptions(props.options || [], t);
  }, [noOptionTranslation, props.options, t]);

  const onChangeHandler = useCallback(
    (option) => {
      if (onChange) {
        onChange(option.value, name);
      }
    },
    [onChange, name]
  );

  return (
    <ReactSelect
      name={name}
      aria-label={ariaLabel}
      value={getOptionForValue(value, options)}
      placeholder={t(placeholder)}
      options={options}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      menuPlacement={menuPlacement}
      menuPortalTarget={useMenuPortal ? document.body : null}
      onChange={onChangeHandler}
      styles={getStyles({ isInvalid })}
      components={components}
    />
  );
}

const ValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

BasicSelect.propTypes = {
  value: ValueType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: ValueType,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: ValueType,
          label: PropTypes.string,
        })
      ),
    })
  ),
  placeholder: PropTypes.string,
  large: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  noOptionTranslation: PropTypes.bool,
};

export default React.memo(withTranslation()(BasicSelect));
