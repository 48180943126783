import React from 'react';
import Input from './Input';
import { isUrlValid } from '../../utils/urlValidationHelper';

function isValidLogoFormat(url = '') {
  return !!url.match(/\.(jpg|png)$/);
}

class ImageURLInput extends React.PureComponent {
  render() {
    return (
      <Input
        {...this.props}
        error={!isUrlValid(this.props.value || '') && 'The url provided is invalid'}
        warning={this.props.value && !isValidLogoFormat(this.props.value || '') && 'NOTIFICATIONS_LOGO_FORMAT_WARNING'}
      />
    );
  }
}

export default ImageURLInput;
