import React from 'react';
import ClosableMessage from '../../common2/ClosableMessage';
import Locale from '../../Translate/Locale';

function TakeActionPanelError({ children }) {
  return (
    <div role="alert">
      <ClosableMessage intent="danger">
        <Locale>{children}</Locale>
      </ClosableMessage>
    </div>
  );
}

export default React.memo(TakeActionPanelError);
