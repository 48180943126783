import React from 'react';
import './SettingsList.scss';

class SettingsList extends React.PureComponent {
  render() {
    return (
      <div styleName="list">
        {this.props.children}
      </div>
    );
  }
}

export default SettingsList;
