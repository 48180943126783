import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MenuItem.scss';

function MenuItem({ children, intent = 'base', selected, onClick }) {
  return (
    <div styleName={classNames('item', intent, { selected })} onClick={onClick}>
      {children}
    </div>
  );
}

MenuItem.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  intent: PropTypes.oneOf(['base', 'danger']),
};

export default React.memo(MenuItem);
