import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectedShopIdSelector } from './selectors';

import container from '../container';
import { selectParcelFinderReturns } from '../selectors/parcelFinderReturnsSelectors';
import { isApiError } from '../api/Api';

const initialState = {
  order: null,
  carrierServiceId: undefined,
  carrierCode: undefined,
  labelsCount: 1,
  referenceNumber: null,
  returnShipments: [],
  isLoading: false,
};

class CreateReturnError extends Error {
  constructor(type) {
    super();
    this.name = 'CreateReturnError';
    this.type = type;
  }
}

export const createReturnShipments = createAsyncThunk(
  'parcelFinderReturns/createReturnShipments',
  (_, { getState, rejectWithValue }) => {
    const state = getState();
    const shopId = selectedShopIdSelector(state);
    const { labelsCount, referenceNumber, order, carrierServiceId, carrierCode } = selectParcelFinderReturns(state);

    return container.touchpointsApi
      .createReturnShipments(shopId, {
        count: labelsCount,
        referenceNumber,
        orderId: order.id,
        carrierServiceId,
        carrierCode,
      })
      .catch((error) => {
        const errorType = isApiError(error) && error.response.status === 409 ? 'conflict' : 'unknown';
        return rejectWithValue(new CreateReturnError(errorType));
      });
  }
);

const slice = createSlice({
  name: 'parcelFinderReturns',
  initialState,
  reducers: {
    initReturn(state, { payload: orders }) {
      state.order = orders;
    },
    cancelReturn() {
      return initialState;
    },
    setLabelsCount(state, { payload: count }) {
      state.labelsCount = count;

      if (count > 1) {
        state.referenceNumber = null;
      }
    },
    setReferenceNumber(state, { payload: referenceNumber }) {
      state.referenceNumber = referenceNumber;
    },
    setCarrierService(state, { payload: carrierService }) {
      state.carrierServiceId = carrierService.id;
      state.carrierCode = carrierService.carrierCode;
    },
  },
  extraReducers: {
    [createReturnShipments.pending](state) {
      state.isLoading = true;
    },
    [createReturnShipments.fulfilled](state, action) {
      state.isLoading = false;
      state.returnShipments = action.payload;
    },
    [createReturnShipments.rejected](state) {
      state.isLoading = false;
    },
  },
});

export const { initReturn, cancelReturn, setLabelsCount, setReferenceNumber, setCarrierService } = slice.actions;

export default slice.reducer;
