import React from 'react';
import PropTypes from 'prop-types';

import groupBy from '../../../utils/groupBy';
import ListHeaderAccordion from '../../touchpoints/ListHeaderAccordion';
import ArchivedSeparator from '../../ArchivedSeparator/ArchivedSeparator';
import { filterActiveShopLocalizations, filterArchivedShopLocalizations } from '../../../utils/shopLocalizationsHelper';
import TrackingPagesLocalizationGroup from './TrackingPagesLocalizationGroup';

class TrackingPagesList extends React.PureComponent {
  render() {
    const { trackingPages, onMakeDefault, onDuplicate, onDelete, onRename, onLiveLinkClick } = this.props;

    const localizations = filterActiveShopLocalizations(this.props.shopLocalizations);
    const archivedLocalizations = filterArchivedShopLocalizations(this.props.shopLocalizations);

    const trackingPagesByLocalizationId = groupBy(trackingPages, (trp) => trp.localization.id);
    const localizationIds = localizations.map(({ id }) => id);

    return (
      <ListHeaderAccordion name="trackingPagesExpansionList" localizationIds={localizationIds}>
        {localizations.map((localization) => (
          <TrackingPagesLocalizationGroup
            key={localization.id}
            localization={localization}
            trackingPages={trackingPagesByLocalizationId[localization.id]}
            onMakeDefault={onMakeDefault}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onRename={onRename}
            onLiveLinkClick={onLiveLinkClick}
          />
        ))}
        {archivedLocalizations.length > 0 && (
          <React.Fragment>
            <ArchivedSeparator>Archived tracking pages</ArchivedSeparator>
            {archivedLocalizations.map((localization) => (
              <TrackingPagesLocalizationGroup
                key={localization.id}
                localization={localization}
                trackingPages={trackingPagesByLocalizationId[localization.id]}
              />
            ))}
          </React.Fragment>
        )}
      </ListHeaderAccordion>
    );
  }
}

TrackingPagesList.defaultProps = {
  shopLocalizations: [],
  trackingPages: [],
};

TrackingPagesList.propTypes = {
  shopLocalizations: PropTypes.arrayOf(PropTypes.object),
  trackingPages: PropTypes.arrayOf(PropTypes.object),
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  onMakeDefault: PropTypes.func,
  onLiveLinkClick: PropTypes.func,
};

export default TrackingPagesList;
