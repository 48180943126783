import React from 'react';
import PropTypes from 'prop-types';

import './ResultCard.scss';

function ResultCard(props) {
  return (
    <div styleName="card">
      {props.children}
    </div>
  );
}

ResultCard.propTypes = {
  children: PropTypes.node,
};

export default ResultCard;