/**
 * @param {object} customerResponse
 * @param {string} language
 * @returns {string|null} The customer response text, or null if not found.
 */
export function getCustomerResponseText(customerResponse, language) {
  const { text, translations = {} } = customerResponse || {};

  if (text) return text;

  const translation = translations[language] || translations.en;
  return translation?.message ?? null;
}
