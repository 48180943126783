import React from 'react';
import Locale from '../../Translate/Locale';

import './ReturnBadge.scss';

function ReturnBadge() {
  return (
    <div styleName="badge">
      <Locale>Return shipment</Locale>
    </div>
  );
}

export default ReturnBadge;
