import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from '../../common/DropdownButton';
import EditIcon from '../../../icons/edit-field.svg';

import './LocalizationEditButtons.scss';

function LocalizationEditButtons({ onClick = () => {}, children }) {
  return (
    <div styleName="group">
      <button styleName="edit-button" onClick={onClick}>
        <EditIcon styleName="edit-icon" />
      </button>
      <DropdownButton>{children}</DropdownButton>
    </div>
  );
}

LocalizationEditButtons.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default LocalizationEditButtons;
