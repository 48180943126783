import React from 'react';
import PropTypes from 'prop-types';

import ListWithCollapsibleLocalizationHeader from '../../ListWithLocalizationHeader/ListWithCollapsibleLocalizationHeader';
import Locale from '../../Translate/Locale';
import WidgetsListTracking from '../../Widget/WidgetsListTracking';
import TrackingPagesListItem from './TrackingPagesListItem';
import { sortWidgets } from '../../../utils/sortWidgets';

function TrackingPagesLocalizationGroup(props) {
  const { trackingPages, localization, onMakeDefault, onDelete, onDuplicate, onRename, onLiveLinkClick } = props;

  if (!trackingPages) {
    return null;
  }

  return (
    <ListWithCollapsibleLocalizationHeader
      key={localization.id}
      {...localization}
      renderElements={getRenderCountElement(localization, trackingPages)}
    >
      <WidgetsListTracking>
        {sortWidgets(trackingPages).map((item) => (
          <TrackingPagesListItem
            key={item.id}
            trackingPage={item}
            onMakeDefault={() => onMakeDefault(item.id)}
            onDelete={() => onDelete(item)}
            onDuplicate={() => onDuplicate(item)}
            onRename={() => onRename(item)}
            onLiveLinkClick={() => onLiveLinkClick(item)}
          />
        ))}
      </WidgetsListTracking>
    </ListWithCollapsibleLocalizationHeader>
  );
}

function getRenderCountElement(localization, trackingPages) {
  if (!localization.archived) {
    return null;
  }

  return () => <Locale options={{ count: trackingPages.length }}>{'{{count}} tracking page(s)'}</Locale>;
}

TrackingPagesLocalizationGroup.defaultProps = {
  onMakeDefault: () => {},
  onDuplicate: () => {},
  onDelete: () => {},
  onRename: () => {},
  onLiveLinkClick: () => {},
};

TrackingPagesLocalizationGroup.propTypes = {
  trackingPages: PropTypes.arrayOf(PropTypes.object),
  localizations: PropTypes.arrayOf(PropTypes.object),
  onMakeDefault: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onRename: PropTypes.func,
  onLiveLinkClick: PropTypes.func,
};

export default React.memo(TrackingPagesLocalizationGroup);
