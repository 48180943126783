const initialState = {
  localization: {},
  isDrawerOpen: false,
  reFetch: false,
};

function shopLocalizationFormReducer(state = initialState, action = {}) {
  if (action.type === 'SET_SHOP_LOCALIZATION') {
    const { data } = action;

    return {
      ...state,
      localization: data,
      isDrawerOpen: true,
    };
  }

  if (action.type === 'SET_IS_DRAWER_OPEN') {
    const { data } = action;
    if (!data) {
      return initialState;
    }

    return {
      ...state,
      isDrawerOpen: data,
    };
  }

  if (
    action.type === 'CREATE_SHOP_LOCALIZATION_SUCCESS' ||
    action.type === 'RESTORE_SHOP_LOCALIZATION_SUCCESS' ||
    action.type === 'EDIT_SHOP_LOCALIZATION_SUCCESS'
  ) {
    return {
      ...initialState,
      reFetch: true,
    };
  }

  if (action.type === 'SHOP_LOCALIZATIONS_SUCCESS') {
    return initialState;
  }

  return state;
}

export default shopLocalizationFormReducer;
