import container from '../container';

export function getLegacyTrackingPage(shopId) {
  return withApiActions(
    api => api.getLegacyTrackingPage(shopId),
    shopId
  )
}

export function removeLegacyTrackingPage(shopId) {
  return withApiActions(
    api => {
      return api.removeLegacyTrackingPage(shopId)
        .then(() => api.getLegacyTrackingPage(shopId));
    },
    shopId
  )
}

export function makeDefaultLegacyTrackingPage(shopId) {
  return withApiActions(
    api => api.updateLegacyTrackingPage(shopId, {default: true}),
    shopId
  )
}

function withApiActions(fetchApi, shopId) {
  return (dispatch) => {
    dispatch(legacyTrackingPageRequest(shopId));

    return fetchApi(container.api)
      .then(data => dispatch(legacyTrackingPageSuccess(shopId, data)))
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch(legacyTrackingPageNotFound(shopId));
        } else {
          dispatch(legacyTrackingPagError(shopId, error));
        }
      });
  };
}

function legacyTrackingPageRequest(shopId) {
  return { type: 'LEGACY_TRACKING_PAGE_REQUEST', shopId };
}

function legacyTrackingPageSuccess(shopId, data) {
  return { type: 'LEGACY_TRACKING_PAGE_SUCCESS', shopId, data };
}

function legacyTrackingPageNotFound(shopId) {
  return { type: 'LEGACY_TRACKING_PAGE_NOT_FOUND', shopId };
}

function legacyTrackingPagError(shopId, error) {
  return { type: 'LEGACY_TRACKING_PAGE_ERROR', shopId, error };
}