import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../SettingsGroupCard';
import FontSelect from './FontSelect';
import FontSizeSelect from './FontSizeSelect';

class TypographySettings extends React.PureComponent {
  onChange = (option , name) => {
    this.props.onSettingsChange({
      [name]: option.value,
    });
  };

  renderFontSettings(fontKey, sizeKey, fontSizes, settings) {
    const font = settings[fontKey];
    const size = settings[sizeKey];

    return (
      <React.Fragment>
        <FontSelect
          name={fontKey}
          allowGoogleFonts={this.props.allowGoogleFonts}
          value={font}
          onChange={this.onChange}
        />
        <FontSizeSelect
          name={sizeKey}
          sizes={fontSizes}
          value={size}
          onChange={this.onChange}
        />
      </React.Fragment>
    );
  }

  render() {
    const {
      groups,
      defaultSettings,
    } = this.props;

    const settings = {
      ...defaultSettings,
      ...this.props.settings,
    };

    return (
      <React.Fragment>
        {
          groups.map(({ title, subtitle, settings: { fontKey, sizeKey, fontSizes } }) => (
            <SettingsGroupCard key={title} title={title} subtitle={subtitle}>
              { this.renderFontSettings(fontKey, sizeKey, fontSizes, settings) }
            </SettingsGroupCard>
          ))
        }
      </React.Fragment>
    );
  }
}

TypographySettings.defaultProps = {
  defaultSettings: {},
  allowGoogleFonts: true,
};

TypographySettings.propTypes = {
  defaultSettings: PropTypes.object,
  settings: PropTypes.object,
  onSettingsChange: PropTypes.func,
  allowGoogleFonts: PropTypes.bool,
};

export default TypographySettings;
