import { normalizeUserInfo } from '../models/userInfo';

function getInitialState() {
  return {
    entities: {},
    selectedShopId: getSelectedShopIdFromLocalStorage(),
  };
}

export default function userReduser(state = getInitialState(), action) {
  switch (action.type) {
    case 'FETCH_ME_REQUEST':

      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_ME_SUCCESS':
      const { result: info, entities } = normalizeUserInfo(action.data);
      
      return {
        ...state,
        loading: false,
        loaded: true,
        info,
        entities,
        selectedShopId: getSelectedShopId(state, info),
      };
    case 'FETCH_ME_ERROR':
      // in case of LOGOUT during FETCH_ME_REQUEST we don't want to apply an error
      if (state.loading) {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

    case 'SELECT_SHOP':
      
      return {
        ...state,
        selectedShopId: action.shopId,
      };
    case 'LOGOUT':
    
      return {
        entities: {},
        selectedShopId: null,
      };
  }

  return state;
}

function getSelectedShopId(state, userInfo) {
  if (!userInfo || userInfo.shops.length === 0) {
    return null;
  }

  if (!state.selectedShopId || !userInfo.shops.includes(state.selectedShopId)) {
    return userInfo.shops[0];
  }

  return state.selectedShopId;
}

function getSelectedShopIdFromLocalStorage() {
  const selectedShopId = localStorage.getItem('selectedShopId');  

  if (selectedShopId && !isNaN(parseInt(selectedShopId))) {
    return +selectedShopId;
  }

  return null;
}