import React from 'react';
import Notification from './Notification';
import notificationFactory from './notificationFactory';
import { withTranslation } from 'react-i18next';

function withNotifications(options) {
  return (WrappedComponent) => {
    class NotificationWrapper extends React.Component {
      componentDidMount() {
        this.notification = notificationFactory(options);
      }

      componentWillUnmount() {
        this.notification.destroy();
      }

      showNotice = ({ type, message, localeOptions }) => {
        this.notification.notice(
          <Notification type={type}>{this.props.i18n.t(message, { ...localeOptions })}</Notification>
        );
      };

      showError = (message = 'Something went wrong') => {
        this.showNotice({
          type: 'error',
          message: String(message),
        });
      };

      resolveWithNotice = (promise, message, localeOptions) => {
        return promise
          .then(() => {
            this.showNotice({ message, localeOptions, type: 'success' });
            return { success: true };
          })
          .catch(() => {
            this.showError();
            return { error: true };
          });
      };

      render() {
        return (
          <WrappedComponent
            showNotice={this.showNotice}
            showError={this.showError}
            resolveWithNotice={this.resolveWithNotice}
            {...this.props}
          />
        );
      }
    }

    return withTranslation()(NotificationWrapper);
  };
}

export default withNotifications;
