/**
 * Sort by desired field. The sorting order is newest to oldest (ie. descending order).
 *
 * @param {array} arr The array to be sorted.
 * @param {function(*): *} fieldAccessors The array of function that return fields to be sorted.
 * @param {object} options, if you want ascending order set asc to true
 * @returns {array} A sorted array.
 */
export function sortByField(arr, fieldAccessors, options = {}) {
  const {
    asc = false,
  } = options;

  return arr.sort((first, second) => {
    const pairs = fieldAccessors.map(
      valueExtractor => ([
        valueExtractor(first),
        valueExtractor(second),
      ])
    );

    return asc ? -compare(pairs) : compare(pairs);
  });
}

function compare(pairs) {
  for (const item of pairs) {
    if (item[0] > item[1]) {
      return -1;
    }

    if (item[0] < item[1]) {
      return 1;
    }
  }

  return 0;
}