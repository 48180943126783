import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import container from '../container';

const initialState = {
  isLoading: false,
  loaded: false,
  error: null,
  carriers: [],
};

export const getCarriers = createAsyncThunk('core/getCarriers', () => container.touchpointsApi.getCarriers());

export const carriersSelector = (state) => state.carriers;

const slice = createSlice({
  name: 'carriers',
  initialState,
  extraReducers: {
    [getCarriers.pending](state) {
      state.isLoading = true;
      state.error = null;
    },
    [getCarriers.fulfilled](state, action) {
      state.carriers = action.payload;
      state.isLoading = false;
      state.loaded = true;
    },
    [getCarriers.rejected](state, action) {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export default slice.reducer;
