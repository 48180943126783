import React from 'react';
import PropTypes from 'prop-types';
import ClaimsTable from './ClaimsTable/ClaimsTable';
import ClaimsTableHeader from './ClaimsTable/ClaimsTableHeader';
import ClaimsTableRow from './ClaimsTable/ClaimsTableRow';

import './ClaimsCenterResults.scss';

function ClaimsCenterResults(props) {
  const { claims, onTakeActionButtonClick, onCancelClaimButtonClick } = props;

  return (
    <div styleName="results">
      <ClaimsTable>
        <ClaimsTableHeader />
        <tbody>
          {claims.map((claim) => (
            <ClaimsTableRow
              key={claim.id}
              claim={claim}
              onTakeActionButtonClick={onTakeActionButtonClick}
              onCancelClaimButtonClick={onCancelClaimButtonClick}
            />
          ))}
        </tbody>
      </ClaimsTable>
    </div>
  );
}

ClaimsCenterResults.propTypes = {
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      claimType: PropTypes.string,
      netValue: PropTypes.number,
      createdAt: PropTypes.string,
      trackingCode: PropTypes.string,
      shipmentId: PropTypes.number,
      netValueCurrency: PropTypes.string,
    })
  ),
  onTakeActionButtonClick: PropTypes.func,
};

export default React.memo(ClaimsCenterResults);
