import React from 'react';

import './ModalContent.scss';

function ModalContent({children}) {
  return (
    <div styleName="main-section">
      {children}
    </div>
  );
}

export default React.memo(ModalContent);