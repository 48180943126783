import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Overlay from './Overlay';

import './Dropdown.scss';

const POSITION_STYLES = {
  'bottomRight': ['bottom', 'right'],
  'topRight': ['top', 'right'],
  'bottomLeft': ['bottom', 'left'],
  'topLeft': ['top', 'left'],
};

export default class Dropdown extends React.PureComponent {
  state = {
    x: 0,
    y: 0,
  };

  onTargetRef = (ref) => this.targetRef = ref;

  onPanelRef = (ref) => {
    this.panelRef = ref;
    this.props.show && this.updatePosition();
  };

  componentWillReceiveProps(nextProps) {
    nextProps.show && this.updatePosition();
  }

  getPositions() {
    const [verticalPosition, horizontalPosition] = POSITION_STYLES[this.props.position];
    const { offsetHeight } = document.body;
    const { bottom } = this.targetRef.getBoundingClientRect();
    const { height: panelHeight } = this.panelRef.getBoundingClientRect();

    if (bottom + panelHeight >= offsetHeight) {
      return ['top', horizontalPosition];
    }

    return [verticalPosition, horizontalPosition];
  }

  updatePosition() {
    if (!this.targetRef || !this.panelRef) {
      return;
    }

    const [verticalPosition, horizontalPosition] = this.getPositions();
    const { bottom, top, right, left } = this.targetRef.getBoundingClientRect();

    const x = horizontalPosition === 'left' ? right : left;
    const y = verticalPosition === 'bottom' ? bottom : top;

    this.setState({
      x,
      y,
      verticalPosition,
      horizontalPosition,
    });
  }

  renderMenu() {
    const {
      x,
      y,
      verticalPosition,
      horizontalPosition,
    } = this.state;

    const panelStyleName = classNames('dropdownPanel', verticalPosition, horizontalPosition);

    return (
      <div styleName="dropdownPoint" style={{left: x, top: y}}>
        <div styleName={panelStyleName} ref={this.onPanelRef}>
          {this.props.content}
          <Overlay onClick={this.props.onClose} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div styleName="dropdownTargetContainer" ref={this.onTargetRef}>
        {this.props.children}
        {this.props.show && this.renderMenu()}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  position: 'bottomLeft',
};

Dropdown.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.oneOf(Object.keys(POSITION_STYLES)),
};
