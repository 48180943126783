import { useSelector } from 'react-redux';
import { getSelectedShop, selectedShopIdSelector } from '../reducers/selectors';

function useSelectedShop() {
  return useSelector((state) => getSelectedShop(state));
}

export function useSelectedShopId() {
  return useSelector((state) => selectedShopIdSelector(state));
}

export default useSelectedShop;
