import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

function Icon(props) {
  const { icon: WrappedIcon, label, intent = 'info', size = 48 } = props;
  const iconSize = size / 2;

  return (
    <div styleName={classNames('container', intent)} style={{ width: size, height: size }}>
      <WrappedIcon aria-label={label} style={{ width: iconSize, height: iconSize }} />
    </div>
  );
}

Icon.propTypes = {
  icon: PropTypes.elementType,
  label: PropTypes.string,
  size: PropTypes.number,
  intent: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
};

export default React.memo(Icon);
