import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../SettingsGroupCard';
import LogoSettings from './common/LogoSettings';
import SiteLinksSettings from './common/SiteLinksSettings';

class HeaderWidgetSettings extends React.PureComponent {
  render() {
    const {
      settings,
      onSettingsChange,
    } = this.props;
    return (
      <React.Fragment>
        <SettingsGroupCard
          title="Logo"
          subtitle="Upload your own logo and set an alignment"
        >
          <LogoSettings settings={settings} onChange={onSettingsChange} />
        </SettingsGroupCard>
        <SiteLinksSettings settings={settings} onChange={onSettingsChange} />
      </React.Fragment>
    );
  }
}

HeaderWidgetSettings.defaultProps = {
  onSettingsChange: () => {},
};

HeaderWidgetSettings.propTypes = {
  settings: PropTypes.object,
  onSettingsChange: PropTypes.func,
};

export default HeaderWidgetSettings;
