import React from 'react';

import Row from '../../../common/Row/Row';

import './SelectorWrapper.scss';

function SelectorWrapper(props) {
  return (
    <div styleName="container">
      <Row spacing={24}>{props.children}</Row>
    </div>
  );
}

export default React.memo(SelectorWrapper);
