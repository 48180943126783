import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Picture.scss';

function Picture(props) {
  const { alt, onClick = () => {}, fallback, children } = props;

  return (
    <picture onClick={onClick} styleName={classNames('picture', { 'stretch-horizontally': props.stretchHorizontally })}>
      {children}
      <img styleName="image" src={fallback} alt={alt} />
    </picture>
  );
}

Picture.propTypes = {
  onClick: PropTypes.func,
  alt: PropTypes.string,
  fallback: PropTypes.string,
};

export default React.memo(Picture);
