import React from 'react';
import PropTypes from 'prop-types';

import Heading2 from '../common/Heading2';
import Link from '../common/Link';
import Locale from '../Translate/Locale';
import LeftIcon from '../../icons/left.svg';

import './SettingsNavigationHeader.scss';

class SettingsNavigationHeader extends React.PureComponent {
  render() {
    const {
      title,
      backLink,
      backTitle,
      noBack,
      children,
    } = this.props;

    return (
      <div styleName="header">
        { !noBack &&
          <Link to={backLink}>
            <div styleName="back">
              <LeftIcon styleName="icon" />
              <Locale>{backTitle}</Locale>
            </div>
          </Link>
        }
        <div styleName="content">
          <Heading2 styleName="title">{title}</Heading2>
          { children && <div styleName="children">{children}</div> }
        </div>
      </div>
    );
  }
}

SettingsNavigationHeader.defaultProps = {
  backLink: '/settings',
  backTitle: 'Settings',
};

SettingsNavigationHeader.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
  backTitle: PropTypes.string,
  noBack: PropTypes.bool,
  children: PropTypes.element,
};

export default SettingsNavigationHeader;