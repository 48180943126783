import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../../Translate/Locale';

import './Table.scss';

class Table extends React.PureComponent {
  render() {
    const {
      columns,
      styles,
      children,
    } = this.props;

    const header = columns.map((element, i) => (
      <th key={i}>
        {
          (typeof element === 'string') ? <Locale>{element}</Locale> : element
        }
      </th>
    ));

    return (
      <table styleName="table">
        <thead>
          <tr>{header}</tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    );
  }
}

Table.defaultProps = {
  styles: {},
};

Table.propTypes = {
  styles: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.node),
};

export default Table;