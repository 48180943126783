import React, { useCallback } from 'react';

import Select from '../../../common2/Select';
import useOnFormChange from '../../../../hooks/useOnFormChange';

function CarrierSelect(props) {
  const {
    carrierCode,
    carrierCountryCode,
    carrierOptions,
    carrierCountryOptions,
    errors = {},
    isDisabled,
    onChange,
  } = props;

  const onCarrierCodeChange = useCallback((value, name) => onChange({ [name]: value, carrierCountryCode: null }), [
    onChange,
  ]);

  const onCarrierCountryCodeChange = useOnFormChange(onChange);

  return (
    <>
      <Select
        name="carrierCode"
        label="Carrier"
        placeholder="Select carrier"
        isRequired
        isSearchable
        noOptionTranslation
        value={carrierCode}
        options={carrierOptions}
        error={errors.carrierCode}
        noErrorTranslation
        isDisabled={isDisabled || carrierOptions.length < 1}
        onChange={onCarrierCodeChange}
      />
      <Select
        name="carrierCountryCode"
        label="Carrier country"
        placeholder="Select country"
        isRequired
        isSearchable
        noOptionTranslation
        value={carrierCountryCode}
        options={carrierCountryOptions}
        error={errors.carrierCountryCode}
        noErrorTranslation
        isDisabled={isDisabled || !carrierCode}
        onChange={onCarrierCountryCodeChange}
      />
    </>
  );
}

export default React.memo(CarrierSelect);
