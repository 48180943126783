import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../common/Table';
import ContentLibraryMessagesListItem from './ContentLibraryMessagesListItem';
import { getMessageEditUrl, messageShape } from '../contentLibraryHelper';

import './ContentLibraryMessagesList.scss';

const ContentLibraryMessagesList = ({ data, titleColumnName }) => {
  const columns = ['', titleColumnName, 'Message', '', ''];

  return (
    <div styleName="list">
      <Table columns={columns}>
        {data.map(message => (
          <ContentLibraryMessagesListItem key={message.id} message={message} />
        ))}
      </Table>
    </div>
  );
};

ContentLibraryMessagesList.defaultProps = {
  titleColumnName: 'Event',
  data: [],
};

ContentLibraryMessagesList.propTypes = {
  titleColumnName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape(messageShape)),
};

export default ContentLibraryMessagesList;
