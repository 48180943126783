import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../SettingsGroupCard';
import ColorButton from '../../common/ColorButton';
import { getDefaultColors } from '../../editors/defaultColors';
import Locale from '../../Translate/Locale';
import { withEditorConfig } from '../../editors/EditorContext';

import styles from './ColorSettings.scss';
import { getWidgetTypes } from '../../editors/widgetsHelper';
import { filterColorGroupsByWidgetTypes } from './colorSettingsHelper';

class ColorsSettings extends React.PureComponent {
  onChange = (color, name) => {
    this.props.onSettingsChange({ [name]: color });
  };

  getColorGroups() {
    const {
      widgets,
      editorConfig: {
        colorGroups,
        defaultSettings,
      },
    } = this.props;

    const widgetTypes = getWidgetTypes(widgets, defaultSettings);

    return filterColorGroupsByWidgetTypes(colorGroups, widgetTypes);
  }

  render() {
    const settings = {
      ...getDefaultColors(this.props.editorType),
      ...this.props.settings,
    };

    const colorGroups = this.getColorGroups();

    return (
      <React.Fragment>
        {
          colorGroups.map(({ title, subtitle, settings: groupSettings }) => (
            <SettingsGroupCard
              key={title}
              title={title}
              subtitle={subtitle}
              compact
            >
              { groupSettings.map(({ key, label }) => (
                <div key={key} className={styles['button-wrapper']}>
                  <ColorButton name={key} color={settings[key]} onChange={this.onChange} />
                  <div className={styles.label}>
                    <Locale>{label}</Locale>
                  </div>
                </div>
              )) }
            </SettingsGroupCard>
          ))
        }
      </React.Fragment>
    );
  }
}

ColorsSettings.defaultProps = {
  widgets: [],
};

ColorsSettings.propTypes = {
  onSettingsChange: PropTypes.func,
  settings: PropTypes.object,
  widgets: PropTypes.arrayOf(PropTypes.object),
  editorConfig: PropTypes.shape({
    colorGroups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        settings: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
  }),
};

export default withEditorConfig(ColorsSettings);
