import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import Text from '../common/Text';
import { getRoleName } from '../../firewall/roles';

import './ShopMenuItem.scss';

const getInitials = (shopName) => shopName.substring(0, 2).toUpperCase();

function displayRoles(roles, t) {
  if (roles.length === 0) {
    return t('User');
  }

  return roles
    .map((role) => getRoleName(role))
    .map((roleName) => t(roleName))
    .join(', ');
}

export function ShopMenuItem(props) {
  const { shopName = '', roles = [], selected, onClick, t } = props;

  const styles = classNames('shop-card', { selected });

  return (
    <div styleName={styles} onClick={onClick} data-test-id="shop-menu-item">
      <div styleName="circle">
        <div styleName="initials">{getInitials(shopName)}</div>
      </div>
      <div styleName="data" data-test-id="shop-data">
        <Text ink>{shopName}</Text>
        <Text small>{displayRoles(roles, t)}</Text>
      </div>
    </div>
  );
}

ShopMenuItem.propTypes = {
  onClick: PropTypes.func,
  shopName: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default withTranslation()(React.memo(ShopMenuItem));
