export function getParcelFinderResultsState(state) {
  const { isLoading, loaded } = state;

  const results = state.results || {};

  if (isLoading && !loaded) {
    return 'loader';
  }

  if (results.orders?.length) {
    return 'results';
  }

  if (loaded) {
    return 'no-results';
  }

  return 'start';
}
