import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  messages: [],
  selectedLanguage: 'en',
  filtersByType: {},
};

const contentLibraryReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'CONTENT_LIBRARY_MESSAGES')) {
    return requestProducer(draft, action, 'CONTENT_LIBRARY_MESSAGES', {
      successProducer: (draft, action) => {
        const {
          data,
          language
        } = action.data;

        draft.messages = data;
        draft.selectedLanguage = language;
      }
    });
  }

  if (action.type === 'CONTENT_LIBRARY_FILTER') {
    const {
      messageType,
      meaningType,
      filterText,
    } = action;

    draft.filtersByType[messageType] = {
      meaning: meaningType,
      text: filterText,
    };

    draft.selectedType = messageType;

    return draft;
  }

  return draft;
});

export default contentLibraryReducer;