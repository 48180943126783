import produce from 'immer';
import { isRequestAction, requestProducer } from './requestReducerHelper';
import storage from '../models/storage';

const initialState = {};

const successProducer = (draft, action) => setTokens(draft, action.data);

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    if (isRequestAction(action, 'LOGIN')) {
      return requestProducer(draft, action, 'LOGIN', { successProducer });
    }

    if (isRequestAction(action, 'SIGNUP')) {
      return requestProducer(draft, action, 'SIGNUP', { successProducer });
    }

    switch (action.type) {
      case 'INIT':
        return initTokens(draft);
      case 'REFRESH_TOKEN':
        return setTokens(draft, action.data);
      case 'LOGOUT':
        return initialState;
      case 'RESET_PASSWORD_REQUEST':
        return initialState;
    }
  });

function initTokens(draft) {
  const token = storage.getItem('token');

  draft.token = token;
  draft.refreshToken = storage.getItem('refreshToken');
}

function setTokens(draft, data) {
  const { token, refresh_token: refreshToken } = data;

  storage.setItem('token', token);
  storage.setItem('refreshToken', refreshToken);

  draft.token = token;
  draft.refreshToken = refreshToken;
}

export default authReducer;
