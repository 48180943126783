import React from 'react';
import BannerAccess from '../StartGuidePage/BannerAccess';

function ParcelFinderStartGuideBanner() {
  return (
    <BannerAccess
      heading="Start using Parcel Finder"
      description="PARCEL_FINDER_START_GUIDE_BANNER_DESC"
      url="/parcel-finder"
      cta="Discover Parcel Finder"
    />
  );
}

export default React.memo(ParcelFinderStartGuideBanner);
