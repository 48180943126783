import React from 'react';
import PropTypes from 'prop-types';

import './ClaimsHeaderContainer.scss';

function ClaimsHeaderContainer({ children }) {
  return <div styleName="header">{children}</div>;
}

ClaimsHeaderContainer.propTypes = {
  children: PropTypes.node,
};

export default React.memo(ClaimsHeaderContainer);
