const sections = ['branding', 'reasons', 'policy', 'confirmationEmail', 'keepItems', 'translations'];
const externalPortalSections = ['branding', 'reasons', 'policy', 'translations'];
const lightPortalSections = ['branding', 'policy', 'confirmationEmail', 'translations'];

export function getActiveSections(returnPortal) {
  if (returnPortal?.isLite) {
    return lightPortalSections;
  }

  if (returnPortal?.settings?.isExternalRedirectEnabled) {
    return externalPortalSections;
  }

  return sections;
}

export function getVisibleSections(returnPortal) {
  if (returnPortal?.isLite) {
    return lightPortalSections;
  }

  return sections;
}

export function getNextSectionId(section, returnPortal) {
  const sections = getActiveSections(returnPortal);
  return sections[sections.indexOf(section) + 1];
}
