import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import RemoveIcon from '../../../icons/remove.svg';

import './FileItem.scss';

function FileItem(props) {
  const { fileName, onRemove } = props;

  return (
    <div styleName="file-item" data-testid="file-item">
      <Text styleName="file-name" small>
        {fileName}
      </Text>
      <RemoveIcon data-testid="remove" styleName="close-icon" onClick={onRemove} />
    </div>
  );
}

FileItem.propTypes = {
  onRemove: PropTypes.func,
  file: PropTypes.object,
};

export default React.memo(FileItem);
