import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import LoginPage from '../login/LoginPage';
import RouterError from './RouterError';
import HomePage from '../homepage/HomePage';
import AuthRoot from '../../containers/AuthRoot';
import ForgotPasswordPage from '../forgotpassword/ForgotPasswordPage';
import ResetPasswordPage from '../resetpassword/ResetPasswordPage';
import UserSettingsPage from '../UserSettingsPage';

import AnalyticsRoutes from './AnalyticsRoutes';
import TrackingRoutes from './TrackingRoutes';
import SuspendedCustomerNotificationsRoutes from './SuspendedCustomerNotificationsRoutes';
import ShopSettingsRoutes from './ShopSettingsRoutes';
import ContentLibraryRoutes from './ContentLibraryRoutes';
import ParcelFinderRoutes from './ParcelFinderRoutes';
import UserManagementPage from '../settings/user-management/UserManagementPage';
import ReturnsRoutes from './ReturnsRoutes';
import CommsCenterRoutes from './CommsCenterRoutes';
import ChatbotRoutes from './ChatbotRoutes';
import PageViewsAnalyticsTracker from './PageViewsAnalyticsTracker';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<RouterError />}>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route element={<AuthenticatedRoutes />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/settings/user" element={<UserSettingsPage />} />
        <Route path="/settings/user-management" element={<UserManagementPage />} />
        <Route path="/settings/shop/*" element={<ShopSettingsRoutes />} />
        <Route path="/content-library/*" element={<ContentLibraryRoutes />} />
        <Route path="/analytics/*" element={<AnalyticsRoutes />} />
        <Route path="/customer-notifications/*" element={<SuspendedCustomerNotificationsRoutes />} />
        <Route path="/tracking/*" element={<TrackingRoutes />} />
        <Route path="/parcel-finder/*" element={<ParcelFinderRoutes />} />
        <Route path="/returns/*" element={<ReturnsRoutes />} />
        <Route path="/communication-center/*" element={<CommsCenterRoutes />} />
        <Route path="/chatbot/*" element={<ChatbotRoutes />} />
      </Route>
    </Route>
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function AuthenticatedRoutes() {
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);

  if (!token) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} />;
  }

  return (
    <AuthRoot>
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
    </AuthRoot>
  );
}

function Root() {
  return (
    <>
      <Outlet />
      <PageViewsAnalyticsTracker />
    </>
  );
}

function AppRoutes() {
  return <RouterProvider router={router} />;
}

export default AppRoutes;
