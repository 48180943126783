import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';

function useStartGuideTabs(tabIds) {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const selectedTabId = hash.substring(1);
  const defaultTabId = tabIds[0];

  useEffect(() => {
    if (!tabIds.includes(selectedTabId)) {
      navigate(`#${defaultTabId}`);
    }
  }, [selectedTabId]);

  const setAnchor = (newAnchor) => {
    navigate(`#${newAnchor}`);
  };

  return [selectedTabId, setAnchor];
}

export default useStartGuideTabs;
