import React from 'react';
import { Navigate } from 'react-router-dom';
import container from '../container';

export function withPermissionRedirect(firewall) {
  return (requiredAccess, redirectPath = '/') => (DecoratedComponent) => (props) => {
    if (firewall.hasAccess(requiredAccess)) {
      return <DecoratedComponent {...props} />;
    }

    return <Navigate to={redirectPath} />;
  };
}

export default withPermissionRedirect(container.firewall);
