import React from 'react';
import PropTypes from 'prop-types';

import BasicModal from '../../modal/BasicModal';
import Locale from '../../Translate';
import ModalButtonsContainer from '../../modal/ModalButtonsContainer';
import ModalTitle from '../../modal/ModalTitle';
import ModalBottom from '../../modal/ModalBottom';
import Button from '../../common/Button';
import ModalBlockquoteBody from '../../modal/ModalBlockquoteBody';
import { getTrackingPagePreviewUrl } from '../../../utils/trackingPageHelper';
import ExternalLink from '../../common/ExternalLink';

import './TrackingPageLinkModal.scss';

function TrackingPageLinkModal(props) {
  const { trackingPage, onOK } = props;

  if (!trackingPage) {
    return null;
  }

  return (
    <BasicModal show title="Tracking page link" onClose={onOK}>
      <ModalTitle>Tracking page link</ModalTitle>
      <ModalBlockquoteBody label="Your tracking page link:">
        {getTrackingPagePreviewUrl(trackingPage)}
      </ModalBlockquoteBody>
      <ModalBottom heading="What’s next:">
        <ul styleName="whats-next-list">
          <li key="replace">
            <Locale>LIVE_LINK_REPLACE_ORDER_ID_IN_URL</Locale>
          </li>
          <li key="placeholder">
            {/* because i18next will interpolate a {{TrackingPageUrl}} placeholder */}
            <Locale options={{ TrackingPageUrl: '{{TrackingPageUrl}}' }}>LIVE_LINK_PUT_TRP_PLACEHOLDER</Locale>
          </li>
        </ul>
        <div styleName="doc-link">
          <ExternalLink href="https://support.portal.sevensenders.com/support/solutions/articles/15000010426-designing-and-publishing-a-tracking-page">
            Read more info in our documentation
          </ExternalLink>
        </div>
        <ModalButtonsContainer align="right">
          <Button onClick={onOK} primary>
            Got it
          </Button>
        </ModalButtonsContainer>
      </ModalBottom>
    </BasicModal>
  );
}

PropTypes.propTypes = {
  trackingPage: PropTypes.object,
  onOK: PropTypes.func,
};

export default TrackingPageLinkModal;
