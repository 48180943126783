import React from 'react';

import Button from '../../common2/Button/Button';
import LiveIcon from '../../../icons/check-circle-filled.svg';

function TakeActionSubmitButton(props) {
  const { isLoading, onClick } = props;

  return (
    <Button intent="success" isLoading={isLoading} icon={LiveIcon} onClick={onClick}>
      Submit
    </Button>
  );
}

export default React.memo(TakeActionSubmitButton);
