import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { GraphQLJSONObject } from 'graphql-type-json';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import container from '../../container';
import logout from '../../actions/logout';
import { isGraphQLError } from './graphQLUtils';
import { ApiError } from '../../api/Api';

import typeDefs from './generatedSchema.gql';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const httpLink = new BatchHttpLink({
  uri: `${process.env.NOTIFICATIONS_API_URL}/graphql`,
  batchMax: 5,
  batchInterval: 20,
});
const authMiddleware = new ApolloLink((operation, forward) => {
  const authorization = `Bearer ${localStorage.getItem('token')}`;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization,
    },
  }));

  return forward(operation);
});

const errorLink = onError((error) => {
  const { store } = container;

  const { graphQLErrors, networkError } = error;

  if (networkError && networkError.statusCode === 401) {
    return store.dispatch(logout());
  }

  if (isGraphQLError(graphQLErrors, '403', 'FORBIDDEN')) {
    return store.dispatch({ type: 'API_ERROR', error: new ApiError({ status: 403, statusText: 'FORBIDDEN' }) });
  }
});

export const notificationsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authMiddleware, httpLink]),
  defaultOptions,
  typeDefs,
  resolvers: {
    JSONObject: GraphQLJSONObject,
  },
});
