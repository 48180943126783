import React from 'react';
import { components } from 'react-select3';

import SelectArrowsIcon from '../../../icons/select-arrows.svg';

export default {
  DropdownIndicator: () => <SelectArrowsIcon />,
  IndicatorSeparator: () => null, // hide indicator separator
  MenuList: (props) => (
    <components.MenuList {...props}>
      <div role="listbox">{props.children}</div>
    </components.MenuList>
  ),
};
