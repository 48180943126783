import React from 'react';
import PropTypes from 'prop-types';
import RightIcon from '../../../icons/right.svg';
import Locale from '../../Translate/Locale';

import './ExternalLink.scss';

/**
 * Use for external links only.
 * Because if there is no onClick handler
 * it will refresh entire page and all state will be lost.
 * @see InternalLink
 */
class ExternalLink extends React.Component {
  render() {
    const { children, ...props } = this.props;

    return (
      <a styleName="link" {...props}>
        <RightIcon styleName="icon" />
        <Locale>{children}</Locale>
      </a>
    );
  }
}

ExternalLink.defaultProps = {
  target: '_blank',
};

ExternalLink.propTypes = {
  children: PropTypes.string,
};

export default ExternalLink;
