import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import { InputLabel } from '../Input';

import 'react-select/dist/react-select.css';
import './ReactSelect.css';
import ArrowDown from '../../../icons/arrow-down.svg';

class Select extends React.PureComponent {
  constructor(props) {
    super(props);

    if (props.isOpen) {
      this.onRef = (select) => {
        if (select) {
          select.setState({ isOpen: true });
        }
      };
    }
  }

  onChange = (option) => {
    const { onChange } = this.props;
    onChange && onChange(option, this.props.name);
  };

  arrowRenderer = () => <ArrowDown />;

  render() {
    const {
      children,
      onChange, // unused  in render
      ...props
    } = this.props;

    const select = (
      <ReactSelect
        ref={this.onRef}
        {...props}
        onChange={this.onChange}
        clearable={false}
        arrowRenderer={this.arrowRenderer}
      />
    );

    return labelWrapper(children, select);
  }
}

function labelWrapper(label, select) {
  if (label) {
    return (
      <InputLabel label={label}>
        {select}
      </InputLabel>
    );
  }

  return select;
}

Select.defaultProps = {
  searchable: true,
};

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

Select.propTypes = {
  placeholder: PropTypes.string,
  value: valueType,
  name: PropTypes.string,
  searchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: valueType,
    label: PropTypes.string,
  })),
};

export default Select;