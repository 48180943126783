import React from 'react';

import './ClaimsTable.scss';

function ClaimsTable(props) {
  const { children } = props;
  return <table styleName="claims-table">{children}</table>;
}

export default ClaimsTable;
