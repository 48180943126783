import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';

import { selectAnalyticsDashboards } from '../../reducers/analyticsDashboardsReducer';
import * as permissions from '../../firewall/permissions';

/* eslint-disable camelcase */
const mapLookerIdsToReactRouterUrl = {
  order_status_overview: '/analytics/orders/status-overview',
  order_fulfillment_performance: '/analytics/orders/fulfillment-performance',
  order_list: '/analytics/orders/list',
  days_from_order_status_changed_outliers: '/analytics/orders/days-from-status-changed-outliers',
  returns_overview: '/analytics/shipments/returns-overview',
  claims_overview: '/analytics/claims/claims-overview',
  shipment_status_overview: '/analytics/shipments/status-overview',
  shipment_list: '/analytics/shipments/list',
  shipment_carrier_performance: '/analytics/shipments/carrier-performance',
  shipment_carrier_comparison: '/analytics/shipments/carrier-comparison',
  days_from_shipment_status_changed_outliers: '/analytics/shipments/days-from-status-changed-outliers',
};

export function useAnalyticsMenuItems() {
  const { dashboards: embeddables } = useSelector(selectAnalyticsDashboards);
  const { t } = useTranslation();

  const countryViews = embeddables.filter((dashboard) => dashboard.id.startsWith('country_view'));
  const nonCountryViews = embeddables.filter((dashboard) => !dashboard.id.startsWith('country_view'));
  const fixedDashboards = nonCountryViews.filter((dashboard) => dashboard.type === 'dashboard' && dashboard.isFixed);
  const customDashboards = nonCountryViews.filter((dashboard) => dashboard.type === 'dashboard' && !dashboard.isFixed);
  const looks = nonCountryViews.filter((dashboard) => dashboard.type === 'look');

  const buildMenuItem = (embeddable) => {
    const { id, name, type, isFixed } = embeddable;

    return {
      id: mapLookerIdsToReactRouterUrl[id] || `/analytics/${type}s/${id}`,
      link: mapLookerIdsToReactRouterUrl[id] || `/analytics/${type}s/${id}`,
      permission: permissions.ANALYTICS_PERMISSION,
      title: isFixed ? t(name) : name, // no point in translating user-defined dashboard name
      noTitleTranslation: true,
    };
  };

  const buildSortedMenuItems = (embeddables) => sortBy(embeddables.map(buildMenuItem), 'title');

  return {
    fixedDashboards: buildSortedMenuItems(fixedDashboards),
    customDashboards: buildSortedMenuItems(customDashboards),
    looks: buildSortedMenuItems(looks),
    countryViews: buildSortedMenuItems(countryViews),
  };
}
