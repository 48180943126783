import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../Translate/Locale';
import SevenSendersLogo from '../../icons/seven-senders-portal-logo-white.svg';

import './ErrorPages.scss';

function ErrorPage(props) {
  return (
    <div styleName="background">
      <div styleName="lost-track-background-image" />
      <SevenSendersLogo styleName="logo-white" />
      <div styleName="main-content">
        <h3 styleName="upper-header">
          <Locale>{props.upperHeader}</Locale>
        </h3>
        <h1 styleName="header">
          <Locale>{props.header}</Locale>
        </h1>
        <h3 styleName="sub-header">
          <Locale>{props.subHeader}</Locale>
        </h3>
        {props.children}
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  upperHeader: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
};

export default ErrorPage;
