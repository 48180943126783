import React, { useReducer } from 'react';

import Link from '../common/Link';
import MobileMenu from './MobileMenu';
import Text from '../common/Text';
import Locale from '../Translate/Locale';

import MenuOpenIcon from '../../icons/menu-hamburger.svg';
import MenuCloseIcon from '../../icons/menu-close.svg';
import LogoIcon from '../../icons/navigation/7s-logo.svg';

import './MobilePageContainer.scss';

function MobilePageContainer(props) {
  const { menuItems, location, menuTitle, menuTitleBadge, selectedShop, hideMobileExperienceWarning, children } = props;
  const [isMenuOpen, toggleMenu] = useReducer((state) => !state, false);

  const MenuIcon = isMenuOpen ? MenuCloseIcon : MenuOpenIcon;

  return (
    <div styleName="page">
      <header>
        <button styleName="menu-button">
          <MenuIcon onClick={() => toggleMenu()} />
        </button>
        <Link to="/home" styleName="logo">
          <LogoIcon />
        </Link>
        {isMenuOpen && (
          <div styleName="menu-container">
            <MobileMenu
              shop={selectedShop}
              items={menuItems}
              title={menuTitle}
              titleBadge={menuTitleBadge}
              pathname={location.pathname}
            />
          </div>
        )}
      </header>
      <div styleName="content">{children}</div>
      {!hideMobileExperienceWarning && (
        <footer>
          <Text>
            <Locale>MOBILE_EXPERIENCE_WARNING</Locale>
          </Text>
        </footer>
      )}
    </div>
  );
}

export default MobilePageContainer;
