import React from 'react';

const EditorContext = React.createContext({
  editorType: undefined,
  defaultSettings: {},
  widgets: [],
});

export default EditorContext;

export const withEditorConfig = (Component) => props => (
  <EditorContext.Consumer>
    { config => <Component editorConfig={config} {...props} /> }
  </EditorContext.Consumer>
);