import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '../../common2/drawer/Drawer';
import TakeActionPanel from './TakeActionPanel';
import DisputeDeclinedReasonPanel from './DisputeDeclinedReasonPanel';
import useClaimCustomerResponse from '../../../hooks/useClaimCustomerResponse';
import { customerActionTypeEnum, getActionTypeForClaim } from './actionHelper';
import ViewDeclinedReasonPanel from './ViewDeclinedReasonPanel';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';

const panelByActionType = {
  [customerActionTypeEnum.TAKE_CUSTOMER_ACTION]: TakeActionPanel,
  [customerActionTypeEnum.DISPUTE_DECLINED_REASON]: DisputeDeclinedReasonPanel,
  [customerActionTypeEnum.VIEW_DECLINED_REASON]: ViewDeclinedReasonPanel,
};

// should never appear visible
function DefaultNonExistentPanel() {
  return null;
}

function TakeActionSidePanel(props) {
  const { claim, onClose, onSubmitSuccess } = props;

  const claimId = claim?.id;
  const shopId = useSelectedShopId();
  const { customerResponse, isLoading } = useClaimCustomerResponse(shopId, claimId);

  const actionType = getActionTypeForClaim(claim);
  const ActionPanel = panelByActionType[actionType] || DefaultNonExistentPanel;

  // composition TakeActionSidePanel -> ActionPanel helps to reset state when claim changes

  return (
    <Drawer isOpen={!!claim} isLoading={isLoading}>
      <ActionPanel
        claim={claim}
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
        customerResponse={customerResponse}
      />
    </Drawer>
  );
}

TakeActionSidePanel.propTypes = {
  claim: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    customerResponseId: PropTypes.number,
    status: PropTypes.string,
    freshdeskTicketUrl: PropTypes.string,
    rejectedAt: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
};

export default React.memo(TakeActionSidePanel);
