import React from 'react';
import PropTypes from 'prop-types';

import './ResultTable.scss';

function ResultTable(props) {
  return (
    <table styleName="table">
      {props.children}
    </table>
  );
}

ResultTable.propTypes = {
  children: PropTypes.node,
};

export default ResultTable;