import React from 'react';
import PropTypes from 'prop-types';
import CirclesIcon from '../../icons/circles.svg';
import CheckIcon from '../../icons/checkbox-check.svg';
import './Pagination.scss';
import Locale from '../Translate/Locale';

class Pagination extends React.PureComponent {
  render () {
    const {
      page,
      pagesCount,
      title,
      checkLastPage
    } = this.props;

    const pages = [];

    for (let i = 1; i < pagesCount + 1; i++) {
      if (i === page) {
        pages.push(
          <div key={i} styleName="page-active">
            <CirclesIcon styleName="circles"/>
            <div styleName="page-content">
              { checkLastPage && i === pagesCount ? <CheckIcon /> : i}
            </div>
          </div>
        );
      } else {
        pages.push(
          <div key={i} styleName="page"/>
        );
      }
    }

    return (
      <div styleName="container">
        {title && <span styleName="title">
          <Locale>{title}</Locale>
        </span>}
        <div styleName="pages">
          {pages}
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  page: PropTypes.number,
  pagesCount: PropTypes.number,
  title: PropTypes.string,
  checkLastPage: PropTypes.bool,
};

export default Pagination;