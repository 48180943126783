import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../common2/Modal';
import ModalContent from '../common2/Modal/ModalContent';
import ModalFooter from '../common2/Modal/ModalFooter';
import ModalHeader from '../common2/Modal/ModalHeader';
import Column from '../common/layout/Column';
import Input from '../common2/Input';
import Button from '../common2/Button';
import RenameIcon from '../../icons/edit.svg';

function RenameModal(props) {
  const { objectWithName, title, onRename, onCancel } = props;
  const [name, setName] = useState('');

  const onChange = (name) => {
    setName(name);
  };

  useEffect(() => {
    setName(objectWithName?.name || '');
  }, [objectWithName, setName]);

  return (
    <Modal show={!!objectWithName} title={title} onClose={onCancel}>
      <ModalHeader icon={RenameIcon}>{title}</ModalHeader>
      <ModalContent>
        <Column spacing="small">
          <Input label="Enter a new name" value={name} onChange={onChange} />
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => onRename(name)} disabled={!name}>
          Rename
        </Button>
      </ModalFooter>
    </Modal>
  );
}

RenameModal.propTypes = {
  title: PropTypes.string.isRequired,
  objectWithName: PropTypes.shape({
    name: PropTypes.string,
  }),
  onRename: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(RenameModal);
