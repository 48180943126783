import React from 'react';

import PageContainer from '../pagecontainer/PageContainer';
import { EMBEDDED_WIDGETS_PERMISSION, TRACKING_PAGES_PERMISSION } from '../../firewall/permissions';

export const menuItems = [
  {
    id: '/tracking/tracking-pages',
    link: '/tracking/tracking-pages',
    permission: TRACKING_PAGES_PERMISSION,
    title: 'Tracking pages',
  },
  {
    id: '/tracking/embedded-widgets',
    link: '/tracking/embedded-widgets',
    permission: EMBEDDED_WIDGETS_PERMISSION,
    title: 'Embedded widgets',
  },
  {
    id: 'tracking/start-guide',
    link: '/tracking/start-guide',
    title: 'Start guide',
  },
];

class TrackingContainer extends React.PureComponent {
  render() {
    const { children, ...pageProps } = this.props;

    return (
      <PageContainer {...pageProps} menuTitle="Tracking" menuItems={menuItems}>
        {children}
      </PageContainer>
    );
  }
}

export default TrackingContainer;
