import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useFocus from '../../../hooks/useFocus';
import SearchIcon from '../../../icons/parcel-center-search.svg';

import './ClaimsSearchInput.scss';

function ClaimsSearchInput(props) {
  const { value, onSearchClaim, onSetTrackingCode, onResetSearch } = props;
  const [focused, callbacks] = useFocus(false);

  const handleClear = () => {
    onResetSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearchClaim(value);
    }
  };

  const onChange = (e) => {
    onSetTrackingCode(e.target.value);
  };

  return (
    <div key="search" styleName={classNames('container', { focused })}>
      <input
        value={value}
        type="text"
        styleName="input"
        name="claims-center-search"
        placeholder="Enter shipment tracking code"
        onKeyPress={handleKeyPress}
        onChange={onChange}
        autoFocus
        data-testid="claims-search"
        {...callbacks}
      />
      {value && (
        <button styleName="clear-button" onClick={handleClear}>
          Clear
        </button>
      )}
      <button
        styleName="search-button"
        data-testid="search-button"
        onClick={() => {
          onSearchClaim(value);
        }}
      >
        <SearchIcon styleName="icon" />
      </button>
    </div>
  );
}

ClaimsSearchInput.propTypes = {
  value: PropTypes.string,
  onSearchClaim: PropTypes.func,
  onSetTrackingCode: PropTypes.func,
  onResetSearch: PropTypes.func,
};

export default React.memo(ClaimsSearchInput);
