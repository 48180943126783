import React from 'react';
import PropTypes from 'prop-types';

import SettingsGroupCard from '../../SettingsGroupCard';
import Checkbox from '../../../common/Checkbox';

class PromoSettings extends React.PureComponent {
  onShowPromo = (e) => {
    this.props.onChange({
      showPromo: e.target.checked,
    });
  };

  render() {
    const {
      settings: {
        showPromo = false,
      },
    } = this.props;

    return (
      <SettingsGroupCard
        title="Sendwise promo"
        subtitle="Add a sendwise promo here"
      >
        <Checkbox checked={showPromo} onChange={this.onShowPromo}>
          Show promo
        </Checkbox>
      </SettingsGroupCard>
    );
  }
}

PromoSettings.propTypes = {
  settings: PropTypes.shape({
    showPromo: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

export default PromoSettings;