import React from 'react';

import Locale from '../../Translate/Locale';

import './Heading1.scss';

function Heading1(props) {
  return (
    <h1 className={props.className} styleName="default">
      <Locale>{props.children}</Locale>
    </h1>
  );
}

export default Heading1;