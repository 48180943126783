import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../common2/Input';
import Select from '../../common2/Select';
import NewFormMessage from './NewFormMessage';
import { getSelectOptions } from '../../../utils/langHelper';
import Column from '../../common/layout/Column';

import './ShopLocalizationForm.scss';

function ShopLocalizationForm(props) {
  const {
    isNew,
    archived,
    placeholder = 'e.g. https://www.yourshop.com',
    onChange,
    url,
    language,
    urlError,
    languageError,
  } = props;

  return (
    <div styleName="form">
      <Column spacing="medium">
        {isNew && <NewFormMessage />}
        <Input
          name="url"
          label="URL"
          type="url"
          placeholder={placeholder}
          value={url}
          onChange={onChange}
          error={urlError}
          disabled={archived}
          helpText="THIS_IS_THE_URL_OF_YOUR_SHOP"
        />
        <Select
          name="language"
          label="Language"
          placeholder="Select a language"
          options={getSelectOptions()}
          error={languageError}
          value={language}
          isDisabled={archived}
          onChange={onChange}
        />
      </Column>
    </div>
  );
}

ShopLocalizationForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  archived: PropTypes.bool.isRequired,
  placeholder: PropTypes.string, // Url input placeholder
  url: PropTypes.string,
  language: PropTypes.string,
  urlError: PropTypes.string,
  languageError: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(ShopLocalizationForm);
