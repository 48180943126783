import React from 'react';
import { useLocation } from 'react-router-dom';

import { trackEvent } from '../../utils/googleAnalytics';
import Button from '../common/Button';
import Link from '../common/Link';
import ErrorPage from './ErrorPage';

import './Error404.scss';

function Error404() {
  trackEvent({
    category: '404',
    action: window.location.pathname,
  });

  return (
    <ErrorPage upperHeader="404 ERROR" header="Lost Track" subHeader="Sorry for that. I'm only human">
      <div styleName="link-button">
        <Link to="/home">
          <Button negativeOutline>Take me home</Button>
        </Link>
      </div>
    </ErrorPage>
  );
}

export default Error404;
