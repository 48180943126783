import React from 'react';
import { withTranslation } from 'react-i18next';

import InputContainer from '../InputContainer';
import BasicSelect from '../BasicSelect';

export function Select(props) {
  const { label, isRequired, error, helpText, t, i18n, noErrorTranslation, ...selectProps } = props;

  const errorText = noErrorTranslation ? error : t(error);

  return (
    <InputContainer label={label} labelIsRequired={isRequired} errorWithTranslation={errorText} helpText={helpText}>
      <BasicSelect ariaLabel={label} isInvalid={!!errorText} {...selectProps} />
    </InputContainer>
  );
}

export default React.memo(withTranslation()(Select));
