import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../common2/Badge';
import Text from '../../common/Text/Text';

import './TrackingCodeWithBadge.scss';

function TrackingCodeWithBadge({ trackingCode, isNon7sShipment }) {
  return (
    <div styleName="wrapper">
      <Text ink>{trackingCode}</Text>
      {isNon7sShipment && <Badge intent="warning">Non 7S</Badge>}
    </div>
  );
}

TrackingCodeWithBadge.propTypes = {
  trackingCode: PropTypes.string,
  isNon7sShipment: PropTypes.bool,
};

export default React.memo(TrackingCodeWithBadge);
