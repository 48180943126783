import React from 'react';

import PlainButton from '../../../common/PlainButton';

import BackIcon from '../../../../icons/arrow-left.svg';

function BackButton(props) {
  return (
    <PlainButton {...props} icon={BackIcon}>
      Back
    </PlainButton>
  );
}

export default React.memo(BackButton);