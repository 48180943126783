import React from 'react';
import { useRouteError } from 'react-router';
import Error404 from '../error-pages/Error404';
import Error5xx from '../error-pages/Error5xx';
import Error403 from '../error-pages/Error403';

// NOTE: error pages is a legacy mess, might be reconsidered especially due to the route nested layout
export default function RouterError() {
  const error = useRouteError();
  const statusCode = error?.status;

  if (statusCode === 403) {
    return <Error403 />;
  }

  if (statusCode === 404) {
    return <Error404 />;
  }

  if (statusCode < 500) {
    return <Error5xx message={statusCode.toString()} />;
  }

  return <Error5xx message="Internal Application" />;
}
