import React from 'react';
import PropTypes from 'prop-types';

function Show(props) {
  const { when, children, fallback } = props;
  return when ? children : fallback;
}

Show.propTypes = {
  when: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default React.memo(Show);
