import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';
import Locale from '../../Translate';

import './DetailsCardItem.scss';

function DetailsCardItem(props) {
  const {
    label,
    children,
  } = props;

  return (
    <div styleName="container">
      <div styleName="label">
        <Text title>
          <Locale>{label}</Locale>:
        </Text>
      </div>
      <Text styleName="text">{children ? children : '-'}</Text>
    </div>
  );
}

DetailsCardItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default React.memo(DetailsCardItem);