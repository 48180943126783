import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../Translate';

import './ModalBottom.scss';

function ModalBottom(props) {
  const {
    heading,
    children,
  } = props;

  return (
    <div styleName="bottom">
      <h4 styleName="heading">
        <Locale>{heading}</Locale>
      </h4>
      {children}
    </div>
  );
}


ModalBottom.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ModalBottom;