import React from 'react';

import ExternalLink from '../../common2/links/ExternalLink';
import InternalLink from '../../common2/links/InternalLink';

function FeatureCardLink(props) {
  const {
    to,
    children,
  } = props;

  const Link = to.startsWith('/') ? InternalLink : ExternalLink;

  return <Link to={to}>{children}</Link>;
}

export default React.memo(FeatureCardLink);