import React from 'react';
import PropTypes from 'prop-types';

import ModalHeader from '../../common2/Modal/ModalHeader';
import WarningIcon from '../../../icons/warning-triangle.svg';
import ModalContent from '../../common2/Modal/ModalContent';
import ModalFooter from '../../common2/Modal/ModalFooter';
import Button from '../../common2/Button';
import Modal from '../../common2/Modal';
import Text from '../../common/Text/Text';
import Locale from '../../Translate/Locale';
import Column from '../../common/layout/Column';

function ExitClaimsModal(props) {
  const { show, onCancel, onExit } = props;

  return (
    <Modal show={show} onClose={onCancel}>
      <ModalHeader icon={WarningIcon} intent="warning">
        CLAIMS_EXIT_FORM_TITLE
      </ModalHeader>
      <ModalContent>
        <Column spacing="extra-small">
          <Text>
            <Locale>Are you sure you want to exit without submitting your claim?</Locale>
          </Text>
          <Text>
            <Locale>Your progress will be lost</Locale>
          </Text>
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button intent="warning" onClick={onExit}>
          Exit
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ExitClaimsModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onExit: PropTypes.func,
};

export default React.memo(ExitClaimsModal);
