import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common2/Modal';
import ModalHeader from '../common2/Modal/ModalHeader';
import ModalContent from '../common2/Modal/ModalContent';
import ModalFooter from '../common2/Modal/ModalFooter';
import Button from '../common2/Button/Button';
import Column from '../common/layout/Column';
import Locale from '../Translate/Locale';

import DeleteIcon from '../../icons/delete.svg';

import './EmbeddedWidgetDeleteModal.scss';

function EmbeddedWidgetDeleteModal(props) {
  const { embeddedWidget, onDelete, onCancel } = props;

  return (
    <Modal show={!!embeddedWidget} onClose={onCancel}>
      <ModalHeader icon={DeleteIcon} intent="danger">
        Delete embedded widget
      </ModalHeader>
      <ModalContent>
        <Column>
          <Locale>Are you sure you want to delete this embedded widget?</Locale>
          <div styleName="entity-name">{embeddedWidget?.name}</div>
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onCancel}>
          Cancel
        </Button>
        <Button intent="danger" onClick={onDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

EmbeddedWidgetDeleteModal.propTypes = {
  embeddedWidget: PropTypes.object,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};

export default React.memo(EmbeddedWidgetDeleteModal);
