import React from 'react';
import PropTypes from 'prop-types';
import { eventTypes } from '../contentLibraryHelper';

import './WidgetIcon.scss';

class WidgetIcon extends React.PureComponent {
  removeIcon() {
    if (this.unmount && this.unmount()) {
      this.unmount = null;
    }
  }

  onRef = (ref) => {
    if (!ref) {
      this.removeIcon();
      return;
    }

    this.unmount = this.props.iconRenderer(ref, this.props.type, { name: this.props.name, size: 32, fill: 'gray' });
  };

  render() {
    const {
      name,
    } = this.props;

    return name ? <div key={name} ref={this.onRef} styleName="icon-wrapper" /> : null;
  }
}

WidgetIcon.propTypes = {
  type: PropTypes.oneOf(eventTypes).isRequired,
  name: PropTypes.string,
  iconRenderer: PropTypes.func.isRequired,
};

export default WidgetIcon;