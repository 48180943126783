import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../Wizard/Switch';

function TouchpointsOverviewSwitch(props) {
  const state = getLoadingState(props);

  return (
    <Switch selected={state}>
      {props.children}
    </Switch>
  );
}

function getLoadingState(props) {
  const { isLoading, loaded, data = [] } = props;

  if (loaded) {
    return data.length > 0 ? 'data' : 'empty';
  }

  return isLoading ? 'loader' : '';
}

TouchpointsOverviewSwitch.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default React.memo(TouchpointsOverviewSwitch);
