import React from 'react';

import Text from '../../common/Text/Text';
import Locale from '../../Translate/Locale';
import FeatureCardLink from './FeatureCardLink';
import Paper from '../../common2/Paper';

import './FeatureCard.scss';

function FeatureCard(props) {
  const { id, title, description, icon, badge, intent, url, callToActionText } = props;

  return (
    <div data-product-id={id}>
      <Paper intent={intent}>
        <div styleName="container">
          <div styleName="icon">{icon}</div>
          <div styleName="content">
            <div styleName="title">
              <Text title>
                <Locale>{title}</Locale>
              </Text>
              <div styleName="badge">{badge}</div>
            </div>
            <Text styleName="description">
              <Locale>{description}</Locale>
            </Text>
            {url && (
              <FeatureCardLink to={url}>
                <Locale>{callToActionText}</Locale>
              </FeatureCardLink>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default React.memo(FeatureCard);
