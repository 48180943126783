import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import RemoveButton from './RemoveButton';

import BasicSelect from '../../../../common2/BasicSelect';
import BasicInput from '../../../../common2/BasicInput';

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => ({
  label: n.toString(),
  value: n,
}));

function ItemInfoUnit(props) {
  const {
    info: { amount, itemName, damageDescription },
    hasDescription,
    errors = {},
    onChange,
    onRemoveClick,
    hasRemoveButton,
  } = props;

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  return (
    <tr>
      <td>
        <BasicSelect name="amount" options={options} value={amount} onChange={onInputChange} />
      </td>
      <td>
        <BasicInput
          name="itemName"
          value={itemName}
          isInvalid={!!errors.itemName}
          onChange={onInputChange}
          autoComplete="off"
        />
      </td>
      {hasDescription && (
        <td>
          <BasicInput
            name="damageDescription"
            value={damageDescription}
            isInvalid={!!errors.damageDescription}
            onChange={onInputChange}
            autoComplete="off"
          />
        </td>
      )}
      {hasRemoveButton && (
        <td>
          <RemoveButton onClick={onRemoveClick} />
        </td>
      )}
    </tr>
  );
}

ItemInfoUnit.propTypes = {
  info: PropTypes.shape({
    amount: PropTypes.string,
    itemName: PropTypes.string,
    damageDescription: PropTypes.string,
  }).isRequired,
  errors: PropTypes.object,
  hasDescription: PropTypes.bool,
  onChange: PropTypes.func,
  onRemoveClick: PropTypes.func,
  hasRemoveButton: PropTypes.bool,
};

export default React.memo(ItemInfoUnit);
