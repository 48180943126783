import React from 'react';

import IntercomButton from './IntercomButton';
import StartGuideCard from './StartGuideCard';

function BannerNoAccess(props) {
  const {
    heading,
    description,
    cta,
  } = props;

  return (
    <StartGuideCard
      heading={heading}
      description={description}
    >
      <IntercomButton intent="success">
        {cta}
      </IntercomButton>
    </StartGuideCard>
  );
}

export default React.memo(BannerNoAccess);
