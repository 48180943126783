import React from 'react';

import HeaderIcon from '../../icons/header.svg';
import StatusIcon from '../../icons/status.svg';
import FooterIcon from '../../icons/footer.svg';
import BannerIcon from '../../icons/banner.svg';
import TypographyIcon from '../../icons/typography.svg';
import ColorsIcon from '../../icons/colors.svg';
import StylingIcon from '../../icons/styling.svg';
import ProductsIcon from '../../icons/products.svg';
import MessageContentIcon from '../../icons/message-content.svg';
import ContactShopIcon from '../../icons/contact-shop.svg';
import EtaIcon from '../../icons/eta.svg';
import LinkButton from '../../icons/link-button.svg';
import CarrierIcon from '../../icons/contact-carrier.svg';
import OrderItemsIcon from '../../icons/order-items.svg';

const icons = {
  header: HeaderIcon,
  footer: FooterIcon,
  status: StatusIcon,
  banner: BannerIcon,
  products: ProductsIcon,
  typography: TypographyIcon,
  colors: ColorsIcon,
  styling: StylingIcon,
  'message-content': MessageContentIcon,
  'contact-shop': ContactShopIcon,
  eta: EtaIcon,
  'link-button': LinkButton,
  carrier: CarrierIcon,
  'order-items': OrderItemsIcon,
};

export default ({ type, size = 64 }) => {
  const Icon = icons[type];

  return Icon ? <Icon width={size} height={size} /> : null;
};
