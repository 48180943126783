import React from 'react';
import PropTypes from 'prop-types';
import SettingsPanel from '../SettingsPanel';
import ColorsSettings from './ColorsSettings';
import TypographySettings from './TypographySettings';
import { withEditorConfig } from '../../editors/EditorContext';

class WidgetDesignSettings extends React.PureComponent {
  render() {
    const {
      type,
      onClose,
      onSettingsChange,
      settings,
      widgets,
      editorConfig: {
        editorType,
        designSettingsPanel = {},
      },
    } = this.props;

    const settingsProps = {
      onSettingsChange,
      settings,
      widgets,
      editorType,
    };

    const {
      styling: StylingSettings,
      typography: TypographySettings,
    } = designSettingsPanel;

    return (
      <SettingsPanel selected={type} title={getTitle(type)} onClose={onClose}>
        <ColorsSettings key="colors" {...settingsProps} />
        { TypographySettings && <TypographySettings key="typography" {...settingsProps} /> }
        { StylingSettings && <StylingSettings key="styling" {...settingsProps} /> }
      </SettingsPanel>
    );
  }
}

const types = {
  colors: 'Colors',
  styling: 'Styling',
  typography: 'Typography',
};

function getTitle(type) {
  return types[type];
}

WidgetDesignSettings.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  settings: PropTypes.object,
  widgets: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(Object.keys(types)),
  onClose: PropTypes.func,
  editorType: PropTypes.string,
  onSettingsChange: PropTypes.func,
};

export default withEditorConfig(WidgetDesignSettings);
