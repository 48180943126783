const items = [
  {
    id: 1,
    isReturnable: true,
    name: 'Grey trousers',
    size: 'M',
    price: 39.99,
    currency: 'EUR',
    productImageUrl:
      'https://7s-cdn.s3.eu-central-1.amazonaws.com/master/product_images/return-portal-preview/grey-trousers.jpg',
  },
  {
    id: 2,
    isReturnable: true,
    name: 'White bag',
    size: 'one size',
    price: 15,
    currency: 'EUR',
    productImageUrl:
      'https://7s-cdn.s3.eu-central-1.amazonaws.com/master/product_images/return-portal-preview/white-bag.jpg',
  },
];

export const order = {
  customerEmail: 'customer@email.com',
  deliveryDate: '2020-05-12T17:21:00+00:00',
  id: 10023,
  items,
  shopOrderId: '#1081392040',
};

const returnItems = items.map((item, i) => ({
  ...item,
  quantity: i + 1,
  orderItemId: item.id,
  reason: 'general_i_do_not_like_this_item',
}));

export const returnRequest = {
  id: 123,
  customerEmail: order.customerEmail,
  items: returnItems,
  createdAt: '2020-03-24T10:30:00+00:00',
  workflowType: 'normal',
};

export const returnRequestLite = {
  id: 123,
  customerEmail: order.customerEmail,
  createdAt: '2020-03-24T10:30:00+00:00',
  workflowType: 'lite',
};

export const settings = {
  accentColor: '#1B73E9',
  font: 'Roboto',
  logoImageUrl: '',
  bannerImageUrl: '',
  bannerDesktopImageUrl: '',
  logoLink: '',
  showBannerOnlyOnLogin: false,
  isFooterEnabled: true,
  reasons: [],
  nextSteps: [
    {
      title: 'Pack up your return',
      description: 'Collect your return items and attach the return label to the box.',
    },
    {
      title: 'Ship it',
      description: 'Drop of your package at a drop-off point near you.',
    },
    {
      title: 'Get your refund',
      description: 'You can expect your refund 2 weeks after you shipped your return.',
    },
    {
      title: 'Print your shipping label',
      description: 'If you are unable to print, bring it to the drop-off point for assistance.',
      hasLabelButton: true,
      buttonText: 'Download your return label',
    },
  ],
  returnPeriod: 90,
  policyLink: '',
  customerServiceLink: '',
  emailFrom: '',
  emailAddress: '',
  emailSubject: '',
  emailPreviewText: '',
  emailMessage: '',
  isExternalRedirectEnabled: false,
  externalPortalLink: '',
  isTranslationsEnabled: true,
  isKeepItemsEnabled: false,
  keepItemsCutoffValue: undefined,
  keepItemsConfirmationTitle: 'Good news, you do not need to ship anything',
  keepItemsConfirmationBody:
    'You don’t need to return the item(s). We’ll issue  your refund, gift card or exhance once we process your return.',
  keepItemsEmailSubject: 'Your return request',
  keepItemsEmailPreviewText: '',
  keepItemsEmailMessage: '',
};
