import React from 'react';

import Select from '../../common/Select';

const FontSizeSelect = ({
  name,
  value,
  sizes,
  onChange,
}) => (
  <Select
    searchable={false}
    name={name}
    value={value}
    placeholder="Select a font size"
    options={getOptions(sizes)}
    onChange={onChange}
  >
    Base size
  </Select>
);

function getOptions(sizes) {
  return sizes.map(value => ({ value, label: `${value}px`}));
}

export default FontSizeSelect;