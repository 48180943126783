const pageOptions = [
  { value: 'login', label: 'Login' },
  { value: 'overview', label: 'Overview Pro' },
  { value: 'confirmation', label: 'Confirmation Pro' },
  { value: 'confirmationPrinterless', label: 'Confirmation Printerless Pro' },
  { value: 'confirmationHomePickup', label: 'Confirmation Home Pick-Up Pro' },
  { value: 'keepItemsConfirmation', label: 'Confirmation Keep Items' },
];
const litePageOptions = [
  { value: 'login', label: 'Login' },
  { value: 'liteOverview', label: 'Overview' },
  { value: 'confirmation', label: 'Confirmation' },
  { value: 'confirmationPrinterless', label: 'Confirmation Printerless' },
  { value: 'confirmationHomePickup', label: 'Confirmation Home Pick-Up' },
];

export function getPageOptions({ isLite }) {
  if (isLite) {
    return litePageOptions;
  }
  return pageOptions;
}
