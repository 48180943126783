import React from 'react';

import PageContainer from '../pagecontainer/PageContainer';
import LookerDashboard from './LookerDashboard';
import Heading2 from '../common/Heading2/Heading2';
import useSelectedShop from '../../hooks/useSelectedShop';
import useFirewall from '../../hooks/useFirewall';
import { hasCommsCenterAccess } from '../../utils/permissionsHelper';
import { INVOICE_DETAILS } from '../../firewall/permissions';

function CommsCenterPage(props) {
  const shop = useSelectedShop();
  const firewall = useFirewall(shop.id);
  const hasAccess = hasCommsCenterAccess(firewall);
  const { dashboardId, type } = props;

  const menuItems = [
    {
      id: 'delivery-performance',
      title: 'Delivery performance',
      link: '/communication-center/delivery-performance',
    },
    {
      id: 'partner-interface-usage',
      title: 'Partner interface usage',
      link: '/communication-center/partner-interface-usage',
    },
    {
      id: 'invoice-details',
      title: 'Invoice details',
      link: '/communication-center/invoice-details',
      permission: INVOICE_DETAILS,
    },
  ];

  return (
    <PageContainer {...props} menuItems={menuItems} menuTitle="Comms center" collapsible>
      {!hasAccess && <Heading2>You don't have the necessary permissions to see this page</Heading2>}
      {hasAccess && <LookerDashboard key={dashboardId} dashboardId={dashboardId} type={type} />}
    </PageContainer>
  );
}

export default React.memo(CommsCenterPage);
