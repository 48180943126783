import React from 'react';

import ParcelFinderContainer from '../parcel-finder/ParcelFinderContainer';
import Column from '../common/layout/Column';
import StartGuideCard from './StartGuidePage/StartGuideCard';
import StartGuideTabs from './StartGuidePage/StartGuideTabs';
import StartGuideTabContent from './StartGuidePage/StartGuideTabContent';
import StartGuideBulletedList from './StartGuidePage/StartGuideBulletedList';
import BulletedListItem from '../common/bulleted-list/BulletedListItem';
import StartGuideImage from './StartGuidePage/StartGuideImage';
import CenterLayout from '../common2/CenterLayout/CenterLayout';
import StartGuideHeading from './StartGuidePage/StartGuideHeading';
import ParcelFinderStartGuideBanner from './banners/ParcelFinderStartGuideBanner';

const tabs = [
  {
    id: 'claims',
    title: 'Claims',
  },
  {
    id: 'parcel-status',
    title: 'Parcel Status',
  },
];

export function ParcelFinderStartGuide(props) {
  return (
    <ParcelFinderContainer {...props}>
      <CenterLayout>
        <StartGuideHeading />
        <Column spacing="large">
          <ParcelFinderStartGuideBanner />
          <StartGuideCard
            heading="PARCEL_FINDER_START_GUIDE_CONTENT_HEADING"
            description="PARCEL_FINDER_START_GUIDE_CONTENT_DESK"
          >
            <StartGuideTabs tabs={tabs}>
              <StartGuideTabContent key="claims">
                <StartGuideBulletedList>
                  <BulletedListItem>PARCEL_FINDER_START_GUIDE_CLAIMS_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>PARCEL_FINDER_START_GUIDE_CLAIMS_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/parcel-finder/parcel-finder-claims.png" alt="claims" />
              </StartGuideTabContent>
              <StartGuideTabContent key="parcel-status">
                <StartGuideBulletedList>
                  <BulletedListItem>PARCEL_FINDER_START_GUIDE_STATUS_LIST_ITEM_0</BulletedListItem>
                  <BulletedListItem>PARCEL_FINDER_START_GUIDE_STATUS_LIST_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage
                  src="/images/start-guides/parcel-finder/parcel-finder-parcel-status.png"
                  alt="parcel-status"
                />
              </StartGuideTabContent>
            </StartGuideTabs>
          </StartGuideCard>
        </Column>
      </CenterLayout>
    </ParcelFinderContainer>
  );
}

export default React.memo(ParcelFinderStartGuide);
