import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MobilePreviewPopup from './MobilePreviewPopup';
import editorTypeEnum from '../editors/visualEditorTypeEnum';

import './WidgetPreviewCanvas.scss';

function WidgetPreviewCanvas({ mobileView, editorType, children }) {
  const containerStyleName = classnames(
    'preview-container',
    mobileView ? 'mobile' : 'desktop',
    {
      'email-template': editorType === editorTypeEnum.EMAIL_TEMPLATE,
    }
  );

  return (
    <div styleName="canvas">
      { mobileView && <div styleName="mobile-popup"><MobilePreviewPopup /></div> }
      <div styleName={containerStyleName}>
        <div styleName="preview">
          <div styleName="children">{children}</div>
        </div>
      </div>
    </div>
  );
}

WidgetPreviewCanvas.defaultProps = {
  mobileView: true,
};

WidgetPreviewCanvas.propTypes = {
  mobileView: PropTypes.bool,
};

export default WidgetPreviewCanvas;