import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { getReturnInfo, selectParcelFinderReturns } from '../../../selectors/parcelFinderReturnsSelectors';
import { createReturnShipments, setCarrierService, setLabelsCount,
  setReferenceNumber,
} from '../../../reducers/parcelFinderReturnsReducer';
import { parcelFinderReload } from '../../../reducers/parcelFinderReducer';
import useNotifications from '../../../hooks/useNotifications';
import CreateReturn from './CreateReturn';
import { isFulfilledAction } from '../../../actions/apiActionHelper';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';
import DrawerHeader from '../../common2/drawer/DrawerHeader';
import Heading2 from '../../common/Heading2';
import Drawer from '../../common2/drawer/Drawer';
import QueryResult from '../../common2/QueryResult';
import container from '../../../container';

function ReturnsSidePanel(props) {
  const { isOpen, onClose } = props;

  const dispatch = useDispatch();
  const info = useSelector(getReturnInfo);
  const { labelsCount, referenceNumber, carrierServiceId, isLoading, returnShipments } = useSelector(selectParcelFinderReturns);
  const shopId = useSelectedShopId();
  const countryCode = info.carrier?.countryCode;

  const returnCarrierServicesResult = useQuery({
    queryKey: ['returnCarrierServices', shopId, countryCode],
    queryFn: () => container.touchpointsApi.getReturnCarrierServices(shopId, countryCode),
    enabled: isOpen && !!countryCode,
  });

  const { showNotice, showError } = useNotifications();

  const onCreateReturn = useCallback(async () => {
    const action = await dispatch(createReturnShipments());

    if (isFulfilledAction(action)) {
      showNotice({
        message: 'Return successfully created',
        type: 'success',
      });
    } else {
      showError(action.payload.type === 'conflict' ? 'CREATE_RETURN_REF_NUMBER_CONFLICT_ERROR' : undefined);
    }

    dispatch(parcelFinderReload());
  }, [dispatch, showNotice, showError]);

  const onChangeLabelsCount = useCallback((value) => dispatch(setLabelsCount(value)), [dispatch]);
  const onChangeReferenceNumber = useCallback((value) => dispatch(setReferenceNumber(value)), [dispatch]);
  const onChangeCarrierService = useCallback((value) => dispatch(setCarrierService(value)), [dispatch]);

  return (
    <Drawer isOpen={isOpen}>
      <DrawerHeader onClose={onClose}>
        <Heading2>CREATE_RETURN_PANEL_TITLE</Heading2>
      </DrawerHeader>
      <QueryResult result={returnCarrierServicesResult}>
        {(result) => (
          <CreateReturn
            info={info}
            returnShipments={returnShipments}
            onCreate={onCreateReturn}
            isLoading={isLoading}
            labelsCount={labelsCount}
            referenceNumber={referenceNumber}
            carrierServiceId={carrierServiceId}
            onChangeLabelsCount={onChangeLabelsCount}
            onChangeReferenceNumber={onChangeReferenceNumber}
            onChangeCarrierService={onChangeCarrierService}
            carrierServices={result.data}
            onCreateReturn={onCreateReturn}
          />
        )}
      </QueryResult>
    </Drawer>
  );
}

ReturnsSidePanel.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(ReturnsSidePanel);
