import React from 'react';
import useApi from '../../../hooks/useApi';
import ShipmentCard from './ShipmentCard';
import DetailsCard from '../DetailsCard';
import Loader from '../../common/Loader';
import Heading3 from '../../common/Heading3/Heading3';

function Shipments(props) {
  const { shopId, orderId } = props;

  const [shipments, loading, error] = useApi(({ touchpoints }) => touchpoints.getShipments(shopId, orderId), [
    shopId,
    orderId,
  ]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Heading3>Error</Heading3>;
  }

  if (shipments) {
    return (
      <DetailsCard title="Shipments">
        {shipments.map((shipment) => (<ShipmentCard {...shipment} key={shipment.id} />))}
      </DetailsCard>
    );
  }
}

export default React.memo(Shipments);
