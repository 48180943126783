import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../common/Link';
import Button from '../../common2/Button';
import Locale from '../../Translate/Locale';
import AddIcon from '../../../icons/add.svg';
import Icon from '../../common2/Icon';
import InfoLinkCard from '../../common/InfoLinkCard';
import ExternalLink from '../../common2/links/ExternalLink';
import Text from '../../common/Text/Text';
import TouchpointsOverview from '../TouchpointsOverview';

import './TouchpointsEmptyOverview.scss';

function dummyContainer({ children }) {
  return <div>{children}</div>;
}

function TouchpointsEmptyOverview(props) {
  const {
    pageTitle,
    title,
    icon,
    buttonText,
    buttonLink,
    link,
    text = 'LEARN_HOW_TO',
    linkText,
    onClick = () => {},
    headerElement: HeaderElement,
  } = props;

  const Container = pageTitle ? TouchpointsOverview : dummyContainer;
  const AddLink = buttonLink ? Link : dummyContainer;

  return (
    <Container title={pageTitle}>
      {HeaderElement && <HeaderElement />}
      <div styleName="instruction-card">
        <div styleName="items">
          <Icon icon={icon} />
          <h4>
            <Locale>{title}</Locale>
          </h4>
          <AddLink to={buttonLink}>
            <Button icon={AddIcon} onClick={onClick}>
              {buttonText}
            </Button>
          </AddLink>
        </div>
      </div>
      <div styleName="instruction-link">
        <InfoLinkCard>
          <Text styleName="how-to">
            <Locale>{text}</Locale>&nbsp;
          </Text>
          <ExternalLink to={link}>
            <Locale>{linkText}</Locale>
          </ExternalLink>
        </InfoLinkCard>
      </div>
    </Container>
  );
}

TouchpointsEmptyOverview.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  headerElement: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default React.memo(TouchpointsEmptyOverview);
