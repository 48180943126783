import React from 'react';
import Tabs from '../../common2/Tabs';
import Switch from '../../Wizard/Switch';
import useStartGuideTabs from './useStartGuideTabs';

function StartGuideTabs(props) {
  const { tabs, children } = props;

  const tabIds = tabs.map((tab) => tab.id);
  const [selectedTab, setSelectedTab] = useStartGuideTabs(tabIds);

  return (
    <React.Fragment>
      <Tabs tabs={tabs} selectedTab={selectedTab} onChange={setSelectedTab} />
      <Switch selected={selectedTab}>{children}</Switch>
    </React.Fragment>
  );
}

export default React.memo(StartGuideTabs);
