function selectShop(shopId) {
  localStorage.setItem('selectedShopId', shopId.toString());

  return selectShopAction(shopId);
}

export function selectShopAction(shopId) {
  return { type: 'SELECT_SHOP', shopId };
}

export default selectShop;
