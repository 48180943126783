import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

function usePrompt(when, message) {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const result = window.confirm(message);
      if (result) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);
}

export default usePrompt;
