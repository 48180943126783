import React from 'react';
import PropTypes from 'prop-types';

import Select from './Select';

class SortedSelect extends React.PureComponent {
  render() {
    const {
      options,
      getSortKey,
      ...props
    } = this.props;

    return (
      <Select
        options={ sortOptions(options, getSortKey) }
        { ...props }
      />
    );
  }
}

function sortOptions(options, getSortKey) {
  return options.slice().sort(
    (a, b) => {
      const keyA = getSortKey(a);
      const keyB = getSortKey(b);

      if (keyA < keyB) {
        return -1;
      }

      if (keyA > keyB) {
        return 1;
      }

      return 0;
    }
  );
}

SortedSelect.defaultProps = {
  getSortKey: x => x.label,
};

SortedSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  getSortKey: PropTypes.func,
};

export default SortedSelect;