import { denormalizeShop } from '../models/userInfo';
import { filterActiveShopLocalizations } from '../utils/shopLocalizationsHelper';

export const selectedShopIdSelector = (state) => state.user.selectedShopId;
export const embeddedWidgetsSelector = (state) => state.embeddedWidgets;
export const widgetSettingsSelector = (state) => state.widgetSettings;
export const trackingPagesSelector = (state) => state.trackingPages;
export const shopLocalizationsSelector = (state) => state.shopLocalizations;
export const shopLocalizationFormSelector = (state) => state.shopLocalizationFormReducer;
export const previewTrackingSelector = (state) => state.previewTracking;
export const contentLibrarySelector = (state) => state.contentLibrary;
export const contentLibraryFormSelector = (state) => state.contentLibraryForm;
export const notificationEventsSelector = (state) => state.notificationEvents;
export const carrierCompaniesSelector = (state) => state.carrierCompanies;
export const emailTemplateSelector = (state) => state.emailTemplate;
export const emailTemplatesSelector = (state) => state.emailTemplates;
export const sendingLogicSelector = (state) => state.sendingLogic;
export const sendingLogicStatsSelector = (state) => state.sendingLogicStats.stats;
export const tokenSelector = (state) => state.auth.token;

export const getApiError = (state) => state.apiError;
export const getFirewallByShopId = (state, shopId) => state.permissions.firewallByShop[shopId];
export const getFirewallForSelectedShop = (state) => getFirewallByShopId(state, selectedShopIdSelector(state));

export const getEmbeddedWidgetsByShopId = (state, shopId) => embeddedWidgetsSelector(state).widgetsByShop[shopId];
export const getTrackingPagesByShopId = (state, shopId) => trackingPagesSelector(state).trackingPagesByShop[shopId];
export const getAllLocalizationsByShopId = (state, shopId) =>
  shopLocalizationsSelector(state).localizationsByShop[shopId];
export const getLegacyTrackingPageByShopId = (state, shopId) => state.legacyTrackingPage.legacyByShop[shopId];
export const getEmailTemplatesByShopId = (state, shopId) => emailTemplatesSelector(state).templatesByShop[shopId];
export const getEmailTemplatesLocalizationsByShopId = (state, shopId) =>
  emailTemplatesSelector(state).localizationsByShop[shopId];
export const getSendingLogicByShopId = (state, shopId) => sendingLogicSelector(state).logicByShop[shopId];
export const getSendingLogicLocalizationsByShopId = (state, shopId) =>
  sendingLogicSelector(state).localizationsByShop[shopId];

export function getLocalizationsByShopId(state, shopId) {
  const localizations = shopLocalizationsSelector(state).localizationsByShop[shopId] || [];
  return filterActiveShopLocalizations(localizations);
}

export const getSelectedShop = (state) => {
  const {
    user: { entities, selectedShopId },
  } = state;

  return denormalizeShop(selectedShopId, entities);
};
