import Firewall from './Firewall';

export function getPermissionsFromStore(store) {
  const {
    user: {
      selectedShopId
    },
    permissions: {
      permissionsByShop
    }
  } = store.getState();

  const { whiteList, blackList } = permissionsByShop[selectedShopId] || {};
  return [ whiteList, blackList ];
}

export function createFirewall(store) {
  const firewall = new Firewall(...getPermissionsFromStore(store));

  store.subscribe(() => {
    firewall.updatePermissions(...getPermissionsFromStore(store));
  });

  return firewall;
}
