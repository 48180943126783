import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../modal/ConfirmationModal';

const ConfirmExitModal = (props) => {
  const {
    show,
    onClose,
    onCancel,
    onOK,
  } = props;

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onOK={onOK}
      onCancel={onCancel}
      okText="Save"
      cancelText="Exit"
      title="Want to exit without saving?"
      message="Are your sure you want to exit without saving? All your changes will be lost"
    />
  );
};

ConfirmExitModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  onSave: PropTypes.func,
};

export default React.memo(ConfirmExitModal);