import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';
import Text from '../../common/Text/Text';

import './SidePanelContentHeader.scss';

function SidePanelContentHeader(props) {
  const { children } = props;

  return (
    <div styleName="heading">
      <Text small>
        <Locale>{children}</Locale>
      </Text>
    </div>
  );
}

SidePanelContentHeader.propTypes = {
  children: PropTypes.string,
};

export default React.memo(SidePanelContentHeader);
