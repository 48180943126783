import React from 'react';

import './Blockquote.scss';

function Blockquote(props) {
  return (
    <div styleName="input">
      {props.children}
    </div>
  );
}

export default Blockquote;