import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  events: [],
};

const notificationEventsReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'NOTIFICATION_EVENTS')) {
    return requestProducer(draft, action, 'NOTIFICATION_EVENTS', {
      successProducer: (draft, action) => {
        draft.events = action.data;
      },
    });
  }

  return draft;
});

export default notificationEventsReducer;