import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../common/Select/index';
import makeAsyncScriptLoader from 'react-async-script';
import WidgetIcon from './WidgetIcon';
import { eventTypes } from '../contentLibraryHelper';
import { getWidgetPreviewUrl } from '../../../utils/previewHelper';

class WidgetIconSelect extends React.PureComponent {
  renderOption = (option) => {
    const {
      SendwiseEmbeddedWidget: { renderIcon },
    } = this.props;

    return <WidgetIcon type={option.type} name={option.value} iconRenderer={renderIcon} />;
  };

  getOptionsForEvent(type) {
    const { SendwiseEmbeddedWidget } = this.props;

    if (!SendwiseEmbeddedWidget) {
      return [];
    }

    return SendwiseEmbeddedWidget.getIconsList(type).map(value => ({ value, type }));
  }

  getOptions(type) {
    if (type === 'notification_event') {
      return [
        ...this.getOptionsForEvent('order_event'),
        ...this.getOptionsForEvent('shipment_event'),
      ];
    }

    return this.getOptionsForEvent(type);
  }

  render() {
    const {
      type,
      value,
      onChange,
    } = this.props;

    return (
      <Select
        value={value}
        valueRenderer={this.renderOption}
        optionRenderer={this.renderOption}
        options={this.getOptions(type)}
        searchable={false}
        onChange={onChange}
      >
        Icon
      </Select>
    );
  }
}

WidgetIconSelect.propTypes = {
  type: PropTypes.oneOf([...eventTypes, 'notification_event']),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default makeAsyncScriptLoader(
  getWidgetPreviewUrl,
  { globalName: 'SendwiseEmbeddedWidget' }
)(WidgetIconSelect);
