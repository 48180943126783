import React from 'react';
import PropTypes from 'prop-types';
import { getFonts, getCustomFonts } from '@sendwise/font-helpers';

import GroupedSelect from '../../common/Select/GroupedSelect';

const groupLabels = {
  standard: 'Standard web fonts',
  google: 'Google web fonts',
};

class FontSelect extends React.PureComponent {
  render() {
    const {
      allowGoogleFonts,
      ...selectProps
    } = this.props;

    const groups = allowGoogleFonts ? ['standard', 'google'] : ['standard'];

    return (
      <GroupedSelect
        {...selectProps}
        options={getFontOptions(groups)}
        groups={getFontGroups(groups)}
        placeholder="Select a font"
      >
        Font
      </GroupedSelect>
    );
  }
}

function getFontGroups(groups) {
  return groups.map(value => ({
    value,
    label: groupLabels[value],
  }));
}

function getFontOptions(groups) {
  const fonts = {
    standard: getFonts(),
    ...getCustomFonts(),
  };

  const options = [];

  groups.forEach(group => {
    fonts[group].map(value => options.push({
      value,
      label: value,
      group,
    }));
  });

  return options;
}

FontSelect.propTypes = {
  allowGoogleFonts: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FontSelect;