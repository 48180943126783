import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import container from '../container';

export const getAnalyticsDashboards = createAsyncThunk('analytics/getDashboards', (shopId) => {
  return container.touchpointsApi.getAnalyticsDashboards(shopId);
});

export const selectAnalyticsDashboards = (state) => state.analyticsDashboards;

const analyticsSlice = createSlice({
  name: 'analyticsDashboards',
  initialState: {
    dashboards: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  extraReducers: {
    [getAnalyticsDashboards.pending](state) {
      state.isLoading = true;
      state.error = null;
    },
    [getAnalyticsDashboards.fulfilled](state, action) {
      state.dashboards = action.payload;
      state.isLoaded = true;
      state.isLoading = false;
    },
    [getAnalyticsDashboards.rejected](state, action) {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export default analyticsSlice.reducer;
