import React from 'react';
import { Navigate } from 'react-router';
import useSelectedShop from '../hooks/useSelectedShop';

export function withShopSettingsRedirect() {
  return (requiredShopAccess, redirectPath = '/') => (DecoratedComponent) => (props) => {
    const shop = useSelectedShop();
    const hasAccess = !!shop[requiredShopAccess];

    if (hasAccess) {
      return <DecoratedComponent {...props} />;
    }

    return <Navigate to={redirectPath} />;
  };
}

export default withShopSettingsRedirect();
