// temporary solution to format datetime according to UI mock
import { getDate } from '../../utils/getDate';
import { format } from 'date-fns';

export default function formatDateTime(date) {
  if (!date) {
    return '';
  }

  const d = getDate(date);
  const dayAndMonth = d.toLocaleString('en-US', { month: 'short', day: '2-digit' });
  const year = d.getFullYear();
  const time = d.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

  return `${dayAndMonth}, ${year} ${time}`;
}

export function formatPublishDate(date) {
  if (!date) {
    return '';
  }

  return format(getDate(date), "dd MMM yyyy 'at' HH:mm");
}
