import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import DrawerBody from '../../common2/drawer/DrawerBody';
import DrawerFooter from '../../common2/drawer/DrawerFooter';
import ColumnsLayout from '../../common/layout/ColumnsLayout';
import LabeledValue from '../../common2/LabeledValue/LabeledValue';
import Column from '../../common/layout/Column';
import Select from '../../common2/Select';
import Button from '../../common2/Button';
import Input from '../../common2/Input';
import SidePanelSection from './SidePanelSection';
import ReturnLabelsList from './ReturnLabelsList';
import { formatDate } from '../cards/dateHelper';

function CreateReturn(props) {
  const {
    onCreate,
    onChangeLabelsCount,
    onChangeReferenceNumber,
    onChangeCarrierService,
    info: { shopOrderId, deliveryDate, carrier, customer, returnShipmentsLeft = 0 } = {},
    returnShipments = [],
    carrierServices = [],
    isLoading,
    labelsCount,
    referenceNumber,
    carrierServiceId,
  } = props;

  // pre-select first carrier service after loaded
  useEffect(() => {
    if (!carrierServiceId && carrierServices.length > 0) {
      onChangeCarrierService(carrierServices[0]);
    }
  }, [onChangeCarrierService, carrierServices, carrierServiceId]);

  const countOptions = useMemo(
    () =>
      Array.from({ length: returnShipmentsLeft }).map((_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    [returnShipmentsLeft]
  );

  const carrierServicesOptions = useMemo(
    () =>
      carrierServices.map((service) => ({
        value: service.id,
        label: service.name,
      })),
    [carrierServices]
  );

  const showLabelsList = returnShipments.length > 0;
  const onChangeLabelsCountHandler = useCallback((value) => onChangeLabelsCount(parseInt(value)), [
    onChangeLabelsCount,
  ]);

  const onChangeCarrierServiceHandler = useCallback(
    (carrierServiceId) => {
      const carrierService = carrierServices.find((service) => service.id === carrierServiceId);
      onChangeCarrierService(carrierService);
    },
    [onChangeCarrierService]
  );

  return (
    <>
      <DrawerBody>
        <Column spacing="large">
          <SidePanelSection title="Order information">
            <ColumnsLayout>
              <LabeledValue label="Order ID">{shopOrderId}</LabeledValue>
              <LabeledValue label="Delivery date">{formatDate(deliveryDate)}</LabeledValue>
              <LabeledValue label="Carrier">{carrier?.name}</LabeledValue>
              <LabeledValue label="Carrier country">{carrier?.countryName}</LabeledValue>
            </ColumnsLayout>
          </SidePanelSection>
          <SidePanelSection title="Customer information">
            <ColumnsLayout>
              <LabeledValue label="Recipient name">{customer?.name}</LabeledValue>
              <LabeledValue label="Email">{customer?.email}</LabeledValue>
              <LabeledValue label="Phone">{customer?.phone}</LabeledValue>
            </ColumnsLayout>
          </SidePanelSection>
          <SidePanelSection title="Create a return">
            {showLabelsList ? (
              <ReturnLabelsList shipments={returnShipments} />
            ) : (
              <ColumnsLayout>
                <Select
                  name="labelsCount"
                  label="Number of labels"
                  placeholder="Select an amount..."
                  helpText="RETURN_LABELS_COUNT_SELECT_HELP_TEXT"
                  menuPlacement="auto"
                  useMenuPortal
                  value={labelsCount}
                  options={countOptions}
                  onChange={onChangeLabelsCountHandler}
                  isDisabled={isLoading}
                  isRequired
                />
                {carrierServicesOptions.length > 0 && (
                  <Select
                    name="carrierServiceId"
                    label="Carrier service"
                    menuPlacement="auto"
                    useMenuPortal
                    value={carrierServiceId}
                    options={carrierServicesOptions}
                    onChange={onChangeCarrierServiceHandler}
                    isDisabled={isLoading}
                  />
                )}
                <Input
                  name="referenceNumber"
                  value={referenceNumber}
                  label="CREATE_RETURN_REF_NUMBER_INPUT_LABEL"
                  placeholder="Add a reference number..."
                  helpText="CREATE_RETURN_REF_NUMBER_INPUT_HELP_TEXT"
                  disabled={isLoading || labelsCount > 1}
                  onChange={onChangeReferenceNumber}
                />
              </ColumnsLayout>
            )}
          </SidePanelSection>
        </Column>
      </DrawerBody>
      <DrawerFooter>
        {!showLabelsList && (
          <Button disabled={!labelsCount} isLoading={isLoading} onClick={onCreate}>
            Create a return
          </Button>
        )}
      </DrawerFooter>
    </>
  );
}

CreateReturn.propTypes = {
  onCreate: PropTypes.func,
  onChangeLabelsCount: PropTypes.func,
  info: PropTypes.shape({
    shopOrderId: PropTypes.string,
    deliveryDate: PropTypes.string,
    carrier: PropTypes.shape({
      name: PropTypes.string,
      countryName: PropTypes.string,
    }),
    customer: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    returnShipmentsLeft: PropTypes.number,
  }),
  returnShipments: PropTypes.array,
  isLoading: PropTypes.bool,
  labelsCount: PropTypes.number,
};

export default React.memo(CreateReturn);
