import React from 'react';
import { Navigate } from 'react-router-dom';
import PageContainer from './pagecontainer/PageContainer';

/**
 * User for route redirects like /returns -> /returns/return-portals
 *
 * When PageContainer isn't rendered as route layout while redirecting, it will cause the page to blink.
 * To avoid it we wrap Navigate to PageContainer.
 */

function PageNavigate(props) {
  return (
    <PageContainer>
      <Navigate {...props} replace />
    </PageContainer>
  );
}

export default PageNavigate;
