import React from 'react';
import PropTypes from 'prop-types';

import './DrawerFooter.scss';

function DrawerFooter(props) {
  const { children } = props;

  return <div styleName="footer">{children}</div>;
}

DrawerFooter.propTypes = {
  children: PropTypes.node,
};

export default React.memo(DrawerFooter);
