import React from 'react';
import Locale from '../../Translate/Locale';
import './Toggle.scss';

class Toggle extends React.PureComponent {
  render() {
    const {
      children,
      ...props
    } = this.props;

    return (
      <label styleName="toggle-container">
        <input type="checkbox" styleName="toggle" {...props} />
        <div styleName="indicator">
          <div styleName="knob" />
          <span styleName="label"><Locale>{children}</Locale></span>
        </div>
      </label>
    );
  }
}

export default Toggle;