// there is a hardcoded list in tracking pages as well
const languages = {
  en: 'English',
  de: 'German',
  fr: 'French',
  it: 'Italian',
  nl: 'Dutch',
  pl: 'Polish',
  cs: 'Czech',
  sk: 'Slovak',
  da: 'Danish',
  fi: 'Finnish',
  no: 'Norwegian',
  sv: 'Swedish',
  es: 'Spanish',
  pt: 'Portuguese',
  hu: 'Hungarian',
  ro: 'Romanian',
  el: 'Greek',
};

// Returns list of available language codes
export function getLanguages() {
  return Object.keys(languages);
}

// Returns language name for specified language code
export function getLanguageName(code) {
  return languages[code];
}

export function getSelectOptions() {
  return getLanguages()
    .map((value) => ({ value, label: getLanguageName(value) }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}
