import React from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { connect } from 'react-redux';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Compressor from '@uppy/compressor';

import InputContainer from '../../common2/InputContainer';
import Invitation from './Invitation';
import DragDrop from './DragDrop';
import ProgressIndicator from './ProgressIndicator';
import FilesList from './FilesList';
import withNotifications from '../../Notification/withNotifications';

import './Uploader.scss';

export class Uploader extends React.Component {
  state = {
    showLoader: false,
  };

  constructor(props) {
    super(props);

    const {
      maxNumberOfFiles = 1,
      maxFileSize = 10 * 1024 * 1024,
      minFileSize = 1,
      endpoint = `${process.env.TOUCHPOINTS_API_URL}/files`,
      requestFieldName = 'file',
      allowedFileTypes,
      files = [],
      headers,
    } = props;

    // eslint-disable-next-line new-cap
    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles,
        maxFileSize,
        allowedFileTypes,
        minFileSize,
      },
      autoProceed: true,
      inputName: requestFieldName,
      id: nanoid(5),
    });

    this.uppy.use(XHRUpload, {
      endpoint,
      formData: true,
      fieldName: requestFieldName,
      headers: {
        ...headers,
        authorization: `Bearer ${props.token}`,
        'X-SHOP-ID': `${props.shopId}`,
      },
    });

    const quality = 0.6;
    this.uppy.use(Compressor, { quality });

    this.uppy.setState({ files: filesArrayToObject(files) });

    this.uppy.on('upload-success', this.onUpdateFiles);
    this.uppy.on('upload-error', this.onUploadError);
    this.uppy.on('restriction-failed', this.onRestrictionFailed);
    this.uppy.on('upload', this.onUpload);
    this.uppy.on('complete', this.onComplete);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.files !== this.props.files) {
      this.uppy.setState({ files: filesArrayToObject(this.props.files) });
    }
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  onUploadError = (file, error) => {
    this.props.showError('File upload error');
    this.uppy.removeFile(file.id);
    this.setUploaderBusy(false);
  };

  onUpload = () => {
    this.setUploaderBusy(true);
  };

  onComplete = () => {
    this.setUploaderBusy(false);
  };

  onRemove = (fileId) => {
    this.uppy.removeFile(fileId);
    this.onUpdateFiles();
  };

  onUpdateFiles = () => {
    const files = this.uppy.getFiles().map((file) => serializeFile(file));
    this.props.onChange(files, this.props.name);
  };

  onFileListChange = (files) => {
    this.props.onChange(files, this.props.name);
  };

  onRestrictionFailed = (file, error) => {
    this.props.showError(error.message);
  };

  setUploaderBusy = (busy) => {
    this.setState({ showLoader: busy }, () => this.props.onBusy(busy));
  };

  render() {
    const {
      label,
      isRequired,
      dropZoneText = getDefaultDropZoneText(this.props),
      helpText,
      errorWithTranslation,
      topRightElement,
      isDisabled,
      files,
      errors,
      filesList: FilesListComponent = FilesList,
    } = this.props;

    const { showLoader } = this.state;

    return (
      <div styleName="container">
        <InputContainer
          label={label}
          errorWithTranslation={errorWithTranslation}
          helpText={errorWithTranslation ? '' : helpText}
          labelIsRequired={isRequired}
          topRightElement={topRightElement}
        >
          <DragDrop uppy={this.uppy} isDisabled={isDisabled}>
            {showLoader ? (
              <ProgressIndicator>File uploading...</ProgressIndicator>
            ) : (
              <Invitation>{dropZoneText}</Invitation>
            )}
          </DragDrop>
        </InputContainer>
        <FilesListComponent files={files} errors={errors} onFilesChange={this.onFileListChange} />
      </div>
    );
  }
}

function getDefaultDropZoneText(props) {
  return props.maxNumberOfFiles > 1 ? 'UPLOAD_MULTIPLE_FILES_DROP_ZONE_TEXT' : 'UPLOAD_SINGLE_FILE_DROP_ZONE_TEXT';
}

/**
 *  @typedef {Object} UploaderFile
 *  @property id - unique file id assigned by uppy (not an actual file id from the backend)
 *  @property name - file name
 *  @property type - file type
 *  @property [response] - server response from uploading file
 *  @property [fileType] - one of claim file types
 *
 *  @property {object} data - consider deprecated and use top-level name, size and type instead
 *  @property {string} data.name - file name
 *  @property {number} data.size - file size
 *  @property {string} data.type - file type
 */

/**
 * File object that Uppy returns isn't serializable and gets modified inside store (lastModifiedDate)
 * after we open file dialog. Check https://developer.mozilla.org/en-US/docs/Web/API/File
 *
 * @param {File} file - native file object
 * @return {UploaderFile}
 */
function serializeFile(file) {
  const { data } = file;

  return {
    ...file,
    data: {
      name: data.name,
      size: data.size,
      type: data.type,
    },
  };
}

function filesArrayToObject(files) {
  const filesById = {};

  files.forEach((file) => (filesById[file.id] = file));

  return filesById;
}

Uploader.defaultProps = {
  onBusy: () => {},
};

Uploader.propTypes = {
  maxNumberOfFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  name: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  helpText: PropTypes.string,
  dropZoneText: PropTypes.string,
  errorWithTranslation: PropTypes.string,
  endpoint: PropTypes.string,
  requestFieldName: PropTypes.string, // used in multipart/form-data request
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onBusy: PropTypes.func,
  headers: PropTypes.object,
  filesList: PropTypes.elementType,
};

function mapStateToProps(state) {
  const { token } = state.auth;
  const { selectedShopId } = state.user;

  return {
    token,
    shopId: selectedShopId,
  };
}

export default connect(mapStateToProps)(withNotifications()(Uploader));
