import React from 'react';
import BannerAccess from '../StartGuidePage/BannerAccess';
import BannerNoAccess from '../StartGuidePage/BannerNoAccess';

function TrackingStartGuideBanner(props) {
  if (props.hasAccess) {
    return (
      <BannerAccess
        heading="Get started with tracking"
        description="TRACKING_START_GUIDE_BANNER_DESC"
        url="/tracking/tracking-pages"
        cta="Discover tracking pages"
        learnMoreUrl="https://support.portal.sevensenders.com/support/solutions/folders/15000003851"
      />
    );
  } else {
    return (
      <BannerNoAccess
        heading="Get started with tracking"
        description="TRACKING_START_GUIDE_BANNER_NO_ACCESS_DESK"
        cta="Get tracking now"
      />
    );
  }
}

export default React.memo(TrackingStartGuideBanner);
