import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Text from '../../common/Text';
import Locale from '../../Translate/Locale';
import Select from '../../common2/Select';
import Column from '../../common/layout/Column';
import CellPhoneIcon from '../../../icons/cell-phone.svg';
import PreviewContainer from '../return-portal-editor/ReturnPortalEditorPreview/PreviewContainer';
import ReturnPortalPreview from '../return-portal-editor/ReturnPortalEditorPreview/ReturnPortalPreview';

import { getPageOptions } from './previewHelpers';

import { returnRequest, returnRequestLite, order, settings } from '../return-portal-editor/previewData';

import './ReturnPortalTranslationsPreview.scss';

function ReturnPortalTranslationsPreview(props) {
  const { translations, isLite } = props;
  const [selectedPage, setSelectedPage] = useState('login');
  const pageOptions = getPageOptions({ isLite });

  const availableLanguages = Object.keys(translations);

  return (
    <PreviewContainer>
      <div styleName="container">
        <Column spacing="extra-small">
          <div styleName="item heading">
            <CellPhoneIcon styleName="icon" />
            <Text title>
              <Locale>Mobile preview</Locale>
            </Text>
          </div>
          <div styleName="item preview">
            <ReturnPortalPreview
              key="preview"
              page={selectedPage}
              settings={settings}
              translations={translations}
              availableLanguages={availableLanguages}
              returnRequest={isLite ? returnRequestLite : returnRequest}
              isLite={isLite}
              order={order}
            />
          </div>
          <div styleName="item controls">
            <Column spacing="extra-small">
              <Select
                name="page"
                data-testid="screen-select"
                menuPlacement="auto"
                value={selectedPage}
                options={pageOptions}
                onChange={setSelectedPage}
              />
            </Column>
          </div>
        </Column>
      </div>
    </PreviewContainer>
  );
}

ReturnPortalTranslationsPreview.propTypes = {
  translations: PropTypes.object,
};

export default React.memo(ReturnPortalTranslationsPreview);
