export default function groupBy(items, getKey) {
  return items.reduce(
    (groupped, item) => {
      const key = getKey(item);
      (groupped[key] || (groupped[key] = [])).push(item);
      return groupped;
    },
    {}
  )
}
