import ReactGA from 'react-ga';

export function trackEvent(event) {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event(event);
  }
}

export function trackPageview(url) {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(url);
  }
}