import React from 'react';

import DetailsCard from '../DetailsCard';
import DetailsCardItem from '../DetailsCard/DetailsCardItem';

function RecipientDetailsCard(props) {
  const { title, recipient, children } = props;

  if (!recipient) {
    return null;
  }

  return (
    <DetailsCard title={title}>
      <DetailsCardItem label="Name">{recipient.name}</DetailsCardItem>
      <DetailsCardItem label="Email">{recipient.email}</DetailsCardItem>
      <DetailsCardItem label="Phone">{recipient.phone}</DetailsCardItem>
      <DetailsCardItem label="Delivery address">{createAddressStringFromRecipient(recipient)}</DetailsCardItem>
      {children}
    </DetailsCard>
  );
}

function createAddressStringFromRecipient(recipient) {
  return [
    recipient.address ?? '',
    recipient.company ?? '',
    `${recipient.zipCode ?? ''} ${recipient.city ?? ''}`,
    recipient.countryName ?? '',
  ]
    .filter((s) => s.trim())
    .filter((s) => !!s)
    .join('\n');
}

export default React.memo(RecipientDetailsCard);
