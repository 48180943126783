import { trackingPageColors, emailTemplateColors } from '@sendwise/default-settings';

import visualEditorTypeEnum from './visualEditorTypeEnum';

const defaultColors = {
  [visualEditorTypeEnum.TRACKING_PAGE]: trackingPageColors,
  [visualEditorTypeEnum.EMBEDDED_WIDGET]: trackingPageColors,
  [visualEditorTypeEnum.EMAIL_TEMPLATE]: emailTemplateColors,
};

export function getDefaultColors(editorType) {
  return defaultColors[editorType];
}