import React from 'react';
import ClaimsTableCell from './ClaimsTableCell';
import Text from '../../common/Text/Text';
import Locale from '../../Translate/Locale';

import './ClaimsTableHeader.scss';

function ClaimsTableHeader() {
  return (
    <thead>
      <tr>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Status</Locale>
          </Text>
        </ClaimsTableCell>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Type</Locale>
          </Text>
        </ClaimsTableCell>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Value</Locale>
          </Text>
        </ClaimsTableCell>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Submission date</Locale>
          </Text>
        </ClaimsTableCell>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Shipment tracking code</Locale>
          </Text>
        </ClaimsTableCell>
        <ClaimsTableCell as="th">
          <Text styleName="title">
            <Locale>Actions</Locale>
          </Text>
        </ClaimsTableCell>
      </tr>
    </thead>
  );
}

export default ClaimsTableHeader;
