import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import container from '../container';
import { getNextSectionId } from '../components/returns/return-portal-editor/returnPortalEditorHelpers';
import { getReturnPortalT } from '../components/returns/i18n';
import { getTranslatedDefaultSettings } from '../components/returns/translators';
import { fromCentsToCurrencyString, fromCurrencyStringToCents } from '../components/returns/currencyHelpers';
import { isApiError } from '../api/Api';

const initialState = {
  isLoading: false,
  error: null,
  verifiedEmailAddresses: [],
};

class NoAccessError extends Error {
  constructor() {
    super();
    this.name = 'NoAccessError';
  }
}

export const getReturnPortal = createAsyncThunk(
  'returnPortalEditor/getReturnPortal',
  ({ shopId, portalHash }, { rejectWithValue }) => {
    return container.touchpointsApi.getReturnPortal(shopId, portalHash).catch((err) => {
      if (isApiError(err) && err.response.status === 403) {
        return rejectWithValue(new NoAccessError());
      }

      return rejectWithValue(err);
    });
  }
);

export const applyDefaultSettings = createAsyncThunk(
  'returnPortalEditor/applyDefaultValues',
  async ({ defaultSettings = {} }, { getState }) => {
    const { returnPortal } = getState().returnPortalEditor;
    const t = await getReturnPortalT(returnPortal.language);
    return getTranslatedDefaultSettings(defaultSettings, t);
  }
);

export const saveReturnPortal = createAsyncThunk(
  'returnPortalEditor/saveReturnPortal',
  ({ shopId, portalHash }, { getState }) => {
    const data = selectDataForUpdate(getState());
    return container.touchpointsApi.updateReturnPortal(shopId, portalHash, data);
  }
);

export const publishReturnPortal = createAsyncThunk(
  'returnPortalEditor/publishReturnPortal',
  ({ shopId, portalHash }, { rejectWithValue, getState }) => {
    const data = selectDataForUpdate(getState());

    return container.touchpointsApi
      .updateReturnPortal(shopId, portalHash, data)
      .then(() => container.touchpointsApi.publishReturnPortal(shopId, portalHash));
  }
);

export const isEmailAddressVerified = createAsyncThunk('returnPortalEditor/isEmailAddressVerified', ({ email }) => {
  return container.touchpointsApi.isEmailAddressVerified(email);
});

export const sendTestEmail = createAsyncThunk(
  'returnPortalEditor/sendTestEmail',
  ({ shopId, settings, ...requestData }) => {
    return container.touchpointsApi.sendTestReturnConfirmationEmail(shopId, {
      ...requestData,
      settings: toServerSettings(settings),
    });
  }
);

const slice = createSlice({
  name: 'returnPortalEditor',
  initialState,
  reducers: {
    clearEditor() {
      return initialState;
    },
    changeSettings: (state, action) => {
      state.isReturnPortalChanged = true;
      Object.assign(state.returnPortal.settings, action.payload);
    },
    toggleSection: (state, action) => {
      const clickedSectionId = action.payload;
      const { expandedSectionId, returnPortal } = state;

      if (!returnPortal.sectionsDone) {
        returnPortal.sectionsDone = {};
      }

      if (expandedSectionId) {
        returnPortal.sectionsDone[expandedSectionId] = true;
      }

      state.expandedSectionId =
        clickedSectionId === expandedSectionId ? getNextSectionId(clickedSectionId, returnPortal) : clickedSectionId;
    },
  },
  extraReducers: {
    [getReturnPortal.pending](state) {
      state.isLoading = true;
      state.isPortalLoaded = false;
      state.error = null;
      state.isReturnPortalChanged = false;
    },
    [getReturnPortal.fulfilled](state, action) {
      state.returnPortal = {
        ...action.payload,
        settings: fromServerSettings(action.payload.settings),
      };

      if (state.returnPortal.isEmailVerified) {
        state.verifiedEmailAddresses = [state.returnPortal.settings.emailAddress];
      }

      state.isLoading = false;
      state.isPortalLoaded = true;
    },
    [getReturnPortal.rejected](state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    [saveReturnPortal.pending](state) {
      state.isLoading = true;
    },
    [saveReturnPortal.fulfilled](state) {
      state.isLoading = false;
      state.isReturnPortalChanged = false;
    },
    [saveReturnPortal.rejected](state) {
      state.isLoading = false;
    },
    [publishReturnPortal.pending](state) {
      state.isLoading = true;
    },
    [publishReturnPortal.fulfilled](state) {
      state.isLoading = false;
      state.isReturnPortalChanged = false;
    },
    [publishReturnPortal.rejected](state) {
      state.isLoading = false;
    },
    [isEmailAddressVerified.pending](state) {
      state.isEmailVerificationInProgress = true;
    },
    [isEmailAddressVerified.fulfilled](state, action) {
      const { email, isVerified } = action.payload;

      if (isVerified && !state.verifiedEmailAddresses.includes(email)) {
        state.verifiedEmailAddresses = [...state.verifiedEmailAddresses, email];
      }

      state.isEmailVerificationInProgress = false;
    },
    [isEmailAddressVerified.rejected](state) {
      state.isEmailVerificationInProgress = false;
    },
    [applyDefaultSettings.pending](state) {
      state.isLoading = true;
    },
    [applyDefaultSettings.fulfilled](state, action) {
      const defaultSettings = action.payload;
      const { returnPortal } = state;

      state.isLoading = false;

      returnPortal.settings = { ...defaultSettings, ...returnPortal.settings };

      // optional sections are done by default for already published return portals
      if (returnPortal.isPublished) {
        returnPortal.sectionsDone.keepItems = true;
        returnPortal.sectionsDone.translations = true;
      }
    },
    [applyDefaultSettings.rejected](state, action) {
      state.isLoading = false;
    },
  },
});

export const { changeSettings, toggleSection, clearEditor } = slice.actions;

function fromServerSettings(settings) {
  const { returnPeriod, keepItemsCutoffValue, ...rest } = settings;

  return {
    ...rest,
    returnPeriod: (returnPeriod || '').toString(),
    keepItemsCutoffValue: fromCentsToCurrencyString(keepItemsCutoffValue),
  };
}

function toServerSettings(settings) {
  const { returnPeriod, keepItemsCutoffValue, reasonOptions, ...rest } = settings;

  return {
    ...rest,
    returnPeriod: parseIntWithDefault(returnPeriod),
    keepItemsCutoffValue: fromCurrencyStringToCents(keepItemsCutoffValue),
  };
}

function parseIntWithDefault(strValue, defaultValue = 0) {
  const value = parseInt(strValue);
  return isNaN(value) ? defaultValue : value;
}

function selectDataForUpdate(state) {
  const { settings, sectionsDone } = state.returnPortalEditor.returnPortal;
  return { settings: toServerSettings(settings), sectionsDone };
}

export default slice.reducer;
