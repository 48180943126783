import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {
  legacyByShop: {},
};

const legacyTrackingPageReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'LEGACY_TRACKING_PAGE')) {
    return requestProducer(draft, action, 'LEGACY_TRACKING_PAGE', {
      successProducer: (draft, action) => {
        const { shopId, data } = action;
        draft.legacyByShop[shopId] = data;
      },
    });
  }

  if (action.type === 'LEGACY_TRACKING_PAGE_NOT_FOUND') {
    draft.isLoading = false;
    draft.loaded = true;
    delete draft.legacyByShop[action.shopId];
  }

  return draft;
});

export default legacyTrackingPageReducer;