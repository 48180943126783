import React from 'react';

import SidePanelHeader from '../../common2/side-panel/SidePanelHeader';

function TakeActionPanelHeading({ children, onClose }) {
  return (
    <SidePanelHeader
      onClose={onClose}
      heading={children}
      helpLink="https://support.portal.sevensenders.com/support/solutions/articles/15000047925-the-claim-centre"
    />
  );
}

export default React.memo(TakeActionPanelHeading);
