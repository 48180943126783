import React from 'react';
import PropTypes from 'prop-types';

import WizardStep from '../Wizard/WizardStep';
import SelectLanguage from '../common/SelectLanguage';

import LocalizationIcon from '../../icons/artwork/localization.svg';

class WidgetLanguagePage extends React.PureComponent {
  state = {
    language: this.props.language,
  };

  onChange = option => {
    const {
      value,
    } = option;

    this.setState({ language: value });
  }

  onSave = () => {
    this.props.onSelectLanguage(this.state.language);
  };

  render() {
    const { language } = this.state;

    return (
      <WizardStep
        {...this.props}
        header="Choose a language"
        tooltipText="The language will be set automatically based on the browser settings of the user"
        icon={LocalizationIcon}
        onSubmit={this.onSave}
        disabled={!this.state.language}
      >
        <SelectLanguage
          value={language}
          onChange={this.onChange}
        />
      </WizardStep>
    );
  }
}

WidgetLanguagePage.defaultProps = {
  onSelectLanguage: () => {},
};

WidgetLanguagePage.propTypes = {
  onSelectLanguage: PropTypes.func,
  language: PropTypes.string,
};

export default WidgetLanguagePage;