import React from 'react';
import AnalyticsPage from './AnalyticsPage';
import LookerDashboard from './LookerDashboard';

const ExplorePage = (props) => (
  <AnalyticsPage {...props} menuCollapsed>
    <LookerDashboard dashboardId="explore" type="explore" />
  </AnalyticsPage>
);

export default ExplorePage;