import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Column.scss';

function Column(props) {
  const { spacing = 'medium', children } = props;

  const styleName = classNames('column', spacing);

  const childrenArray = React.Children.toArray(children);
  const truthyChildren = childrenArray.filter(Boolean);

  return (
    <div styleName={styleName}>
      {truthyChildren.map((child, i) => (
        <div key={i} styleName="item">
          {child}
        </div>
      ))}
    </div>
  );
}

Column.propTypes = {
  spacing: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large']),
};

export default Column;
