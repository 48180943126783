import React from 'react';

import ClaimStepContainer from './common/ClaimStepContainer';
import SelectorWrapper from './common/SelectorWrapper';
import TypeButton from './common/TypeButton';

import DamageIcon from '../../../icons/damage.svg';

function ChooseDamageTypeStep(props) {
  const { onClickPartialDamage, onClickWholeDamage, ...restProps } = props;

  return (
    <ClaimStepContainer {...restProps}>
      <SelectorWrapper>
        <TypeButton
          icon={DamageIcon}
          title="Partial damage"
          type="warning"
          description="PARTIAL_DAMAGE_CHOICE_DESCRIPTION"
          onClick={onClickPartialDamage}
        />
        <TypeButton
          icon={DamageIcon}
          title="Whole damage"
          type="danger"
          description="WHOLE_DAMAGE_CHOICE_DESCRIPTION"
          onClick={onClickWholeDamage}
        />
      </SelectorWrapper>
    </ClaimStepContainer>
  );
}

export default React.memo(ChooseDamageTypeStep);
