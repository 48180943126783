import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Locale from '../../Translate/Locale';

import './DefaultBadge.scss';

const DefaultBadge = ({ changed }) => {
  const styleName = classNames('default-badge', { changed });
  return <div styleName={styleName}><Locale>{ changed ? 'Custom settings' : 'Default settings' }</Locale></div>;
};

DefaultBadge.propTypes = {
  changed: PropTypes.bool,
};

export default DefaultBadge;