import { useCallback, useEffect, useRef } from 'react';

/**
 * Simplifies creation and updating live preview
 *
 * @param globalInstance - globalName from makeAsyncScriptLoader
 * @param previewName - field name in globalInstance associated with preview
 * @param props - preview props
 * @param deps - dependency list to optimize preview update
 * @return ref do be passed to placeholder container
 */
function useLivePreview({ globalInstance, previewName, props }) {
  const previewRef = useRef(null);

  useEffect(() => {
    previewRef.current && previewRef.current.setProps(props);
  }, [props]);

  const elementRef = useCallback(
    (ref) => {
      if (!ref) {
        previewRef.current && previewRef.current.unmount();
        return;
      }

      previewRef.current = globalInstance[previewName](ref, props);
    },
    [globalInstance]
  );

  return globalInstance ? elementRef : null;
}

export default useLivePreview;
