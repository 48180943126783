import React from 'react';
import { connect } from 'react-redux';

import useApi from '../../../hooks/useApi';
import { selectedShopIdSelector } from '../../../reducers/selectors';
import SettingsPage from '../../SettingsPage';
import SettingsBodyWrapper from '../../SettingsPage/SettingsBodyWrapper';
import Loader from '../../common/Loader';
import UsersList from './UserList';
import IntercomMessengerButton from '../../common/IntercomMessengerButton';
import HelpCard from '../../HelpCard/HelpCard';

import LightHouseIcon from '../../../icons/artwork/lighthouse.svg';

import './UserManagementPage.scss';

const link = 'https://support.portal.sevensenders.com/support/solutions/articles/15000024256-managing-sendwise-user-';

function UserManagementPage(props) {
  const { shopId } = props;
  const [users, loading] = useApi(({ touchpoints }) => touchpoints.getUsers(shopId), [shopId]);
  return (
    <SettingsPage
      {...props}
      title="User management"
      renderHeaderElements={() => <IntercomMessengerButton>Add or change users</IntercomMessengerButton>}
    >
      <SettingsBodyWrapper>
        <div styleName="container">
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <UsersList users={users} />
              <div styleName="helpcard-wrapper">
                <HelpCard
                  title="Get Help"
                  icon={LightHouseIcon}
                  description="Learn more about managing users."
                  linkUrl={link}
                  linkText="Read our documentation"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </SettingsBodyWrapper>
    </SettingsPage>
  );
}

function mapStateToProps(state) {
  return {
    shopId: selectedShopIdSelector(state),
  };
}

export default connect(mapStateToProps)(UserManagementPage);
