import React from 'react';

import CheckMark from '../../../icons/checkmark.svg';
import Locale from '../../Translate/Locale';

import './MessageWithIcon.scss';

function MessageWithIcon(props) {
  const { children } = props;

  return (
    <div styleName="message">
      <CheckMark styleName="icon" />
      <Locale>{children}</Locale>
    </div>
  );
}

export default MessageWithIcon;