import React from 'react';
import PropTypes from 'prop-types';

import TypographySettings from '../../WidgetEditionPage/WidgetDesignSettings/TypographySettings';

const settingsGroups = [
  {
    title: 'Headings',
    subtitle: 'This is the top section of your page and widgets',
    settings: {
      fontKey: 'headingFont',
      sizeKey: 'headingFontSize',
      fontSizes: [16, 18, 20],
    },
  },
  {
    title: 'Body text',
    subtitle: 'The body is the middle section of your page, most details are shown here',
    settings: {
      fontKey: 'bodyFont',
      sizeKey: 'bodyFontSize',
      fontSizes: [10, 12, 14],
    },
  },
];

const defaultSettings = {
  headingFont: 'Open Sans',
  headingFontSize: 18,

  bodyFont: 'Open Sans',
  bodyFontSize: 12,
};

function TrackingPageTypographySettings(props) {
  return (
    <TypographySettings
      {...props}
      groups={settingsGroups}
      defaultSettings={defaultSettings}
    />
  );
}

TrackingPageTypographySettings.propTypes = {
  settings: PropTypes.object,
  onSettingsChange: PropTypes.func,
};

export default TrackingPageTypographySettings;