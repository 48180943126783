import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import LoginContainer from './LoginContainer';
import LoginForm from './LoginForm';
import Locale from '../Translate/Locale';
import login from '../../actions/login';
import Heading1 from '../common/Heading1';
import ErrorIcon from '../../icons/error2.svg';

import './LoginPage.scss';
import { useLoginRedirect } from './useLoginRedirect';

function LoginPage() {
  const dispatch = useDispatch();
  const { auth, resetPassword } = useSelector((state) => state);

  const onSubmit = useCallback(
    ({ email, password }) => {
      dispatch(login(email, password));
    },
    [dispatch]
  );

  useLoginRedirect();

  return (
    <LoginContainer>
      <div styleName="login-box">
        <Heading1 styleName="title">Sign in to Sendwise</Heading1>
        <div styleName={classnames('sub-title', { error: !!auth.error })}>
          <Locale>Enter your details below</Locale>
        </div>
        {auth.error && (
          <div styleName="error-container" data-test-id="login-error">
            <ErrorIcon styleName="icon" />
            <Locale>Sorry, the credentials used are wrong. Please try again or create a new account</Locale>
          </div>
        )}
        {resetPassword.status && (
          <div styleName="reset-container">
            <Locale>Your password has been reset. Please sign in again</Locale>
          </div>
        )}
        <LoginForm onSubmit={onSubmit} isLoading={auth.isLoading} />
      </div>
    </LoginContainer>
  );
}

export default LoginPage;
