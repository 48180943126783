import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Locale } from '../../Translate';

import './StatusBadge.scss';

const StatusBadge = ({ children, published, neutral }) => (
  <span styleName={classNames('status', { published, neutral })}>
    <Locale>{children}</Locale>
  </span>
);

StatusBadge.propTypes = {
  children: PropTypes.string,
};

export default StatusBadge;