import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

/* !! DO NOT USE FOR NEW CODE !!
 * We have plenty of old class-components which rely on old react-router API where params and history
 * were passed as props. To avoid re-writing them and potentially introducing bugs we can use this HOC
 * to emulate the old behavior. Works similar to old withRouter HOC.
 */
function withLegacyRouter(WrappedComponent) {
  return function (props) {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const match = useMemo(() => ({ params }), [params]);
    const history = useMemo(
      () => ({
        push: (path) => navigate(path),
        replace: (path) => navigate(path, { replace: true }),
        back: () => navigate(-1),
      }),
      [navigate]
    );

    return <WrappedComponent {...props} location={location} match={match} history={history} />;
  };
}

export default withLegacyRouter;
