import React from 'react';
import { getParcelFinderResultsState } from './parcelFinderResultsHelper';
import ParcelFinderStart from './ParcelFinderStart';
import ParcelFinderLoader from './ParcelFinderLoader';
import ParcelFinderNoResults from './ParcelFinderNoResults';
import ParcelFinderResults from './ParcelFinderResults';
import Switch from '../Wizard/Switch';

function ParcelFinderSwitch(props) {
  const { onClaimShipment, onCreateReturn } = props;

  const { orders } = props.results || {};

  return (
    <Switch selected={getParcelFinderResultsState(props)}>
      <ParcelFinderStart key="start" />
      <ParcelFinderLoader key="loader" />
      <ParcelFinderNoResults key="no-results" />
      <ParcelFinderResults
        key="results"
        orders={orders}
        onClaimShipment={onClaimShipment}
        onCreateReturn={onCreateReturn}
      />
    </Switch>
  );
}

export default ParcelFinderSwitch;
