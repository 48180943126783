import { isRequestAction, requestProducer } from './requestReducerHelper';
import produce from 'immer';

const initialState = {};

const shopIntegrationsReducer = (state = initialState, action = {}) => produce(state, draft => {
  if (isRequestAction(action, 'GET_SHOP_INTEGRATIONS')) {
    return requestProducer(draft, action, 'GET_SHOP_INTEGRATIONS', {
      requestProducer: (draft) => draft.accessKey = '',
      successProducer: (draft, action) => draft.integrations = action.data
    });
  }

  return draft;
});

export default shopIntegrationsReducer;