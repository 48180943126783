import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-55440238-8');
}

function PageViewsAnalyticsTracker() {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  return null;
}

export default PageViewsAnalyticsTracker;
