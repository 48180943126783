import React from 'react';
import BannerAccess from '../StartGuidePage/BannerAccess';
import BannerNoAccess from '../StartGuidePage/BannerNoAccess';

function ReturnsStartGuideBanner(props) {
  if (props.hasAccess) {
    return (
      <BannerAccess
        heading="Get started with returns"
        description="START_GUIDE_RETURNS_BANNER_ACCESS_DESC"
        url="/returns/return-portals"
        cta="Discover returns"
      />
    );
  } else {
    return (
      <BannerNoAccess
        heading="Get started with returns"
        description="START_GUIDE_RETURNS_BANNER_NO_ACCESS_DESC"
        cta="Get returns now"
      />
    );
  }
}

export default React.memo(ReturnsStartGuideBanner);
