import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import WizardStep from './WizardStep';
import Localizations from './Localizations';

import LocalizationIcon from '../../icons/artwork/localization.svg';

function ChooseLocalizationStep(props) {
  const { localizations = [], localizationId, onSubmit, ...stepProps } = props;
  const [selectedLocalizationId, setSelectedLocalizationId] = useState(localizationId);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedLocalizationId);
  }, [onSubmit, selectedLocalizationId]);

  return (
    <WizardStep
      {...stepProps}
      header="Choose a Localization"
      icon={LocalizationIcon}
      onSubmit={handleSubmit}
      disabled={!selectedLocalizationId}
    >
      <Localizations
        onChange={setSelectedLocalizationId}
        shopLocalisationId={selectedLocalizationId}
        localizations={localizations}
      />
    </WizardStep>
  );
}

ChooseLocalizationStep.propTypes = {
  localizations: PropTypes.array,
  localizationId: PropTypes.number,
  onSubmit: PropTypes.func,
};

export default React.memo(ChooseLocalizationStep);
