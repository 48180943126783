import React from 'react';
import PropTypes from 'prop-types';

import SidePanelContainer from '../../common2/side-panel/SidePanelContainer';
import SidePanelContent from '../../common2/side-panel/SidePanelContent';
import SidePanelFooter from '../../common2/side-panel/SidePanelFooter';
import Column from '../../common/layout/Column';
import Message from '../../common2/Message';
import TakeActionPanelHeading from './TakeActionPanelHeading';
import DeclinedDateSection from './DeclinedDateSection';
import DeclinedReasonSection from './DeclinedReasonSection';
import Locale from '../../Translate/Locale';

function DisputeDeclinedReasonPanel(props) {
  const { claim, onClose, customerResponse } = props;

  return (
    <SidePanelContainer>
      <TakeActionPanelHeading onClose={onClose}>Declined</TakeActionPanelHeading>
      <SidePanelContent>
        <Column spacing="medium">
          <DeclinedDateSection rejectedAt={claim.rejectedAt} />
          <DeclinedReasonSection customerResponse={customerResponse} />
          <Message intent="danger">
            <Locale>CLAIM_DECLINED_TOO_LATE_TO_DISPUTE</Locale>
          </Message>
        </Column>
      </SidePanelContent>
      <SidePanelFooter />
    </SidePanelContainer>
  );
}

DisputeDeclinedReasonPanel.propTypes = {
  claim: PropTypes.shape({
    rejectedAt: PropTypes.string,
  }),
  customerResponse: PropTypes.object,
  onClose: PropTypes.func,
};

export default React.memo(DisputeDeclinedReasonPanel);
