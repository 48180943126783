import React from 'react';

import Column from '../common/layout/Column';
import StartGuideCard from './StartGuidePage/StartGuideCard';
import StartGuideTabs from './StartGuidePage/StartGuideTabs';
import StartGuideTabContent from './StartGuidePage/StartGuideTabContent';
import StartGuideBulletedList from './StartGuidePage/StartGuideBulletedList';
import BulletedListItem from '../common/bulleted-list/BulletedListItem';
import StartGuideImage from './StartGuidePage/StartGuideImage';
import CenterLayout from '../common2/CenterLayout/CenterLayout';
import StartGuideHeading from './StartGuidePage/StartGuideHeading';
import ReturnsContainer from '../returns/ReturnsContainer';
import ReturnsStartGuideBanner from './banners/ReturnsStartGuideBanner';
import usePermissionAccess from '../../hooks/usePermissionAccess';
import { RETURNS } from '../../firewall/permissions';

const tabs = [
  {
    id: 'return-portal',
    title: 'Return portal',
  },
  {
    id: 'tracking',
    title: 'Tracking and notifications',
  },
  {
    id: 'analytics',
    title: 'Analytics',
  },
];

export function ReturnsStartGuide(props) {
  const hasAccess = usePermissionAccess(RETURNS);
  return (
    <ReturnsContainer {...props}>
      <CenterLayout>
        <StartGuideHeading />
        <Column spacing="large">
          <ReturnsStartGuideBanner hasAccess={hasAccess} />
          <StartGuideCard heading="START_GUIDE_RETURNS_CONTENT_HEADING" description="START_GUIDE_RETURNS_CONTENT_DESC">
            <StartGuideTabs tabs={tabs}>
              <StartGuideTabContent key="return-portal">
                <StartGuideBulletedList>
                  <BulletedListItem>START_GUIDE_RETURNS_PORTAL_ITEM_0</BulletedListItem>
                  <BulletedListItem>START_GUIDE_RETURNS_PORTAL_ITEM_1</BulletedListItem>
                  <BulletedListItem>START_GUIDE_RETURNS_PORTAL_ITEM_2</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/returns/portal.jpg" alt="return-portal" />
              </StartGuideTabContent>
              <StartGuideTabContent key="tracking">
                <StartGuideBulletedList>
                  <BulletedListItem>START_GUIDE_RETURNS_TRACKING_ITEM_0</BulletedListItem>
                  <BulletedListItem>START_GUIDE_RETURNS_TRACKING_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/returns/tracking.jpg" alt="tracking and notifications" />
              </StartGuideTabContent>
              <StartGuideTabContent key="analytics">
                <StartGuideBulletedList>
                  <BulletedListItem>START_GUIDE_RETURNS_ANALYTICS_ITEM_0</BulletedListItem>
                  <BulletedListItem>START_GUIDE_RETURNS_ANALYTICS_ITEM_1</BulletedListItem>
                </StartGuideBulletedList>
                <StartGuideImage src="/images/start-guides/returns/analytics.png" alt="analytics" />
              </StartGuideTabContent>
            </StartGuideTabs>
          </StartGuideCard>
        </Column>
      </CenterLayout>
    </ReturnsContainer>
  );
}

export default React.memo(ReturnsStartGuide);
