import React from 'react';
import PropTypes from 'prop-types';

import './Menu.scss';

class Menu extends React.PureComponent {
  onClick = () => this.props.onClose && this.props.onClose();

  render() {
    return (
      <div styleName="menu" role="menu" onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  }
}

Menu.propTypes = {
  onClose: PropTypes.func,
};

export default Menu;
