import React from 'react';
import PropTypes from 'prop-types';
import TabControl from '../common/TabControl';
import WidgetsList from './WidgetsList';
import WidgetDesignList from './WidgetDesignList';
import Switch from '../Wizard/Switch';
import ExitHeader from './ExitHeader';
import { editorTypeProp } from '../editors/editorHelpers';

import './ConfigurationTabs.scss';

const tabs = [
  { id: 'widgets', title: 'Widgets' },
  { id: 'design', title: 'Page design' },
];

class ConfigurationTabs extends React.PureComponent {
  render() {
    const {
      widgets,
      selected,
      onSelectTab,
      onSelectDesignSettings,
      onShowAddWidgetPanel,
      title,
      onExit,
    } = this.props;

    return (
      <div styleName="configuration">
        <div styleName="header">
          <ExitHeader title={title} onExit={onExit} />
        </div>
        <div styleName="body">
          <TabControl selected={selected} tabs={tabs} onSelect={onSelectTab} editor />
          <Switch selected={selected}>
            <WidgetsList
              key="widgets"
              widgets={widgets}
              onShowAddWidgetPanel={onShowAddWidgetPanel}
            />
            <WidgetDesignList key="design" onSelect={onSelectDesignSettings} />
          </Switch>
        </div>
      </div>
    );
  }
}

ConfigurationTabs.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onSelectTab: PropTypes.func,
  onSelectDesignSettings: PropTypes.func,
  onShowAddWidgetPanel: PropTypes.func,
  title: PropTypes.string,
  onExit: PropTypes.func,
};

export default ConfigurationTabs;
