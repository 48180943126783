import React from 'react';
import PropTypes from 'prop-types';

import './ArchivedSeparator.scss';
import Heading2 from '../common/Heading2/Heading2';

function ArchivedSeparator({ children }) {
  return (
    <Heading2 styleName="separator">
      {children}
    </Heading2>
  );
}

ArchivedSeparator.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ArchivedSeparator;