import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';
import Button from '../../common2/Button';
import ProgressBar from '../../common2/ProgressBar';

import './ClaimsPaginator.scss';
function ClaimsPaginator(props) {
  const { max, value, isLoading, onLoadMoreBtnClick } = props;

  return (
    <div styleName="container">
      <div styleName="header">
        <Locale options={{ value, max }}>{'Showing {{value}} of {{max}} results'}</Locale>
      </div>
      <div styleName="progress-bar">
        <ProgressBar max={max} value={value} />
      </div>
      {value < max && (
        <Button intent="base" isLoading={isLoading} onClick={onLoadMoreBtnClick}>
          Load more
        </Button>
      )}
    </div>
  );
}

ClaimsPaginator.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
  onLoadMoreBtnClick: PropTypes.func,
};

export default React.memo(ClaimsPaginator);
