import React from 'react';
import Input from './Input';
import { isUrlValid } from '../../utils/urlValidationHelper';

class URLInput extends React.PureComponent {
  render() {
    return <Input {...this.props} error={!isUrlValid(this.props.value || '') && 'The url provided is invalid'} />;
  }
}

export default URLInput;