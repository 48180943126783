import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TouchpointsOverview from '../touchpoints/TouchpointsOverview/TouchpointsOverview';
import LegacyTrackingPage from './LegacyTrackingPage';
import TrackingPagesList from './TrackingPagesList';
import ConfirmDefaultTrackingPageModal from './ConfirmDefaultTrackingPageModal';
import ConfirmTrackingPageDeleteModal from './ConfirmTrackingPageDeleteModal/ConfirmTrackingPageDeleteModal';
import DuplicateTrackingPageModal from '../TrackingPagesPage/DuplicateTrackingPageModal';
import RenameModal from '../touchpoints/RenameModal';
import TrackingPageLinkModal from './TrackingPageLinkModal';

import container from '../../container';
import { duplicateTrackingPage } from '../../actions/trackingPages';
import { withNotifications } from '../Notification';

function TrackingPagesOverview(props) {
  const {
    shopLocalizations,
    trackingPages,
    legacy,
    shopId,
    createUrl,
    resolveWithNotice,
    getTrackingPages,
    getLegacyTrackingPage,
    setDefaultTrackingPage,
    makeDefaultLegacyTrackingPage,
    removeLegacyTrackingPage,
  } = props;

  const [trackingPageToDuplicate, setTrackingPageToDuplicate] = useState(null);
  const [trackingPageToRename, setTrackingPageToRename] = useState(null);
  const [trackingPageToDelete, setTrackingPageToDelete] = useState(null);
  const [trackingPageForLiveLink, setTrackingPageForLiveLink] = useState(null);
  const [makeDefaultCallback, setMakeDefaultCallback] = useState(null);

  const onRemoveLegacy = () => removeLegacyTrackingPage(shopId);

  const onMakeDefaultLegacy = () => setMakeDefaultCallback(() => makeDefaultLegacyTrackingPage(shopId));

  const onMakeDefault = (trackingPageId) => setMakeDefaultCallback(() => () => setDefaultTrackingPage(trackingPageId));

  const onDelete = (trackingPage) => setTrackingPageToDelete(trackingPage);

  const onDuplicate = (trackingPage) => setTrackingPageToDuplicate(trackingPage);

  const onRename = (trackingPage) => setTrackingPageToRename(trackingPage);

  const onClickLiveLink = (trackingPage) => setTrackingPageForLiveLink(trackingPage);

  const onCloseLiveLinkModal = () => setTrackingPageForLiveLink(null);

  const onConfirmDefaultTrackingPage = () => {
    if (makeDefaultCallback) {
      makeDefaultCallback().then(() => {
        getTrackingPages(shopId);
        getLegacyTrackingPage(shopId);
      });
      setMakeDefaultCallback(null);
    }
  };
  const onCancelConfirmDefaultTrackingPage = () => setMakeDefaultCallback(null);

  const onDeleteTrackingPage = () => {
    if (trackingPageToDelete) {
      const apiResult = container.api.removeTrackingPage(trackingPageToDelete.id);
      resolveWithNotice(apiResult, 'Tracking page successfully deleted').then(
        ({ success }) => success && getTrackingPages(shopId)
      );
      setTrackingPageToDelete(null);
    }
  };

  const onCancelDeleteTrackingPage = () => setTrackingPageToDelete(null);

  const onDuplicateTrackingPage = (trackingPageId, newData) => {
    setTrackingPageToDuplicate(null);
    const result = duplicateTrackingPage(trackingPageId, newData, shopId);
    resolveWithNotice(result, 'The tracking page has been successfully duplicated').then(
      ({ success }) => success && getTrackingPages(shopId)
    );
  };

  const onCancelDuplicateTrackingPage = () => setTrackingPageToDuplicate(null);

  const onRenameTrackingPage = (name) => {
    setTrackingPageToRename(null);
    if (trackingPageToRename && trackingPageToRename.name !== name) {
      const apiResult = container.api.updateTrackingPageSettings(trackingPageToRename.id, { name });
      resolveWithNotice(apiResult, 'Tracking page was successfully renamed').then(
        ({ success }) => success && getTrackingPages(shopId)
      );
    }
  };

  const onCancelRenameTrackingPage = () => setTrackingPageToRename(null);

  return (
    <TouchpointsOverview title="Your tracking pages" buttonText="Create a new tracking page" buttonLink={createUrl}>
      {legacy && <LegacyTrackingPage legacy={legacy} onRemove={onRemoveLegacy} onMakeDefault={onMakeDefaultLegacy} />}
      <TrackingPagesList
        key={shopId}
        shopLocalizations={shopLocalizations}
        trackingPages={trackingPages}
        onMakeDefault={onMakeDefault}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onRename={onRename}
        onLiveLinkClick={onClickLiveLink}
      />
      <ConfirmDefaultTrackingPageModal
        show={!!makeDefaultCallback}
        onConfirm={onConfirmDefaultTrackingPage}
        onCancel={onCancelConfirmDefaultTrackingPage}
      />
      {trackingPageToDelete?.name && (
        <ConfirmTrackingPageDeleteModal
          removingPage={trackingPageToDelete}
          onDelete={onDeleteTrackingPage}
          onCancel={onCancelDeleteTrackingPage}
        />
      )}
      <DuplicateTrackingPageModal
        trackingPage={trackingPageToDuplicate}
        localizations={shopLocalizations}
        onDuplicate={onDuplicateTrackingPage}
        onCancel={onCancelDuplicateTrackingPage}
      />
      <RenameModal
        title="Rename tracking page"
        objectWithName={trackingPageToRename}
        onRename={onRenameTrackingPage}
        onCancel={onCancelRenameTrackingPage}
      />
      <TrackingPageLinkModal trackingPage={trackingPageForLiveLink} onOK={onCloseLiveLinkModal} />
    </TouchpointsOverview>
  );
}

TrackingPagesOverview.defaultProps = {
  trackingPages: [],
  shopLocalizations: [],
  resolveWithNotice: (p) => p,
};

TrackingPagesOverview.propTypes = {
  trackingPages: PropTypes.array,
  shopLocalizations: PropTypes.array,
  setDefaultTrackingPage: PropTypes.func,
  isLoading: PropTypes.bool,
  loaded: PropTypes.bool,
  resolveWithNotice: PropTypes.func,
  shopId: PropTypes.number,
  createUrl: PropTypes.string,
};

export default withNotifications()(TrackingPagesOverview);
