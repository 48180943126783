import React from 'react';
import Locale from '../../Translate/Locale';
import ExternalLink from '../../common2/links/ExternalLink';
import ClosableMessage from '../../common2/ClosableMessage';

export const localisationSupportLink =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010418-managing-shop-localisations';

function NewFormMessage() {
  return (
    <ClosableMessage intent="info">
      <Locale>LEARN_HOW_TO</Locale>&nbsp;
      <ExternalLink intent="underline" to={localisationSupportLink}>
        <Locale>USE_LOCALISATIONS</Locale>
      </ExternalLink>
    </ClosableMessage>
  );
}

export default React.memo(NewFormMessage);
