import React from 'react';
import ListItem from '../../ListItem';
import { ItemPropType } from '../../../utils/listItemHelper';
import classNames from 'classnames';
import './Tab.scss';
import Locale from '../../Translate';

class Tab extends React.PureComponent {
  render() {
    const {
      title,
      markup,
      selected,
      disabled,
      editor,
      navigation,
  } = this.props;

    const styleName = classNames('tab', {
      'selected-tab': selected,
      'disabled-tab': disabled,
      'editor-tab': editor,
      navigation
    });

    return (
      <ListItem styleName={styleName} {...this.props}>
        { markup ? markup : <span styleName="tab-title"><Locale>{title}</Locale></span> }
      </ListItem>
    );
  }
}

Tab.propTypes = ItemPropType;

export default Tab;