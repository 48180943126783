import React from 'react';
import OptionWithSmallBadge from './OptionWithSmallBadge';

function Option({ selectValue, isFocused, label, badgeText, value }) {
  const isSelected = selectValue.length > 0 && selectValue[0].value === value;
  return (
    <OptionWithSmallBadge badgeText={badgeText} isFocused={isFocused} isSelected={isSelected} inline={false}>
      {label}
    </OptionWithSmallBadge>
  );
}

export default React.memo(Option);
