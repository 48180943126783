import React from 'react';
import classNames from 'classnames';

import './ModalButtonsContainer.scss';

function ModalButtonsContainer({ children, align }) {
  return (
    <div styleName={classNames('buttons', align)}>
      { children }
    </div>
  );
}

ModalButtonsContainer.defaultProps = {
  align: 'center',
};

export default ModalButtonsContainer;