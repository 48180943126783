import { featureToggled } from '../../utils/featureToggle';

export function findWidgetById(widgets, id) {
  return widgets.find((w) => w.id === id);
}

export function findWidgetByType(widgets, type) {
  return widgets.find((w) => w.type === type);
}

export function findWidgetIndexById(widgets, id) {
  return widgets.findIndex((w) => w.id === id);
}

export function getLastWidgetID(topWidgets, middleWidgets) {
  if (middleWidgets.length) {
    return middleWidgets[middleWidgets.length - 1].id;
  } else if (topWidgets.length) {
    return topWidgets[topWidgets.length - 1].id;
  }

  return null;
}

export function isWidgetOptional(type, { top = [], bottom = [] }) {
  return !top.includes(type) && !bottom.includes(type);
}

// Returns false if widget is unique and already has been added
export function isWidgetCanBeAdded(type, singletonWidgets, widgets) {
  // temporary notificationsOrderItems feature toggle
  if (type === 'order-items' && !featureToggled('notificationsOrderItems')) {
    return false;
  }

  const widget = widgets.find((w) => w.type === type);

  if (widget && (singletonWidgets || []).includes(widget.type)) {
    return false;
  }

  return true;
}

export function getWidgetName(type, editorConfig) {
  const { name = '' } = editorConfig.widgetsInfo[type] || {};
  return name;
}

export function getWidgetWithPreview(widgets, previewWidget) {
  const tmpWidgets = widgets.slice();

  if (previewWidget) {
    // insert preview widget into widgets list
    tmpWidgets.splice(previewWidget.position, 0, previewWidget.widget);
    return tmpWidgets;
  }

  return widgets;
}

export function getWidgetTypes(widgets, defaultSettings = {}) {
  const types = widgets.map((w) => w.type);
  const statusWidget = findWidgetByType(widgets, 'status');
  const footerWidget = findWidgetByType(widgets, 'footer');

  return [
    ...types,
    ...(statusWidget ? getStatusNestedWidgetTypes(statusWidget, defaultSettings.status) : []),
    ...(footerWidget ? getFooterNestedWidgetTypes(footerWidget, defaultSettings.footer) : []),
  ];
}

function getStatusNestedWidgetTypes(statusWidget, defaultSettings) {
  const settings = {
    ...defaultSettings,
    ...statusWidget.settings,
  };
  const statusTypes = getStatusWidgetsEnabled(settings);
  return statusTypes.map((type) => `status.${type}`);
}

function getStatusWidgetsEnabled(statusSettings) {
  return [
    ...(statusSettings.showStatus ? ['status'] : []),
    ...(statusSettings.showETA ? ['eta'] : []),
    ...(statusSettings.showCarrier ? ['carrier'] : []),
    ...(statusSettings.showTimeline ? ['timeline'] : []),
    ...(statusSettings.showCarrierMessages ? ['carrierMessages'] : []),
    ...(statusSettings.showOrderItems ? ['orderItems'] : []),
  ];
}

function getFooterNestedWidgetTypes(widget, defaultSettings) {
  const settings = {
    ...defaultSettings,
    ...widget.settings,
  };

  return settings.showPromo ? ['footer.promo'] : [];
}
