import { normalize, schema } from 'normalizr';

const responseSchema = {
  data: [{ localization: new schema.Entity('localizations') }],
};

export function normalizeDataArrayWithLocalization(response) {
  const {
    entities: { localizations = [] },
    result: { data = [] },
  } = normalize(response, responseSchema);

  return {
    localizations,
    data,
  };
}
