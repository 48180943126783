import React from 'react';
import { useParams } from 'react-router-dom';

import EventsHistory from '../EventsHistrory';
import OrderDetails from './OrderDetails';
import Heading2 from '../../common/Heading2';
import Loader from '../../common/Loader';
import Badge from '../../common/Badge';
import { getIntentForOrderState } from '../parcelFinderHelper';
import TrackingPageLink from '../details-links/TrackingPageLink';
import useApi from '../../../hooks/useApi';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';
import OrderNotificationsLog from '../NotificationLog/OrderNotificationsLog';

import './OrderDetailsPage.scss';
import ParcelFinderContainer from '../../parcel-finder/ParcelFinderContainer';

export function OrderDetailsPage(props) {
  const shopId = useSelectedShopId();
  const params = useParams();
  const orderId = parseInt(params.id);

  const [order, loading, error] = useApi(({ touchpoints }) => touchpoints.getOrder(shopId, orderId), [shopId, orderId]);

  const { shopOrderId, state, trackingPageUrl, events } = order || {};

  return (
    <ParcelFinderContainer {...props}>
      {loading && <Loader />}
      {error && <Heading2>Error getting Order</Heading2>}
      {order && !error && (
        <div styleName="container">
          <div styleName="heading">
            <Heading2 localeOptions={{ orderId: shopOrderId }}>{'Order: {{orderId}}'}</Heading2>
            <div styleName="badge">{state && <Badge intent={getIntentForOrderState(state)}>{state}</Badge>}</div>
          </div>
          {trackingPageUrl && <TrackingPageLink href={trackingPageUrl} />}
          <div styleName="content">
            <EventsHistory events={events} />
            <OrderDetails {...order} />
          </div>
          <OrderNotificationsLog shopId={shopId} orderId={orderId} />
        </div>
      )}
    </ParcelFinderContainer>
  );
}

export default React.memo(OrderDetailsPage);
