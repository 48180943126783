import React from 'react';
import './ModalMessage.scss';
import Locale from '../Translate/Locale';

const ModalMessage = ({children}) => {
  return (
    <span styleName="message">
      <Locale>{children}</Locale>
    </span>
  );
};

export default ModalMessage;