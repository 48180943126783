import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../common/Button';
import SettingsPage from '../../SettingsPage';
import { getShopLocalizations, setIsDrawerOpen } from '../../../actions/shopLocalizations';
import {
  getAllLocalizationsByShopId,
  selectedShopIdSelector,
  shopLocalizationsSelector,
  shopLocalizationFormSelector,
} from '../../../reducers/selectors';
import Loader from '../../common/Loader';
import TouchpointsOverviewSwitch from '../../touchpoints/TouchpointsOverviewSwitch';
import TouchpointsEmptyOverview from '../../touchpoints/TouchpointsEmptyOverview';
import ShopLocalizationsOverview from './ShopLocalizationsOverview';
import LocalizationIcon from '../../../icons/localization2.svg';
import LocalizationFormDrawer from '../LocalizationFormDrawer';
import useNotifications from '../../../hooks/useNotifications';
import useDispatchCallback from '../../../hooks/useDispatchCallback';

export const localisationSupportLink =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010418-managing-shop-localisations';

function ShopLocalizationsPage(props) {
  const { showError } = useNotifications();
  const shopId = useSelector((state) => selectedShopIdSelector(state));
  const localizations = useSelector((state) => getAllLocalizationsByShopId(state, shopId));
  const { isLoading, loaded } = useSelector((state) => shopLocalizationsSelector(state));
  const { isDrawerOpen, reFetch } = useSelector((state) => shopLocalizationFormSelector(state));

  const dispatch = useDispatch();

  const onOpenPanel = useDispatchCallback(() => setIsDrawerOpen(true));
  const onClosePanel = useDispatchCallback(() => setIsDrawerOpen(false));
  const loadLocalisations = () => dispatch(getShopLocalizations(shopId)).then(({ error }) => error && showError());

  useEffect(() => {
    loadLocalisations();
  }, [reFetch]);

  const headerElement =
    loaded && localizations.length > 0 ? () => <Button onClick={onOpenPanel}>Add a localization</Button> : undefined;

  return (
    <SettingsPage {...props} title="Localizations" renderHeaderElements={headerElement}>
      <TouchpointsOverviewSwitch isLoading={isLoading} loaded={loaded} data={localizations}>
        <Loader key="loader" />
        <TouchpointsEmptyOverview
          key="empty"
          icon={LocalizationIcon}
          title="NO_LOCALISATIONS"
          buttonText="CREATE_YOUR_FIRST_LOCALISATION"
          onClick={onOpenPanel}
          link={localisationSupportLink}
          text="LEARN_MORE_ABOUT"
          linkText="LOCALISATIONS"
        />
        <ShopLocalizationsOverview key="data" loadLocalisations={loadLocalisations} localizations={localizations} />
      </TouchpointsOverviewSwitch>
      <LocalizationFormDrawer isOpen={isDrawerOpen} onClose={onClosePanel} />
    </SettingsPage>
  );
}

export default React.memo(ShopLocalizationsPage);
