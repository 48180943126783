import React from 'react';

import './HelpCardList.scss';

const HelpCardList = ({ children }) => (
  <div styleName="cards">
    {children}
  </div>
);

export default HelpCardList;