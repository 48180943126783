import React from 'react';
import Button from './Button';

function openIntercomMessenger() {
  const { Intercom } = window;
  Intercom && Intercom('show');
}

function IntercomMessengerButton(props) {
  const {
    children,
  } = props;

  return (
    <Button onClick={openIntercomMessenger}>{children}</Button>
  );
}

export default React.memo(IntercomMessengerButton);