import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '../../../common/RadioGroup';

import './AlignmentSettings.scss';

const options = [
  {
    label: 'Align left',
    value: 'left'
  },
  {
    label: 'Align right',
    value: 'right'
  }
];

class AlignmentSettings extends React.PureComponent {
  onChange = (e) => this.props.onChange(e.target.value);

  render() {
    return <RadioGroup options={options} value={this.props.value} onChange={this.onChange} />;
  }
}

AlignmentSettings.defaultProps = {
  value: 'left',
  onChange: () => {},
};

AlignmentSettings.propTypes = {
  value: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func,
};

export default AlignmentSettings;