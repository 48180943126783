import React from 'react';
import PropTypes from 'prop-types';
import ProductItemSettings from './ProductItemSettings';
import SettingsGroupCard from '../SettingsGroupCard';
import Input from '../../common/Input';

class ProductsWidgetSettings extends React.PureComponent {
  onTitleChange = (e) => {
    this.props.onSettingsChange({ title: e.target.value });
  };

  render() {
    const {
      settings,
      onSettingsChange
    } = this.props;

    return (
      <React.Fragment>
        <SettingsGroupCard key="header" title="Header">
          <Input label="Title" value={settings.title} onChange={this.onTitleChange}/>
        </SettingsGroupCard>
        {[0, 1, 2, 3].map(index => (
          <SettingsGroupCard key={index} title={`Shop product ${index + 1}`}>
            <ProductItemSettings  {...getProductItemProps(index, settings, onSettingsChange) } />
          </SettingsGroupCard>
        ))}
      </React.Fragment>
    );
  }
}

// Returns props for ProductItemSettings component with specific index
function getProductItemProps(index, settings, onSettingsChange) {
  const prefix = `product${index + 1}`;

  return {
    name: settings[prefix + 'Name'],
    price: settings[prefix + 'Price'],
    link: settings[prefix + 'Link'],
    imageUrl: settings[prefix + 'ImageUrl'],
    mobileImageUrl: settings[prefix + 'MobileImageUrl'],
    onNameChange: value => onSettingsChange({ [prefix + 'Name']: value }),
    onPriceChange: value => onSettingsChange({ [prefix + 'Price']: value }),
    onLinkChange: value => onSettingsChange({ [prefix + 'Link']: value }),
    onImageChange: ({ imageUrl, mobileImageUrl }) => onSettingsChange({
      [prefix + 'ImageUrl']: imageUrl,
      [prefix + 'MobileImageUrl']: mobileImageUrl,
    }),
  };

}

ProductsWidgetSettings.defaultProps = {
  onSettingsChange: () => {},
};

ProductsWidgetSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func,
};

export default ProductsWidgetSettings;
