import React from 'react';

import './StartGuideTabContent.scss';

function StartGuideTabContent(props) {
  return (
    <div styleName="tab">
      {props.children}
    </div>
  );
}

export default React.memo(StartGuideTabContent);