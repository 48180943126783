import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Locale from '../../../Translate/Locale';

import Text from '../../../common/Text';

import './Tab.scss';

function Tab(props) {
  const { children, selected, onClick } = props;

  const styleName = classNames('tab', { selected });
  const styleText = classNames('text', { selected });

  return (
    <div styleName={styleName} onClick={onClick}>
      <Text styleName={styleText} ink={selected}>
        <Locale>{children}</Locale>
      </Text>
    </div>
  );
}

Tab.propTypes = {
  children: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(Tab);
