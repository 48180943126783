import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getItemsWithPermission, ItemsContainerPropType } from '../../../utils/listItemHelper';
import Tab from './Tab';
import useSelectedShop from '../../../hooks/useSelectedShop';
import useFirewall from '../../../hooks/useFirewall';

import './TabControl.scss';

function TabControl(props) {
  const { selected, onSelect, editor, grow, vertical, navigation } = props;
  const shop = useSelectedShop();
  const firewall = useFirewall(shop.id);

  const tabs = getItemsWithPermission({ items: props.tabs, firewall });
  const styleName = classNames('tab-control', { grow }, { vertical });

  return (
    <div styleName={styleName}>
      {tabs.map((tab) => (
        <Tab {...getItemProps(tab, onSelect, selected)} navigation={navigation} editor={editor} />
      ))}
    </div>
  );
}

export function getItemProps(item, onSelect, selection) {
  const selected = item.id === selection;

  return {
    ...item,
    key: item.id,
    selected,
    onClick: () => {
      onSelect && onSelect(item.id);
      item.onSelect && item.onSelect();
    },
  };
}

TabControl.propTypes = {
  ...ItemsContainerPropType,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  editor: PropTypes.bool,
  grow: PropTypes.bool,
  vertical: PropTypes.bool,
  navigation: PropTypes.bool,
};

export default React.memo(TabControl);
