import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ItemPicturesUploader from '../common/ItemPicturesUploader';
import ItemInfo from '../common/ItemInfo';
import CustomerCommentInput from '../common/CustomerCommentInput';
import useStepValidation from '../common/useStepValidation';
import { createMissingItemInfoValidator } from '../claimValidators';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import Column from '../../../common/layout/Column';

function MissingItemInfoStep(props) {
  const { as: Container, onChange, claim, carriers, onNext } = props;

  const isPicturesOfMissingItemRequired = carriers.find(
    ({ code, countryCode }) => code === claim.carrierCode && countryCode === claim.carrierCountryCode
  )?.isPicturesOfMissingItemRequired;

  const [errors, onClickNext] = useStepValidation(
    claim,
    onNext,
    createMissingItemInfoValidator({ isPicturesOfMissingItemRequired })
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeDamageInfo = (missingItemInfo) => onChange({ missingItemInfo });
  const onInputChange = (value, name) => onChange({ [name]: value });

  return (
    <Container {...props} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Missing item information</SidePanelContentHeader>
      <Column spacing="small">
        <ItemInfo
          data={claim.missingItemInfo}
          emptyInfoErrorText="BAD_MISSING_ITEM_INFO_ITEMS"
          errorsWithTranslation={errors.missingItemInfo}
          onChange={onChangeDamageInfo}
        />
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        <ItemPicturesUploader
          name="picturesOfMissingItem"
          label="MISSING_ITEM_PICTURES_UPLOADER_LABEL"
          helpText="CLAIMS_DAMAGE_PICTURES_HELP_TEXT"
          claim={claim}
          errors={errors}
          isRequired={isPicturesOfMissingItemRequired}
          onChange={onInputChange}
          onBusy={setIsDisabled}
        />
      </Column>
    </Container>
  );
}

MissingItemInfoStep.propTypes = {
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      isPicturesOfMissingItemRequired: PropTypes.bool,
    })
  ).isRequired,
  claim: PropTypes.shape({
    missingItemInfo: PropTypes.arrayOf(PropTypes.object),
    picturesOfMissingItem: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onChange: PropTypes.func,
};

export default React.memo(MissingItemInfoStep);
