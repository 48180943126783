import React, { useCallback } from 'react';
import notificationFactory from '../components/Notification/notificationFactory';
import Notification from '../components/Notification/Notification';
import { isFulfilledAction } from '../actions/apiActionHelper';
import { useTranslation } from 'react-i18next';

const notification = notificationFactory({
  duration: 5,
});

export default function useNotifications() {
  const { t } = useTranslation();

  const showNotice = useCallback(
    ({ type, message, localeOptions }) => {
      notification.notice(
        <Notification type={type} localeOptions={localeOptions}>
          {t(message, { ...localeOptions })}
        </Notification>
      );
    },
    [t]
  );

  const showError = useCallback(
    (message = 'Something went wrong') => {
      showNotice({
        type: 'error',
        message: String(message),
      });
    },
    [showNotice]
  );

  const resolveWithNotice = useCallback(
    (promise, message, localeOptions = {}) =>
      promise
        .then(() => {
          showNotice({ message, localeOptions, type: 'success' });
          return { success: true };
        })
        .catch(() => {
          showError();
          return { error: true };
        }),
    [showError, showNotice]
  );

  const resolveWithAction = useCallback(
    (dispatchAction, message, localeOptions) =>
      dispatchAction.then((action) => {
        if (isFulfilledAction(action)) {
          showNotice({ message, localeOptions, type: 'success' });
          return { success: true };
        } else {
          showError();
          return { error: true };
        }
      }),
    [showError, showNotice]
  );

  return {
    showNotice,
    showError,
    resolveWithNotice,
    resolveWithAction,
  };
}
