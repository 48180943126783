import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../Translate/Locale';

import InfoIcon from '../../icons/intents/info.svg';
import SuccessIcon from '../../icons/intents/success.svg';
import DangerIcon from '../../icons/intents/danger.svg';

import './Notification.scss';

const iconTypes = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: DangerIcon,
  error: DangerIcon,
  default: InfoIcon,
};

function Notification(props) {
  const { type, children } = props;
  const Icon = iconTypes[type] || iconTypes.default;

  return (
    <div styleName={classNames('notification', type)}>
      <div styleName="content">
        <Icon styleName="icon" />
        <div styleName="message">{children}</div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  type: PropTypes.oneOf(Object.keys(iconTypes)),
  children: PropTypes.string,
};

export default React.memo(Notification);
