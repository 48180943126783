import React from 'react';
import storage from '../../models/storage';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/circle-close.svg';

import './Popup.scss';

class Popup extends React.PureComponent {
  state = {
    closed: storage.getItem(this.props.storageCloseKey)
  };

  close = () => {
    storage.setItem(this.props.storageCloseKey, true);
    this.setState({ closed: true });
  };

  render() {
    const {
      storageCloseKey,
      children,
    } = this.props;

    return (!this.state.closed &&
      <div styleName="popup">
        <div styleName="topline"/>
        <div styleName="columns">
          {children && <div>{children}</div>}
          {storageCloseKey && <CloseIcon styleName="close" onClick={this.close}/>}
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  storageCloseKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

export default Popup;