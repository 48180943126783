import React from 'react';

import Locale from '../../Translate/Locale';

import './Heading3.scss';

function Heading3(props) {
  return (
    <h3 className={props.className} styleName="default">
      <Locale options={props.localeOptions}>{props.children}</Locale>
    </h3>
  );
}

export default Heading3;