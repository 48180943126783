import colors from '../colors';

const fontStyles = {
  fontFamily: "'Public Sans', sans-serif",
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  color: colors.darkGray,
};

export const styles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 36,
    borderRadius: 4,
    borderStyle: 'solid',
    backgroundColor: colors.snow,
    cursor: 'pointer',
    padding: state.isFocused ? ' 0' : '0 1px',
    borderColor: getControlBorderColor(state),
    borderWidth: state.isFocused ? 2 : 1,
    ...fontStyles,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 11px', // because control container has 1px padding (0px when it's focused, but 2px border then)
    flex: '1',
  }),
  input: () => ({
    '& input': {
      font: 'inherit',
    },
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? colors.silver : colors.grey,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    margin: 0,
    color: isDisabled ? colors.silver : colors.darkGray,
  }),
  option: (provided, state) => ({
    height: 40,
    boxSizing: 'border-box',
    padding: '8px 24px',
    backgroundColor: getOptionBackgroundColor(state),
    cursor: 'default',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  group: () => {},
  groupHeading: () => ({
    boxSizing: 'border-box',
    padding: '10px 24px 10px 23px',
    fontSize: '12px',
    lineHeight: '20px',
    color: colors.grey,
    fontWeight: 600,
    letterSpacing: 1.6,
    textTransform: 'uppercase',
    borderBottom: `1px solid ${colors.isabeline}`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menuList: (provided, { options }) => ({
    ...provided,
    paddingTop: hasGroups(options) ? 0 : 8,
    paddingBottom: 8,
  }),
  menu: (provided) => ({
    ...provided,
    ...fontStyles,
    minWidth: 80,
    marginTop: 4,
    borderRadius: 3,
    boxShadow: `0 0 0 1px ${colors.smoke}, 0 2px 16px 0 #2d323319`,
    backgroundColor: colors.white,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '0px 7px 0px 8px', // 7px because of control container
    fill: state.isDisabled ? colors.silver : colors.darkGray,
  }),
};

function getOptionBackgroundColor({ isFocused, isSelected }) {
  if (isSelected) {
    return colors.lighterSkyBlueTwo;
  } else if (isFocused) {
    return colors.smoke;
  }

  return 'none';
}

function getControlBorderColor({ isFocused, isDisabled }) {
  if (isDisabled) {
    return colors.smoke;
  } else if (isFocused) {
    return colors.skyBlue;
  }

  return colors.lightGrey;
}

function hasGroups(options) {
  const [option] = options || [];
  return option && option.options;
}

export function getStyles({ isInvalid } = {}) {
  if (isInvalid) {
    return {
      ...styles,
      control: (...args) => ({
        ...styles.control(...args),
        borderColor: colors.darkRed,
        backgroundColor: `${colors.darkRed}19`, // 10% transparency
      }),
    };
  }

  return styles;
}
