import React from 'react';
import { Route, Routes } from 'react-router-dom';

import EmbeddedWidgetsPage from '../EmbeddedWidgetsPage';
import CreateWidgetPage from '../CreateWidgetPage';
import EmbeddedWidgetEditor from '../editors/embedded-widget-editor/EmbeddedWidgetEditor';
import InstallCodePage from '../InstallCodePage';
import TrackingPagesPage from '../TrackingPagesPage';
import CreateTrackingPage from '../touchpoints/CreateTrackingPage';
import TrackingPageEditor from '../editors/tracking-page-editor/TrackingPageEditor';
import PublishTrackingPage from '../PublishTrackingPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import TrackingStartGuidePage from '../start-guides/TrackingStartGuidePage';
import PageNavigate from '../PageNavigate';

const withEmbeddedWidgetsAccess = withPermissionRedirect(
  permissions.EMBEDDED_WIDGETS_PERMISSION,
  '/tracking/start-guide'
);
const withTrackingPagesAccess = withPermissionRedirect(permissions.TRACKING_PAGES_PERMISSION, '/tracking/start-guide');
const withTouchpointsAccess = withPermissionRedirect(permissions.TOUCHPOINTS_PERMISSION, '/tracking/start-guide');

const TrackingRoutes = () => (
  <Routes>
    <Route path="embedded-widgets/*" element={<EmbeddedWidgetRoutesWithAccess />} />
    <Route path="tracking-pages/*" element={<TrackingPageRoutesWithAccess />} />
    <Route path="start-guide" element={<TrackingStartGuidePage />} />
    <Route index element={<PageNavigate to="tracking-pages" />} />
  </Routes>
);

const EmbeddedWidgetRoutes = () => (
  <Routes>
    <Route index element={<EmbeddedWidgetsPage />} />
    <Route path="start" element={<EmbeddedWidgetsPage />} />
    <Route path="create/:step" element={<CreateWidgetPage />} />
    <Route exact path=":id/edit" element={<EmbeddedWidgetEditor />} />
    <Route exact path=":id/install-code" element={<InstallCodePage />} />
    <Route path="create" element={<PageNavigate to="choose-language" />} />
  </Routes>
);

const EmbeddedWidgetRoutesWithAccess = withTouchpointsAccess(withEmbeddedWidgetsAccess(EmbeddedWidgetRoutes));

const TrackingPageRoutes = () => (
  <Routes>
    <Route index element={<TrackingPagesPage />} />
    <Route path="start" element={<TrackingPagesPage />} />
    <Route
      path="create/:step" element={<CreateTrackingPage />}
    />
    <Route path=":id/edit" element={<TrackingPageEditor />} />
    <Route path=":id/publish" element={<PublishTrackingPage />} />
    <Route path="create" element={<PageNavigate to="choose-localization" />} />
  </Routes>
);

const TrackingPageRoutesWithAccess = withTouchpointsAccess(withTrackingPagesAccess(TrackingPageRoutes));

export default TrackingRoutes;
