import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton from '../../common2/DropdownButton/DropdownButton';
import DropdownMenuItem from '../../common/DropdownMenuItem/DropdownMenuItem';
import RenameIcon from '../../../icons/edit.svg';
import EditIcon from '../../../icons/more-vertical.svg';
import DuplicateIcon from '../../../icons/duplicate.svg';
import LinkIcon from '../../../icons/link.svg';
import DeleteIcon from '../../../icons/delete.svg';
import ViewCodeIcon from '../../../icons/view-code.svg';

function WidgetActionDropdownButton(props) {
  const { onRename, onDuplicate, onViewCode, onDelete, onMakeDefault } = props;
  return (
    <DropdownButton ariaLabel="actions-dropdown-button" icon={EditIcon} tooltipText="More">
      {onRename && (
        <DropdownMenuItem icon={RenameIcon} onClick={onRename}>
          Rename
        </DropdownMenuItem>
      )}
      {onDuplicate && (
        <DropdownMenuItem icon={DuplicateIcon} onClick={onDuplicate}>
          Duplicate
        </DropdownMenuItem>
      )}
      {onViewCode && (
        <DropdownMenuItem icon={ViewCodeIcon} onClick={onViewCode}>
          View code
        </DropdownMenuItem>
      )}
      {onMakeDefault && (
        <DropdownMenuItem icon={LinkIcon} onClick={onMakeDefault}>
          Make default
        </DropdownMenuItem>
      )}
      {onDelete && (
        <DropdownMenuItem onClick={onDelete} icon={DeleteIcon} intent="danger">
          Delete
        </DropdownMenuItem>
      )}
    </DropdownButton>
  );
}

WidgetActionDropdownButton.propTypes = {
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRename: PropTypes.func,
  onViewCode: PropTypes.func,
  onMakeDefault: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default React.memo(WidgetActionDropdownButton);
