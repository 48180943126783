import React from 'react';
import Locale from '../../Translate/Locale';
import Link from '../../common/Link/Link';
import Button from '../../common2/Button/Button';
import ChatbotIcon from '../../../icons/homepage/ai-chatbot.svg';
import classNames from 'classnames';

import './NewReleaseBanner.scss';

function NewReleaseBanner({ isChatbotBanner }) {
  const { heading, description, cta, ctaLink, bannerType, intent } = getData({ isChatbotBanner });

  return (
    <div styleName={classNames('container', bannerType)}>
      <div styleName="icon-circle">
        <ChatbotIcon />
      </div>
      <div styleName="text-and-cta">
        <div styleName="text-container">
          <div styleName="heading">
            <Locale>{heading}</Locale>
          </div>
          <div styleName={classNames('description', bannerType)}>
            <Locale>{description}</Locale>
          </div>
        </div>
        <div styleName="cta">
          <Link to={ctaLink}>
            <Button intent={intent}>{cta}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function getData({ isChatbotBanner }) {
  if (isChatbotBanner) {
    return {
      heading: 'CHATBOT_HOME_BANNER_HEADER',
      description: 'CHATBOT_HOME_BANNER_DESCRIPTION',
      cta: 'CHATBOT_HOME_BANNER_CTA',
      ctaLink: '/chatbot/start-guide',
      intent: 'success',
      bannerType: 'success',
    };
  }

  return {
    heading: 'ANALYTICS_COUNTRY_VIEWS_BANNER_HEADER',
    description: 'ANALYTICS_COUNTRY_VIEWS_BANNER_DESCRIPTION',
    cta: 'ANALYTICS_COUNTRY_VIEWS_BANNER_CTA',
    ctaLink: '/analytics/dashboards/country_view_france',
    intent: 'midnight',
    bannerType: 'info',
  };
}

export default React.memo(NewReleaseBanner);
