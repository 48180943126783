import { Api } from './Api';

class NotificationsServiceApi {
  constructor(baseUrl, token = null, handlers = {}) {
    this.api = new Api(baseUrl, token, handlers);
  }

  setToken(token) {
    this.api.setToken(token);
  }

  refreshLocalizations() {
    return this.api.post('/refresh-localizations', {});
  }
}

export default NotificationsServiceApi;
