import { withApiActions } from './apiActionHelper';
import container from '../container';

export function getShopLocalizations(shopId) {
  return withApiActions(
    (api) => api.getShopLocalizations(shopId).then((data) => ({ shopId, data })),
    'SHOP_LOCALIZATIONS'
  );
}

export function createShopLocalization(shopId, url, language) {
  return withApiActions(
    (api) =>
      api.createShopLocalization(shopId, url, language).then((data) => {
        container.notificationsApi.refreshLocalizations();
        return { shopId, data };
      }),
    'CREATE_SHOP_LOCALIZATION'
  );
}

export function editShopLocalization(localisationId, url, language) {
  return withApiActions(
    (api) =>
      api.editShopLocalization(localisationId, url, language).then(() => {
        container.notificationsApi.refreshLocalizations();
      }),
    'EDIT_SHOP_LOCALIZATION'
  );
}

export function restoreShopLocalization(localisationId) {
  return withApiActions((api) => api.restoreShopLocalization(localisationId), 'RESTORE_SHOP_LOCALIZATION');
}

export function setShopLocalization(data) {
  return { type: 'SET_SHOP_LOCALIZATION', data };
}

export function setIsDrawerOpen(data) {
  return { type: 'SET_IS_DRAWER_OPEN', data };
}
