import container from '../container';
import { selectedShopIdSelector } from '../reducers/selectors';

const storage = global.localStorage;

export default {
  getItem: (key) => storage && storage.getItem(key),
  getItemWithShop: (key) => {
    try {
      return storage && JSON.parse(storage.getItem(`${getCurrentShopId()}-${key}`));
    } catch (e) {
      return null;
    }
  },
  setItem: (key, value) => storage && storage.setItem(key, value),
  setItemWithShop: (key, value) => storage && storage.setItem(`${getCurrentShopId()}-${key}`, JSON.stringify(value)),
  removeItem: (key) => storage && storage.removeItem(key),
  clear: () => storage && storage.clear(),
};

function getCurrentShopId() {
  return selectedShopIdSelector(container.store.getState());
}