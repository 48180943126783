import React from 'react';
import PropTypes from 'prop-types';

import SidePanelContainer from '../../common2/side-panel/SidePanelContainer';
import SidePanelContent from '../../common2/side-panel/SidePanelContent';
import SidePanelFooter from '../../common2/side-panel/SidePanelFooter';
import Column from '../../common/layout/Column';
import TakeActionPanelError from './TakeActionPanelError';
import ActionRequiredMessage from './ActionRequiredMessage';
import { useTakeAction } from './useTakeAction';
import TakeActionForm from './TakeActionForm';
import TakeActionSubmitButton from './TakeActionSubmitButton';
import TakeActionPanelHeading from './TakeActionPanelHeading';
import SidePanelContentSection from '../../common2/side-panel/SidePanelContentSection';
import ExternalLink from '../../common2/links/ExternalLink';

function TakeActionPanel(props) {
  const { claim, onClose, onSubmitSuccess, customerResponse } = props;
  const { action, onChange, submit, isSubmitting, errors, firstError } = useTakeAction({
    claimId: claim.id,
    reason: customerResponse?.name || claim.comment,
    onSubmitSuccess,
  });

  const hasFiles = customerResponse?.files?.length > 0;

  return (
    <SidePanelContainer>
      <TakeActionPanelHeading onClose={onClose}>Action required</TakeActionPanelHeading>
      <SidePanelContent>
        <Column spacing="medium">
          {firstError && <TakeActionPanelError>{firstError}</TakeActionPanelError>}
          <SidePanelContentSection heading="Action required">
            <ActionRequiredMessage customerResponse={customerResponse} comment={claim.comment} />
          </SidePanelContentSection>
          {hasFiles && (
            <SidePanelContentSection heading="Supporting files">
              <Column spacing="extra-small">
                {customerResponse.files.map((file) => (
                  <ExternalLink key={file.id} to={file.publicUrl} download={file.name} target="_self">
                    {file.name}
                  </ExternalLink>
                ))}
              </Column>
            </SidePanelContentSection>
          )}
          <TakeActionForm action={action} onChange={onChange} errors={errors} isSubmitting={isSubmitting} />
        </Column>
      </SidePanelContent>
      <SidePanelFooter>
        <TakeActionSubmitButton isLoading={isSubmitting} onClick={submit} />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

TakeActionPanel.propTypes = {
  claim: PropTypes.shape({
    id: PropTypes.number,
    customerResponseId: PropTypes.number,
    comment: PropTypes.string,
  }),
  customerResponse: PropTypes.object,
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
};

export default React.memo(TakeActionPanel);
