import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DesktopPageContainer from './DesktopPageContainer';
import MobilePageContainer from './MobilePageContainer';
import useSelectedShop from '../../hooks/useSelectedShop';
import useIsMobile from '../../hooks/useIsMobile';
import { useLocation } from 'react-router-dom';

function PageContainer(props) {
  const selectedShop = useSelectedShop();
  const location = useLocation();

  const Container = useIsMobile() ? MobilePageContainer : DesktopPageContainer;

  return <Container {...props} selectedShop={selectedShop} location={location} />;
}

PageContainer.propTypes = {
  mobileMenuOnly: PropTypes.bool, // display menu only on mobile
  hideMobileExperienceWarning: PropTypes.bool, // do not display a footer with a text about bad mobile experience
  menuTitle: PropTypes.string,
  menuTitleBadge: PropTypes.element,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  selectedMenuItem: PropTypes.string,
  onSelectMenu: PropTypes.func,

  // desktop only
  menuCollapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
};

export default PageContainer;
