import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import container from '../container';

const initialState = {
  isLoading: false,
  loaded: false,
  error: null,
  data: {},
};

export const getClaimCustomerResponse = createAsyncThunk(
  'core/getClaimCustomerResponse',
  ({ shopId, claimId }) => container.touchpointsApi.getClaimCustomerResponse(shopId, claimId),
  {
    condition: ({ claimId }, { getState }) => {
      const { customerResponses } = getState();

      if (customerResponses.data[claimId]) {
        return false;
      }

      return true;
    },
  }
);

export const customerResponseSelector = (state) => state.customerResponses;

const slice = createSlice({
  name: 'customerResponses',
  initialState,
  extraReducers: {
    [getClaimCustomerResponse.pending](state) {
      state.isLoading = true;
      state.error = null;
    },
    [getClaimCustomerResponse.fulfilled](state, action) {
      state.data[action.payload.claimId] = action.payload;
      state.isLoading = false;
      state.loaded = true;
    },
    [getClaimCustomerResponse.rejected](state, action) {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export default slice.reducer;
