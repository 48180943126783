import React from 'react';
import PropTypes from 'prop-types';

import SidePanelContentHeader from './SidePanelContentHeader';

import './SidePanelContentSection.scss';

function SidePanelContentSection(props) {
  const { children, heading } = props;

  return (
    <section>
      <SidePanelContentHeader>{heading}</SidePanelContentHeader>
      <div styleName="content">{children}</div>
    </section>
  );
}

SidePanelContentSection.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(SidePanelContentSection);
