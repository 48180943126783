import React from 'react';

import PageContainer from '../pagecontainer/PageContainer';
import * as permissions from '../../firewall/permissions';

export const menuItems = [
  {
    id: '/returns/return-portals',
    link: '/returns/return-portals',
    matchPaths: ['/returns/return-portals', '/returns/return-portals/:id/edit', '/returns/return-portals/start'],
    title: 'Return portals',
    permission: permissions.RETURNS,
  },
  {
    id: '/returns/translations',
    link: '/returns/translations',
    title: 'Translations',
    permission: permissions.RETURNS,
  },
  {
    id: '/returns/start-guide',
    link: '/returns/start-guide',
    title: 'Start guide',
  },
];

function ReturnsContainer(props) {
  return <PageContainer {...props} menuTitle="Returns" menuItems={menuItems} />;
}

export default React.memo(ReturnsContainer);
