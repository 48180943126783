import React from 'react';
import PropTypes from 'prop-types';

import './ClaimsCenterContainer.scss';
function ClaimsCenterContainer({ children }) {
  return <div styleName="container">{children}</div>;
}

ClaimsCenterContainer.propTypes = {
  children: PropTypes.node,
};

export default React.memo(ClaimsCenterContainer);
