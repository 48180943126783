import React from 'react';
import PropTypes from 'prop-types';

import './SidePanelFooter.scss';

function SidePanelFooter(props) {
  const { children } = props;

  return <div styleName="footer">{children}</div>;
}

SidePanelFooter.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default React.memo(SidePanelFooter);
