const headerSettings = [
  { key: 'headerLinksColor', label: 'Text links' },
  { key: 'headerLinksHoverColor', label: 'Text links hover' },
  { key: 'headerBackgroundColor', label: 'Background' },
];

const languageSelectorSettings = [
  { key: 'languageSelectorTextColor', label: 'Text' },
  { key: 'languageSelectorTextHoverColor', label: 'Text hover' },
  { key: 'languageSelectorMenuTextColor', label: 'Menu text' },
  { key: 'languageSelectorMenuBackgroundColor', label: 'Menu background' },
  { key: 'languageSelectorMenuTextHoverColor', label: 'Menu text hover' },
  { key: 'languageSelectorMenuBackgroundHoverColor', label: 'Menu hover background' },
  { key: 'languageSelectorMenuTextActiveColor', label: 'Menu text active' },
  { key: 'languageSelectorMenuBackgroundActiveColor', label: 'Menu active background' },
];

const statusSettings = [
  {
    title: 'Status',
    widget: 'status.status',
    settings: [
      { key: 'bodyHeadingsColor', label: 'Headings' },
      { key: 'bodyTextColor', label: 'Text' },
      { key: 'iconColor', label: 'Icon color' },
      { key: 'iconBackgroundColor', label: 'Icon background' },
      { key: 'bodyShipmentActiveTabColor', label: 'Shipment active tab' },
      { key: 'shipmentActiveIconColor', label: 'Active shipment icon' },
      { key: 'bodyShipmentInactiveTabColor', label: 'Shipment inactive tab' },
      { key: 'shipmentInactiveIconColor', label: 'Inactive shipment icon' },
      { key: 'bodyBackgroundColor', label: 'Background' },
    ],
  },
  {
    title: 'ETA',
    widget: 'status.eta',
    settings: [
      { key: 'etaTextColor', label: 'Text' },
      { key: 'etaIconColor', label: 'Icon color' },
      { key: 'etaBackgroundColor', label: 'Background' },
    ],
  },
  {
    title: 'Timeline',
    widget: 'status.timeline',
    settings: [
      { key: 'timelineTextColor', label: 'Text' },
      { key: 'bodyBordersAndLinesColor', label: 'Borders and lines' },
      { key: 'timelineIconColor', label: 'Icons' },
      { key: 'timelineIconBackgroundColor', label: 'Icons background' },
      { key: 'timelineBackgroundColor', label: 'Background' },
    ],
  },
  {
    title: 'Carrier messages',
    widget: 'status.carrierMessages',
    settings: [
      { key: 'carrierMessagesHeadingsColor', label: 'Headings' },
      { key: 'carrierMessagesTextColor', label: 'Text' },
      { key: 'bodyTextLinksColor', label: 'Text links' },
      { key: 'bodyTextLinksHoverColor', label: 'Text links hover' },
      { key: 'carrierMessagesIconColor', label: 'Icons' },
      { key: 'carrierMessagesBackgroundColor', label: 'Background' },
    ],
  },
  {
    title: 'Carrier',
    widget: 'status.carrier',
    settings: [
      { key: 'carrierTextColor', label: 'Text' },
      { key: 'carrierTextLinkColor', label: 'Text links' },
      { key: 'carrierTextLinkHoverColor', label: 'Text links hover' },
      { key: 'carrierBackgroundColor', label: 'Background' },
    ],
  },
];

export const trpSettingsGroups = [
  {
    title: 'Header',
    subtitle: 'This is the top section of your page where you can find your logo and site links',
    settings: headerSettings,
  },
  {
    title: 'Language selector',
    settings: languageSelectorSettings,
  },
  ...statusSettings,
  {
    title: 'Footer',
    subtitle: 'The body is the bottom section of your page',
    settings: [
      { key: 'footerCopyrightTextColor', label: 'Text' },
      { key: 'footerBackgroundColor', label: 'Background' },
    ],
  },
  {
    title: 'Sendwise promo',
    widget: 'footer.promo',
    settings: [
      { key: 'promoTextColor', label: 'Text' },
      { key: 'promoLinkColor', label: 'Text link' },
      { key: 'promoLinkHoverColor', label: 'Text link hover' },
      { key: 'promoBackgroundColor', label: 'Background' },
    ],
  },
];

export const ewSettingsGroups = [
  ...statusSettings,
];

export function filterColorGroupsByWidgetTypes(colorGroups, widgetTypes) {
  return colorGroups.filter(group => !group.widget || widgetTypes.includes(group.widget));
}