import React from 'react';
import PropTypes from 'prop-types';

import './BulletedList.scss';

function BulletedList(props) {
  return (
    <ul styleName="list">
      {props.children}
    </ul>
  );
}

BulletedList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default React.memo(BulletedList);