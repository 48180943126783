import React from 'react';

import Column from '../components/common/layout/Column';
import ChatbotPageContainer from './ChatbotPageContainer';
import StartGuideHeading from '../components/start-guides/StartGuidePage/StartGuideHeading';
import CenterLayout from '../components/common2/CenterLayout/CenterLayout';
import BannerAccess from '../components/start-guides/StartGuidePage/BannerAccess';
import StartGuideContentSections from '../components/start-guides/StartGuidePage/StartGuideContentSections';

const sections = [
  {
    id: 'welcome',
    title: 'Welcome to ParcelAI',
    content: 'CHATBOT_START_GUIDE_WELCOME_TEXT',
    imageUrl: '/images/start-guides/chatbot/welcome.png',
  },
  {
    id: 'features',
    title: 'Key Features',
    content: 'CHATBOT_START_GUIDE_FEATURES_TEXT',
    imageUrl: '/images/start-guides/chatbot/features.png',
  },
  {
    id: 'prompts',
    title: 'Prompt Suggestions',
    content: 'CHATBOT_START_GUIDE_PROMPTS_TEXT',
    imageUrl: '/images/start-guides/chatbot/prompts.png',
  },
  {
    id: 'tips',
    title: 'Tips for Effective Use',
    content: 'CHATBOT_START_GUIDE_TIPS_TEXT',
    imageUrl: '/images/start-guides/chatbot/tips.png',
  },
];

function ChatbotStartGuidePage(props) {
  return (
    <ChatbotPageContainer {...props}>
      <CenterLayout>
        <StartGuideHeading />
        <Column spacing="large">
          <BannerAccess
            heading="CHATBOT_START_GUIDE_HELP_HEADING"
            description="CHATBOT_START_GUIDE_HELP_DESC"
            cta="Discover ParcelAI"
            url="/chatbot"
          />
          <StartGuideContentSections heading="CHATBOT_START_GUIDE_CONTENT_HEADING" sections={sections} />
        </Column>
      </CenterLayout>
    </ChatbotPageContainer>
  );
}

export default ChatbotStartGuidePage;
