import React from 'react';

import Heading2 from '../../common/Heading2';

import './SearchEnabled.scss';

function SearchHelpText() {
  return (
    <div styleName="text">
      <Heading2>Use the search bar to find claims by shipment tracking code</Heading2>
    </div>
  );
}

export default SearchHelpText;
