import { trackEvent } from '../../utils/googleAnalytics';

export function sendSearchResultsToGA(search, results) {
  const { orders } = results || [];

  trackEvent({
    category: 'parcel-finder',
    action: orders.length ? 'search-result' : 'search-no-result',
    label: search,
  });
}
