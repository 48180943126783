import React from 'react';

import Locale from '../Translate/Locale';

import './ModalBlockquoteBody.scss';

function ModalBlockquoteBody(props) {
  const {
    label,
    children,
  } = props;

  return (
    <div styleName="body">
      <Locale>
        {label}
      </Locale>
      <blockquote styleName="blockquote">
        {children}
      </blockquote>
    </div>
  );
}

export default React.memo(ModalBlockquoteBody);