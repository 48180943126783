import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';

import Text from '../../common/Text';

import './TimelineSegment.scss';

function TimelineSegment(props) {
  const { date: strDate, isLastEvent, isFirstEvent } = props;

  const styleName = classNames('segment', {
    'last-event': isLastEvent,
    'first-event': isFirstEvent,
  });

  const date = new Date(strDate);

  return (
    <div styleName={styleName}>
      <div styleName="pre" />
      <div styleName="content">
        {strDate && (
          <React.Fragment>
            <Text as="div" styleName="date" small ink>
              {format(date, 'dd.MM.yyyy')}
            </Text>
            <Text as="div" styleName="time" small>
              {format(date, 'HH:mm')}
            </Text>
          </React.Fragment>
        )}
        <div styleName="circle" />
      </div>
    </div>
  );
}

TimelineSegment.propTypes = {
  date: PropType.string,
  isLastEvent: PropType.bool,
  isFirstEvent: PropType.bool,
};

export default React.memo(TimelineSegment);
