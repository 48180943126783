import React from 'react';
import PropTypes from 'prop-types';

import FileItem from './FileItem';

import './FilesList.scss';

function FilesList(props) {
  const { files = [], onFilesChange } = props;

  return (
    <div data-test-id="file-list" styleName="files">
      {files.map((file) => (
        <FileItem
          fileName={file.data.name}
          key={file.id}
          onRemove={() => onFilesChange(files.filter((f) => f.id !== file.id))}
        />
      ))}
    </div>
  );
}

FilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  onFilesChange: PropTypes.func,
};

export default React.memo(FilesList);
