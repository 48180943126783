import React from 'react';

import Link from '../../common/Link';
import Button from '../../common2/Button';
import StartGuideCard from './StartGuideCard';
import ExternalLink from '../../common2/links/ExternalLink';
import ButtonsGroup from '../../common/layout/ButtonsGroup';

import './BannerAccess.scss';

function BannerAccess(props) {
  const { heading, description, url, cta, learnMoreUrl } = props;

  return (
    <StartGuideCard heading={heading} description={description}>
      <div styleName="bannerButtons">
        <ButtonsGroup>
          <Link to={url}>
            <Button>{cta}</Button>
          </Link>
          {learnMoreUrl && (
            <ExternalLink to={learnMoreUrl}>
              <Button intent="base">Learn more</Button>
            </ExternalLink>
          )}
        </ButtonsGroup>
      </div>
    </StartGuideCard>
  );
}

export default React.memo(BannerAccess);
