import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleLocalizationHeader from './CollapsibleLocalizationHeader';
import Card from '../common/Card/Card';
import AccordionContext from '../touchpoints/AccordionContext';
import classNames from 'classnames';
import './ListWithCollapsibleLocalizationHeader.scss';

class ListWithCollapsibleLocalizationHeader extends React.PureComponent {
  render() {
    const { url, language, id, archived, children, renderElements } = this.props;

    return (
      <AccordionContext.Consumer>
        {({ onHeaderClick, expandedSections }) => (
          <div styleName={classNames('list', { expanded: expandedSections[id] })}>
            <Card noPadding>
              <React.Fragment>
                <CollapsibleLocalizationHeader
                  url={url}
                  language={language}
                  archived={archived}
                  isExpanded={expandedSections[id]}
                  onClick={() => onHeaderClick(id)}
                >
                  {renderElements && renderElements()}
                </CollapsibleLocalizationHeader>
                {!archived && expandedSections[id] ? children : null}
              </React.Fragment>
            </Card>
          </div>
        )}
      </AccordionContext.Consumer>
    );
  }
}

ListWithCollapsibleLocalizationHeader.propTypes = {
  url: PropTypes.string,
  language: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  archived: PropTypes.bool,
  renderElements: PropTypes.func,
};

export default ListWithCollapsibleLocalizationHeader;
