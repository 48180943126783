import React from 'react';
import PropTypes from 'prop-types';

function Source(props) {
  const { type, srcset, media } = props;

  return <source type={type} srcSet={srcset} media={media} />;
}

Source.propTypes = {
  type: PropTypes.string,
  srcset: PropTypes.string,
  media: PropTypes.string,
};

export default React.memo(Source);
