import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { SettingsListItem } from '../SettingsList';
import { withEditorConfig } from '../../editors/EditorContext';
import { getWidgetName } from '../../editors/widgetsHelper';

class SortableWidgetItem extends React.PureComponent {
  render() {
    const {
      widget,
      onClick,
      isSortable,
    } = this.props;

    const widgetTitle = getWidgetName(widget.type, this.props.editorConfig);

    return (
      <SettingsListItem
        key={widget.id}
        type={widget.type}
        title={widgetTitle}
        onClick={onClick}
        isSortable={isSortable}
      />
    );
  }
}

SortableWidgetItem.propTypes = {
  editorConfig: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isSortable: PropTypes.bool,
  highlighted: PropTypes.bool,
};

export default SortableElement(withEditorConfig(SortableWidgetItem));