import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '../../common2/drawer/Drawer';
import CreateClaimContainer from './CreateClaimContainer';

function SubmitClaimPanel(props) {
  const { isOpen, onClose, onClaimSubmitted } = props;
  return (
    <Drawer isOpen={isOpen}>
      <CreateClaimContainer onClose={onClose} onClaimSubmitted={onClaimSubmitted} />
    </Drawer>
  );
}

SubmitClaimPanel.propTypes = {
  onClaimSubmitted: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(SubmitClaimPanel);
