import React from 'react';
import classNames from 'classnames';

import './NavItem.scss';

function NavItem(props) {
  const {
    selected,
  } = props;

  const style = classNames('item', {
    selected,
  });

  return (
    <div styleName={style} {...props}>
      {props.children}
    </div>
  );
}

export default React.memo(NavItem);