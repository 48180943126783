import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import InputContainer from '../../../common2/InputContainer/InputContainer';

import SelectArrowsIcon from '../../../../icons/select-arrows.svg';

import './CurrencyInput.scss';

export const currencies = ['EUR', 'CHF', 'CZK', 'DKK', 'GBP', 'HUF', 'NOK', 'PLN', 'RON', 'SEK'];

function CurrencyInput(props) {
  const {
    label,
    isRequired,
    error,
    onChange,
    amount,
    currency,
    amountName,
    currencyName,
    noErrorTranslation,
    t,
  } = props;

  const onChangeHandler = (e) => {
    onChange({ [e.target.name]: e.target.value });
  };

  const errorText = noErrorTranslation ? error : t(error);

  return (
    <InputContainer label={label} labelIsRequired={isRequired} errorWithTranslation={errorText}>
      <div styleName="container">
        <input
          styleName={classNames('input', { error: !!error })}
          placeholder="0.0"
          value={amount || ''}
          name={amountName}
          onChange={onChangeHandler}
        />
        <div styleName="select-wrapper">
          <select styleName="select" value={currency} name={currencyName} onChange={onChangeHandler}>
            {currencies.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <div styleName="arrow-element">
            <SelectArrowsIcon />
          </div>
        </div>
      </div>
    </InputContainer>
  );
}

export default React.memo(withTranslation()(CurrencyInput));
