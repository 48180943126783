import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

function useDispatchCallback(callback) {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => dispatch(callback(...args)),
    [dispatch]
  );
}

export default useDispatchCallback;