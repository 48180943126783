import React from 'react';
import PropTypes from 'prop-types';

import SidePanelContentSection from '../../common2/side-panel/SidePanelContentSection';
import { formatDate } from '../../parcel-finder/cards/dateHelper';

function DeclinedDateSection(props) {
  return (
    <SidePanelContentSection heading="Declined date">{formatDate(props.rejectedAt) || 'N/A'}</SidePanelContentSection>
  );
}

DeclinedDateSection.propTypes = {
  rejectedAt: PropTypes.string,
};

export default React.memo(DeclinedDateSection);
