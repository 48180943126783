import React from 'react';
import SettingsGroupCard from '../SettingsGroupCard';
import PropTypes from 'prop-types';
import ImageSettings from './common/ImageSettings';
import URLInput from '../../common/URLInput';

class BannerWidgetSettings extends React.PureComponent {
  onImageChange = ({imageUrl, mobileImageUrl}) => {
    this.props.onSettingsChange({ imageUrl, mobileImageUrl });
  };

  onLinkChange = (e) => {
    this.props.onSettingsChange({ link: e.target.value });
  };

  render() {
    const {
      settings: {
        imageUrl,
        mobileImageUrl,
        link,
      },
    } = this.props;

    return (
      <SettingsGroupCard title="Banner image">
        <ImageSettings
          imageUrl={imageUrl}
          mobileImageUrl={mobileImageUrl}
          label="Banner image URL"
          mobileLabel="Mobile banner image URL"
          onChange={this.onImageChange}
        />
        <URLInput
          label="Banner Link Url"
          value={link}
          placeholder="https://linktoyourstore.com"
          onChange={this.onLinkChange}
        />
      </SettingsGroupCard>
    );
  }
}

BannerWidgetSettings.defaultProps = {
  onSettingsChange: () => {},
};

BannerWidgetSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingsChange: PropTypes.func,
};

export default BannerWidgetSettings;
