import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import Locale from '../../Translate/Locale';

import './BulletedListItem.scss';

function BulletedListItem(props) {
  return (
    <Text as="li" styleName="item">
      <Locale>{props.children}</Locale>
    </Text>
  );
}

BulletedListItem.propTypes = {
  children: PropTypes.string,
};

export default React.memo(BulletedListItem);