import React from 'react';
import PropTypes from 'prop-types';

import Heading3 from '../../common/Heading3';
import Badge from '../../common/Badge';
import BooleanBadge from '../BooleanBadge';
import EventStatusBadge from '../EventStatusBadge';
import DetailsCard from '../DetailsCard';
import DetailsCardItem from '../DetailsCard/DetailsCardItem';
import RecipientDetailsCard from './RecipientDetailsCard';
import ExternalLink from '../../common2/links/ExternalLink';
import InternalLink from '../../common2/links/InternalLink';
import ClaimStatusBadge from '../../parcel-finder/ClaimStatusBadge/ClaimStatusBadge';
import { shipmentTagsToString, getIntentForOrderState, formatDate } from '../parcelFinderHelper';
import meaningEnum from '../../ShopContentLibraryPage/meaningEnum';

import './ShipmentDetails.scss';

function ShipmentDetails(props) {
  const {
    referenceNumber,
    creationDate,
    isReturnShipment,
    tags,
    comment,
    trackingCode,
    carrierService,
    carrierTrackingUrl,
    pickupPointSelected,
    plannedPickupDate,
    isTrackable,
    weight,
    carrier,
    customer,
    warehouse,
    warehouseAddress,
    order,
    claimStatus,
  } = props;

  return (
    <div styleName="container">
      <Heading3 styleName="heading">Shipment details</Heading3>
      <DetailsCard title="SHIPMENT_DETAILS_BASIC_INFO">
        <DetailsCardItem label="Reference number">{referenceNumber}</DetailsCardItem>
        <DetailsCardItem label="Creation date">{formatDate(creationDate)}</DetailsCardItem>
        <DetailsCardItem label="Return shipment">
          <BooleanBadge value={isReturnShipment} />
        </DetailsCardItem>
        {claimStatus && (
          <DetailsCardItem label="Claim status">
            <ClaimStatusBadge status={claimStatus} />
          </DetailsCardItem>
        )}
        <DetailsCardItem label="Tags">{tags && shipmentTagsToString(tags)}</DetailsCardItem>
        <DetailsCardItem label="Comment">{comment}</DetailsCardItem>
      </DetailsCard>
      <DetailsCard title="SHIPMENT_DETAILS_CARRIER">
        {carrier.logoUrl && <img styleName="carrier-logo" src={carrier.logoUrl} />}
        <DetailsCardItem label="Tracking code">
          {trackingCode &&
            (carrierTrackingUrl ? <ExternalLink to={carrierTrackingUrl}>{trackingCode}</ExternalLink> : trackingCode)}
        </DetailsCardItem>
        <DetailsCardItem label="Country">{carrier.countryName}</DetailsCardItem>
        <DetailsCardItem label="Service">
          <span styleName="carrier-service">{carrierService}</span>
        </DetailsCardItem>
      </DetailsCard>
      <RecipientDetailsCard title="SHIPMENT_DETAILS_CUSTOMER_INFO" recipient={customer}>
        <DetailsCardItem label="Pickup point selected">
          <BooleanBadge value={pickupPointSelected} />
        </DetailsCardItem>
      </RecipientDetailsCard>
      {isReturnShipment && (
        <RecipientDetailsCard title="SHIPMENT_OUTBOUND_RECIPIENT_INFO" recipient={order.recipient} />
      )}
      <DetailsCard title="SHIPMENT_DETAILS_WAREHOUSE">
        <DetailsCardItem label={warehouse.name}>{warehouseAddress}</DetailsCardItem>
        <DetailsCardItem label="Planned pickup date">{formatDate(plannedPickupDate)}</DetailsCardItem>
      </DetailsCard>
      <DetailsCard title="SHIPMENT_DETAILS_PARCEL_INFO">
        <DetailsCardItem label="Trackable">
          <BooleanBadge value={isTrackable} />
        </DetailsCardItem>
        <DetailsCardItem label="Weight">{weight}</DetailsCardItem>
      </DetailsCard>
      <DetailsCard title="SHIPMENT_DETAILS_ORDER">
        <DetailsCardItem label="Shipment order ID">
          {order.id && <InternalLink to={`/parcel-finder/orders/${order.id}`}>{order.shopOrderId}</InternalLink>}
        </DetailsCardItem>
        <DetailsCardItem label="Status">
          {order.state && (
            <div styleName="order-state">
              <Badge intent={getIntentForOrderState(order.state)}>{order.state}</Badge>
            </div>
          )}
        </DetailsCardItem>
        <DetailsCardItem label="Last event">
          {order.lastEvent && <EventStatusBadge {...order.lastEvent} />}
        </DetailsCardItem>
      </DetailsCard>
    </div>
  );
}

const recipientPropTypesShape = PropTypes.shape({
  name: PropTypes.string,
  zipCode: PropTypes.string,
  countryName: PropTypes.string,
  countryCode: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
});

ShipmentDetails.propTypes = {
  id: PropTypes.number,
  referenceNumber: PropTypes.string,
  creationDate: PropTypes.string,
  isReturnShipment: PropTypes.bool,
  comment: PropTypes.string,
  trackingCode: PropTypes.string,
  carrierService: PropTypes.oneOf(['standard', 'express', 'other']),
  carrierTrackingUrl: PropTypes.string,
  pickupPointSelected: PropTypes.bool,
  plannedPickupDate: PropTypes.string,
  isTrackable: PropTypes.bool,
  weight: PropTypes.number,
  lastEvent: PropTypes.shape({
    type: PropTypes.string,
    meaning: PropTypes.oneOf(Object.values(meaningEnum)),
  }),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  carrier: PropTypes.shape({
    name: PropTypes.string,
    countryName: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  claimStatus: PropTypes.string,
  customer: recipientPropTypesShape,
  warehouse: PropTypes.shape({
    name: PropTypes.string,
  }),
  warehouseAddress: PropTypes.string,
  order: PropTypes.shape({
    id: PropTypes.number,
    shopOrderId: PropTypes.string,
    status: PropTypes.string,
    lastEvent: PropTypes.shape({
      type: PropTypes.string,
      meaning: PropTypes.oneOf(Object.values(meaningEnum)),
    }),
    recipient: recipientPropTypesShape,
  }),
};

export default React.memo(ShipmentDetails);
