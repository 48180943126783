import { useState, useCallback, useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import container from '../../container';
import { useSelectedShopId } from '../../hooks/useSelectedShop';

function useClaimsCenterOverview() {
  const limit = 25;
  const shopId = useSelectedShopId();

  const [statuses, setStatuses] = useState([]);
  const [types, setTypes] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortDir, setSortDir] = useState('DESC');

  const queryKey = ['claims', shopId, statuses, types, shipmentTypes, sortBy, sortDir];

  /**
   * @type {import('@tanstack/react-query').UseInfiniteQueryResult<GetClaimsResponse>}
   */
  const { data, error, fetchNextPage, isInitialLoading, isFetchingNextPage, refetch } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) =>
      container.touchpointsApi.getClaimsData({
        shopId,
        limit,
        offset: pageParam,
        status: statuses,
        type: types,
        isNon7s: shipmentTypes.length === 1 ? shipmentTypes.includes('non-7s') : undefined,
        sortBy,
        sortDir,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextOffset = lastPage.offset + limit;
      return nextOffset < lastPage.total ? nextOffset : undefined;
    },
  });

  const applyFilter = useCallback(
    ({ statuses, types, shipmentTypes }) => {
      setStatuses(statuses);
      setTypes(types);
      setShipmentTypes(shipmentTypes);
    },
    [setStatuses, setTypes]
  );

  const clearFilter = useCallback(() => {
    setTypes([]);
    setStatuses([]);
    setShipmentTypes([]);
  }, [setTypes, setStatuses]);

  const setSortOptions = useCallback(
    ({ sortBy, sortDir }) => {
      setSortBy(sortBy);
      setSortDir(sortDir);
    },
    [setSortBy, setSortDir]
  );

  const queryClient = useQueryClient();

  const setQueryToFirstPage = useCallback(() => {
    // Keeping an old behaviour when on reload or mount we reset data to the first page
    // We don't want react-query to refetch all cached pages thereby unnecessarily increasing API load
    queryClient.setQueryData(queryKey, (existingData) => {
      return existingData ? { pageParams: [existingData.pageParams[0]], pages: [existingData.pages[0]] } : undefined;
    });
    queryClient.invalidateQueries(queryKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...queryKey, queryClient]);

  useEffect(() => {
    setQueryToFirstPage();
  }, [setQueryToFirstPage]);

  const reloadClaims = useCallback(async () => {
    await setQueryToFirstPage();
    await refetch();
  }, [setQueryToFirstPage, refetch]);

  const claims = data?.pages.flatMap((page) => page.claims) || [];
  const totalClaimsCount = data?.pages[0]?.total || 0;

  return {
    claims,
    isLoading: isInitialLoading,
    isLoadingMore: isFetchingNextPage,
    error,
    totalClaimsCount,
    statuses,
    shipmentTypes,
    types,
    sortBy,
    sortDir,
    setSortOptions,
    applyFilter,
    clearFilter,
    loadMore: fetchNextPage,
    reloadClaims,
  };
}

export default useClaimsCenterOverview;
