import React from 'react';
import PropTypes from 'prop-types';

import usePermissionAccess from '../../hooks/usePermissionAccess';
import OrderCard from './cards/OrderCard';
import ShipmentCard from './cards/ShipmentCard';
import { ADHOC_RETURNS_LABELS, ORDER_DETAILS, SHIPMENT_DETAILS } from '../../firewall/permissions';

import './ParcelFinderResults.scss';

function ParcelFinderResults(props) {
  const hasAdhocReturnsLabelAccess = usePermissionAccess(ADHOC_RETURNS_LABELS);
  const hasOrderDetailsAccess = usePermissionAccess(ORDER_DETAILS);
  const hasShipmentDetailsAccess = usePermissionAccess(SHIPMENT_DETAILS);
  const { orders = [], onClaimShipment, onCreateReturn } = props;

  return (
    <div styleName="results">
      {orders.map((order) => (
        <React.Fragment key={order.id}>
          <div styleName="order">
            <OrderCard
              {...order}
              onCreateReturn={() => onCreateReturn(order)}
              hasAdhocReturnsLabelAccess={hasAdhocReturnsLabelAccess}
              hasOrderDetailsAccess={hasOrderDetailsAccess}
            />
          </div>
          {order.shipments.map((shipment) => (
            <div key={shipment.id} styleName="shipment">
              <ShipmentCard
                {...shipment}
                onClaim={() => onClaimShipment(shipment)}
                hasShipmentDetailsAccess={hasShipmentDetailsAccess}
              />
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

ParcelFinderResults.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  onClaimShipment: PropTypes.func,
};

export default React.memo(ParcelFinderResults);
