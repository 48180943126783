import React from 'react';
import PropType from 'prop-types';

import PreviewTabs from '../PreviewTabs';
import EditorStatusBadge from '../EditorStatusBadge';
import { getStatusLabel } from '../EditorStatusBadge/editorStatusHelpers';

import './EditorHeader.scss';

class EditorHeader extends React.PureComponent {
  render() {
    const { status, previewMode, onPreviewModeSelect, renderButtons = () => null, headerButtons = null } = this.props;

    return (
      <header styleName="header">
        <div styleName="status">{getStatusLabel(status) && <EditorStatusBadge status={status} />}</div>
        <PreviewTabs previewMode={previewMode} onPreviewModeSelect={onPreviewModeSelect} />
        <div styleName="buttons">
          {renderButtons(status)}
          {headerButtons}
        </div>
      </header>
    );
  }
}

EditorHeader.defaultProps = {
  renderButtons: () => null,
};

EditorHeader.propTypes = {
  status: PropType.string,
  previewMode: PropType.string.isRequired,
  onPreviewModeSelect: PropType.func,
  renderButtons: PropType.func,
  headerButtons: PropType.element,
};

export default EditorHeader;
